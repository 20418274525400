import React, { useEffect, useState } from 'react';
import { Scanner } from '@yudiel/react-qr-scanner';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import Alert from '../Shared/PopUp/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import {
  clearVerifiedOrder,
  orderSelector,
  verifyOrderDetails,
} from '../../store/feature/orderSlice';
import { store_orderSelector } from '../../store/feature/store_orderSlice';
import BookingTicket from '../OrderDetails/Ticket/BookingTicket';
import SuccessPopUp from '../Shared/PopUp/Success';

const QRScanner = ({ styles, data }) => {
  const dispatch = useDispatch();
  const router = useRouter();

  //
  const { isOrderVerifying, isSuccess, isError, errorMessage, verified_order } =
    useSelector(orderSelector);

  //
  const [scanned_result, setScannedResult] = useState(null);
  const [isPaused, setIsPaused] = useState(false);
  // Alert
  const [open_alert, setOpenAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [alert_type, setAlert_type] = useState('success');
  // useEffe
  useEffect(() => {
    if (scanned_result !== null && scanned_result.rawValue !== '') {
      setIsPaused(true);
      setAlert_type('success');
      setOpenAlert(true);
      setMessage('Qr code cached successfully , wait for check the order...');
      dispatch(
        verifyOrderDetails({
          unique: scanned_result.rawValue,
        })
      );
    } else if (scanned_result !== null && scanned_result.rawValue == '') {
      setOpenAlert(true);
      setAlert_type('error');
      setMessage('Qr code cant catch , scan again properly');
    }
  }, [scanned_result]);

  //
  useEffect(() => {
    if (!isOrderVerifying && isError && errorMessage) {
      setOpenAlert(true);
      setAlert_type('error');
      setMessage(errorMessage);
    }
  }, [isError]);

  //
  const ticket_list =
    verified_order?.order?.order_details?.[0]?.order_detail_item_attributes;

  return (
    <div style={get_dynamic_styles(styles?.main_styles)}>
      {/* Alert */}
      <Alert
        className={` fixed z-50 top-0 left-0 right-0  w-full  xs:w-[500px] mx-auto mt-5 `}
        type={alert_type}
        open={open_alert}
        setOpen={setOpenAlert}
        isOpenFunction={undefined}
      >
        <p>{message}</p>
      </Alert>
      <div
        className="flex items-center   "
        style={get_dynamic_styles(styles?.scanner_styles)}
      >
        <Scanner
          onScan={(result) => {
            setScannedResult(result[0]);
          }}
          paused={isPaused || isOrderVerifying}
        />
      </div>

      {/* scan again button */}
      {isPaused && (
        <div className=" flex items-center my-2">
          <button
            className="btn-primary-outline mx-auto"
            onClick={() => {
              setIsPaused(false);
              dispatch(clearVerifiedOrder());
              setScannedResult(null);
            }}
          >
            Scan Again
          </button>
        </div>
      )}

      {/* order verified */}

      {data?.show_success_message && verified_order !== null && (
        <p style={get_dynamic_styles(styles?.success_text_styles)}>
          {data?.verified_message}{' '}
        </p>
      )}

      {data?.show_ticket && (
        <div
          className=" space-y-4"
          style={get_dynamic_styles(styles?.ticket_block_styles)}
        >
          {ticket_list?.map((ticket) => {
            return (
              <BookingTicket
                order_details={verified_order?.order}
                order_item={verified_order?.order?.order_details?.[0]}
                ticket_attribute={ticket}
                isShowButtons={false}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default QRScanner;
