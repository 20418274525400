/* eslint-disable react/prop-types */
import { useState } from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import Image from 'next/image';
import favorite from '../../../assets/Images/Home/favourite@3x.png';
import heartIcon from '../../../assets/Images/Home/heartIcon@3x.png';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';

const DynamicImagePart2 = ({
  images,
  listing_details,
  like,
  showThumbnails = true,
  styles,
}) => {
  const window_width = typeof window !== 'undefined' && window?.innerWidth;

  return (
    <div className=" w-[100%]" style={get_dynamic_styles(styles?.main_styles)}>
      {images ? (
        <>
          <ImageGallery
            items={images.map((img) => {
              return {
                original: img,
                thumbnail: img,
                originalHeight: '100%',
                originalWidth: '100%',
                thumbnailHeight: '55px',
                thumbnailWidth: '55px',
                thumbnailClass:
                  ' active:border-primary rounded-image overflow-hidden',
                originalClass: 'rounded-image shadow overflow-hidden',
              };
            })}
            showThumbnails={showThumbnails}
            showNav={false}
            autoPlay={true}
            thumbnailPosition={
              showThumbnails
                ? window_width > 1000
                  ? 'left'
                  : 'bottom'
                : 'bottom'
            }
            showBullets={images?.length > 1 ? true : false}
            showPlayButton={false}
          />
          {like && (
            <div className=" absolute top-0 right-0 mt-5 mr-5 shadow-md">
              <button
                className=" h-[48px] w-[48px] relative cursor-pointer "
                onClick={() => like(listing_details.id, listing_details.liked)}
              >
                {listing_details.liked ? (
                  <Image
                    src={favorite}
                    alt="follow button"
                    layout="fill"
                    objectFit="cover"
                  />
                ) : (
                  <Image
                    src={heartIcon}
                    alt="follow button"
                    layout="fill"
                    objectFit="cover"
                  />
                )}
              </button>
            </div>
          )}
        </>
      ) : (
        <div className="w-full h-[300px] bg-[#3B3269] bg-opacity-[10%] animate-pulse "></div>
      )}
    </div>
  );
};

export default DynamicImagePart2;
