/* eslint-disable react/prop-types */
import React from 'react';
import { useRouter } from 'next/dist/client/router';
import ListTypeAccountCard from './AccountCardSections/ListTypeAccountCard';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import FlexTypeAccountCard from './AccountCardSections/FlexTypeAccountCard';
import FlexRightTypeAccountCard from './AccountCardSections/FlexRightTypeAccountCard';

const DynamicAccountCard = ({
  item,
  follow,
  follow_title,
  following_title,
  embed,
}) => {
  const router = useRouter();
  const account_card_styles = TYPE_CONSTANT.STYLES_CONFIGS?.account_card ?? {};

  return (
    <>
      {account_card_styles?.card_type?.type === 'list_card' ? (
        <ListTypeAccountCard
          item={item}
          follow={follow}
          follow_title={follow_title}
          following_title={following_title}
          embed={embed}
        />
      ) : account_card_styles?.card_type?.type === 'flex_card_left_image' ? (
        <FlexTypeAccountCard
          item={item}
          follow={follow}
          follow_title={follow_title}
          following_title={following_title}
          embed={embed}
        />
      ) : account_card_styles?.card_type?.type === 'flex_card_right_image' ? (
        <FlexRightTypeAccountCard
          item={item}
          follow={follow}
          follow_title={follow_title}
          following_title={following_title}
          embed={embed}
        />
      ) : (
        <ListTypeAccountCard
          item={item}
          follow={follow}
          follow_title={follow_title}
          following_title={following_title}
          embed={embed}
        />
      )}
    </>
  );
};

export default DynamicAccountCard;
