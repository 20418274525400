export function replaceMultipleStrings(
  inputStr: string,
  replacements: Record<string, string>
): string {
  for (const oldStr in replacements) {
    if (replacements.hasOwnProperty(oldStr)) {
      const newStr: string = replacements[oldStr];
      const regex = new RegExp(oldStr, 'g');
      inputStr = inputStr.replace(regex, newStr);
    }
  }
  return inputStr;
}
