import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  filterSelector,
  getAttributesForFilter,
} from '../../store/feature/filter/filterSlice';
import { get_data_by_current_language } from '../../utils/GetDataByLanguage';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { useRouter } from 'next/router';
import Address_search from '../Shared/Address_search';
import DynamicAddressSearch from '../Shared/DynamicAddressSearch';

const ListingLocationFilter = ({ filer_item, styles }) => {
  const { filter_type, title, translated_data, attribute_id, is_show_title } =
    filer_item;
  const router = useRouter();
  const dispatch = useDispatch();
  const { attributes, filter_params } = useSelector(filterSelector);
  const [selectedLocation, setSelectedLocation] = useState(null);

  useEffect(() => {
    if (attributes === null) {
      dispatch(getAttributesForFilter({}));
    }
  }, [0]);

  const filter_attribute = attributes?.find((item) => item.id == attribute_id);

  return (
    <div style={get_dynamic_styles(styles?.filter_component_style)}>
      {title && is_show_title && (
        <p style={get_dynamic_styles(styles?.filter_title_style)}>
          {get_data_by_current_language({
            data_list: translated_data,
            key_name: 'title',
            default_data: title,
          })}
        </p>
      )}
      <DynamicAddressSearch
        styles={styles}
        placeholder={filter_attribute?.name}
        icon_image={filter_attribute?.icon_path}
        setCoordinates={setSelectedLocation}
        coordinates={selectedLocation}
      />
    </div>
  );
};

export default ListingLocationFilter;
