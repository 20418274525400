import React from 'react';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';
import { get_data_by_current_language } from '../../../utils/GetDataByLanguage';

const AnonymousDonation = ({
  isDonateAnonymously,
  data,
  setIsDonateAnonymously,
  styles,
}) => {
  return (
    <div className="" style={get_dynamic_styles(styles?.main_styles)}>
      <p
        className="flex  "
        style={get_dynamic_styles(styles?.checkbox_line_style)}
        key={Math.random()}
      >
        <span className=" ">
          {get_data_by_current_language({
            data_list: data?.translated_data,
            key_name: 'title',
            default_data: data?.title,
          })}
        </span>
        <input
          className=" form-check-radio  h-3 w-3 border border-gray-300   bg-white checked:bg-primary checked:border-primary focus:outline-none focus:ring-primary checked:hover:bg-primary  checked:focus:ring-primary checked:focus:bg-primary  transition duration-200   align-top bg-no-repeat bg-center bg-contain   mr-2 cursor-pointer "
          type="checkbox"
          onClick={(e) => {
            e.stopPropagation();
            setIsDonateAnonymously(!isDonateAnonymously);
          }}
          checked={isDonateAnonymously}
        />
      </p>
    </div>
  );
};

export default AnonymousDonation;
