import React from 'react';
import AddImageForm from './AddImageForm';
import { image_icon } from '../../Constant/Icons/ComoserIcons';
import { useAppDispatch } from '../../../../hooks/Redux';
import { onOpenSidebar } from '../../../../store/feature/SidebarSlice';

const AddImageButton = ({ editor }) => {
  const dispatch = useAppDispatch();

  const onClickButton = (e) => {
    e.stopPropagation();

    dispatch(
      onOpenSidebar({
        sidebar_id: 'add-image-sidepan',
        modal_section_styles:
          'fixed left-0 right-0 top-0 bottom-0 mx-auto my-auto   z-[60]  block flex items-center  justify-center  transform      rounded-card  overflow-hidden ',
        modal_inside_section_styles:
          ' max-w-xs w-full   flex items-center justify-center   ',
        is_close_by_outer_click: true,

        sidebar_inside_component: <AddImageForm editor={editor} />,
      })
    );
  };
  return (
    <button
      className="    py-2 px-2   justify-between   text-base font-medium rounded-sm   ',
        'bg-transparent text-primary hover:bg-gray-50  "
      onClick={(e) => {
        onClickButton(e);
      }}
    >
      {image_icon}
    </button>
  );
};

export default AddImageButton;
