/* eslint-disable react/prop-types */
import React from 'react';
import { get_dynamic_styles_with_default_value } from '../../../constant/DynamicStylesWithDefaultValue';
import {
  CardBoxDefaultStyles,
  CardMainDefaultStyles,
} from '../../../constant/default_styles/ProductDetailsStyles';

const TitleBox = ({ listing_details, styles, selectedVariant }) => {
  return listing_details !== null ? (
    <div
      className=" w-full   "
      style={get_dynamic_styles_with_default_value(
        styles?.main_styles,
        CardMainDefaultStyles
      )}
    >
      <div
        style={get_dynamic_styles_with_default_value(
          styles?.box_styles,
          CardBoxDefaultStyles
        )}
      >
        <h1 className="  title-h1">{listing_details?.title}</h1>
      </div>
    </div>
  ) : (
    <div className=" min-h-min border bg-[#3B3269] bg-opacity-[10%] shadow rounded-card p-4   w-full mx-auto">
      <div className="animate-pulse flex space-x-4">
        <div className="flex-1 space-y-4 py-1">
          <div className="h-4 bg-[#3B3269] bg-opacity-[20%] rounded-card  w-3/4"></div>
          <div className="space-y-2">
            <div className="h-4 bg-[#3B3269] bg-opacity-[20%] rounded"></div>
            <div className="h-4 bg-[#3B3269] bg-opacity-[20%] rounded-card  w-5/6"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TitleBox;
