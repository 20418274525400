import React, { memo } from 'react';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';

const CustomImage = ({ data, styles }) => {
  return (
    <div className="  " style={get_dynamic_styles(styles?.main_styles)}>
      <div style={get_dynamic_styles(styles?.box_styles)}>
        <img
          style={get_dynamic_styles(styles?.image_styles)}
          src={data.image_src}
          alt="image"
        />
      </div>
    </div>
  );
};

export default memo(CustomImage);
