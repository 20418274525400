import React from 'react';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { get_data_by_current_language } from '../../utils/GetDataByLanguage';
import Link from 'next/link';
import { dynamic_text_convert } from '../../utils/dynamicTextConvert';

const CardBlockTopImageWithButton = ({ data, styles }) => {
  return (
    <div style={get_dynamic_styles(styles?.main_styles)}>
      <div
        style={{
          display: styles?.box_styles?.display,
          gridTemplateColumns: dynamic_text_convert({
            main_text: styles?.box_styles?.columns_box_percentage,
            check_by: '-1%',
            value: '',
          }),
          ...get_dynamic_styles(styles?.box_styles),
        }}
      >
        {data?.cards?.map((item) => {
          return (
            <div style={get_dynamic_styles(styles?.card_styles)}>
              {/* image */}
              {item?.image && (
                <img
                  style={get_dynamic_styles(styles?.image)}
                  src={item?.image}
                  alt="image"
                />
              )}
              {/* Title */}
              {item?.title && (
                <p style={get_dynamic_styles(styles?.title)}>
                  {get_data_by_current_language({
                    data_list: item?.translated_data,
                    key_name: 'title',
                    default_data: item?.title,
                  })}
                </p>
              )}
              {/* Desc */}
              {item?.desc && (
                <p style={get_dynamic_styles(styles?.desc)}>
                  {get_data_by_current_language({
                    data_list: item?.translated_data,
                    key_name: 'desc',
                    default_data: item?.desc,
                  })}
                </p>
              )}
              {/* Desc */}
              {item?.button_title && item?.url && (
                <Link href={item?.url ?? '#'}>
                  <a
                    type="button"
                    target={
                      data?.button_target === '_blank' ? '_blank' : '_self'
                    }
                    style={{
                      display: styles?.button_styles?.display,
                      gridTemplateColumns: dynamic_text_convert({
                        main_text:
                          styles?.button_styles?.columns_box_percentage,
                        check_by: '-1%',
                        value: '',
                      }),
                      ...get_dynamic_styles(styles?.button_styles),
                    }}
                  >
                    {item?.button_title && (
                      <p
                        className="text-lg font-semibold text-gray-700"
                        style={get_dynamic_styles(styles?.button_text_styles)}
                      >
                        {get_data_by_current_language({
                          data_list: item?.translated_data,
                          key_name: 'button_title',
                          default_data: item?.button_title,
                        })}
                      </p>
                    )}

                    {item?.button_icon && (
                      <img
                        className="w-auto object-cover  "
                        style={get_dynamic_styles(styles?.button_icon_styles)}
                        src={item?.button_icon}
                        alt="image"
                      />
                    )}
                  </a>
                </Link>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CardBlockTopImageWithButton;
