import { useRouter } from 'next/router';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { cartSelector } from '../../../../store/feature/cartSlice';
import { changeDateFormat } from '../../../Shared/Constant/Constant';
import { listingSelector } from '../../../../store/feature/listingSlice';
import {
  buyerNegotiationsList,
  negotiateSelector,
  sellerNegotiationsList,
  updateNegotiate,
} from '../../../../store/feature/negotiate/negotiateSlice';
import { loading_icon } from '../../../Shared/Constant/Icons/AllIcons';
import { Listing_text } from '../../../../constant/Translation/Listing';

const BuyerNegotiateCards = ({ buyer_negotiation_details, add_to_Cart }) => {
  const router = useRouter();
  const dispatch = useDispatch();

  // updateStatus
  const updateStatus = (status) => {
    dispatch(
      updateNegotiate({
        negotiation_id: buyer_negotiation_details?.id,
        listing_id: listing_details.id,
        updated_data: {
          negotiation: {
            status: status,
          },
        },
      })
    ).then((res) => {
      dispatch(
        buyerNegotiationsList({
          listing_id: listing_details?.id,
        })
      );
    });
  };

  // data from state (redux)
  const { isFetching: isCartFetching } = useSelector(cartSelector);
  const { isFetching: isNegotiateFetching } = useSelector(negotiateSelector);
  const { listing_details } = useSelector(listingSelector);

  // updateExpiration
  function updateExpiration(expirationDate) {
    const expiration = moment(expirationDate);
    const remainingTime = expiration.fromNow();

    if (
      listing_details?.in_cart === true &&
      !buyer_negotiation_details?.expired
    ) {
      return 'Listing added in cart';
    } else if (
      expiration.isBefore(moment()) &&
      listing_details?.in_cart !== true
    ) {
      return 'Time has expired';
    } else {
      return `Expire ${remainingTime}.`;
    }
  }

  // Is Time expired
  function isTimeExpired(expirationDate) {
    const expiration = moment(expirationDate);

    if (expiration.isBefore(moment()) && listing_details?.in_cart !== true) {
      return true;
    }
    return false;
  }

  return (
    <div className="flex flex-col gap-4 min-h-[100px] p-4  ">
      <div className="  self-end  w-3/5 h-auto p-3   bg-primary_light flex flex-col gap-1 text-end ">
        <p className="text lg font-bold text-primary">You made an offer</p>
        <h1 className="title-h1 leading-none">
          {buyer_negotiation_details?.price?.formatted}
        </h1>
        <p className="description-caption">
          {changeDateFormat(
            buyer_negotiation_details?.created_at,
            'hh:mm DD-MM-YYYY'
          )}
        </p>

        {buyer_negotiation_details?.status === 1 && (
          <button
            className={[
              ' max-w-[200px] w-full  btn-primary-fill  gap-2 self-end ',
              buyer_negotiation_details?.status !== 1 && 'cursor-not-allowed',
            ].join(' ')}
            onClick={() => updateStatus(4)}
            disabled={buyer_negotiation_details?.status !== 1}
          >
            Close offer
            {isNegotiateFetching && loading_icon}
          </button>
        )}
      </div>

      {/* 2nd */}
      {buyer_negotiation_details?.status === 2 && (
        <div className="  self-start  w-3/5 h-auto p-3   bg-primary_light flex flex-col gap-1 text-start ">
          <p className="text lg font-bold text-primary">Accepted offer</p>
          <h1 className="title-h1 leading-none">
            {buyer_negotiation_details?.price?.formatted}
          </h1>
          <p className="description-caption">
            {changeDateFormat(
              buyer_negotiation_details?.updated_at,
              'hh:mm DD-MM-YYYY'
            )}
          </p>
        </div>
      )}

      {/* 3rd step  */}
      {buyer_negotiation_details?.status === 2 && (
        <div className="  self-start  w-3/5 h-auto p-3   bg-primary_light flex flex-col gap-1 text-start">
          <p className="text lg font-bold text-primary ">
            {updateExpiration(
              changeDateFormat(buyer_negotiation_details?.expiry, 'YYYY-MM-DD')
            )}
          </p>

          <div>
            <button
              className={[
                ' max-w-[200px] w-full  btn-primary-fill  gap-2 ',
                (listing_details?.in_cart ||
                  buyer_negotiation_details?.expired) &&
                  'cursor-not-allowed',
              ].join(' ')}
              onClick={() => add_to_Cart()}
              disabled={
                listing_details?.in_cart || buyer_negotiation_details?.expired
              }
            >
              Buy at {buyer_negotiation_details?.price?.formatted}
              {isCartFetching && loading_icon}
            </button>
          </div>
          {
            <p className="description-caption">
              {changeDateFormat(
                buyer_negotiation_details?.updated_at,
                'hh:mm DD-MM-YYYY'
              )}
            </p>
          }
        </div>
      )}

      {/* 4th step */}
      {buyer_negotiation_details?.status === 2 &&
        !buyer_negotiation_details?.expired &&
        listing_details?.in_cart == true && (
          <div className="  self-end  w-3/5 h-auto p-3   bg-primary_light flex flex-col gap-1 text-start">
            {<p className="text lg font-bold text-primary ">Added in cart</p>}
            <div>
              <button
                className=" max-w-[200px] w-full  btn-primary-fill  gap-2"
                onClick={() => router.push('/checkout')}
              >
                {Listing_text.product.go_to_cart}
              </button>
            </div>
          </div>
        )}
      {/* 5th */}
      {buyer_negotiation_details?.status === 3 && (
        <div className="  self-start  w-3/5 h-auto p-3   bg-primary_light flex flex-col gap-1 text-start ">
          <p className="text lg font-bold text-primary"> Offer rejected</p>
          <h1 className="title-h1 leading-none">
            {buyer_negotiation_details?.price?.formatted}
          </h1>
          <p className="description-caption">
            {changeDateFormat(
              buyer_negotiation_details?.updated_at,
              'hh:mm DD-MM-YYYY'
            )}
          </p>
        </div>
      )}

      {/* 6th */}
      {buyer_negotiation_details?.status === 4 && (
        <div className="  self-end  w-3/5 h-auto p-3   bg-primary_light flex flex-col gap-1 text-end ">
          <p className="text lg font-bold text-primary"> Offer closed by you</p>
          <h1 className="title-h1 leading-none">
            {buyer_negotiation_details?.price?.formatted}
          </h1>
          <p className="description-caption">
            {changeDateFormat(
              buyer_negotiation_details?.updated_at,
              'hh:mm DD-MM-YYYY'
            )}
          </p>
        </div>
      )}

      {/* <BuyerNegotiatePart
        negotiation_amount={negotiation_amount}
        setNegotiationAmount={setNegotiationAmount}
        negotiation_message={negotiation_message}
        setNegotiationMessage={setNegotiationMessage}
        currency_format_icon={currency_format_icon}
      /> */}
    </div>
  );
};

export default BuyerNegotiateCards;
