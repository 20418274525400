import React from 'react';
import { cn } from '../../../utils/classNames';

const Heading1 = ({
  children,
  styles,
}: {
  children: string;
  styles: string;
}) => {
  return (
    <h1
      className={cn(
        ' font   font-normal  font-main  text-[26px] md:text-[40px] leading-[29px] md:leading-[44px] text-center ',
        styles
      )}
    >
      {children}
    </h1>
  );
};

export default Heading1;
