import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  commissionsSelector,
  getDemandCommissions,
} from '../../../store/feature/Commissions/CommissionsSlice';
import { Checkout_text } from '../../../constant/Translation/Cart_text';
import { dynamic_translation_set } from '../../../constant/Translation/translation_set';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';
import { authSelector } from '../../../store/feature/authSlice';
import SwitchBox from '../../../UI/FormFields/Switch';

const DemandCommission = ({
  demand_commission,
  setDemandCommission,
  fetch_cart_list,
  styles,
}) => {
  const dispatch = useDispatch();
  // selector
  const { auth_key } = useSelector(authSelector);
  const { demand_commissions } = useSelector(commissionsSelector);

  // Api call base on authkey
  useEffect(() => {
    if (auth_key) {
      dispatch(
        getDemandCommissions({ bodyParam: { type: 'demand', optional: true } })
      );
    }
  }, [auth_key]);

  return (
    demand_commissions?.length > 0 && (
      <div
        className="w-full   flex flex-col gap-4"
        style={get_dynamic_styles(styles?.main_styles)}
      >
        {demand_commissions?.map((commission) => {
          let current_value =
            demand_commission?.filter(
              (demand_com) => commission.id == demand_com
            )?.length > 0
              ? true
              : false;

          return (
            <div className="flex flex-col gap-1">
              <SwitchBox
                title={commission?.title}
                component_styles={'flex flex-row items-center justify-between'}
                title_styles="text-default_gray text-sm leading-6 font-bold"
                current_value={current_value}
                tooltip={commission?.description}
                set_new_value={(vl) =>
                  setDemandCommission((prev) => [
                    ...prev.filter(
                      (old_values) => old_values !== commission.id
                    ),
                    vl && commission.id,
                  ])
                }
              />
            </div>
          );
        })}
      </div>
    )
  );
};

export default DemandCommission;
