import React, { useEffect, useState } from 'react';
import AddAmountPicker from './AddAmountPicker';
import StepsUI from '../../../UI/Steps/StepsUI';
import PrimaryButton from '../../../UI/Buttons/PrimaryButton';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import {
  WalletSelector,
  amountPayInByMangoPay,
  amountPayInByMangoPayBankWire,
  amountPayInByMangoPayCalc,
  amountPayInByPaypal,
  clearPayIN,
  clearPayINBankWire,
  clearWalletError,
  getMangoPayWalletBalance,
  getMangopayWalletTransactions,
  getWallets,
  walletSlice,
} from '../../../store/feature/wallet/walletSlice';
import Cards from '../Cards/Cards';
import { getSavedCards } from '../../../store/feature/card/cardSlice';
import SecondaryButton from '../../../UI/Buttons/SecondaryButton';
import { Wallet_text } from '../../../constant/Translation/wallet';
import { General_text } from '../../../constant/Translation/General';
import { delete_item_from_local_storage } from '../../../utils/localStorage';
import AddAmountSuccess from './AddAmountSuccess';
import PaymentMethod from './PaymentMethod';
import Alert from '../../Shared/PopUp/Alert';
import { useAppDispatch } from '../../../hooks/Redux';
import AllIcons from '../../Shared/Constant/Icons/AllIcons';
import { getCookie } from 'cookies-next';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';

const AddAmountSteps = ({ closeSidebar }) => {
  //
  const router = useRouter();
  const dispatch = useAppDispatch();
  const currency = router.query.code
    ? router.query.code
    : getCookie(`${TYPE_CONSTANT.DOMAIN}_default_currency`) ?? '';

  //
  const [step_info, setStepInfo] = useState({
    current_step: 1,
    next_step: 2,
    prev_step: 0,
  });

  //* effect at first time
  useEffect(() => {
    dispatch(
      // @ts-ignore
      getSavedCards({ currency: currency })
    );
  }, [0]);

  //
  const changeStep = ({ current_step, next_step, prev_step }) => {
    setStepInfo({
      current_step: current_step,
      next_step: next_step ?? current_step + 1,
      prev_step: prev_step ?? current_step - 1,
    });
  };

  //* selectors
  const {
    isFetching,
    isPayINLoaidng,
    isPayINSuccess,
    pay_in_calculation,
    isError,
    errorMessage,
    pay_in_redirect_url,
  } = useSelector(WalletSelector);

  //* step 1 :
  const [amount_for_add, setAddAmount] = useState<number | string>('');
  const changeAmountStep = () => {
    changeStep({
      current_step: 2,
      next_step: 3,
      prev_step: 1,
    });
  };

  //* step 2 :
  //
  const [payment_methods_list, setPaymentMethodList] = useState([
    { id: 1, value: Wallet_text?.wallet.bank_wire_transfer },
    { id: 2, value: Wallet_text?.wallet.cards },
    { id: 3, value: Wallet_text?.wallet.paypal },
  ]);

  //
  const [isShowApplePay, setIsShowApplePay] = useState(false);
  useEffect(() => {
    // @ts-ignore
    if (window?.ApplePaySession) {
      var merchantIdentifier =
        'merchant.mangopay.com.payline.43461661979437.xsportsonelive';
      // var promise =
      //   // @ts-ignore
      //   window.ApplePaySession.canMakePaymentsWithActiveCard(
      //     merchantIdentifier
      //   );
      var canMakePayments =
        // @ts-ignore
        window.ApplePaySession.canMakePayments(
          'merchant.mangopay.com.payline.43461661979437.xsportsonelive'
        );

      if (canMakePayments) {
        console.log('Browser can make payments by apple pay');
        setIsShowApplePay(true);
        setPaymentMethodList([
          ...payment_methods_list,
          { id: 4, value: Wallet_text?.wallet.apple_pay },
        ]);
      } else {
        console.log('Browser can not make payments by apple pay');
        setIsShowApplePay(false);
      }

      // promise
      //   .then(function (canMakePayments) {

      //   })
      //   .catch(function (error) {
      //     console.log('Browser can not make payments by apple pay', error);
      //     setIsShowApplePay(false);
      //   });
    } else {
      console.log('Browser can not make payments by apple pay');
      setIsShowApplePay(false);
    }
  }, []);

  const [selected_payment_method, setSelectedPaymentMethod] = useState({
    id: 1,
    value: Wallet_text?.wallet.bank_wire_transfer,
  });
  const [selected_card, setSelectedCard] = useState(null);

  // changeCardStep (By Bank wire or card)
  const changePaymentCardStep = () => {
    if (selected_payment_method?.id == 1) {
      // data
      let data: { amount: Number; currency?: string } = {
        amount: Number(amount_for_add),
      };
      if (currency) {
        data.currency = currency as string;
      }
      //
      dispatch(
        // @ts-ignore
        amountPayInByMangoPayBankWire(data)
      );
    } else if (selected_payment_method?.id == 3) {
      // data
      let data: { amount: Number; currency?: string; domain: string } = {
        amount: Number(amount_for_add),
        domain: window.location.origin,
      };
      if (currency) {
        data.currency = currency as string;
      }
      dispatch(
        // @ts-ignore
        amountPayInByPaypal(data)
      );
    } else if (selected_payment_method?.id === 4) {
      handleApplePay();
    } else {
      // data
      let data: { currency?: string; card_info: Object; window_info: Object } =
        {
          card_info: {
            amount: Number(amount_for_add),
            card_id: selected_card?.id,
          },
          window_info: {
            color_depth: window.screen.colorDepth,
            screen_height: window.innerHeight,
            screen_width: window.innerWidth,
            time_zone_offset: new Date().getTimezoneOffset(),
          },
        };
      if (currency) {
        data.currency = currency as string;
      }

      //
      dispatch(
        // @ts-ignore
        amountPayInByMangoPay(data)
      );
    }
  };

  //
  const handleApplePay = async () => {
    const request = {
      currencyCode: currency, // from wallet currency
      countryCode: 'GB', // 2 letter code from user.country
      supportedNetworks: ['visa', 'masterCard', 'amex'],
      total: {
        label: 'SXport',
        amount: amount_for_add.toString(),
      },
      merchantCapabilities: ['supports3DS'],
    };

    console.log({ request });

    try {
      // @ts-ignore
      const session = new window.ApplePaySession(3, request);
      session.onvalidatemerchant = (event) => {
        console.log('Validation URL:', event.validationURL);
        var promise = performValidation(event.validationURL); // ours = call API
        promise.then(function (merchantSession) {
          session.completeMerchantValidation(merchantSession);
        });
      };

      // our api will call here
      const performValidation = (valURL) => {
        return new Promise(function (resolve, reject) {
          var xhr = new XMLHttpRequest();
          xhr.onload = function () {
            var data = JSON.parse(this.responseText);
            resolve(data);
          };
          xhr.onerror = reject;
          xhr.open('GET', 'apple_pay_comm.php?u=' + valURL);
          xhr.send();
        });
      };

      //
      session.onpaymentauthorized = function (event) {
        var promise = sendPaymentToken(event.payment.token); // API Call
        promise.then(function (success) {
          var status;
          if (success) {
            // @ts-ignore
            status = ApplePaySession.STATUS_SUCCESS;
            //  document.getElementById('applePay').style.display = 'none';
            //  document.getElementById('success').style.display = 'block';
          } else {
            // @ts-ignore
            status = ApplePaySession.STATUS_FAILURE;
          }

          session.completePayment(status);
          // close the side pane and refresh the wallets API
        });
      };

      //
      const sendPaymentToken = (paymentToken) => {
        return new Promise(function (resolve, reject) {
          console.log(paymentToken);

          // call mango pay api
        });
      };

      session.oncancel = function (event) {
        console.log('Payment cancelled');
        // @ts-ignore
        session.completePayment(ApplePaySession.STATUS_FAILURE);
      };

      session.begin();
    } catch (error) {
      console.error('Error initializing Apple Pay:', error.message);
    }
  };

  //
  useEffect(() => {
    if (isPayINSuccess && !pay_in_redirect_url) {
      dispatch(clearPayIN());
      // dispatch(getMangoPayWalletBalance());
      dispatch(getWallets());
      dispatch(
        // @ts-ignore
        getMangopayWalletTransactions({ currency: currency })
      );
      changeStep({
        current_step: 3,
        next_step: 0,
        prev_step: 0,
      });
    } else if (isPayINSuccess && pay_in_redirect_url) {
      dispatch(clearPayIN());
      dispatch(
        // @ts-ignore
        getMangoPayWalletBalance({ currency: currency })
      );
      dispatch(
        // @ts-ignore
        getMangopayWalletTransactions({ currency: currency })
      );
      router.push(pay_in_redirect_url);
    }
  }, [isPayINSuccess]);

  //* Alert
  const [open_alert, setOpenAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [alert_type, setAlert_type] = useState('success');

  // Error handling
  useEffect(() => {
    if (isError) {
      setAlert_type('error');
      setOpenAlert(true);
      setMessage(errorMessage);
    }
  }, [isError]);

  return (
    // @ts-ignore
    <>
      {/* Alert */}
      <Alert
        className={` fixed z-50 top-0 left-0 right-0  w-full  xs:w-[500px] mx-auto mt-5 `}
        type={alert_type}
        open={open_alert}
        setOpen={setOpenAlert}
        isOpenFunction={undefined}
        funcCallOnClose={() => {
          dispatch(clearWalletError());
        }}
      >
        <p>{message}</p>
      </Alert>

      {/* close button */}
      <button
        className=" absolute right-3 md:left-3 w-10 h-10 flex items-center justify-center top-3 p-3 rounded-full shadow-md bg-white  z-20 text-primary hover:shadow-xl duration-200 "
        onClick={() => {
          dispatch(clearPayIN());
          // dispatch(getMangoPayWalletBalance());
          dispatch(getWallets());
          dispatch(
            // @ts-ignore
            getMangopayWalletTransactions({ currency: currency })
          );
          changeStep({
            current_step: 3,
            next_step: 0,
            prev_step: 0,
          });
          closeSidebar();
          changeStep({ current_step: 1, next_step: 2, prev_step: 0 });
          dispatch(clearPayINBankWire());
          setAddAmount(0);
        }}
      >
        {AllIcons.close_icon}
      </button>

      {/*  */}
      <StepsUI
        styles={{
          steps_style: 'grid grid-cols-3',
        }}
        steps={[
          { title: Wallet_text?.wallet.amount, link: undefined, step_code: 1 },
          {
            title: Wallet_text?.wallet.payment_method,
            link: undefined,
            step_code: 2,
          },
          { title: Wallet_text?.wallet.success, link: undefined, step_code: 3 },
        ]}
        steps_components={[
          {
            step_code: 1,
            component: (
              <AddAmountPicker
                key={1}
                amount={amount_for_add}
                setAmountValue={setAddAmount}
              />
            ),
          },
          {
            step_code: 2,
            component: (
              <PaymentMethod
                key={2}
                selected_amount={Number(amount_for_add)}
                selected_card={selected_card}
                setSelectedCard={setSelectedCard}
                selected_payment_method={selected_payment_method}
                setSelectedPaymentMethod={setSelectedPaymentMethod}
                payment_methods_list={payment_methods_list}
                isShowApplePay={isShowApplePay}
              />
            ),
          },
          {
            step_code: 3,
            component: (
              <AddAmountSuccess
                key={3}
                selected_payment_method={selected_payment_method}
              />
            ),
          },
        ]}
        steps_buttons={[
          // * step 1 button
          {
            button_component: (
              <div
                className=" max-w-md mt-20 w-full flex item-center justify-between"
                key={1}
              >
                <SecondaryButton
                  onClick={() => {
                    changeStep({ current_step: 1, next_step: 2, prev_step: 0 });
                    closeSidebar();
                  }}
                  className=" px-10"
                >
                  {Wallet_text?.wallet.cancel}
                </SecondaryButton>
                <PrimaryButton
                  onClick={(e) => {
                    changeAmountStep();
                  }}
                  className=" px-10"
                  isDisabled={
                    amount_for_add == '' || amount_for_add == 0 || isFetching
                  }
                >
                  {Wallet_text?.wallet.next}
                </PrimaryButton>
              </div>
            ),
            step_code: 1,
          },
          // * step 2 button
          {
            button_component: (
              <div
                className=" max-w-xl mt-20 w-full flex item-center justify-between"
                key={2}
              >
                <SecondaryButton
                  onClick={() =>
                    changeStep({ current_step: 1, next_step: 2, prev_step: 0 })
                  }
                  className=" px-10"
                >
                  {General_text.general.back}
                </SecondaryButton>
                <PrimaryButton
                  onClick={(e) => {
                    changePaymentCardStep();
                  }}
                  className=" px-10"
                  isLoading={isPayINLoaidng}
                  isDisabled={
                    isPayINLoaidng ||
                    (selected_payment_method?.id == 2 && selected_card == null)
                  }
                >
                  {`${Wallet_text.wallet.add}  ${
                    selected_payment_method?.id === 1
                      ? pay_in_calculation?.amount?.formatted
                      : pay_in_calculation?.total_amount?.formatted
                  }`}
                </PrimaryButton>
              </div>
            ),
            step_code: 2,
          },

          // * step 3 buttons
          {
            button_component: (
              <div
                className=" max-w-md mt-20 w-full flex item-center justify-end"
                key={3}
              >
                <PrimaryButton
                  onClick={() => {
                    closeSidebar();
                    changeStep({ current_step: 1, next_step: 2, prev_step: 0 });
                    dispatch(clearPayINBankWire());
                    setAddAmount(0);
                  }}
                  className=" px-10"
                >
                  {Wallet_text?.wallet.finish}
                </PrimaryButton>
              </div>
            ),
            step_code: 3,
          },
        ]}
        current_step={step_info?.current_step}
        next_step={step_info?.next_step}
        prev_step={step_info?.prev_step}
      />
    </>
  );
};

export default AddAmountSteps;
