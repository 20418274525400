/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import { useSelector } from 'react-redux';
import { configsSelector } from '../../../store/feature/configsSlice';
import * as constant from '../../Shared/Constant/TextConstant/listingDetailsConstant';
import { dynamic_translation_set } from '../../../constant/Translation/translation_set';
import { Listing_text } from '../../../constant/Translation/Listing';
import SelectDropDown from '../../Shared/SelectDropDown';
import SelectDropDown2 from '../../Shared/SelectDropDown2';
import ListVariant from './ListTypeVariant';
import DropdownTypeVariants from './DropdownTypeVariants';
import ButtonTypeVariant from './ButtonTypeVariant';
import ButtonTypeVariant2 from './ButtonTypeVariant2';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import { get_dynamic_styles_with_default_value } from '../../../constant/DynamicStylesWithDefaultValue';
import {
  CardBoxDefaultStyles,
  CardMainDefaultStyles,
} from '../../../constant/default_styles/ProductDetailsStyles';
import { get_data_by_current_language } from '../../../utils/GetDataByLanguage';
import Warning from '../../Shared/PopUp/Warning';

const DynamicVariants = ({
  variants,
  setSelectedVariant,
  selectedVariant,
  listing_details,
  styles,
  data,
}) => {
  const { MARKETPLACE_MODULES, listings_configs } =
    useSelector(configsSelector);

  const [filtered_variants, setFilteredVariants] = useState(variants);

  useEffect(() => {
    //
    if (data?.show_all_with_combinations && selectedVariant === null) {
      setFilteredVariants([]);
    } else {
      setFilteredVariants(variants);
    }
    if (data?.load_selected_combinations) {
      setFilteredVariants(
        variants?.filter((vr_list) => vr_list?.id === selectedVariant)
      );
    }
  }, [
    data?.load_selected_combinations,
    data?.show_all_with_combinations,
    selectedVariant,
    variants,
  ]);

  return (
    <div
      className="    w-full  "
      style={get_dynamic_styles_with_default_value(
        styles?.main_styles,
        CardMainDefaultStyles
      )}
    >
      <div
        className=""
        style={get_dynamic_styles_with_default_value(
          styles?.box_styles,
          CardBoxDefaultStyles
        )}
      >
        <h2 className="heading-h2">
          {data?.title
            ? get_data_by_current_language({
                data_list: data?.translated_data,
                key_name: 'title',
                default_data: data?.title,
              })
            : Listing_text.product.select_variant}
        </h2>

        {(data?.show_all_with_combinations ||
          data?.load_selected_combinations) &&
          filtered_variants?.length === 0 && (
            <Warning
              className={' max-w-sm px-2 mx-auto mt-8'}
              message={Listing_text.product.variant_not_available}
            />
          )}
        {filtered_variants?.length > 0 && (
          <div className="  mt-4   flex flex-col gap-4  ">
            {/* By default list type variant will show  */}
            {(data?.variant_type == 'list' ||
              data?.variant_type == undefined) && (
              <ListVariant
                variants={filtered_variants}
                selectedVariant={selectedVariant}
                setSelectedVariant={setSelectedVariant}
                listings_configs={listings_configs}
                listing_details={listing_details}
              />
            )}

            {data?.variant_type == 'dropdown' && (
              <DropdownTypeVariants
                variants={filtered_variants}
                selectedVariant={selectedVariant}
                setSelectedVariant={setSelectedVariant}
              />
            )}
            {data?.variant_type == 'text' && (
              <ButtonTypeVariant2
                variants={filtered_variants}
                selectedVariant={selectedVariant}
                setSelectedVariant={(item) => setSelectedVariant(item.id)}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default DynamicVariants;
