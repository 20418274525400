import React from 'react';
import Breadcrumb2 from '../../Shared/Breadcrumb2';
import { convert_category_link } from '../../../utils/convertCtLink';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';
import Link from 'next/link';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import { slug as SlugChecker } from 'github-slugger';

const ListingBreadcrumb = ({ data, styles, listing_details }) => {
  const breadcrumb_list = [
    {
      name: 'Home',
      link: data?.home_link ?? '/',
      is_hide: data?.home_link === 'hide',
    },
    {
      name: 'Categories',
      link: data?.categories_link ?? '/lc',
      is_hide: data?.categories_link === 'hide',
    },
    {
      name: listing_details?.categories[0]?.name,
      isHide: listing_details?.categories[0]?.name ? false : true,
      link: data?.category_link
        ? convert_category_link({
            link: data?.category_link,
            check_by: [
              {
                checker_text: '{category_slug}',
                replace_by: listing_details?.categories[0]?.slug,
              },
              {
                checker_text: '{category_id}',
                replace_by: listing_details?.categories[0]?.id,
              },
            ],
          })
        : `/lc/${
            listing_details?.categories[0]?.slug
              ? listing_details?.categories[0]?.slug
              : `${listing_details?.categories[0]?.id}-${SlugChecker(
                  listing_details?.categories[0]?.name
                )}`
          }`,
      is_hide: data?.category_link === 'hide',
    },
    {
      name: listing_details?.title,
      link: '',
      is_hide: data?.listing_link === 'hide',
    },
  ];

  return (
    <div
      className="flex items-center justify-between flex-wrap "
      style={get_dynamic_styles(styles?.main_styles)}
    >
      <ul
        className="inline-flex flex-wrap text-sm font-medium text-gray-500"
        style={get_dynamic_styles(styles?.link_text_styles)}
      >
        {breadcrumb_list
          ?.filter((list) => list?.is_hide == false)
          ?.map((list, index, array) => {
            return index !== Number(array?.length - 1) ? (
              <Link href={list.link} key={index}>
                <li className="flex items-center cursor-pointer">
                  <a className=" hover:text-primary" href={list.link}>
                    {list.name}
                  </a>

                  {index !== Number(array?.length - 1) && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-4 h-4 text-current mx-3"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d={
                          TYPE_CONSTANT.DIR === 'rtl'
                            ? 'M15.75 19.5L8.25 12l7.5-7.5'
                            : 'M8.25 4.5l7.5 7.5-7.5 7.5'
                        }
                      />
                    </svg>
                  )}
                </li>
              </Link>
            ) : (
              <li className="flex items-center cursor-text">
                <a className="  hover:text-primary">{list.name}</a>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default ListingBreadcrumb;
