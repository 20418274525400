import React from 'react';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';
import ReadingComposer from '../../Shared/Composer/ReadingComposer';

const AttributesCard = ({ attributes, data, styles }) => {
  return (
    <div style={get_dynamic_styles(styles?.main_styles)}>
      <div
        className="flex"
        style={{
          display: styles?.box_styles?.display
            ? styles?.box_styles?.display
            : 'flex',
          ...get_dynamic_styles(styles?.box_styles),
        }}
      >
        {data?.attribute_items?.map((item) => {
          let selected_attr = item?.attribute_id
            ? attributes?.find((it) => it.id == item?.attribute_id)
            : null;

          return (
            <div
              style={{
                display: styles?.card_style?.display
                  ? styles?.card_style?.display
                  : 'flex',
                ...get_dynamic_styles(styles?.card_style),
              }}
            >
              {selected_attr?.icon_path && (
                <img
                  style={get_dynamic_styles(styles?.icon_styles)}
                  src={selected_attr?.icon_path}
                  alt=""
                />
              )}
              {/* Title */}
              {item?.title && (
                <p style={get_dynamic_styles(styles?.title_style)}>
                  {selected_attr?.name}
                </p>
              )}

              {/*  */}
              {
                <>
                  {selected_attr?.field_type === 6 ? (
                    <ReadingComposer
                      contents={selected_attr?.values[0]}
                      isReadOnly={true}
                    />
                  ) : (
                    <p
                      style={get_dynamic_styles(styles?.value_style)}
                      className="  whitespace-pre-line  overflow-auto  scrollbar-none"
                    >
                      {selected_attr?.values
                        .map((item) => {
                          if (
                            selected_attr?.field_type === 1 ||
                            selected_attr?.field_type === 2
                          ) {
                            return item.name;
                          } else if (
                            selected_attr?.field_type === 3 ||
                            selected_attr?.field_type === 4
                          ) {
                            return item;
                          } else if (selected_attr?.field_type === 12) {
                            return `latitude:${item?.latitude}, longitude:${item?.longitude}`;
                          } else {
                            return item;
                          }
                        })
                        .join(', ')}
                    </p>
                  )}
                </>
              }
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AttributesCard;
