import { Tab } from '@headlessui/react';
import React, { useEffect, useState } from 'react';

// right_button & left_button properties
// title;
// click;
// disabled;
// extra_styles;
// tab_data
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const TabPopupUI = ({
  tab_list,
  tab_panels,
  children,
  title,
  right_button,
  left_button,
  isShowTab = true,
  selectedIndexFromOutside = 0,
  changeOutsideIndex,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  //
  useEffect(() => {
    setSelectedIndex(selectedIndexFromOutside);
  }, [selectedIndexFromOutside]);

  return (
    <div className="w-full h-full flex flex-col justify-between ">
      <Tab.Group
        as="div"
        className="w-full h-full 0 "
        selectedIndex={selectedIndex}
        onChange={(index) => {
          setSelectedIndex(index);
          changeOutsideIndex(index);
        }}
      >
        <div className=" w-full sticky top-0">
          {/* Title */}
          {title && (
            <div className="flex flex-col  px-10   py-4  borderer-b bg-gray-100   ">
              <p className="title-h1  text-center  ">{title}</p>
            </div>
          )}{' '}
          {isShowTab && (
            <Tab.List className="  relative w-full  flex items-center justify-start     border-b-2  border-[#D2D2D2]">
              {tab_list?.map((tab) => {
                return (
                  <Tab
                    as="h2"
                    className={({ selected }) =>
                      classNames(
                        '  flex-grow  m w-full px-4 rounded-lg py-2.5 text-base font-medium leading-5  ',
                        'ring-0 focus:outline-none flex flex-col md:flex-row items-center justify-center gap-1',
                        selected
                          ? 'relative  text-primary after:content-[""] after:h-1 after:w-full  after:block after:absolute after:-bottom-0.5 after:bg-primary after:rounded-card '
                          : 'text-[#4F4F4F]   '
                      )
                    }
                  >
                    {tab?.icon && <span> {tab?.icon}</span>}
                    <span> {tab.title}</span>
                  </Tab>
                );
              })}
            </Tab.List>
          )}
        </div>

        <Tab.Panels>
          {tab_panels?.map((tab_panel) => {
            return <Tab.Panel>{tab_panel}</Tab.Panel>;
          })}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default TabPopupUI;
