import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { General_text } from '../../../constant/Translation/General';
import { Listing_text } from '../../../constant/Translation/Listing';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import { authSelector } from '../../../store/feature/authSlice';
import {
  addListingQNA,
  getListingQNA,
  listingSelector,
} from '../../../store/feature/listingSlice';
import { changeDateFormat } from '../../Shared/Constant/Constant';
import ModalBasic from '../../Shared/Modal/ModalBesic';
import PopupUI from '../../Shared/PopUp/UI/PopupUI';
import { get_dynamic_styles_with_default_value } from '../../../constant/DynamicStylesWithDefaultValue';
import {
  CardBoxDefaultStyles,
  CardMainDefaultStyles,
} from '../../../constant/default_styles/ProductDetailsStyles';

const QNA_section = ({ listing_details, styles }) => {
  const [isOwner, setIsOwner] = useState();
  const [post_loading, setPostLoading] = useState(false);
  const dispatch = useDispatch();
  const router = useRouter();
  const { login } = useSelector(authSelector);

  //
  const [form_open, setForm_open] = useState(false);
  const [post_text, setPostText] = useState('');
  const [answer_form_open, setAnswerForm_open] = useState(false);
  const [selected_qna, setSelectedQna] = useState(null);

  //
  useEffect(() => {
    if (
      Cookies.get(`${TYPE_CONSTANT.DOMAIN}_isStoreAccount`) &&
      JSON.parse(Cookies.get(`${TYPE_CONSTANT.DOMAIN}_isStoreAccount`))
    ) {
      if (
        Cookies.get(`${TYPE_CONSTANT.DOMAIN}_storeAccountID`) &&
        JSON.parse(Cookies.get(`${TYPE_CONSTANT.DOMAIN}_storeAccountID`)) ==
          listing_details?.account_id
      ) {
        setIsOwner(true);
      } else {
        setIsOwner(false);
      }
    }
  }, [listing_details]);

  //
  const post_qna_answer = (e) => {
    let post_data = {
      reference_id: listing_details?.id,
      reference_type: 'listings_qa',
      message: post_text,
    };
    if (answer_form_open) {
      post_data.parent = selected_qna?.id;
      post_data.account_id = listing_details?.account_id;
    }
    dispatch(
      addListingQNA({
        params: { comment: post_data },
      })
    ).then((res) => {
      if (!res.payload.code) {
        dispatch(
          getListingQNA({
            params: {
              reference_type: 'listings_qa',
              reference_id: listing_details?.id,
              page: 1,
              per_page: 10,
              parent: 0,
            },
          })
        );
        e.stopPropagation();
        setForm_open(false);
        setAnswerForm_open(false);
        setSelectedQna(null);
        setPostText('');
      }
    });
  };

  const { qna, isFetching } = useSelector(listingSelector);
  return (
    <>
      {qna ? (
        <div
          id={'qa'}
          className="w-full  "
          style={get_dynamic_styles_with_default_value(
            styles?.main_styles,
            CardMainDefaultStyles
          )}
        >
          <div
            style={get_dynamic_styles_with_default_value(
              styles?.box_styles,
              CardBoxDefaultStyles
            )}
          >
            <h2 className="heading-h2">
              {Listing_text.product.question_title}
            </h2>

            {qna?.length > 0 && (
              <div className="mt-4">
                {qna?.map((qna) => {
                  return (
                    <div className="mb-4 flex flex-col gap-2" id={qna.id}>
                      <div className=" grid   grid-cols-4 md:grid-cols-5 ">
                        <p className=" col-span-1 description-paragraph font-bold min-w-[6em]  ">
                          {Listing_text.product.question}
                        </p>
                        <p
                          className="col-span-3 md:col-span-4 description-paragraph
 text-primary flex items-center justify-start gap-2 flex-wrap"
                        >
                          <span> {qna.message} </span>
                          {isOwner && qna?.replies?.length == 0 && (
                            <button
                              className="  px-2 py-0.5 text-white hover:text-primary bg-primary hover:bg-transparent  border border-primary rounded-button hover: shadow-sm transition duration-300"
                              onClick={(e) => {
                                e.stopPropagation();
                                setForm_open(true);
                                setAnswerForm_open(true);
                                setSelectedQna(qna);
                              }}
                            >
                              {Listing_text.product.answer_the_question}
                            </button>
                          )}
                        </p>
                      </div>

                      <div className="grid  grid-cols-4 md:grid-cols-5">
                        <p className=" col-span-1 description-paragraph font-bold min-w-[6em]  ">
                          {Listing_text.product.answer}
                        </p>
                        <div className="col-span-3 md:col-span-4  flex flex-col gap-1">
                          {qna?.replies?.length > 0 ? (
                            <div className="flex flex-col  ">
                              <p className="  description-paragraph">
                                {qna.replies[0]?.message}
                              </p>
                              <p className=" description-caption ">
                                {changeDateFormat(
                                  qna?.replies[0]?.created_at,
                                  'MMM DD, YYYY'
                                )}
                              </p>
                            </div>
                          ) : (
                            <p className="  description-paragraph ">
                              {Listing_text.product.answer_not_found}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}

            <div className="bg-gray-200 p-3 rounded-card mt-4 flex items-center justify-center md:justify-start gap-3 flex-wrap ">
              <p className="description-paragraph">
                {Listing_text.product?.ask_question_section_title}
              </p>
              <button
                className="bg-white text-sm font-normal px-2 py-1 rounded-button border border-gray-300 hover:shadow-md"
                onClick={(e) => {
                  if (login) {
                    e.stopPropagation();
                    setForm_open(true);
                  } else {
                    router.push(`/sign-in?to=${router.asPath}`);
                  }
                }}
              >
                {Listing_text.product?.ask_question_button_title}
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="border bg-[#3B3269] bg-opacity-[10%] shadow rounded-card   p-4   w-full  mx-auto overflow-hidden">
          <div className="flex  justify-between">
            <div className="h-3 bg-[#3B3269] bg-opacity-[30%] rounded-full   w-28"></div>
          </div>
          <div className="animate-pulse mt-4  ">
            <div className="w-full  min-h-min flex  items-center justify-center xs:justify-start  gap-5 flex-wrap">
              <div className="h-5 bg-[#3B3269] bg-opacity-[20%] rounded-card   w-1/6"></div>
              <div className="h-5 bg-[#3B3269] bg-opacity-[20%] rounded-card   w-4/6"></div>
            </div>
          </div>
          <div className="animate-pulse mt-4  ">
            <div className="w-full  min-h-min flex  items-center justify-center xs:justify-start  gap-5 flex-wrap">
              <div className="h-5 bg-[#3B3269] bg-opacity-[20%] rounded-card   w-1/6"></div>
              <div className="h-5 bg-[#3B3269] bg-opacity-[20%] rounded-card   w-4/6"></div>
            </div>
          </div>
        </div>
      )}

      <ModalBasic
        title="qna-section"
        id="qna-section-form"
        modalOpen={form_open}
        setModalOpen={setForm_open}
        closeByOutsideClick={false}
        close_func={() => {
          setAnswerForm_open(false);
          setSelectedQna(null);
          setPostText('');
        }}
      >
        <section className="min-h-min  ">
          <PopupUI
            title={
              answer_form_open
                ? Listing_text.product?.answer_form_title
                : Listing_text.product?.question_form_title
            }
            left_button={{
              title: General_text.general.cancel,
              click: (e) => {
                e.stopPropagation();
                setForm_open(false);
                setAnswerForm_open(false);
                setSelectedQna(null);
                setPostText('');
              },
            }}
            right_button={{
              title: General_text.general.submit,
              click: (e) => {
                post_text?.replace(/\s/g, '').length > 1 && post_qna_answer(e);
              },
              disabled: post_text?.replace(/\s/g, '').length < 1 ? true : false,
              extra_styles:
                post_text?.replace(/\s/g, '').length < 1
                  ? ' cursor-not-allowed '
                  : ' cursor-pointer',
              icon: isFetching ? (
                <svg
                  className="animate-spin   h-5 w-5  "
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                ''
              ),
            }}
          >
            <div className="px-10 py-6">
              <div>
                {answer_form_open && (
                  <p className="mb-3 ">
                    <b className="text-lg font-bold">
                      {Listing_text.product.question}:
                    </b>
                    <span className="text-base mx-1 font-semibold">
                      {selected_qna?.message}
                    </span>
                  </p>
                )}
                <textarea
                  className="w-full rounded-input border border-primary focus:border-primary "
                  name="qna-form"
                  id=""
                  cols="3"
                  rows="5"
                  value={post_text}
                  onChange={(e) => setPostText(e.target.value)}
                ></textarea>
                {!answer_form_open && (
                  <p className="mt-3 text-base font-medium">
                    {Listing_text.product?.question_form_desc}
                  </p>
                )}
              </div>
            </div>
          </PopupUI>
        </section>
      </ModalBasic>
    </>
  );
};

export default QNA_section;
