/* eslint-disable react/prop-types */
import React from 'react';
import { Listing_text } from '../../../constant/Translation/Listing';
import { get_dynamic_styles_with_default_value } from '../../../constant/DynamicStylesWithDefaultValue';
import {
  CardBoxDefaultStyles,
  CardMainDefaultStyles,
} from '../../../constant/default_styles/ProductDetailsStyles';
import ReadingComposer from '../../Shared/Composer/ReadingComposer';
import ALink from '../../Shared/Link/ALink';
import AttributeDetailsInGrid from './AttributeDetailsInGrid';

const AttributeDetails = ({ attributes, styles, data }) => {
  return (
    <div
      className="    w-full   "
      style={get_dynamic_styles_with_default_value(
        styles?.main_styles,
        CardMainDefaultStyles
      )}
    >
      <div
        style={get_dynamic_styles_with_default_value(
          styles?.box_styles,
          CardBoxDefaultStyles
        )}
      >
        <h2 className="heading-h2">{Listing_text.product.details}</h2>
        {data?.type !== 'grid' && (
          <div className="  mt-4 flex flex-col gap-2">
            {attributes?.map((attr) => {
              return (
                attr.visible &&
                attr.field_type !== 11 && (
                  <div
                    className="grid grid-cols-2 items-center  "
                    key={attr.id}
                  >
                    <span className="flex items-center justify-start gap-2 ">
                      {attr?.icon_path && (
                        <img
                          className=" w-8 h-8 "
                          src={attr?.icon_path}
                          alt=""
                        />
                      )}
                      <p className="description-paragraph">{attr.name}</p>
                    </span>

                    {/* 6 */}
                    {attr.field_type === 6 && (
                      <ReadingComposer
                        contents={attr.values[0]}
                        isReadOnly={true}
                      />
                    )}

                    {/* 1|2 */}
                    {(attr.field_type === 1 || attr.field_type === 2) && (
                      <div className=" flex items-center justify-start gap-2 flex-wrap">
                        {attr.values.map((item) => {
                          if (attr.field_type === 1 || attr.field_type === 2) {
                            return attr?.show_in_filter ? (
                              <ALink
                                href={`/l?attribute_value_id=${item?.id}`}
                                styles={{
                                  link: '  description-paragraph text-primary hover:underline duration-300',
                                }}
                              >
                                {item.name}
                              </ALink>
                            ) : (
                              <p className="overflow-auto  scrollbar-none   description-paragraph ">
                                {item.name}
                              </p>
                            );
                          }
                        })}
                      </div>
                    )}

                    {attr.field_type !== 1 &&
                      attr.field_type !== 2 &&
                      attr.field_type !== 6 && (
                        <p className="  whitespace-pre-line  description-paragraph  overflow-auto  scrollbar-none">
                          {attr.values
                            .map((item) => {
                              if (
                                attr.field_type === 3 ||
                                attr.field_type === 4
                              ) {
                                return item;
                              } else if (attr.field_type === 12) {
                                return `latitude:${item?.latitude}, longitude:${item?.longitude}`;
                              } else {
                                return item;
                              }
                            })
                            .join(', ')}
                        </p>
                      )}
                  </div>
                )
              );
            })}
          </div>
        )}

        {data?.type === 'grid' && (
          <AttributeDetailsInGrid attributes={attributes} />
        )}
      </div>
    </div>
  );
};

export default AttributeDetails;
