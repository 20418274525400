export var Address_text = {
  address: {
    house_number: 'House Number',
    city: 'City',

    shipping_address: 'Shipping Address',
    formatted_address: 'Full Address',
    type: 'Type',
    pickup_address: 'Pickup Address',

    add_address: 'Add Address',
    add_new_address: 'Add a new Address',
    add_pickup_address: 'Add Pickup Address',
    address_added_successfully: 'Address added successfully',
    address_one: 'Address 1',
    address_two: 'Address 2',
    address_updated_successfully: 'Address updated successfully',
    alert_message_add_pickup_address:
      'Pickup Address not available. Please Add Pickup Address',
    alert_message_location_fetch_failed:
      'Couldnt get location at a moment,Try again',
    city: 'City',
    country: 'Country',
    edit_address: 'Edit Address',
    edit_pickup_address: 'Edit Pickup Address',
    'field validation': 'Fill {value} before continuing',
    landmark: 'Landmark',
    location: 'Location',
    my_address: 'My Address',
    name: 'Name',
    no_address: 'No address found',
    no_address_found: 'No Address found',
    ok: 'OK',
    phone: 'Phone',
    save: 'Save',
    search_address: 'Search for address',
    state: 'State',
    street: 'Street Address',
    update_address: 'Update Address',
    use_current_location: 'Use current location',
    zipcode: 'Zipcode',
  },
  addresslist: {
    address_title: 'Address',
    no_address_found: 'No address found',
    address_not_applicable: 'Address not applicable',
    add_new_address: 'Add New Address',
  },
};
