/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/dist/client/router';
import {
  addToCart,
  cartList,
  cartSelector,
  clearCartState,
} from '../../../store/feature/cartSlice';
import { authSelector } from '../../../store/feature/authSlice';
import OutsideClickHandler from 'react-outside-click-handler';
import PopUp from '../../Shared/PopUp/PopUp';
import CustomLoading from '../../Shared/Loading/CustomLoading';
import {
  listingDetails,
  listingsBulkLike,
} from '../../../store/feature/listingSlice';
import ModalBasic from '../../Shared/Modal/ModalBesic';
import axios from 'axios';
import { General_text } from '../../../constant/Translation/General';
import { Listing_text } from '../../../constant/Translation/Listing';
import { push_data_layer } from '../../../constant/GTMDatalayer';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import PopupUI from '../../Shared/PopUp/UI/PopupUI';
import { get_dynamic_styles_with_default_value } from '../../../constant/DynamicStylesWithDefaultValue';
import { CardBoxDefaultStyles } from '../../../constant/default_styles/ProductDetailsStyles';
import Alert from '../../Shared/PopUp/Alert';

const ProductButtons = ({
  listing_details,
  selectedVariant,
  setError_message,
  setShowError,
  styles,
  data,
}) => {
  const { login } = useSelector(authSelector);
  const { isFetching, isSuccess, isError, errorMessage, cart_details } =
    useSelector(cartSelector);
  const dispatch = useDispatch();
  const router = useRouter();
  const [isBuyNow, setIsBuyNow] = useState(false);
  const [form_open, setForm_open] = useState(false);
  // Alert
  const [open_alert, setOpenAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [alert_type, setAlert_type] = useState('success');

  // add to cart
  const add_to_Cart = (_isBuyNow) => {
    if (
      TYPE_CONSTANT.LISTINGS_CONFIGS?.enable_stock &&
      listing_details?.stock == 0
    ) {
      setShowError(true);
      setError_message(Listing_text.product.out_of_stock);
      return false;
    }

    if (_isBuyNow) {
      const cartData = {
        cart: {
          listing_id: listing_details?.id,
          quantity: 1,
          uuid: TYPE_CONSTANT.UUID,
        },
      };
      if (selectedVariant !== null) {
        cartData.cart['variant_id'] = selectedVariant;
      }
      dispatch(addToCart({ data: cartData })).then((res) => {
        if (!res.payload.code) {
          push_data_layer({
            event: 'buynow',
            data: {
              name: listing_details?.title,
              slug: listing_details?.slug,
              price: listing_details?.offer_price.amount,
              stock: listing_details?.stock,
              category: {
                id: listing_details?.categories?.[0]?.id,
                name: listing_details?.categories?.[0]?.name,
              },
              image: listing_details?.images,
              url: window.location.href,
              uuid: TYPE_CONSTANT.UUID,
            },
          });
          setIsBuyNow(false);
          dispatch(cartList({}));
          router.push('/checkout');
        } else {
          if (res.payload.code === 480) {
            setForm_open(true);
          } else if (res.payload.code === 489) {
            clear_cart();
          } else {
            setShowError(true);
            setError_message(res.payload.message);
          }
        }
      });
    } else {
      const cartData = {
        cart: {
          listing_id: listing_details?.id,
          quantity: 1,
          uuid: TYPE_CONSTANT.UUID,
        },
      };
      if (selectedVariant !== null) {
        cartData.cart['variant_id'] = selectedVariant;
      }
      dispatch(addToCart({ data: cartData })).then((res) => {
        if (!res.payload.code) {
          setAlert_type('success');
          setOpenAlert(true);
          setMessage(Listing_text.product.added_successfully);
          setError_message(res.payload.message);
          push_data_layer({
            event: 'add_cart',
            data: {
              name: listing_details?.title,
              slug: listing_details?.slug,
              price: listing_details?.offer_price.amount,
              stock: listing_details?.stock,
              category: {
                id: listing_details?.categories?.[0]?.id,
                name: listing_details?.categories?.[0]?.name,
              },
              image: listing_details?.images,
              url: window.location.href,
              uuid: TYPE_CONSTANT.UUID,
            },
          });
          setIsBuyNow(false);
          dispatch(cartList({}));
          dispatch(
            listingDetails({
              ...router?.query,
            })
          );
        } else {
          if (res.payload.code === 480) {
            setForm_open(true);
          } else if (res.payload.code === 489) {
            clear_cart();
          } else {
            setShowError(true);
            setError_message(res.payload.message);
          }
        }
      });
    }
  };

  const bulk_like = () => {
    dispatch(
      listingsBulkLike({ ids: cart_details?.map((it) => it?.listing?.id) })
    ).then((resp) => {
      if (!resp?.payload?.code) {
        delete_all_carts();
      }
    });
  };

  // delete all carts
  const delete_all_carts = () => {
    axios
      .delete('/api/cart/delete_all_cart')
      .then((res) => {
        add_to_Cart(isBuyNow);
      })
      .catch((error) => {
        setForm_open(false);
        setShowError(true);
        setError_message(error?.response?.data?.message);
      });
  };

  // clear cart
  const clear_cart = () => {
    setForm_open(false);
    if (login) {
      bulk_like();
    } else {
      delete_all_carts();
    }
  };

  return (
    <>
      {isFetching && <CustomLoading />}
      {/*  */}
      <Alert
        className={` fixed z-50 top-0 left-0 right-0  w-full  xs:w-[500px] mx-auto mt-5 `}
        type={alert_type}
        open={open_alert}
        setOpen={setOpenAlert}
        isOpenFunction={undefined}
        funcCallOnClose={() => {
          setMessage('');
        }}
      >
        <p className=" text-lg">{message}</p>
      </Alert>

      {/*  */}
      {listing_details ? (
        <div
          className=" w-full  flex justify-between items-center"
          style={get_dynamic_styles_with_default_value(
            styles?.box_styles,
            CardBoxDefaultStyles
          )}
        >
          {data?.is_show_add_to_cart !== false && (
            <button
              className={[
                '   w-[45%]  h-[44px] md:h-[52px]  flex justify-center items-center border border-primary rounded-button box-border btn-primary-outline   ',
                data?.is_show_buy_now !== false ? ' w-[45%]' : 'w-full',
              ].join(' ')}
              id="buy_now"
              onClick={() => {
                setIsBuyNow(false);
                listing_details?.in_cart
                  ? router.push('/checkout')
                  : listing_details?.variants.length > 0
                  ? selectedVariant === null
                    ? (setShowError(true),
                      setError_message(Listing_text.product.variant_validation))
                    : add_to_Cart(false)
                  : add_to_Cart(false);
              }}
              type="button"
            >
              <span className=" text-base  sm:text-xl  font-semibold ltr:ml-3 rtl:mr-3">
                {listing_details?.in_cart
                  ? Listing_text.product.go_to_cart
                  : Listing_text.product.add_to_cart}
              </span>
            </button>
          )}
          {data?.is_show_buy_now !== false && (
            <button
              className={[
                '   h-[44px]  md:h-[52px]  flex justify-center items-center  btn-primary-fill ',
                data?.is_show_add_to_cart !== false ? ' w-[50%]' : 'w-full',
              ].join(' ')}
              id="add_to_cart"
              // onClick={add_to_Cart}
              onClick={() => {
                setIsBuyNow(true);
                listing_details?.variants.length > 0
                  ? selectedVariant === null
                    ? (setShowError(true),
                      setError_message(Listing_text.product.variant_validation))
                    : add_to_Cart(true)
                  : add_to_Cart(true);
              }}
              type="button"
            >
              <span className=" text-base sm:text-xl  font-semibold  ">
                {Listing_text.product.buy_now}
              </span>
            </button>
          )}
        </div>
      ) : (
        <div className="grid grid-cols-2  gap-5 md:gap-10">
          <div className=" rounded-button h-[40px] bg-[#3B3269] bg-opacity-[10%] animate-pulse"></div>
          <div className="  rounded-button h-[40px] bg-[#3B3269] bg-opacity-[10%] animate-pulse"></div>
        </div>
      )}

      <ModalBasic
        title="Multi Seller"
        id="multi-seller-serror"
        modalOpen={form_open}
        setModalOpen={setForm_open}
        closeByOutsideClick={true}
      >
        <section className="min-h-[100px] ">
          <PopupUI
            left_button={{
              title: General_text.general.cancel,
              click: (e) => {
                e.stopPropagation();
                setForm_open(false);
              },
            }}
            right_button={{
              title: Listing_text.product.clear_cart,
              click: (e) => {
                clear_cart();
              },
            }}
          >
            <div className="flex flex-col  px-10   py-8  borderer-b border-gray-400">
              <p className="text-base text-center mb-3 ">
                {Listing_text.product.clear_cart_info}
              </p>
            </div>
          </PopupUI>
        </section>
      </ModalBasic>
    </>
  );
};

export default ProductButtons;
