import PopOver from '../PopOver/PopOver';
import { cn } from '../../utils/classNames';
import { TSICONS } from '../../constant/Icons/TSICONS';
import { SelectFromListType } from '../../types/FormFieldTypes';

const SelectFromList = ({
  title,
  current_value,
  set_new_value,
  note,
  drop_down_items,
  default_option_text,
  is_disabled,
  styles,
  title_icon,
}: SelectFromListType) => {
  const find_current_item = drop_down_items?.filter(
    (item) => item.value === current_value
  )[0];

  return (
    <div className={cn(' flex flex-col gap-2 ', styles?.component_styles)}>
      {/* Title */}
      <p
        className={cn(
          ' text-gray-700  text-lg text-start flex items-center justify-start gap-2',
          styles?.title_styles
        )}
      >
        {title_icon && <span>{title_icon}</span>}
        {title}
      </p>

      {/* PopOver */}
      <PopOver
        close_by_click_on_children={true}
        is_disabled={is_disabled ?? false}
        className={cn(
          ' w-full  right-0 mt-3 rounded-md shadow-md   h-[100px]  overflow-y-auto  ',
          styles?.popover_style
        )}
        popOverButtonClassName={cn(
          ' relative w-full px-4 py-3 border outline-none border-gray-200 rounded-md bg-transparent placeholder-[#6F767E] text-gray-800  ',
          styles?.field_styles
        )}
        button={
          <button className=" w-full flex items-center gap-4 outline-none ring-0 justify-between ">
            <div className=" flex items-center justify-start gap-4">
              {find_current_item?.image && (
                <img
                  src={find_current_item?.image}
                  alt="flag image"
                  className={cn(
                    'h-7 w-7 rounded-full  object-cover shadow-sm',
                    styles?.option_image_style
                  )}
                />
              )}
              <span>
                {find_current_item
                  ? find_current_item?.label
                  : default_option_text}
              </span>
            </div>

            <span className={[' hidden md:block'].join(' ')}>
              {TSICONS.angle_down}
            </span>
          </button>
        }
      >
        <div className="overflow-y-auto  p-5  shadow-sm bg-white rounded-lg  h-full  min-h-[100px] flex  flex-col   justify-start gap-2">
          {drop_down_items?.map((menu) => {
            return (
              <button
                type="button"
                key={menu.value}
                onClick={() => {
                  set_new_value(menu.value);
                }}
                className={cn(
                  ` text-gray-800 hover:text-primary duration-500 text-base  flex items-center gap-4  text-start ${
                    current_value === menu.value && 'text-primary'
                  }`,

                  styles?.options_style
                )}
              >
                {menu.image && (
                  <img
                    src={menu.image}
                    alt="flag image"
                    className={cn(
                      'h-7 w-7 rounded-full  object-cover shadow-sm',
                      styles?.option_image_style
                    )}
                  />
                )}
                <span className="  ">{menu.label}</span>
              </button>
            );
          })}
        </div>
      </PopOver>
    </div>
  );
};

export default SelectFromList;
