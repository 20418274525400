import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { General_text } from '../../../constant/Translation/General';
import { Listing_text } from '../../../constant/Translation/Listing';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import { authSelector } from '../../../store/feature/authSlice';
import {
  addListingQNA,
  getListingQNA,
  listingSelector,
} from '../../../store/feature/listingSlice';
import { changeDateFormat } from '../../Shared/Constant/Constant';
import ModalBasic from '../../Shared/Modal/ModalBesic';
import PopupUI from '../../Shared/PopUp/UI/PopupUI';
import { get_dynamic_styles_with_default_value } from '../../../constant/DynamicStylesWithDefaultValue';
import {
  CardBoxDefaultStyles,
  CardMainDefaultStyles,
} from '../../../constant/default_styles/ProductDetailsStyles';
import CommentsList from './CommentsList';
import CommentBox from './CommentBox';

const CommentSection = ({ listing_details, styles }) => {
  const [isOwner, setIsOwner] = useState();
  const [post_loading, setPostLoading] = useState(false);
  const dispatch = useDispatch();

  //
  useEffect(() => {
    if (
      Cookies.get(`${TYPE_CONSTANT.DOMAIN}_isStoreAccount`) &&
      JSON.parse(Cookies.get(`${TYPE_CONSTANT.DOMAIN}_isStoreAccount`))
    ) {
      if (
        Cookies.get(`${TYPE_CONSTANT.DOMAIN}_storeAccountID`) &&
        JSON.parse(Cookies.get(`${TYPE_CONSTANT.DOMAIN}_storeAccountID`)) ==
          listing_details?.account_id
      ) {
        setIsOwner(true);
      } else {
        setIsOwner(false);
      }
    }
  }, [listing_details]);

  const { comments, isFetching, isCommentsLoading } =
    useSelector(listingSelector);

  return (
    <>
      {comments ? (
        <div
          id={'qa'}
          className="w-full  "
          style={get_dynamic_styles_with_default_value(
            styles?.main_styles,
            CardMainDefaultStyles
          )}
        >
          <div
            style={get_dynamic_styles_with_default_value(
              styles?.box_styles,
              CardBoxDefaultStyles
            )}
          >
            <h2 className="heading-h2">{Listing_text.product.comment_title}</h2>
            <CommentBox listing_details={listing_details} styles={styles} />
            <CommentsList
              listing_details={listing_details}
              styles={styles}
              comments={comments}
            />
          </div>
        </div>
      ) : (
        <div className="border bg-[#3B3269] bg-opacity-[10%] shadow rounded-card   p-4   w-full  mx-auto overflow-hidden">
          <div className="flex  justify-between">
            <div className="h-3 bg-[#3B3269] bg-opacity-[30%] rounded-full   w-28"></div>
          </div>
          <div className="animate-pulse mt-4  ">
            <div className="w-full  min-h-min flex  items-center justify-center xs:justify-start  gap-5 flex-wrap">
              <div className="h-5 bg-[#3B3269] bg-opacity-[20%] rounded-card   w-1/6"></div>
              <div className="h-5 bg-[#3B3269] bg-opacity-[20%] rounded-card   w-4/6"></div>
            </div>
          </div>
          <div className="animate-pulse mt-4  ">
            <div className="w-full  min-h-min flex  items-center justify-center xs:justify-start  gap-5 flex-wrap">
              <div className="h-5 bg-[#3B3269] bg-opacity-[20%] rounded-card   w-1/6"></div>
              <div className="h-5 bg-[#3B3269] bg-opacity-[20%] rounded-card   w-4/6"></div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CommentSection;
