import {
  center_align_icon,
  left_align_icon,
  redo_icon,
  right_align_icon,
  undo_icon,
  bullet_list_icon,
  number_list_icon,
  underline_icon,
  strikethrugh_icon,
  code_icon,
  text_color_icon,
  link_icon,
  image_icon,
  subscript_icon,
  superscript_icon,
  task_icon,
  code_block_icon,
  table_icon,
  two_by_two_table_icon,
  add_column_icon,
  add_new_row,
  delete_table_icon,
  delete_column_icon,
  delete_row_icon,
} from '../Constant/Icons/ComoserIcons';

import React, { useCallback, useEffect, useState } from 'react';
import DropdownClassic from '../DropDown/DropdownClassic';
import DropdownMore from '../DropDown/DropdownMore';
import AddImageForm from './ImageButton/AddImageForm';
import AddImageButton from './ImageButton/AddImageButton';

// import AddImageForm from './AddImageForm';

const Menubar = ({ editor, composer_id }) => {
  //

  const [AddLinkFormOpen, setAddLinkFormOpen] = useState(false);

  const [AddImageFormOpen, setAddImageFormOpen] = useState(false);

  // Drop down styles
  const [selectedHeadingItem, setSelectedHeadingItem] = useState(1);

  // header_options
  const header_options = [
    {
      id: 0,
      name: 'Normal Text',
      function: () => {
        editor?.chain().focus().setParagraph().run();
        setSelectedHeadingItem(0);
      },
      className: [
        'w-full text-left prose prose-sm     py-3 px-3 cursor-pointer ',
        editor?.isActive('paragraph')
          ? 'bg-primary text-white'
          : 'bg-white hover:bg-gray-50  ',
      ].join(' '),
      selected: editor?.isActive('paragraph') ? true : false,
      tag_name: 'p',
    },
    {
      id: 1,
      name: 'Heading 1 ',
      function: () => {
        editor?.chain().focus().toggleHeading({ level: 1 }).run();
        setSelectedHeadingItem(1);
      },
      className: [
        'w-full  prose prose-sm    text-left py-3 px-3 cursor-pointer ',
        editor?.isActive('heading', { level: 1 })
          ? 'bg-primary text-white'
          : 'bg-white hover:bg-gray-50 text-primary',
      ].join(' '),
      selected: editor?.isActive('heading', { level: 1 }) ? true : false,
      tag_name: 'h1',
    },
    {
      id: 2,
      name: 'Heading 2 ',
      function: () => {
        editor?.chain().focus().toggleHeading({ level: 2 }).run();
        setSelectedHeadingItem(2);
      },
      className: [
        'w-full text-left prose prose-sm    py-3 px-3 cursor-pointer ',
        editor?.isActive('heading', { level: 2 })
          ? 'bg-primary text-white'
          : 'bg-white hover:bg-gray-50 text-primary',
      ].join(' '),
      selected: editor?.isActive('heading', { level: 2 }) ? true : false,
      tag_name: 'h2',
    },
    {
      id: 3,
      name: 'Heading 3 ',
      function: () => {
        editor?.chain().focus().toggleHeading({ level: 3 }).run();
        setSelectedHeadingItem(3);
      },
      className: [
        'w-full text-left prose prose-sm    py-3 px-3 cursor-pointer ',
        editor?.isActive('heading', { level: 3 })
          ? 'bg-primary text-white'
          : 'bg-white hover:bg-gray-50 text-primary',
      ].join(' '),
      selected: editor?.isActive('heading', { level: 3 }) ? true : false,
      tag_name: 'h3',
    },
    {
      id: 4,
      name: 'Heading 4 ',
      function: () => {
        editor?.chain().focus().toggleHeading({ level: 4 }).run();
        setSelectedHeadingItem(4);
      },
      className: [
        'w-full text-left prose prose-sm    py-3 px-3 cursor-pointer ',
        editor?.isActive('heading', { level: 4 })
          ? 'bg-primary text-white'
          : 'bg-white hover:bg-gray-50 text-primary',
      ].join(' '),
      selected: editor?.isActive('heading', { level: 4 }) ? true : false,
      tag_name: 'h4',
    },
    {
      id: 5,
      name: 'Heading 5 ',
      function: () => {
        editor?.chain().focus().toggleHeading({ level: 5 }).run();
        setSelectedHeadingItem(5);
      },
      className: [
        'w-full text-left prose prose-sm    py-3 px-3 cursor-pointer ',
        editor?.isActive('heading', { level: 5 })
          ? 'bg-primary text-white'
          : 'bg-white hover:bg-gray-50  ',
      ].join(' '),
      selected: editor?.isActive('heading', { level: 5 }) ? true : false,
      tag_name: 'h5',
    },
    {
      id: 6,
      name: 'Heading 6 ',
      function: () => {
        editor?.chain().focus().toggleHeading({ level: 6 }).run();
        setSelectedHeadingItem(6);
      },
      className: [
        'w-full text-left prose prose-sm    py-3 px-3 cursor-pointer ',
        editor?.isActive('heading', { level: 6 })
          ? 'bg-primary text-white'
          : 'bg-white hover:bg-gray-50   ',
      ].join(' '),
      selected: editor?.isActive('heading', { level: 6 }) ? true : false,
      tag_name: 'h6',
    },
  ];

  // more_options
  const table_options = [
    {
      id: 0,
      name: 'Add new table',
      function: () =>
        editor
          .chain()
          .focus()
          .insertTable({ rows: 3, cols: 2, withHeaderRow: true })
          .run(),
      className: [
        'w-full text-left      py-2 px-3 cursor-pointer  flex items-center gap-3 bg-white hover:bg-gray-50 text-gray-800',
      ].join(' '),
      selected: false,
      tag_name: 'p',
      icon: two_by_two_table_icon,
    },
    {
      id: 1,
      name: 'Add new column',
      function: () => {
        editor.chain().focus().addColumnAfter().run();
      },
      className: [
        'w-full text-left      py-2 px-3 cursor-pointer  flex items-center gap-3',
        'bg-white hover:bg-gray-50 text-gray-800',
      ].join(' '),
      selected: false,
      tag_name: 'p',
      icon: add_column_icon,
    },

    {
      id: 2,
      name: 'Add new row',
      function: () => {
        editor.chain().focus().addRowAfter().run();
      },
      className: [
        'w-full text-left      py-2 px-3 cursor-pointer  flex items-center gap-3',
        'bg-white hover:bg-gray-50 text-gray-800',
      ].join(' '),
      selected: false,
      tag_name: 'p',
      icon: add_new_row,
    },
    {
      id: 2,
      name: 'Delete table',
      function: () => {
        editor.chain().focus().deleteTable().run();
      },
      className: [
        'w-full text-left      py-2 px-3 cursor-pointer  flex items-center gap-3',
        'bg-white hover:bg-gray-50 text-gray-800',
      ].join(' '),
      selected: false,
      tag_name: 'p',
      icon: delete_table_icon,
    },
    {
      id: 2,
      name: 'Delete column',
      function: () => {
        editor.chain().focus().deleteColumn().run();
      },
      className: [
        'w-full text-left      py-2 px-3 cursor-pointer  flex items-center gap-3',
        'bg-white hover:bg-gray-50 text-gray-800',
      ].join(' '),
      selected: false,
      tag_name: 'p',
      icon: delete_column_icon,
    },
    {
      id: 2,
      name: 'Delete row',
      function: () => {
        editor.chain().focus().deleteRow().run();
      },
      className: [
        'w-full text-left      py-2 px-3 cursor-pointer  flex items-center gap-3',
        'bg-white hover:bg-gray-50 text-gray-800',
      ].join(' '),
      selected: false,
      tag_name: 'p',
      icon: delete_row_icon,
    },
  ];
  // more_options
  const more_options = [
    {
      id: 0,
      name: 'Underline',
      function: () => editor?.chain().focus().toggleUnderline().run(),
      className: [
        'w-full text-left      py-2 px-3 cursor-pointer  flex items-center gap-3',
        editor?.isActive('underline')
          ? 'bg-primary text-white'
          : 'bg-white hover:bg-gray-50 text-gray-800',
      ].join(' '),
      selected: editor?.isActive('underline') ? true : false,
      tag_name: 'p',
      icon: underline_icon,
    },
    {
      id: 1,
      name: 'Strikethrough',
      function: () => {
        editor?.chain().focus().toggleStrike().run();
      },
      className: [
        'w-full text-left  py-2 px-3 cursor-pointer  flex items-center gap-3',
        editor?.isActive('strike')
          ? 'bg-primary text-white'
          : 'bg-white hover:bg-gray-50 text-gray-800',
      ].join(' '),
      selected: editor?.isActive('strike') ? true : false,
      tag_name: 'p',
      icon: strikethrugh_icon,
    },
    {
      id: 2,
      name: 'Code',
      function: () => {
        editor?.chain().focus().toggleCode().run();
      },
      className: [
        'w-full text-left   py-2 px-3 cursor-pointer  flex items-center gap-3',
        editor?.isActive('code')
          ? 'bg-primary text-white'
          : 'bg-white hover:bg-gray-50  text-gray-800',
      ].join(' '),
      selected: editor?.isActive('code') ? true : false,
      tag_name: 'p',
      icon: code_icon,
    },
    {
      id: 2,
      name: 'Code block',
      function: () => {
        editor.chain().focus().toggleCodeBlock().run();
      },
      className: [
        'w-full text-left   py-2 px-3 cursor-pointer  flex items-center gap-3',
        editor?.isActive('codeblock')
          ? 'bg-primary text-white'
          : 'bg-white hover:bg-gray-50  text-gray-800',
      ].join(' '),
      selected: editor?.isActive('codeblock') ? true : false,
      tag_name: 'p',
      icon: code_block_icon,
    },
    {
      id: 3,
      name: 'Subscript',
      function: () => {
        editor.chain().focus().toggleSubscript().run();
      },
      className: [
        'w-full text-left   py-2 px-3 cursor-pointer  flex items-center gap-3',
        editor?.isActive('subscript')
          ? 'bg-primary text-white'
          : 'bg-white hover:bg-gray-50  text-gray-800',
      ].join(' '),
      selected: editor?.isActive('subscript') ? true : false,
      tag_name: 'p',
      icon: subscript_icon,
    },
    {
      id: 4,
      name: 'Superscript',
      function: () => {
        editor.chain().focus().toggleSuperscript().run();
      },
      className: [
        'w-full text-left   py-2 px-3 cursor-pointer  flex items-center gap-3',
        editor?.isActive('superscript')
          ? 'bg-primary text-white'
          : 'bg-white hover:bg-gray-50  text-gray-800',
      ].join(' '),
      selected: editor?.isActive('superscript') ? true : false,
      tag_name: 'p',
      icon: superscript_icon,
    },
  ];

  // text alignment
  const text_alignment = [
    {
      id: 0,
      value: left_align_icon,
      name: (
        <span className="flex items-center justify-between">
          Align Left
          {left_align_icon}
        </span>
      ),
      function: () => {
        editor?.chain().focus().setTextAlign('left').run();
      },
      className: [
        'w-full text-left      py-2 px-3 cursor-pointer ',
        editor?.isActive('left')
          ? 'bg-primary text-white'
          : 'bg-white hover:bg-gray-50 text-gray-800',
      ].join(' '),
      selected: editor?.isActive({ textAlign: 'left' }) ? true : false,
      tag_name: 'p',
    },
    {
      id: 1,
      value: center_align_icon,
      name: (
        <span className="flex items-center justify-between">
          Align Center {center_align_icon}
        </span>
      ),
      function: () => {
        editor?.chain().focus().setTextAlign('center').run();
      },
      className: [
        'w-full text-left      py-2 px-3 cursor-pointer ',
        editor?.isActive({ textAlign: 'center' })
          ? 'bg-primary text-white'
          : 'bg-white hover:bg-gray-50 text-gray-800',
      ].join(' '),
      selected: editor?.isActive({ textAlign: 'center' }) ? true : false,
      tag_name: 'p',
    },
    {
      id: 2,
      value: right_align_icon,
      name: (
        <span className="flex items-center justify-between">
          Align Right {right_align_icon}
        </span>
      ),
      function: () => {
        editor?.chain().focus().setTextAlign('right').run();
      },
      className: [
        'w-full text-left      py-2 px-3 cursor-pointer ',
        editor?.isActive({ textAlign: 'right' })
          ? 'bg-primary text-white'
          : 'bg-white hover:bg-gray-50 text-gray-800',
      ].join(' '),
      selected: editor?.isActive({ textAlign: 'right' }) ? true : false,
      tag_name: 'p',
    },
  ];

  // Link

  const setLink = useCallback(() => {
    const previousUrl = editor.getAttributes('link').href;
    const url = window.prompt('URL', previousUrl);

    // cancelled
    if (url === null) {
      return;
    }

    //
    console.log(editor);

    // empty
    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink().run();

      return;
    }

    // update link
    editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
  }, [editor]);

  if (!editor) {
    return <></>;
  }

  return (
    <div className=" relative flex    items-center justify-start  flex-wrap  bg-white shadow-md  w-full min-h-10 py-1  gap-1">
      {/*  */}

      <button
        onClick={() => editor?.chain().focus().undo().run()}
        disabled={!editor?.can().undo()}
        className={[
          'py-1.5 px-2    justify-between   rounded-sm   bg-transparent text-primary',
          !editor?.can().undo() ? 'cursor-not-allowed' : ' cursor-pointer',
        ].join(' ')}
      >
        {undo_icon}
      </button>
      <button
        onClick={() => editor?.chain().focus().redo().run()}
        disabled={!editor?.can().redo()}
        className={[
          'py-1.5 px-2 justify-between   rounded-sm   bg-transparent text-primary',
          !editor?.can().redo() ? 'cursor-not-allowed' : ' cursor-pointer',
        ].join(' ')}
      >
        {redo_icon}
      </button>

      <hr className=" border  h-8 border-r-2 border-gray-200 rounded" />

      {/*  
      //
      
      //
      
      //
      */}

      <DropdownClassic
        options={header_options}
        main_btn_class={
          'py-1.5 px-2   flex items-center justify-end gap-2  rounded-sm  justify-between min-w-[150px] bg-white border-gray-200 hover:border-gray-50 hover:bg-gray-50 text-gray-500 hover:text-gray-600'
        }
        dropdown_box_width={'w-[200px]'}
      />

      <hr className=" border  h-8 border-r-2 border-gray-200 rounded" />

      {/*  
      //
      
      //
      
      //
      */}

      <button
        onClick={() => editor?.chain().focus().toggleBold().run()}
        className={[
          'py-1.5 px-2  justify-between   text-base font-medium rounded-sm   ',
          editor?.isActive('bold')
            ? 'bg-gray-700  text-white'
            : 'bg-transparent text-primary hover:bg-gray-50',
        ].join(' ')}
      >
        <b>b</b>
      </button>
      <button
        onClick={() => editor?.chain().focus().toggleItalic().run()}
        className={[
          'py-1.5 px-2   justify-between  text-base font-medium rounded-sm   ',
          editor?.isActive('italic')
            ? 'bg-gray-700  text-white'
            : 'bg-transparent text-primary hover:bg-gray-50',
        ].join(' ')}
      >
        <i>I</i>
      </button>
      <DropdownMore
        align={'right'}
        options={table_options}
        main_btn_class={
          '!py-1.5 !px-1  btn    text-base font-medium rounded-sm  bg-transparent text-primary hover:bg-gray-50 '
        }
        more_icon={table_icon}
      />
      <DropdownMore
        align={'left'}
        options={more_options}
        main_btn_class={
          '!py-1.5 !px-1  btn    text-base font-medium rounded-sm  bg-transparent text-primary hover:bg-gray-50 '
        }
        more_icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-5 h-[18px]"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
            />
          </svg>
        }
      />

      <hr className=" border  h-8 border-r-2 border-gray-200 rounded" />

      {/*  
      //
      
      //
      
      //
      */}

      <button
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={[
          'py-2 px-2   justify-between   text-base font-medium rounded-sm   ',
          editor?.isActive('bulletList')
            ? 'bg-gray-700  text-white'
            : 'bg-transparent text-primary hover:bg-gray-50',
        ].join(' ')}
      >
        {bullet_list_icon}
      </button>
      <button
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={[
          'py-2 px-2   justify-between   text-base font-medium rounded-sm   ',
          editor?.isActive('orderedList')
            ? 'bg-gray-700  text-white'
            : 'bg-transparent text-primary hover:bg-gray-50',
        ].join(' ')}
      >
        {number_list_icon}
      </button>
      <button
        onClick={() => editor.chain().focus().toggleTaskList().run()}
        className={[
          '  py-1.5 px-1 justify-between   text-base font-medium rounded-sm   ',
          editor?.isActive('taskList')
            ? 'bg-gray-700  text-white'
            : 'bg-transparent text-primary hover:bg-gray-50',
        ].join(' ')}
      >
        {task_icon}
      </button>
      {/* <button
        onClick={(e) => {
          e.stopPropagation();
          setAddLinkFormOpen(true);
        }}
        className={[
          'py-2 px-2   justify-between   text-base font-medium rounded-sm   ',
          editor.isActive('link')
            ? 'bg-gray-700  text-white'
            : 'bg-transparent text-primary hover:bg-gray-50',
        ].join(' ')}
      >
        {link_icon}
      </button> */}
      <AddImageButton editor={editor} />
      <div
        onClick={() =>
          document.getElementById(`colortype_iput-${composer_id}`).click()
        }
        className={[
          ' block py-2 px-2   justify-between   text-base font-medium rounded-sm    relative bg-transparent text-primary hover:bg-gray-50',
        ].join(' ')}
        style={{ color: editor?.getAttributes('textStyle')?.color }}
      >
        <span className=" transform  scale-150 "> {text_color_icon}</span>
        <input
          id={`colortype_iput-${composer_id}`}
          type="color"
          onInput={(event) =>
            editor.chain().focus().setColor(event.target.value).run()
          }
          className=" absolute bottom-3 right-0 left-0 mx-auto   w-0 h-0 rounded  custom-icon-gradient  border-0  shadow-none   appearance-none"
        />
        <span className=" absolute bottom-1 right-0 left-0 mx-auto   w-5 h-1.5 rounded  custom-icon-gradient  border-0 " />
      </div>

      {/*  */}

      <DropdownClassic
        options={text_alignment}
        main_btn_class={
          'py-[7px] px-2  rounded-sm  justify-between  bg-white border-gray-200 hover:border-gray-50 hover:bg-gray-50 text-primary flex items-center gap-1'
        }
        dropdown_box_width={'w-[200px]'}
      />

      {/*  
      //
      
      //
      
      //
      */}

      {/* <SidePan
        id="set-link"
        sidepanOpen={AddLinkFormOpen}
        setSidepanOpen={setAddLinkFormOpen}
        closeByOutsideClick={true}
        justify_align={'center'}
        custom_content_box_styles={'max-w-[400px] w-full'}
      >
        <AddLinkForm
          editor={editor}
          setFormOpen={setAddLinkFormOpen}
          tenant_details={tenant_details}
        />
      </SidePan> */}
      {/* <SidePan
        id="add-image-link"
        sidepanOpen={AddImageFormOpen}
        setSidepanOpen={setAddImageFormOpen}
        closeByOutsideClick={false}
        justify_align={'center'}
        custom_content_box_styles={'max-w-[400px] w-full'}
      >
        <AddImageForm
          editor={editor}
          setFormOpen={setAddImageFormOpen}
          tenant_details={tenant_details}
        />
      </SidePan> */}
    </div>
  );
};

export default Menubar;
