import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import React, { useEffect, useState } from 'react';
import Menubar from './Menubar';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Commands from './suggetion/commands';
import getSuggestionItems from './suggetion/item';
import renderItems from './suggetion/renderItems';
import Embed from './embeds/Embeds';
import { Color } from '@tiptap/extension-color';
import Text from '@tiptap/extension-text';
import TextStyle from '@tiptap/extension-text-style';
import Link from '@tiptap/extension-link';
import Image from '@tiptap/extension-image';
import Subscript from '@tiptap/extension-subscript';
import Superscript from '@tiptap/extension-superscript';
import TaskItem from '@tiptap/extension-task-item';
import TaskList from '@tiptap/extension-task-list';
import CodeBlock from '@tiptap/extension-code-block';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import { Markdown } from 'tiptap-markdown';
import Youtube from '@tiptap/extension-youtube';

const Composer2 = ({
  contents,
  setContents,
  isReadOnly = false,
  mainClass,
  placeholder,
  dependents = contents,
  id = 'composer-field',
}) => {
  const [temp_contents, setTempContents] = useState(contents);

  const editor = useEditor(
    {
      extensions: [
        StarterKit,
        Underline,
        TextAlign.configure({
          types: ['heading', 'paragraph'],
        }),
        Commands.configure({
          suggestion: {
            items: getSuggestionItems,
            render: renderItems,
          },
        }),
        Embed,
        Color,
        Text,
        TextStyle,
        Link.configure({
          openOnClick: false,
        }),
        Image,
        Subscript,
        Superscript,
        TaskList,
        TaskItem.configure({
          nested: true,
        }),
        CodeBlock,
        Table.configure({
          resizable: true,
        }),
        TableRow,
        TableHeader,
        TableCell,
        Markdown,
        Youtube.configure({
          controls: false,
        }),
      ],
      editorProps: {
        attributes: {
          class: mainClass
            ? `prose prose-sm sm:prose lg:prose-lg xl:prose-xl mt-2 focus:outline-none min-h-[200px]     prose-a:text-primary  prose-a:border-0 hover:prose-a:underline prose-img:m-0 ${mainClass} `
            : `prose prose-sm sm:prose lg:prose-lg xl:prose-xl mt-2  focus:outline-none min-h-[200px]     prose-a:text-primary  prose-a:border-0 hover:prose-a:underline prose-img:m-0`,
        },
      },
      editable: !isReadOnly,
      content: contents,
      onBlur({ editor }) {
        setTempContents(editor.getHTML());
      },
    },
    []
  );

  useEffect(() => {
    if (editor?.getHTML() === '<p></p>') {
      editor?.commands.setContent(contents);
    }
  }, [contents]);

  useEffect(() => {
    {
      setContents && setContents(temp_contents);
    }
  }, [temp_contents]);

  return (
    <div className="w-full h-full  relative">
      {!isReadOnly && <Menubar editor={editor} composer_id={id} />}
      <EditorContent
        editor={editor}
        className=" cursor-text"
        placeholder={placeholder ?? ''}
      />
    </div>
  );
};
export default Composer2;
