import { Listing_text } from '../../constant/Translation/Listing';
import { Payment_text } from '../../constant/Translation/Payment';
import { Shipping_text } from '../../constant/Translation/Shipping_text';
import { dynamic_translation_set } from '../../constant/Translation/translation_set';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import * as EmailValidator from 'email-validator';
import {
  addToCart,
  checkout,
  paymentIntent,
} from '../../store/feature/cartSlice';
import { push_data_layer } from '../../constant/GTMDatalayer';
import { base_url } from '../../constant/url';
import { Add_product_text } from '../../constant/Translation/AddProduct_text';
import { depositByPawaPay } from '../../store/feature/web_payment/web_payment';

export const checkout_submit = async ({
  cart_details,
  cart,
  specialCheckoutTypes,
  checkoutType,
  selectedScheduleTimeIndex,
  setShowError,
  commissions,
  setError_message,
  auth_key,
  selectShippingAddress,
  shippingMethod,
  selectStorageHubAddress,
  scheduleArray,
  selectedDateIndex,
  cart_commission,
  paymentMethod,
  dispatch,
  setShowSuccessMessage,
  guest_information,
  guestShippingAddress,
  router,
  mangopay_balance,
  selected_shipment_method,
  demand_commission,
  isDonateAnonymously,
  item_attributes,
  cart_attributes,
  domain_details,
}) => {
  // schedule check
  if (
    specialCheckoutTypes.includes(checkoutType) &&
    checkoutType !== 'donation' &&
    selectedScheduleTimeIndex === null &&
    cart_details?.[0]?.metadata?.day == undefined &&
    cart_details?.[0]?.schedule_start_at === null &&
    cart_details?.[0]?.schedule_end_at === null
  ) {
    setShowError(true);
    setError_message(Listing_text.productschedule.select_date_time);
    return false;
  }

  // shipping method check
  if (
    shippingMethod === null &&
    checkoutType !== 'digital' &&
    !specialCheckoutTypes.includes(checkoutType)
  ) {
    setShowError(true);
    setError_message(Shipping_text.shipping.selec_shipping_method);
    return false;
  }

  // delivery type shipping check
  if (
    shippingMethod?.type === 'delivery' &&
    !specialCheckoutTypes.includes(checkoutType)
  ) {
    if (
      (auth_key && selectShippingAddress === null) ||
      selectShippingAddress === undefined
    ) {
      setShowError(true);
      setError_message(Shipping_text.shipping.select_shipping_address);
      return false;
    }
    if (!auth_key && guestShippingAddress === null) {
      setShowError(true);
      setError_message(Shipping_text.shipping.select_shipping_address);
      return false;
    }
  }

  // checking shipment method
  if (
    shippingMethod?.channel === 'sendcloud' &&
    selected_shipment_method === null
  ) {
    setShowError(true);
    setError_message(Shipping_text.shipping.select_shipment_method);
    return false;
  }

  // storage_hub type shipping check
  if (shippingMethod?.type === 'storage_hub') {
    if (
      selectStorageHubAddress === null ||
      selectStorageHubAddress === undefined
    ) {
      setShowError(true);
      setError_message(Shipping_text.shipping.select_shipping_address);
      return false;
    }
  }

  // payment method check
  if (paymentMethod === null) {
    setShowError(true);
    setError_message(Payment_text.payment.select_payment_option);
    return false;
  }
  // wallet check method check
  if (
    paymentMethod?.channel === 'wallet' &&
    mangopay_balance?.amount < cart?.grand_total?.amount
  ) {
    setShowError(true);
    setError_message(Payment_text.payment.wallet_alert);
    return false;
  }

  // commission check
  if (
    commissions?.length > 0 &&
    commissions.filter(
      (commission) =>
        commission?.commission_data?.optional === false &&
        !cart_commission.some((cart_com) => cart_com.id === commission.id)
    )?.length > 0
  ) {
    let required_commission = commissions.filter(
      (commission) => commission?.commission_data?.optional === false
    )[0];
    setShowError(true);
    setError_message(
      dynamic_translation_set(
        Payment_text.payment.cart_commission_alert,
        required_commission?.title
      )
    );
    return false;
  }

  // guest_information check
  if (!auth_key && !EmailValidator.validate(guest_information?.email)) {
    setShowError(true);
    setError_message(Payment_text.payment.guest_info_error);

    return false;
  }

  // attributes check
  //* Attribute checking
  if (cart_attributes?.length > 0 && item_attributes?.length) {
    for (let j = 0; j < item_attributes.length; j++) {
      const attributes = item_attributes[j];
      let isBreak = false;

      for (let i = 0; i < cart_attributes.length; i++) {
        const attribute = cart_attributes[i];
        if (!attribute.optional) {
          if (
            !attributes?.filter(
              (at_data) => at_data.id === attribute.id && at_data?.values !== ''
            )?.length > 0 ||
            attributes === undefined
          ) {
            setShowError(true);
            setError_message(
              dynamic_translation_set(
                Add_product_text.addproduct.alert_attribute_required,
                attribute.name
              )
            );
            isBreak = true;
            return false;
            break;
          }
        }
      }

      if (isBreak) {
        break;
        return false;
      }
    }
  }

  // checkout data

  let checkout_data;
  if (shippingMethod?.type === 'delivery' && auth_key) {
    checkout_data = {
      order: {
        payment_method_id: paymentMethod.id,
        shipping_method_id: shippingMethod?.id,
        shipping_address_id: selectShippingAddress?.id,
      },
    };
  } else if (shippingMethod?.type === 'delivery' && !auth_key) {
    checkout_data = {
      order: {
        payment_method_id: paymentMethod.id,
        shipping_method_id: shippingMethod?.id,
        shipping_address: guestShippingAddress,
      },
    };
  } else if (shippingMethod?.type === 'storage_hub') {
    checkout_data = {
      order: {
        payment_method_id: paymentMethod.id,
        shipping_method_id: shippingMethod?.id,
        shipping_address_id: selectStorageHubAddress.id,
      },
    };
  } else {
    checkout_data = {
      order: {
        payment_method_id: paymentMethod.id,
        shipping_method_id: shippingMethod?.id,
      },
    };
  }

  // shipment method
  if (isDonateAnonymously) {
    checkout_data.order = {
      ...checkout_data.order,
      anonymous_donation: isDonateAnonymously,
    };
  }

  //
  if (specialCheckoutTypes.includes(checkoutType)) {
    delete checkout_data.order.shipping_method_id;
    delete checkout_data.order.shipping_address;
    delete checkout_data.order.shipping_address_id;
  }
  if (shippingMethod?.channel === 'sendcloud' && selected_shipment_method) {
    // shipment method
    checkout_data.order.external_shipping_method_id =
      selected_shipment_method.id;
  }
  // checkout type
  checkout_data.order.type = checkoutType;

  // event type
  if (
    (checkoutType === 'events' || checkoutType === 'appointments') &&
    cart_details?.[0]?.schedule_start_at === null &&
    cart_details?.[0]?.schedule_end_at === null
  ) {
    const metadata = cart_details?.[0]?.metadata;
    const start_time =
      metadata?.start_at ??
      scheduleArray[selectedDateIndex].schedules[selectedScheduleTimeIndex]
        .start_time;
    const end_time =
      metadata?.end_at ??
      scheduleArray[selectedDateIndex].schedules[selectedScheduleTimeIndex]
        .end_time;
    const day = metadata?.day ?? scheduleArray[selectedDateIndex].day;

    checkout_data.order.schedule_start_at = `${day} ${start_time}:00`;
    checkout_data.order.schedule_end_at = `${day} ${end_time}:00`;
  }

  //
  if (cart_commission?.length > 0) {
    checkout_data.order.cart_commission = cart_commission;
  }
  if (demand_commission?.length > 0) {
    checkout_data.order.demand_commission = demand_commission;
  }

  if (!auth_key && EmailValidator.validate(guest_information?.email)) {
    checkout_data.order.guest = true;
    checkout_data.order.guest_email = guest_information?.email;
    checkout_data.cart = { uuid: TYPE_CONSTANT.UUID };
  }

  if (item_attributes?.length) {
    const cartData = {
      cart: {
        listing_id: cart_details[0].listing.id,
        item_attributes,
        quantity: cart_details[0].quantity,
      },
    };
    await dispatch(addToCart({ data: cartData }));
  }

  if (paymentMethod.channel === 'web') {
    dispatch(
      checkout({
        checkoutData: checkout_data,
      })
    ).then((res) => {
      if (!res.payload.code) {
        push_data_layer({
          event: 'checkout',
          data: {
            products: cart_details.map((item) => {
              return {
                name: item?.listing.title,
                slug: item?.listing.slug,
                price: item?.listing.offer_price.amount,
                stock: item?.listing.stock,
                image: item?.listing.images,
                quantity: item.quantity,
                cart_id: item.id,
              };
            }),
            payment_method: paymentMethod.name,
            shipping_method: shippingMethod?.name,
            grand_total: cart.grand_total.formatted,
          },
        });
        window.open(
          `${base_url()}/v1/payments/web/paymentIntent?order_reference=${
            res.payload.order_reference
          }&auth_key=${auth_key}&payment_method_id=${
            paymentMethod.id
          }&agent=web&domain=${domain_details?.id}`
        );
      }
    });
  } else if (paymentMethod.channel === 'native') {
    dispatch(
      checkout({
        checkoutData: checkout_data,
      })
    ).then((res) => {
      const ref_id = res.payload.order_reference;
      if (!res.payload.code) {
        push_data_layer({
          event: 'checkout',
          data: {
            products: cart_details.map((item) => {
              return {
                name: item?.listing.title,
                slug: item?.listing.slug,
                price: item?.listing.offer_price.amount,
                stock: item?.listing.stock,
                image: item?.listing.images,
                quantity: item.quantity,
                cart_id: item.id,
              };
            }),
            payment_method: paymentMethod.name,
            shipping_method: shippingMethod?.name,
            grand_total: cart.grand_total.formatted,
          },
        });
        dispatch(
          depositByPawaPay({
            params: {
              amount: `${cart.grand_total.amount}`,
              reason: cart_details?.[0]?.listing.title,
              metadata: [
                {
                  fieldName: 'offer_total',
                  fieldValue: cart.offer_total?.formatted,
                },
                {
                  fieldName: 'list_total',
                  fieldValue: cart.list_total?.formatted,
                },
                {
                  fieldName: 'order_reference',
                  fieldValue: ref_id,
                },
              ],
            },
            order_reference: ref_id,
            payment_method: 'pawa_pay',
          })
        ).then((res) => {
          if (res.payload?.redirect_url) {
            window.open(res.payload?.redirect_url);
          }
        });
      }
    });
  } else {
    if (paymentMethod.type === 'stripe') {
      dispatch(
        checkout({
          checkoutData: checkout_data,
        })
      ).then((res) => {
        if (!res.payload.code) {
          dispatch(
            paymentIntent({
              sendData: {
                order_reference: res.payload.order_reference,
              },
            })
          ).then((res) => {
            if (!res.payload.code) {
              push_data_layer({
                event: 'checkout',
                data: {
                  products: cart_details.map((item) => {
                    return {
                      name: item?.listing.title,
                      slug: item?.listing.slug,
                      price: item?.listing.offer_price.amount,
                      stock: item?.listing.stock,
                      image: item?.listing.images,
                      quantity: item.quantity,
                      cart_id: item.id,
                    };
                  }),
                  payment_method: paymentMethod.name,
                  shipping_method: shippingMethod?.name,
                  grand_total: cart.grand_total.formatted,
                },
              });
              router.push('/payment');
            }
          });
        }
      });
    } else {
      dispatch(
        checkout({
          checkoutData: checkout_data,
        })
      ).then((res) => {
        if (!res.payload.code) {
          push_data_layer({
            event: 'checkout',
            data: {
              products: cart_details.map((item) => {
                return {
                  name: item?.listing.title,
                  slug: item?.listing.slug,
                  price: item?.listing.offer_price.amount,
                  stock: item?.listing.stock,
                  image: item?.listing.images,
                  quantity: item.quantity,
                  cart_id: item.id,
                };
              }),
              payment_method: paymentMethod.name,
              shipping_method: shippingMethod?.name,
              grand_total: cart.grand_total.formatted,
            },
          });
          setShowSuccessMessage(true);
        }
      });
    }
  }
};
