import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { commissionsSelector } from '../../../store/feature/Commissions/CommissionsSlice';
import { Checkout_text } from '../../../constant/Translation/Cart_text';
import { dynamic_translation_set } from '../../../constant/Translation/translation_set';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';
import SignInButton from '../../Shared/Buttons/SignInButton';
import SignUpButton from '../../Shared/Buttons/SignUpButton';

const PayWith = ({ styles, wallet_info, data, selected_payment_method }) => {
  return (
    <div className=" w-full  " style={get_dynamic_styles(styles?.main_styles)}>
      <div className="flex" style={get_dynamic_styles(styles?.box_styles)}>
        <p style={get_dynamic_styles(styles?.title_style)} className="">
          {Checkout_text.cart.pay_with}
        </p>
        <div className=" flex flex-col gap-2">
          <p style={get_dynamic_styles(styles?.primary_item_styles)}>
            {selected_payment_method?.name}
          </p>
          <p style={get_dynamic_styles(styles?.secondary_item_styles)}>
            {Checkout_text.cart.available_balance}:$000
          </p>
        </div>
      </div>
    </div>
  );
};

export default PayWith;
