import React, { useEffect } from 'react';
import DynamicListingCard from '../Shared/Cards/DynamicListingCard';
import { check_login } from '../../constant/check_auth';
import {
  listingLike,
  listingSelector,
  listingSlice,
  singleListingDetails,
} from '../../store/feature/listingSlice';
import { useDispatch, useSelector } from 'react-redux';

const SingleListingCard = ({ data, styles }) => {
  const dispatch = useDispatch();
  //
  const like = (id, isLiked) => {
    if (check_login(router)) {
      dispatch(
        listingLike({
          id,
          isLiked,
        })
      ).then((res) => {
        if (!res.payload.code) {
          dispatch(
            singleListingDetails({
              prams: { ...router.query, status: 2 },
            })
          );
        }
      });
    }
  };

  useEffect(() => {
    if (data?.slug) {
      dispatch(
        singleListingDetails({
          slug: data?.slug,
        })
      );
    }
  }, [data?.slug]);

  const { single_listing_details } = useSelector(listingSelector);

  return (
    single_listing_details && (
      <DynamicListingCard
        product={single_listing_details}
        like={like}
        is_load_variants={false}
      />
    )
  );
};

export default SingleListingCard;
