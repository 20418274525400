/* eslint-disable react/prop-types */
import React from 'react';
import Image from 'next/image';
import tradly from 'tradly';
import { useSelector } from 'react-redux';
import { authSelector } from '../../../store/feature/authSlice';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/dist/client/router';
import { listingDetails } from '../../../store/feature/listingSlice';
import Link from 'next/link';
import axios from 'axios';
import { check_login } from '../../../constant/check_auth';
import { getThumbnailImage } from '../../Shared/Constant/Constant';
import { Account_text } from '../../../constant/Translation/Account';
import { getCookie } from 'cookies-next';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import { get_dynamic_styles_with_default_value } from '../../../constant/DynamicStylesWithDefaultValue';
import {
  CardBoxDefaultStyles,
  CardMainDefaultStyles,
} from '../../../constant/default_styles/ProductDetailsStyles';
import { replaceSpecificStyles } from '../../../utils/replaceSpacificStyles';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';
import AccountShareButtons from './AccountShareButtons';
import ListingShareButtons from './ListingShareButtons';

const DynamicStoreNameBox = ({ account, styles, data }) => {
  const dispatch = useDispatch();
  const router = useRouter();

  const follow = (id, isFollow) => {
    if (check_login(router)) {
      axios.post('/api/a/follow_account', { id, isFollow }).then((res) => {
        dispatch(
          listingDetails({
            ...router?.query,
          })
        );
      });
    }
  };

  return (
    <div
      className="    w-full  "
      style={get_dynamic_styles_with_default_value(
        styles?.main_styles,
        CardMainDefaultStyles
      )}
    >
      <div
        className="flex"
        style={{
          display: styles?.box_styles?.display
            ? styles?.box_styles?.display
            : 'flex',
          ...get_dynamic_styles(styles?.box_styles),
        }}
      >
        {data?.title && (
          <p style={get_dynamic_styles(styles?.block_title_styles)}>
            {data?.title}
          </p>
        )}

        {account?.images[0] && (
          <img
            src={getThumbnailImage(account?.images[0])}
            alt={account?.name}
            style={get_dynamic_styles(styles?.image_styles)}
          />
        )}

        <div className="flex flex-col">
          {data?.isShowAccountName && (
            <p style={get_dynamic_styles(styles?.account_name_styles)}>
              {account?.name}
            </p>
          )}
          {data?.isShowUserName && (
            <p style={get_dynamic_styles(styles?.user_name_styles)}>
              {account?.user?.first_name} {account?.user?.last_name}
            </p>
          )}
        </div>
        {data?.isShowFollowButton &&
          getCookie(`${TYPE_CONSTANT.DOMAIN}_storeAccountID`) !=
            account?.id && (
            <button
              className="border"
              style={get_dynamic_styles(
                account.following
                  ? styles?.button_styles
                  : replaceSpecificStyles({
                      styles: styles?.button_styles,
                      remove_keys: {
                        background: 'transparent',
                        text_color: styles?.button_styles?.background,
                      },
                      initial_values: {
                        border_color: styles?.button_styles?.background,
                      },
                    })
              )}
              onClick={() => follow(account?.id, account.following)}
            >
              {account.following
                ? Account_text.storelist.following
                : Account_text.storelist.follow}
            </button>
          )}
        {data?.isShowShareButton && (
          <AccountShareButtons account_details={account} styles={styles} />
        )}
        {data?.isShowListingShareButton && (
          <ListingShareButtons account_details={account} styles={styles} />
        )}
      </div>
    </div>
  );
};

export default DynamicStoreNameBox;
