import React, { memo } from 'react';
import { get_dynamic_styles } from '../../constant/DynamicStyles';

const EmptyBlock = ({ styles }) => {
  const { main_styles, box_styles } = styles || {};

  return (
    <div
      className="flex items-center justify-center"
      style={get_dynamic_styles(main_styles)}
    >
      <div
        className="flex  flex-col  md:flex-row    overflow-hidden"
        style={get_dynamic_styles(box_styles)}
      />
    </div>
  );
};

export default memo(EmptyBlock);
