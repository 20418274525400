import React from 'react';
import {
  getFormatBySelectedCurrency,
  getFormatOFCurrencyFromFormatted,
  getFormatOFSelectedCurrency,
} from '../../../utils/CurrencyFunctions';
import Heading1 from '../../../UI/Text/Headers/Heading1';
import { Wallet_text } from '../../../constant/Translation/wallet';
import {
  amountPayInByMangoPayCalc,
  WalletSelector,
} from '../../../store/feature/wallet/walletSlice';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { useAppSelector } from '../../../hooks/Redux';
import { currency_list } from '../../../data/CurrencyData';
import { getCookie } from 'cookies-next';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';

const AddAmountPicker = ({ amount, setAmountValue }) => {
  const router = useRouter();
  const fixed_donation = [100, 150, 200, 250];
  const dispatch = useDispatch();
  const currency = router.query.code
    ? router.query.code
    : getCookie(`${TYPE_CONSTANT.DOMAIN}_default_currency`) ?? '';

  //
  const { wallets_list } = useAppSelector(WalletSelector);
  const selected_wallet = wallets_list?.find(
    (wallet: any) => wallet.currency === router?.query?.code
  );

  //
  const amountChange = (value) => {
    setAmountValue(value);

    if (Number(value) > 0) {
      let data: { amount: Number; currency?: string } = {
        amount: Number(value),
      };
      if (currency) {
        data.currency = currency as string;
      }
      dispatch(
        // @ts-ignore
        amountPayInByMangoPayCalc(data)
      );
    }
  };

  return (
    <div className=" max-w-lg mx-auto bg-white flex flex-col gap-4 p-6">
      <Heading1 styles=" md:text-[32px] my-6">
        {Wallet_text.wallet.add_amount}
      </Heading1>
      <div className="flex  items-center justify-start flex-wrap gap-4">
        {fixed_donation.map((item) => {
          return (
            <button
              className={
                item !== amount
                  ? ' min-w-[100px] h-5 px-6 py-4 border border-gray-200 flex items-center justify-center rounded-button    transition duration-700    hover:border-primary hover:text-primary text-center'
                  : ' min-w-[100px] h-5 px-6 py-4 border   flex items-center justify-center    border-transparent bg-primary text-white rounded-button cursor-pointer  ring-2 ring-primary ring-offset-2 text-center '
              }
              key={item}
              onClick={() => amountChange(item)}
            >
              {getFormatBySelectedCurrency(
                item,
                currency_list?.find(
                  (item) => item.code == selected_wallet?.currency
                )?.format
              )}
            </button>
          );
        })}
      </div>

      <div
        className="
                    mt-0
                    block
                    w-full    px-4
                    flex items-center gap-2 justify-start
                    rounded-input focus:border-primary
                    border-2 border-gray-200 transition  duration-700
                  "
      >
        <p className="text-base   font-semibold   text-center leading-none ">
          {router?.query?.code}
        </p>

        <input
          value={amount.toString()}
          className=" focus:ring-0   border-0  flex-grow"
          type="number"
          placeholder="1"
          onChange={(e) => {
            amountChange(e.target.value);
          }}
          onWheel={(event) => event.currentTarget.blur()}
        />
      </div>
    </div>
  );
};

export default AddAmountPicker;
