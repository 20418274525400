/* eslint-disable react/no-unescaped-entities */

import Heading1 from '../../../UI/Text/Headers/Heading1';
import { Wallet_text } from '../../../constant/Translation/wallet';
import Paragraph from '../../../UI/Text/Paragraph/Paragraph';

const WithdrawAmountSuccess = ({}) => {
  return (
    <div className=" max-w-lg mx-auto bg-white flex flex-col gap-4 p-6">
      <Heading1 styles=" md:text-[32px] my-6">
        {Wallet_text.wallet.withdraw_success}
      </Heading1>

      <Paragraph>{Wallet_text.wallet.withdraw_success_message}</Paragraph>
    </div>
  );
};

export default WithdrawAmountSuccess;
