import React, { memo } from 'react';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';
import { get_dynamic_styles_with_default_value } from '../../../constant/DynamicStylesWithDefaultValue';
import { HomeCardBoxDefaultStyles } from '../../../constant/default_styles/HomeDefaultStyles';
import ReadingComposer from '../../Shared/Composer/ReadingComposer';

const ListingAttribute = ({ styles, attributes, data }) => {
  const selected_attribute = attributes?.find(
    (it) => it.id == data?.attribute_id
  );
  return selected_attribute ? (
    <div className="    " style={get_dynamic_styles(styles?.main_styles)}>
      <div
        style={{
          display: styles?.box_styles?.display,
          ...get_dynamic_styles(styles?.box_styles),
        }}
      >
        {/* Icon path */}
        {selected_attribute?.icon_path && data?.icon_enabled && (
          <img
            style={get_dynamic_styles(styles?.image_styles)}
            src={selected_attribute?.icon_path}
            alt=""
          />
        )}

        {/* name value */}
        {selected_attribute.name && data?.title_enabled && (
          <p style={get_dynamic_styles(styles?.title_styles)}>
            {selected_attribute.name}
          </p>
        )}

        {/*  */}
        {data?.value_enabled && (
          <>
            {selected_attribute.field_type === 6 ? (
              <ReadingComposer
                contents={selected_attribute.values[0]}
                isReadOnly={true}
              />
            ) : (
              <p
                style={get_dynamic_styles(styles?.value_styles)}
                className="  whitespace-pre-line  overflow-auto  scrollbar-none"
              >
                {selected_attribute.values
                  .map((item) => {
                    if (
                      selected_attribute.field_type === 1 ||
                      selected_attribute.field_type === 2
                    ) {
                      return item.name;
                    } else if (
                      selected_attribute.field_type === 3 ||
                      selected_attribute.field_type === 4
                    ) {
                      return item;
                    } else if (selected_attribute.field_type === 12) {
                      return `latitude:${item?.latitude}, longitude:${item?.longitude}`;
                    } else {
                      return item;
                    }
                  })
                  .join(', ')}
              </p>
            )}
          </>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default memo(ListingAttribute);
