export var Wallet_text = {
  wallet: {
    cancel: 'Cancel',
    next: 'Next',
    back: 'Back',
    finish: 'Finish',
    verify: 'Verify',
    success: 'Success',
    bank_wire_transfer: 'Bank Wire Transfer',
    paypal: 'PayPal',
    cards: 'Cards',
    payment_method: 'Payment Method',
    select_payment_method: 'Select Payment Method',
    add_new_card: 'Add New Card',
    expiry_date: 'Expiry Date',
    save_card: 'Save Card',
    card_type: 'Card Type',
    select_card_type: 'Select card type',
    card_number: 'Card Number',
    cvc: 'CVC',
    apple_pay: 'Apple Pay',
    pay: 'Pay',
    //
    wallet: 'Wallet',
    fee: 'Fee',
    date: 'Date',
    your_balance: 'Your Balance',
    balance: 'Balance',
    amount: 'Amount',
    add_amount: 'Add Amount',
    add_amount_success: 'Success',
    add_amount_success_message: ` Congratulations! Your financial fortress just got a significant boost.
        You've successfully added money to your digital wallet.`,
    add_amount_success_message_by_bank: `Kindly transfer the amount to the bank account below mentioned. Amount will be added to your wallet within 48 hours or less. 
`,
    add: 'Add',
    withdraw_amount: 'Withdraw Amount',
    withdraw: 'Withdraw',
    your_balance: 'Your Balance',
    status: 'Status',
    history: 'Transactions History',
    all_transitions: 'All Transitions',
    type: 'Type',
    top_up_type: 'Top Up',
    transfer: 'Transfer',
    payout_to_bank_type: 'Payout To Bank ',
    reverse_transfer: 'Reverse Transfer ',
    credit_status: 'Credit ',
    debit_status: 'Debit ',
    debit_status: 'Debit ',
    fee_alert_message:
      'A fee of {value} is added for top up wallet using card payment.',
    withdraw_fee_alert_message:
      'A fee of {fee_amount} will be deducted for this payout. Total of {total_amount} will be deducted from your balance and you will receive {withdraw_amount} in your bank account.',
    card_type_required: 'Select card type.',
    card_number_required: 'Card number is required.',
    card_exp_required: 'Expiry date is required.',
    card_cvc_required: 'CVC number is required.',
    verify_withdraw: 'Verify withdraw',
    verify_withdraw_description:
      'One time verification code has been sent to your registered email address.',
    withdraw_success: 'Withdraw success',
    withdraw_success_message:
      "Success! Your financial maneuver has been executed flawlessly. You've just made a strategic withdrawal   from your digital fortress. ",
    withdraw_description:
      'Instant transfers are available for amounts below €250.00, while transfers of higher amounts may take up to 48 hours.',
    add_card_info:
      'The card details are not stored with us, it is sent to MangoPay',
  },
  kyc: {
    company_profile: 'Company Profile',
    company_details: 'Company details',
    legal_name_of_the_entity: 'Business Name of the entity',
    legal_type_of_the_entity: 'Business Type of the entity',
    select_legal_type_of_the_entity: 'Select Legal Type',
    legal_representative_first_name: 'Legal Representative First Name',
    legal_representative_last_name: 'Legal Representative Last Name',
    legal_representative_birthday: 'Legal Representative Birthday',
    legal_representative_nationality: 'Legal Representative Nationality',
    legal_representative_country_of_residence:
      'Legal Representative Country of Residence',
    company_registration_number: 'Company Registration Number',
    email_address_of_the_company: 'Email Address of the company',
    headquarters_address: 'Headquarters Address',
    account_number: 'Account Number',
    short_code: 'Short Code',
    registration_file_title: 'Registration Proof (PDF,PNG,JPG,JPEG)',
    articles_file_title: 'Articles of association (PDF,PNG,JPG,JPEG)',
    share_holder_declaration: 'Shareholder Declaration (PDF,PNG,JPG,JPEG)',
    alert_doc_upload: 'Upload all the documents in PDF, PNG, JPG, JPEG format.',
    //
    profile: 'Profile',
    bank: 'Bank',
    bank_details: 'Bank Details',
    document: 'Document',
    status: 'Status',
    kyc_description: `"Identity confirmation by Payment service provider. Mangopay, our trusted payment provider, uses this form to collect information and confirm your identity, know as KYC Check."`,
    cancel: 'Cancel',
    next: 'Next',
    back: 'Back',
    submit: 'Submit ',
    re_submit: 'Resubmit ',
    close: 'Close',
    personal_details: 'Personal details',
    birth_date: 'Birth date',
    nationality: 'Nationality',
    country_of_residence: 'Country of residence',
    owner_name: 'Owner Name',
    iban: 'IBAN',
    address: 'Address',
    address_1: 'Address 1',
    address_2: 'Address 2',
    city: 'City',
    state: 'State',
    country_of_birth: 'Country of Birth',
    country: 'Country',
    postal_code: 'Postal Code',
    select_country: ' Select from country',
    kyc_details: 'kyc details',
    add_file: 'Add file',
    select_file_title: 'Identity Proof (PDF,PNG,JPG,JPEG)',
    kyc_status: 'KYC Status',
    bic: 'BIC',
    wire_reference: 'Wire Reference',
  },
};
