import React, { useEffect, useRef, useState } from 'react';
import SidePan from '../Shared/Modal/SidePan';
import WithdrawAmountSteps from './Withdraw/WithdrawAmountSteps';
import KYCSteps from './Withdraw/KYCSteps';
import BankSteps from './Withdraw/BankSteps';
import { Wallet_text } from '../../constant/Translation/wallet';
import { useDispatch, useSelector } from 'react-redux';
import { kycOpenHandler, KYCSelector } from '../../store/feature/KYC/kycSlice';
import { useRouter } from 'next/router';

const Withdraw = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const withDrawRef = useRef(null);

  //
  const [sidePanOpen, setSidePanOpen] = useState(false);
  const [isBankKYCOpen, setIsBankKYCOpen] = useState(false);
  const [isKYCOpen, setIsKYCOpen] = useState(false);
  const { kyc_details, open_kyc_by_default } = useSelector(KYCSelector);

  //handleSidepanOpen
  const handleAmountSidepanOpen = (e) => {
    if (
      kyc_details?.mangopay_kyc_submission &&
      kyc_details?.mangopay_kyc_status === 'VALIDATED' &&
      kyc_details?.bank_account_setup === true
    ) {
      e.stopPropagation();
      setSidePanOpen(true);
      setIsKYCOpen(false);
      setIsBankKYCOpen(false);
    } else if (
      kyc_details?.bank_account_setup === false &&
      kyc_details?.mangopay_kyc_submission === true
    ) {
      e.stopPropagation();
      setSidePanOpen(true);
      setIsBankKYCOpen(true);
    } else if (
      kyc_details?.mangopay_kyc_submission === undefined ||
      kyc_details?.mangopay_kyc_submission !== true ||
      kyc_details?.mangopay_kyc_status === 'VALIDATION_ASKED'
    ) {
      e.stopPropagation();
      setSidePanOpen(true);
      setIsKYCOpen(true);
      setIsBankKYCOpen(false);
    } else {
      e.stopPropagation();
      setSidePanOpen(true);
      setIsKYCOpen(true);
      setIsBankKYCOpen(false);
    }
  };

  //
  const handleCloseBankKYC = () => {
    setIsBankKYCOpen(false);
    setIsKYCOpen(true);
  };
  //

  useEffect(() => {
    if (open_kyc_by_default == true && kyc_details) {
      dispatch(kycOpenHandler(false));
      withDrawRef.current.click();
    }
  }, [kyc_details, open_kyc_by_default]);

  return (
    <>
      {/* button */}
      <button
        className="btn-primary-outline "
        onClick={(e) => {
          handleAmountSidepanOpen(e);
        }}
        ref={withDrawRef}
      >
        {Wallet_text.wallet.withdraw_amount}
      </button>
      {/* Sidepan 1st step */}

      <SidePan
        id={`amount-withdraw-sidepan`}
        sidePanOpen={sidePanOpen}
        setSidePanOpen={setSidePanOpen}
        modal_section_styles={
          'fixed  ltr:right-0 rtl:left-0 bottom-0 lg:top-0 lg:my-auto   z-[60]  block flex items-center  justify-center  transform bg-white rounded shadow-lg   max-w-full md:max-w-4xl w-full   rounded-t-[40px]   lg:rounded-card  overflow-hidden'
        }
        modal_inside_section_styles="w-full   max-h-[80VH] md:max-h-full  h-full lg:scrollbar-thin   lg:scrollbar-track-transparent overflow-auto  lg:scrollbar-thumb-transparent  "
        modal_width={'max-w-xl w-full'}
        close_function={undefined}
        is_close_by_outside_click={true}
      >
        {isBankKYCOpen ? (
          <BankSteps
            sidePanOpen={sidePanOpen}
            setSidePanOpen={setSidePanOpen}
            setISKYCOpen={setIsKYCOpen}
            handleCloseBankKYC={handleCloseBankKYC}
          />
        ) : isKYCOpen ? (
          <KYCSteps
            sidePanOpen={sidePanOpen}
            setSidePanOpen={setSidePanOpen}
            setISKYCOpen={setIsKYCOpen}
          />
        ) : (
          <WithdrawAmountSteps
            sidePanOpen={sidePanOpen}
            setSidePanOpen={setSidePanOpen}
          />
        )}
      </SidePan>
    </>
  );
};

export default Withdraw;
