/* eslint-disable @typescript-eslint/ban-types */

import React from 'react';
import { cn } from '../../utils/classNames';

const SecondaryButton = ({
  children,
  className,
  onClick,
  isLoading,
  isDisabled,
}: {
  className: string;
  children: React.ReactNode;
  onClick?: Function;
  isLoading?: boolean;
  isDisabled?: boolean;
}) => {
  return (
    <button
      className={cn(
        [
          'btn-primary-outline',
          isDisabled ? 'cursor-not-allowed' : 'cursor-pointer',
        ].join(' '),
        className
      )}
      onClick={(e) => {
        e.preventDefault();
        onClick && onClick();
      }}
      disabled={isDisabled}
    >
      {children}
    </button>
  );
};

export default SecondaryButton;
