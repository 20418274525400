import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { negotiateSelector } from '../../../../store/feature/negotiate/negotiateSlice';
import BuyerNegotiateCards from './BuyerNegotiateCards';
import BuyerNegotiatePart from './BuyerNegotiatePart';

const BuyerNegotiationList = ({ sidePanOpen, add_to_Cart }) => {
  const { buyer_negotiations_list } = useSelector(negotiateSelector);
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [sidePanOpen]);

  return (
    <div className="w-full h-full" ref={ref}>
      {buyer_negotiations_list &&
        buyer_negotiations_list?.map((list_item, index, array) => {
          const item_from_last = array[array.length - 1 - index];
          return (
            <BuyerNegotiateCards
              buyer_negotiation_details={item_from_last}
              add_to_Cart={add_to_Cart}
            />
          );
        })}
      {buyer_negotiations_list?.filter(
        (list) =>
          (list?.status == 1 || list?.status == 2) && list?.expired == false
      )?.length == 0 && <BuyerNegotiatePart />}
    </div>
  );
};

export default BuyerNegotiationList;
