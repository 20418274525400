import React, { useEffect, useState } from 'react';
import Heading1 from '../../../UI/Text/Headers/Heading1';
import PrimaryButton from '../../../UI/Buttons/PrimaryButton';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import {
  CardSelector,
  getSavedCards,
} from '../../../store/feature/card/cardSlice';
import {
  WalletSelector,
  amountPayInByMangoPay,
  amountPayInByMangoPayCalc,
  clearPayIN,
} from '../../../store/feature/wallet/walletSlice';
import {
  delete_item_from_local_storage,
  get_data_from_local_storage,
} from '../../../utils/localStorage';
import { local_storage_keys } from '../../Localstorage/LocalStorageKeys';
import { getFormatBySelectedCurrency } from '../../../utils/CurrencyFunctions';
import Alert from '../../Shared/PopUp/Alert';
import { Wallet_text } from '../../../constant/Translation/wallet';
import { dynamic_translation_set } from '../../../constant/Translation/translation_set';
import Card from './Card';
import AddCard from './AddCard';

const Cards = ({
  selected_amount,
  selected_card,
  setSelectedCard,
}: {
  selected_amount: number;
  selected_card: any;
  setSelectedCard: any;
}) => {
  const router = useRouter();
  const dispatch = useDispatch();

  //
  const { pay_in_calculation } = useSelector(WalletSelector);
  const { cards } = useSelector(CardSelector);

  //
  useEffect(() => {
    if (cards?.length > 0 && selected_card == null) {
      setSelectedCard(cards[0]);
    } else {
      setSelectedCard(null);
    }
  }, [cards]);

  return (
    <div className=" max-w-lg mx-auto ">
      {/* Title */}
      <Heading1 styles="">{Wallet_text?.wallet.cards}</Heading1>

      {/* Cards list  */}
      <div className=" py-6 flex items-center justify-center gap-6 flex-wrap">
        {cards?.map((card) => {
          return (
            <Card
              card_details={card}
              selected_card={selected_card}
              setSelectedCard={setSelectedCard}
            />
          );
        })}

        <AddCard />
      </div>

      {/*  */}
      <div className=" max-w-lg mx-auto w-full flex flex-col gap-4 items-center justify-center">
        {pay_in_calculation && (
          <Alert
            className={`w-full    `}
            type={'warning'}
            open={true}
            isOpenFunction={false}
            funcCallOnClose={undefined}
            setOpen={undefined}
          >
            <p>
              {dynamic_translation_set(
                Wallet_text.wallet.fee_alert_message,
                pay_in_calculation?.fees?.formatted
              )}
            </p>
          </Alert>
        )}
      </div>
    </div>
  );
};

export default Cards;
