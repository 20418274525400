import { Wallet_text } from '../Translation/wallet';

export const walletTransitionType = (id) => {
  switch (id) {
    case 1:
      return Wallet_text.wallet.top_up_type;
      break;
    case 2:
      return Wallet_text.wallet.transfer;
      break;
    case 3:
      return Wallet_text.wallet.payout_to_bank_type;
      break;
    case 4:
      return Wallet_text.wallet.reverse_transfer;
      break;

    default:
      break;
  }
};

export const walletTransitionStatus = (id) => {
  switch (id) {
    case 1:
      return Wallet_text.wallet.credit_status;
      break;
    case 2:
      return Wallet_text.wallet.debit_status;
      break;

    default:
      break;
  }
};
