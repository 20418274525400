import React, { useRef, useEffect } from 'react';
import Transition from '../utils/Transition';

function ModalBasic({
  children,
  id,
  title,
  modalOpen,
  setModalOpen,
  main_div_width,
  closeByOutsideClick,
  close_func,
}) {
  const modalContent = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!modalOpen || modalContent.current.contains(target)) return;
      closeByOutsideClick && setModalOpen(false);
      closeByOutsideClick && close_func && close_func();
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!modalOpen || keyCode !== 27) return;
      closeByOutsideClick && setModalOpen(false);
      closeByOutsideClick && close_func && close_func();
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  return (
    <>
      {/* Modal backdrop */}
      <Transition
        className="fixed inset-0 bg-gray-900 bg-opacity-30 z-[50] transition-opacity"
        show={modalOpen}
        enter="transition ease-out duration-200"
        enterStart="opacity-0"
        enterEnd="opacity-100"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
        aria-hidden="true"
      />
      {/* Modal dialog */}
      <Transition
        id={id}
        className="fixed inset-0 z-[60] overflow-hidden flex items-center my-4 justify-center transform px-4 sm:px-6"
        role="dialog"
        aria-modal="true"
        show={modalOpen}
        enter="transition ease-in-out duration-200"
        enterStart="opacity-0 translate-y-4"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-in-out duration-200"
        leaveStart="opacity-100 translate-y-0"
        leaveEnd="opacity-0 translate-y-4"
      >
        <div
          ref={modalContent}
          className={[
            'bg-white rounded-card  shadow-lg overflow-auto   max-h-full scrollbar-none',
            main_div_width ? `w-full` : 'w-full  max-w-xl',
          ].join(' ')}
          style={{ maxWidth: main_div_width + 'px' }}
        >
          {children}
        </div>
      </Transition>
    </>
  );
}

export default ModalBasic;
