import Link from 'next/link';
import React from 'react';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { get_dynamic_styles_with_default_value } from '../../constant/DynamicStylesWithDefaultValue';
import {
  CardBoxDefaultStyles,
  CardMainDefaultStyles,
} from '../../constant/default_styles/ProductDetailsStyles';
import { get_data_by_current_language } from '../../utils/GetDataByLanguage';
import { useRouter } from 'next/router';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  filterSelector,
  onSearchByFilter,
} from '../../store/feature/filter/filterSlice';

const SearchButton = ({ filer_item, styles, listings_filter }) => {
  const {
    filter_type,
    title,
    translated_data,
    attribute_id,
    is_show_title,
    search_icon_image,
    url,
  } = filer_item;

  const router = useRouter();
  const dispatch = useDispatch();
  const { queries } = useSelector(filterSelector);

  const onClickSearchButton = () => {
    router.push({
      pathname: url ?? router.pathname,
      query:
        Object.keys(queries).length > 0
          ? queries
          : { page: router.query?.page ? router.query?.page : 1 },
    });
  };

  return (
    <div
      className="flex cursor-pointer"
      style={get_dynamic_styles(styles?.filter_search_button_style)}
      onClick={onClickSearchButton}
    >
      {title && (
        <p style={get_dynamic_styles(styles?.text_styles)}>
          {get_data_by_current_language({
            data_list: translated_data,
            key_name: 'title',
            default_data: title,
          })}
        </p>
      )}
      {search_icon_image && (
        <img
          style={get_dynamic_styles(styles?.filter_search_button_image_style)}
          src={search_icon_image}
          alt="image"
        />
      )}
    </div>
  );
};

export default SearchButton;
