import React, { useState } from 'react';
import { General_text } from '../../constant/Translation/General';
import AllIcons from '../Shared/Constant/Icons/AllIcons';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import {
  postTranslateContent,
  translateContentSelector,
} from '../../store/feature/Translate/translateSlice';
import {
  listingDetails,
  listingSelector,
} from '../../store/feature/listingSlice';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import { getCookie } from 'cookies-next';
import Alert from '../Shared/PopUp/Alert';
import { get_data_by_current_language } from '../../utils/GetDataByLanguage';

const DemandTranslation = ({ styles, data }) => {
  const dispatch = useDispatch();
  const router = useRouter();
  // Selectors
  const { listing_details } = useSelector(listingSelector);
  const { isFetching, isError, errorMessage } = useSelector(
    translateContentSelector
  );
  //
  const [selected_languageCode, setSelectedLanguageCode] = useState(
    getCookie(`${TYPE_CONSTANT.DOMAIN}_default_language`)
  );

  // Alert
  const [open_alert, setOpenAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [alert_type, setAlert_type] = useState('success');

  //

  const translate_handler = () => {
    const translate_data = [];

    // title
    if (
      listing_details?.title &&
      listing_details?.title?.replace(/\s/g, '')?.length > 0
    ) {
      translate_data.push({
        reference_type: 'listing',
        translation_type: 'title',
        reference_id: listing_details?.id,
        value: listing_details?.title,
      });
    }

    // description
    if (
      listing_details?.description &&
      listing_details?.description?.replace(/\s/g, '')?.length > 0
    ) {
      translate_data.push({
        reference_type: 'listing',
        translation_type: 'description',
        reference_id: listing_details?.id,
        value: listing_details?.description,
      });
    }

    // post translation call
    dispatch(
      postTranslateContent({
        translations: translate_data,
        from: listing_details?.locale,
        to: [selected_languageCode],
      })
    ).then((resp) => {
      // listing details call again after translation successful
      if (!resp.error) {
        dispatch(
          listingDetails({
            ...router?.query,
          })
        );
      } else {
        setAlert_type('error');
        setOpenAlert(true);
        setMessage(resp?.payload?.message);
      }
    });
  };

  return (
    <>
      {/* Alert */}
      <Alert
        className={` fixed z-50 top-0 left-0 right-0  w-full  xs:w-[500px] mx-auto mt-5 `}
        type={'error'}
        open={open_alert}
        setOpen={setOpenAlert}
      >
        <p>{message}</p>
      </Alert>

      {/*  */}
      {TYPE_CONSTANT.GENERAL_CONFIGS?.google_translation &&
        listing_details?.locale !== selected_languageCode && (
          <div style={get_dynamic_styles(styles?.main_styles)}>
            <div className="  " style={get_dynamic_styles(styles?.box_styles)}>
              <button
                className="flex"
                style={get_dynamic_styles(styles?.button_styles)}
                onClick={() => translate_handler()}
              >
                {data?.icon && !isFetching && (
                  <img
                    className="   "
                    style={get_dynamic_styles(styles?.icon_styles)}
                    src={data?.icon}
                    alt="image"
                  />
                )}
                {isFetching && AllIcons.loading_icon}

                {get_data_by_current_language({
                  data_list: data?.translated_data,
                  key_name: 'button_title',
                  default_data: data?.button_title,
                })}
              </button>
            </div>
          </div>
        )}
    </>
  );
};

export default DemandTranslation;
{
}
