import Image from 'next/image';
import favorite from '../../../../assets/Images/Home/favourite@3x.png';
import heartIcon from '../../../../assets/Images/Home/heartIcon@3x.png';
import { changeDateFormat, getThumbnailImage } from '../../Constant/Constant';
import { useRouter } from 'next/dist/client/router';
import Link from 'next/link';
import { TYPE_CONSTANT } from '../../../../constant/Web_constant';
import { Listing_text } from '../../../../constant/Translation/Listing';

const GridTypeCard = ({ product, like, is_hide_like_btn }) => {
  const listing_card_styles = TYPE_CONSTANT.STYLES_CONFIGS?.listing_card;

  const { goal_price, donation_analytics } = product || {};

  const funded_percentage = Math.round(
    (donation_analytics?.total_amount?.amount / goal_price?.amount) * 100
  )
    ? Math.round(
        (donation_analytics?.total_amount?.amount / goal_price?.amount) * 100
      )
    : 0;

  return (
    <div className="group relative ">
      <Link
        href={
          product.slug
            ? `/l/${product.slug}`
            : `/l/${product.id}-${product.title.replace(
                /[ /?,#=]+/g,
                '-'
              )}?id=true`
        }
      >
        <a
          className="listing-card flex"
          style={{
            flexDirection: 'var(--listingCardFlexDirection)',
            flexWrap: 'var(--listingCardFlexWrap)',
            alignItems: 'var(--listingCardAlignItems)',
            alignContent: 'var(--listingCardAlignContents)',
            justifyContent: 'var(--listingCardJustifyContent)',
          }}
        >
          {/* product Image */}

          {product.images.length > 0 &&
            listing_card_styles?.image?.enabled !== false && (
              <div
                className={
                  listing_card_styles?.image !== undefined
                    ? 'listing-card-image'
                    : 'aspect-w-1 aspect-h-1 '
                }
              >
                <img
                  className={[
                    'w-full h-full object-cover object-left-top rounded-[var(--listingCardImageBorderRadius)]  overflow-hidden',
                    listing_card_styles?.hover?.image_zoom_in &&
                      'transition ease-in-out delay-150  group-hover:-translate-y-2 group-hover:scale-110  duration-300',
                  ].join(' ')}
                  src={getThumbnailImage(product?.images[0])}
                  onError={(e) => {
                    e.target.src = product?.images[0];
                  }}
                  alt={product.title}
                />
              </div>
            )}
          {/*  */}
          <div className="h-full w-full">
            <div>
              {/* Title */}
              {listing_card_styles?.title?.enabled !== false && (
                <p className=" listing-card-title ">{product.title}</p>
              )}

              {/* Price */}
              {listing_card_styles?.price?.enabled !== false && (
                <p className="listing-card-price">
                  <span className="text-[14px] leading-4 font-semibold   ">
                    {product.offer_price.formatted}
                  </span>

                  {product?.offer_percent > 0 && (
                    <>
                      <span className="text-[14px] leading-4 font-normal    line-through ">
                        {product.list_price.formatted}
                      </span>
                      <span className="text-[14px] leading-4 font-normal text-error ">
                        {product?.offer_percent}%
                      </span>
                    </>
                  )}
                </p>
              )}
            </div>

            {/* Account */}
            {TYPE_CONSTANT.MARKETPLACE_FLAVOURS === 1 &&
              listing_card_styles?.account?.enabled !== false &&
              product?.account && (
                <div className="listing-card-account">
                  {product?.account?.images.length > 0 ? (
                    <div className="h-[--listingCardAccountImageHeight] w-[--listingCardAccountImageWidth] rounded-full overflow-hidden  relative">
                      <img
                        alt="image"
                        src={getThumbnailImage(product?.account.images[0])}
                        onError={(e) => {
                          e.target.src = product?.account?.images[0];
                        }}
                        className="h-full w-full object-cover object-left-top"
                      />
                    </div>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  )}

                  <div className=" ltr:ml-1 rtl:mr-1">
                    <p className=" text-[length:--listingCardAccountNameTextSize] text-[color:--listingCardAccountNameColor]  font-[--listingCardAccountNameTextFontWeight] leading-[--listingCardAccountNameTextLineHeight]">
                      {product?.account?.name.length > 20
                        ? product?.account?.name.substring(0, 18) + '..'
                        : product?.account?.name}
                    </p>
                    {product?.account?.total_followers > 0 && (
                      <p className=" text-[length:--listingCardFollowerNameTextSize] text-[color:--listingCardFollowerNameColor]  font-[--listingCardFollowerNameTextFontWeight] leading-[--listingCardFollowerNameTextLineHeight]">
                        {`${product?.account?.total_followers}  ${Listing_text.product.followers}`}
                      </p>
                    )}
                  </div>
                </div>
              )}

            {/* donation  */}
            {(product?.order_type === 'donation' ||
              listing_card_styles?.donation?.enabled === true) && (
              <div className="listing-card-donation">
                <div className=" relative  w-[100%] h-[25px] overflow-hidden border-[0.5px]  border-primary     flex   items-center justify-start">
                  <p className=" absolute top-0 bottom-0 left-0 right-0 mx-auto my-auto text-center  text-base font-normal text-white  invert">
                    {funded_percentage}% Funded
                  </p>
                  <div
                    className={`h-full bg-primary   text-center flex items-center justify-center`}
                    style={{
                      width: `${
                        funded_percentage === 0 ? 2 : funded_percentage
                      }%`,
                    }}
                  />
                </div>

                <div className="flex items-center justify-end">
                  <button className=" mt-2  btn-primary-fill  w-full ">
                    Donate
                  </button>
                </div>
              </div>
            )}
          </div>
        </a>
      </Link>

      {/* Like button */}
      {!is_hide_like_btn &&
        listing_card_styles?.wish_button?.enabled !== false && (
          <div
            className=" h-[40px] w-[40px]   cursor-pointer  [z-100] absolute top-0  ltr:right-0 rtl:left-0"
            onClick={() => like(product.id, product.liked)}
          >
            {product.liked ? (
              <Image
                src={favorite}
                alt="follow button"
                layout="fill"
                objectFit="cover"
                require
              />
            ) : (
              <Image
                src={heartIcon}
                alt="follow button"
                layout="fill"
                objectFit="cover"
                require
              />
            )}
          </div>
        )}

      {/* Fullfillment icons */}
      {TYPE_CONSTANT.SHIPPING_CONFIGS?.fulfillment_by_platform_enabledd && (
        <div className="  absolute top-0  ltr:left-0 rtl:right-0">
          {product?.fulfilled_by_platform &&
            TYPE_CONSTANT.SHIPPING_CONFIGS.fulfilled_by_platform_icon_path && (
              <img
                alt="image"
                className="w-auto h-auto max-w-[80px]"
                src={
                  TYPE_CONSTANT.SHIPPING_CONFIGS.fulfilled_by_platform_icon_path
                }
              />
            )}
          {!product?.fulfilled_by_platform &&
            TYPE_CONSTANT.SHIPPING_CONFIGS.fulfilled_by_seller_icon_path && (
              <img
                alt="image"
                className="w-auto h-auto max-w-[80px]"
                src={
                  TYPE_CONSTANT.SHIPPING_CONFIGS.fulfilled_by_seller_icon_path
                }
              />
            )}
        </div>
      )}
    </div>
  );
};

export default GridTypeCard;
