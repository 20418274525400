export const get_user_default_data = ({ user_info, prefill_type }) => {
  switch (prefill_type) {
    case 'first_name':
      return user_info?.first_name;

    case 'last_name':
      return user_info?.last_name;

    case 'first_name':
      return user_info?.first_name;

    case 'profile_pic':
      return user_info?.profile_pic;

    case 'email':
      return user_info?.email;

    default:
      return '';
  }
};

export const get_listing_attributes_data = ({
  prefill_type,
  listing_attributes,
}) => {
  const attr = listing_attributes?.find((it) => it?.name === prefill_type);
  let data = '';

  if (attr?.field_type === 1 || attr?.field_type === 2) {
    data = attr?.values
      .map((item) => {
        if (attr?.field_type === 1 || attr?.field_type === 2) {
          return attr?.show_in_filter
            ? item.name // Include link behavior as per your need
            : item.name;
        }
      })
      .filter(Boolean) // Ensure no undefined values are included
      .join(', ');
  } else if (
    attr?.field_type !== 1 &&
    attr?.field_type !== 2 &&
    attr?.field_type !== 6
  ) {
    data = attr?.values
      .map((item) => {
        if (attr?.field_type === 3 || attr?.field_type === 4) {
          return item;
        } else if (attr?.field_type === 12) {
          return `latitude:${item?.latitude}, longitude:${item?.longitude}`;
        } else {
          return item;
        }
      })
      .join(', ');
  }

  return attr ? data : '';
};

//
export const get_account_attributes_data = ({
  prefill_type,
  listing_attributes,
}) => {
  const attr = listing_attributes?.find((it) => it?.name === prefill_type);
  let data = '';

  if (attr?.field_type === 1 || attr?.field_type === 2) {
    data = attr?.values
      .map((item) => {
        if (attr?.field_type === 1 || attr?.field_type === 2) {
          return attr?.show_in_filter
            ? item.name // Include link behavior as per your need
            : item.name;
        }
      })
      .filter(Boolean) // Ensure no undefined values are included
      .join(', ');
  } else if (
    attr?.field_type !== 1 &&
    attr?.field_type !== 2 &&
    attr?.field_type !== 6
  ) {
    data = attr?.values
      .map((item) => {
        if (attr?.field_type === 3 || attr?.field_type === 4) {
          return item;
        } else if (attr?.field_type === 12) {
          return `latitude:${item?.latitude}, longitude:${item?.longitude}`;
        } else {
          return item;
        }
      })
      .join(', ');
  }

  return attr ? data : '';
};
