import { cn } from '../../../utils/classNames';
import AllIcons from '../Constant/Icons/AllIcons';
import PopOver from '../PopOver/PopOver';

const SelectFromList = ({
  title,
  current_value,
  set_new_value,
  note,
  drop_down_items,
  title_styles,
  field_styles,
  component_styles,
  default_option_text,
  options_style,
  is_disabled,
  dropdownBlockStyles,
  options_selected_style,
  options_block_styles,
}) => {
  const find_current_item = drop_down_items.filter(
    (item) => item.value === current_value
  )[0];
  return (
    <div className={cn(' flex flex-col gap-1 w-full ', component_styles)}>
      <p className={cn(' text-sm font-semibold text-[#172327]', title_styles)}>
        {title}
      </p>

      <PopOver
        close_by_click_on_children={true}
        is_disabled={is_disabled ?? false}
        className={cn(
          ' w-full  right-0 mt-3 rounded-md shadow-md   ',
          dropdownBlockStyles
        )}
        popOverButtonClassName={cn(
          ' relative w-full px-4 py-3 border outline outline-1 outline-[#E5EAEF] rounded-md bg-transparent placeholder-[#6F767E]    border-transparent',
          field_styles
        )}
        button={
          <button
            type="button"
            className="flex items-center gap-4 outline-none ring-0 text-sm"
          >
            {find_current_item ? find_current_item?.label : default_option_text}
            <span className=" hidden md:block4">{AllIcons.dropdown_icon}</span>
          </button>
        }
      >
        <div
          className={cn(
            'overflow-hidden  p-5  shadow-sm bg-white rounded-lg   min-h-[100px] flex  flex-col   justify-start gap-4',
            options_block_styles
          )}
        >
          {drop_down_items?.map((menu) => {
            return (
              <button
                type="button"
                key={menu.value}
                onClick={() => {
                  set_new_value(menu.value);
                }}
                className={cn(
                  ` text-d_black_normal hover:text-green duration-500 text-base  flex items-center gap-4  ${
                    current_value === menu.value &&
                    `text-primary ${options_selected_style}`
                  }`,

                  options_style
                )}
              >
                <span className="  ">{menu.label}</span>
              </button>
            );
          })}
        </div>
      </PopOver>
    </div>
  );
};

export default SelectFromList;
