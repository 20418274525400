export const HomeCardBoxDefaultStyles = {};

export const HomeDefaultColumnStyles = {
  flex_direction: 'column',
  column_gap: '0px',
  row_gap: '0px',
};

export const HomeMainDefaultStyles = {
  background: '',
  width: '100%',
};

export const HomeBoxDefaultStyles = {
  background: '',
  margin_top: '0px',
  margin_left: '0px',
  margin_right: '0px',
  padding_bottom: '16px',
};
