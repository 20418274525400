import React, { useEffect } from 'react';
import { Wallet_text } from '../../../constant/Translation/wallet';
import Link from 'next/link';
import { useDispatch, useSelector } from 'react-redux';
import {
  WalletSelector,
  getMangoPayWalletBalance,
  getWallets,
} from '../../../store/feature/wallet/walletSlice';
import AddAmountINWallet from '../../Wallet/AddAmount';
import { getCookie } from 'cookies-next';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';

const WalletPaymentButton = ({
  selectPaymentMethod,
  paymentMethod,
  method,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getMangoPayWalletBalance({
        currency: getCookie(`${TYPE_CONSTANT.DOMAIN}_default_currency`),
      })
    );
    dispatch(getWallets());
  }, [0]);

  const { mangopay_balance } = useSelector(WalletSelector);

  return (
    <button
      className={
        paymentMethod?.id !== method?.id
          ? '   px-2 py-4 border border-gray-300 flex items-start justify-between rounded-button    transition duration-700    hover:border-primary hover:text-primary gap-3'
          : '   px-2 py-4 border  flex items-start justify-between   border-transparent bg-primary text-white rounded-button cursor-pointer  ring-2 ring-primary ring-offset-2  gap-3'
      }
      onClick={() => selectPaymentMethod(method)}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={[
          'h-5 w-5  mt-0.5   ',
          paymentMethod?.id === method.id ? '' : 'text-gray-500',
        ].join(' ')}
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
      <div className=" relative pb-5 flex flex-col   justify-start items-start">
        <p className=" text-base   font-semibold ">{method.name}</p>

        <p className="text-base   font-normal  ">
          {Wallet_text.wallet.your_balance}: {mangopay_balance?.formatted}
        </p>
        <AddAmountINWallet
          button_style={
            'absolute bottom-0 text-xs underline bg-transparent hover:bg-transparent border-0 p-0   text-inherit hover:text-inherit'
          }
        />
      </div>
    </button>
  );
};

export default WalletPaymentButton;
