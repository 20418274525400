/* eslint-disable react/prop-types */
import React from 'react';
import Image from 'next/image';
import tradly from 'tradly';
import { useSelector } from 'react-redux';
import { authSelector } from '../../../store/feature/authSlice';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/dist/client/router';
import { listingDetails } from '../../../store/feature/listingSlice';
import Link from 'next/link';
import axios from 'axios';
import { check_login } from '../../../constant/check_auth';
import { getThumbnailImage } from '../../Shared/Constant/Constant';
import { Account_text } from '../../../constant/Translation/Account';
import { getCookie } from 'cookies-next';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import { get_dynamic_styles_with_default_value } from '../../../constant/DynamicStylesWithDefaultValue';
import {
  CardBoxDefaultStyles,
  CardMainDefaultStyles,
} from '../../../constant/default_styles/ProductDetailsStyles';

const StoreNameBox = ({ account, styles }) => {
  const dispatch = useDispatch();
  const router = useRouter();

  const follow = (id, isFollow) => {
    if (check_login(router)) {
      axios.post('/api/a/follow_account', { id, isFollow }).then((res) => {
        dispatch(
          listingDetails({
            ...router?.query,
          })
        );
      });
    }
  };
  return (
    <div
      className="    w-full  "
      style={get_dynamic_styles_with_default_value(
        styles?.main_styles,
        CardMainDefaultStyles
      )}
    >
      <div
        className="  flex   justify-between items-center "
        style={get_dynamic_styles_with_default_value(
          styles?.box_styles,
          CardBoxDefaultStyles
        )}
      >
        <div className="flex items-center">
          <div className="flex justify-start items-start ">
            {account?.images[0] && (
              <img
                src={getThumbnailImage(account?.images[0])}
                alt={account?.name}
                className=" w-6 h-6 rounded-full overflow-hidden relative object-cover  object-left-top   "
              />
            )}
          </div>
          <Link
            href={
              account?.slug
                ? `/a/${account?.slug}`
                : `/a/${account?.id}-${account?.name.replace(
                    /[ /?,#=]+/g,
                    '-'
                  )}?id=true`
            }
          >
            <a
              className=" block ltr:ml-2 rtl:mr-2 cursor-pointer "
              // onClick={() =>
              //   router.push({
              //     pathname: '/a/[id]',
              //     query: {
              //       id: `${account?.id}-${account?.name.replace(/\s/g, '-')}`,
              //       page: 1,
              //     },
              //   })
              // }
            >
              <p className="description-paragraph ">{account?.name}</p>
            </a>
          </Link>
        </div>
        {getCookie(`${TYPE_CONSTANT.DOMAIN}_storeAccountID`) != account?.id && (
          <div className="  flex justify-center  float-right">
            <button
              className={[
                '  rounded-button min-w-[80px] px-2 h-8 flex justify-center items-center  text-xs leading-3 font-semibold border border-primary',
                account.following
                  ? 'bg-primary text-white '
                  : 'bg-transparent text-primary ',
              ].join(' ')}
              onClick={() => follow(account?.id, account.following)}
            >
              {account.following
                ? Account_text.storelist.following
                : Account_text.storelist.follow}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default StoreNameBox;
