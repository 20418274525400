import { Popover, Transition } from '@headlessui/react';
import { Fragment, useEffect, useRef, useState } from 'react';
import { cn } from '../../utils/classNames';

export default function PopOver({
  close_by_click_on_children,
  children,
  button,
  className: PopoverClass,
  popOverButtonClassName,
  is_disabled = false,
  pop_over_max_height = 200,
}: {
  close_by_click_on_children: boolean;
  children: React.ReactNode;
  button: React.ReactNode;
  className?: string;
  popOverButtonClassName?: string;
  is_disabled?: boolean;
  pop_over_max_height?: number;
}) {
  const buttonRef = useRef(null);
  const [dropdown_position, setDropdownPositionState] = useState('top-10');

  useEffect(() => {
    const getButtonPosition = () => {
      if (buttonRef.current) {
        const buttonRect = buttonRef.current.getBoundingClientRect();
        const innerHeight = window.innerHeight;
        const count_bottom_position =
          innerHeight - (buttonRect.top + buttonRect.height);
        if (count_bottom_position > pop_over_max_height) {
          setDropdownPositionState('top-10');
        } else {
          setDropdownPositionState('bottom-14');
        }
      }
    };

    // Call the function to get button position after the component has rendered
    getButtonPosition();
  }, [button]);

  return (
    <Popover as="div" className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            as="div"
            className={cn('outline-none ring-0', popOverButtonClassName)}
            disabled={is_disabled}
            ref={buttonRef}
          >
            {button}
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel
              className={cn(
                'absolute    z-20',
                PopoverClass,
                dropdown_position
              )}
            >
              {({ close }) => (
                <div
                  onClick={() => {
                    close_by_click_on_children && close();
                  }}
                  className="h-full w-full"
                >
                  {children}
                </div>
              )}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}

// Example
// {
// 	/* Drop down */
// }
// <PopOver
// 	className=" w-[300px]   right-0 mt-3 rounded-md shadow-md   "
// 	button={<button> {ICONS.hand_burger}</button>}
// >
// 	<div className="overflow-hidden  bg-white rounded-lg  ring-0 ring-1 min-h-[100px]"></div>
// </PopOver>;
