import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';

export default function useGeoLocation() {
  const [locationData, setLocationData] = useState(null);
  useEffect(() => {
    if (locationData === null) {
      getLocation();
    }
  }, []);

  async function getLocation() {
    const res = await axios.get('http://ip-api.com/json');
    if (res.status === 200) {
      setLocationData(res.data);
    } else {
      alert(
        'Location is not found. Please  your location other wise location search will not work .'
      );
    }
  }

  return {
    city: locationData?.city,
    country: locationData?.country,
    countryCode: locationData?.countryCode,
    lat: locationData?.lat,
    lon: locationData?.lon,
    region: locationData?.regionName,
    regionCode: locationData?.region,
    timezone: locationData?.timezone,
    zip: locationData?.zip,
  };
}
