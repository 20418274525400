import { Notification_text } from '../../../../constant/Translation/Notification';

export const order_notification_text = (id) => {
  if (id === 1) {
    return Notification_text.notification.order_incomplete;
  }
  if (id === 2) {
    return 'confirmed';
  }
  if (id === 3) {
    return Notification_text.notification.order_is_in_progress;
  }
  if (id === 4) {
    return Notification_text.notification.order_shipped;
  }
  if (id === 5) {
    return Notification_text.notification.order_customer_unreachable;
  }
  if (id === 6) {
    return Notification_text.notification.order_out_for_delivery;
  }
  if (id === 7) {
    return Notification_text.notification.order_deliver_returned;
  }
  if (id === 8) {
    return Notification_text.notification.order_customer_return_confirmed;
  }
  if (id === 9) {
    return 'delivered';
  }
  if (id === 10) {
    return 'delivery confirmed';
  }
  if (id === 11) {
    return Notification_text.notification.order_return_initiated;
  }
  if (id === 12) {
    return 'return picked';
  }
  if (id === 13) {
    return Notification_text.notification.order_customer_return_confirmed;
  }
  if (id === 14) {
    return Notification_text.notification.order_customer_return_disputed;
  }
  if (id === 15) {
    return Notification_text.notification.order_cancelled;
  }
  if (id === 16) {
    return Notification_text.notification.order_cancelled;
  }
  if (id === 17) {
    return Notification_text.notification.order_picked_up;
  }
};
