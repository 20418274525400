/* eslint-disable react/prop-types */
import React from 'react';
import { getThumbnailImage } from '../../Constant/Constant';
import { useRouter } from 'next/dist/client/router';
import { Account_text } from '../../../../constant/Translation/Account';
import ALink from '../../../../constant/ALink';
import { TYPE_CONSTANT } from '../../../../constant/Web_constant';
import { rating_icon } from '../../Constant/Icons/AllIcons';

const FlexTypeAccountCard = ({
  item,
  follow,
  follow_title,
  following_title,
  embed,
}) => {
  const router = useRouter();
  const account_card_styles = TYPE_CONSTANT.STYLES_CONFIGS?.account_card ?? {};

  const main_card_url = item?.slug
    ? `/a/${item?.slug}`
    : `/a/${item.id}-${item.name.replace(/[ /?,#!@#$%^&*=]+/g, '-')}?id=true`;

  //
  const handDirectionClick = ({ latitude, longitude }) => {
    const url = `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`;
    window.open(url);
  };

  //
  const handleMainCardClick = () => {
    router.push(main_card_url);
  };

  //
  const handleCategoryClick = (category) => {
    router.push({
      pathname: '/lc/[slug]',
      query: {
        slug: category.slug
          ? category.slug
          : `${category?.id}-${SlugChecker(category.name)}`,
      },
    });
  };

  // const rating check

  const isRatingShow = (rating_average, rating_position) => {
    return Math.abs(rating_average) >= rating_position;
  };

  return (
    <div
      className=" relative account-card flex gap-y-[--accountCardRowGap] gap-x-[--accountCardColumnGap]"
      onClick={handleMainCardClick}
      href={main_card_url}
      style={{
        flexDirection: 'var(--accountCardFlexDirection)',
        flexWrap: 'var(--accountCardFlexWrap)',
        alignItems: 'var(--accountCardAlignItems)',
        alignContent: 'var(--accountCardAlignContents)',
        justifyContent: 'var(--accountCardJustifyContent)',
      }}
    >
      {account_card_styles?.image?.enabled !== false && (
        <div
          className={
            account_card_styles?.image !== undefined
              ? ' account-card-image  flex-none'
              : 'aspect-w-1 aspect-h-1 flex-none '
          }
        >
          {item?.images?.length > 0 &&
            account_card_styles?.image?.enabled !== false && (
              <img
                src={getThumbnailImage(item.images[0])}
                alt={item.title}
                className="h-full w-full object-cover object-left-top "
              />
            )}
        </div>
      )}

      <div className="flex-grow  flex flex-col gap-1 ">
        {account_card_styles?.account_name?.enabled !== false && (
          <p className="account-card-title  my-2">
            {item?.name?.length > 15
              ? item.name.substring(0, 13) + '..'
              : item.name}
          </p>
        )}

        {account_card_styles?.owner_name?.enabled !== false && (
          <p className="account-card-owner mb-2 ">
            {item?.user?.first_name.length > 10
              ? item?.user?.first_name.substring(0, 8) + '..'
              : item?.user?.first_name}
          </p>
        )}

        {/* Review */}
        {account_card_styles?.review !== undefined && (
          <p className="flex justify-start items-center gap-1  ">
            {isRatingShow(item?.rating_data?.rating_average, 1) && rating_icon}
            {isRatingShow(item?.rating_data?.rating_average, 2) && rating_icon}
            {isRatingShow(item?.rating_data?.rating_average, 3) && rating_icon}
            {isRatingShow(item?.rating_data?.rating_average, 4) && rating_icon}
            {isRatingShow(item?.rating_data?.rating_average, 5) && rating_icon}
          </p>
        )}
        {/* Category  */}

        <div className="flex flex-col gap-1 justify-start items-start flex-wrap">
          {account_card_styles?.category_button?.enabled !== false &&
            item?.categories?.length > 0 &&
            item?.categories[0]?.hierarchy.map((category, index, array) => {
              return (
                <button
                  className=" account-card-category-button"
                  onClick={() => handleCategoryClick(category)}
                >
                  {category.name}
                </button>
              );
            })}
        </div>

        {/* location */}
        {item?.location && account_card_styles?.location?.enabled !== false && (
          <a
            href={main_card_url}
            className="w-full account-card-location  whitespace-pre-wrap"
          >
            {item?.location?.formatted_address}
          </a>
        )}

        {/* direction button */}
        <div className=" flex items-center justify-between">
          {account_card_styles?.follow_button?.enabled !== false && (
            <button
              className={[
                ' px-2 py-1 text-[10px] leading-3 items-center rounded-full border border-primary ',
                item.following
                  ? 'text-[#FFFFFF]  bg-primary'
                  : 'text-primary   bg-transparent',
              ].join(' ')}
              onClick={(e) => {
                e.stopPropagation();
                follow(item.id, item.following);
              }}
            >
              {item.following
                ? Account_text.storelist.following
                : Account_text.storelist.follow}
            </button>
          )}

          {account_card_styles?.direction_button?.enabled !== false && (
            <button
              className="account-card-direction-button "
              onClick={(e) => {
                e.stopPropagation();
                item?.latitude &&
                  item.longitude &&
                  handDirectionClick({
                    latitude: item.latitude,
                    longitude: item.longitude,
                  });
              }}
            >
              {'Get Direction'}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default FlexTypeAccountCard;
