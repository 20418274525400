import React from 'react';
import { cartSelector } from '../../../store/feature/cartSlice';
import { useSelector } from 'react-redux';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';
import Link from 'next/link';
import { Shipping_text } from '../../../constant/Translation/Shipping_text';

const GlobalCheckoutButton = ({ styles, data }) => {
  const { cart } = useSelector(cartSelector);

  return cart?.grand_total?.formatted ? (
    <div className=" w-full  " style={get_dynamic_styles(styles?.main_styles)}>
      <Link href={'/checkout'}>
        <button className=" w-full btn-primary-fill px-8 py-3 text-sm font-medium  mx-auto">
          {Shipping_text.shipping.checkout} {cart?.grand_total?.formatted}
        </button>
      </Link>
    </div>
  ) : (
    <></>
  );
};

export default GlobalCheckoutButton;
