import React, { useEffect, useState } from 'react';
import FileInput from '../../UI/FormFields/FileInput';
import { DOCUMENT_DETAILS_FORMAT } from './types/KYCTypes';
import { useRouter } from 'next/router';

import Alert from '../Shared/PopUp/Alert';
import { getBase64 } from '../../utils/getBase64';
import { Wallet_text } from '../../constant/Translation/wallet';

const KYCLegalDocument = ({
  kyc_document,
  setDocumentDetails,
  group_name,
  registration_proof_details,
  setRegistrationProofDetails,
  articles_of_association_details,
  setArticlesOfAssociationDetails,
  share_holder_declaration_details,
  setShareholderDeclarationDetails,
}: {
  kyc_document: DOCUMENT_DETAILS_FORMAT;
  setDocumentDetails: React.Dispatch<
    React.SetStateAction<DOCUMENT_DETAILS_FORMAT>
  >;
  group_name: string;
  registration_proof_details: DOCUMENT_DETAILS_FORMAT;
  setRegistrationProofDetails: React.Dispatch<
    React.SetStateAction<DOCUMENT_DETAILS_FORMAT>
  >;
  articles_of_association_details: DOCUMENT_DETAILS_FORMAT;
  setArticlesOfAssociationDetails: React.Dispatch<
    React.SetStateAction<DOCUMENT_DETAILS_FORMAT>
  >;
  share_holder_declaration_details: DOCUMENT_DETAILS_FORMAT;
  setShareholderDeclarationDetails: React.Dispatch<
    React.SetStateAction<DOCUMENT_DETAILS_FORMAT>
  >;
}) => {
  //
  const router = useRouter();

  // Alert
  const [open_alert, setOpenAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [alert_type, setAlert_type] = useState('error');

  //  value change handler
  const fileChangeHandler = async <K extends keyof DOCUMENT_DETAILS_FORMAT>({
    value,
    type,
  }: {
    value: DOCUMENT_DETAILS_FORMAT[K];
    key_name: Array<K>;
    type:
      | 'identification'
      | 'registration_proof'
      | 'articles_of_association'
      | 'share_holder';
  }) => {
    if (value?.length > 0) {
      const base_64 = await getBase64(value[0]);

      if (type === 'identification') {
        setDocumentDetails((arg: any) => {
          // return profile with new value
          return {
            ...arg,
            file: value[0],
            file_url: value[0].name,
            file_name: value[0].name,
            base_64: base_64,
          };
        });
      }
      if (type === 'registration_proof') {
        setRegistrationProofDetails((arg: any) => {
          // return profile with new value
          return {
            ...arg,
            file: value[0],
            file_url: value[0].name,
            file_name: value[0].name,
            base_64: base_64,
          };
        });
      }
      if (type === 'articles_of_association') {
        setArticlesOfAssociationDetails((arg: any) => {
          // return profile with new value
          return {
            ...arg,
            file: value[0],
            file_url: value[0].name,
            file_name: value[0].name,
            base_64: base_64,
          };
        });
      }
      if (type === 'share_holder') {
        setShareholderDeclarationDetails((arg: any) => {
          // return profile with new value
          return {
            ...arg,
            file: value[0],
            file_url: value[0].name,
            file_name: value[0].name,
            base_64: base_64,
          };
        });
      }
    }
  };

  console.log(kyc_document);

  //
  return (
    <>
      {/* Alert */}
      <Alert
        className={` fixed z-50 top-0 left-0 right-0  w-full  xs:w-[500px] mx-auto mt-5 `}
        type={alert_type}
        open={open_alert}
        setOpen={setOpenAlert}
        isOpenFunction={undefined}
        funcCallOnClose={undefined}
      >
        <p>{message}</p>
      </Alert>
      {/*  */}
      <div className=" space-y-6">
        <h2 className="text-center text-2xl font-bold">
          {Wallet_text?.kyc.kyc_details}
        </h2>
        <div className="   space-y-4">
          <FileInput
            id="kyc_document"
            key={Wallet_text?.kyc.select_file_title}
            title={Wallet_text?.kyc.select_file_title}
            add_file_title={Wallet_text?.kyc.add_file}
            current_value={kyc_document.file_url}
            accepted_file=".jpg,.pdf,.png,.jepg,"
            max_size={1}
            min_size={0.01125}
            setErrorMessage={setMessage}
            setIsError={setOpenAlert}
            set_new_value={(value) =>
              fileChangeHandler({
                value: value,
                key_name: ['file', 'file_name', 'file_url'],
                type: 'identification',
              })
            }
            delete_file={() => {
              setDocumentDetails((arg: any) => {
                return {
                  ...arg,
                  file: null,
                  file_url: null,
                  file_name: null,
                };
              });
            }}
          />
          {/*  */}
          <FileInput
            id="registration_file_title"
            key={Wallet_text?.kyc.registration_file_title}
            title={Wallet_text?.kyc.registration_file_title}
            add_file_title={Wallet_text?.kyc.add_file}
            current_value={registration_proof_details.file_url}
            accepted_file=".jpg,.pdf,.png,.jepg,"
            max_size={1}
            min_size={0.01125}
            setErrorMessage={setMessage}
            setIsError={setOpenAlert}
            set_new_value={(value) =>
              fileChangeHandler({
                value: value,
                key_name: ['file', 'file_name', 'file_url'],
                type: 'registration_proof',
              })
            }
            delete_file={() => {
              setRegistrationProofDetails((arg: any) => {
                return {
                  ...arg,
                  file: null,
                  file_url: null,
                  file_name: null,
                };
              });
            }}
          />
          {/*  */}
          <FileInput
            id="articles_of_association"
            title={Wallet_text?.kyc.articles_file_title}
            add_file_title={Wallet_text?.kyc.add_file}
            current_value={articles_of_association_details.file_url}
            accepted_file=".jpg,.pdf,.png,.jepg,"
            max_size={1}
            min_size={0.01125}
            setErrorMessage={setMessage}
            setIsError={setOpenAlert}
            set_new_value={(value) =>
              fileChangeHandler({
                value: value,
                key_name: ['file', 'file_name', 'file_url'],
                type: 'articles_of_association',
              })
            }
            delete_file={() => {
              setArticlesOfAssociationDetails((arg: any) => {
                return {
                  ...arg,
                  file: null,
                  file_url: null,
                  file_name: null,
                };
              });
            }}
          />
          {/*  */}
          <FileInput
            title={Wallet_text?.kyc.share_holder_declaration}
            id={'share_holder_declaration'}
            add_file_title={Wallet_text?.kyc.add_file}
            current_value={share_holder_declaration_details.file_url}
            accepted_file=".jpg,.pdf,.png,.jepg,"
            max_size={1}
            min_size={0.01125}
            setErrorMessage={setMessage}
            setIsError={setOpenAlert}
            set_new_value={(value) =>
              fileChangeHandler({
                value: value,
                key_name: ['file', 'file_name', 'file_url'],
                type: 'share_holder',
              })
            }
            delete_file={() => {
              setShareholderDeclarationDetails((arg: any) => {
                return {
                  ...arg,
                  file: null,
                  file_url: null,
                  file_name: null,
                };
              });
            }}
          />
        </div>
      </div>
    </>
  );
};

export default KYCLegalDocument;
