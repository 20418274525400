import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  filterSelector,
  getCategoriesForFilter,
  onFilterParamsUpdate,
} from '../../store/feature/filter/filterSlice';
import { get_data_by_current_language } from '../../utils/GetDataByLanguage';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { useRouter } from 'next/router';
import { getMainPath } from '../../utils/getMainPath';
import { fetch_all_categories } from '../../constant/fetch_all_categories';

const ListingCategoryFilter = ({ filer_item, styles }) => {
  const {
    filter_type,
    title,
    translated_data,
    category_ids,
    is_show_title,
    is_depend_on_search,
  } = filer_item;
  const router = useRouter();
  const dispatch = useDispatch();
  const { categories, filter_params, queries } = useSelector(filterSelector);
  const selected_category_ids = filter_params?.category_ids ?? [];

  useEffect(() => {
    if (categories === null) {
      dispatch(getCategoriesForFilter({}));
    }
  }, [0]);

  //
  const get_category = (id) => {
    const ct_list = fetch_all_categories(categories, true);
    const category = ct_list?.find((item) => item.id == id);
    return category;
  };

  // filter by category
  const filter_by_category = (id) => {
    const check = selected_category_ids?.find((ct) => ct == id);
    if (check === undefined) {
      dispatch(
        onFilterParamsUpdate({
          category_ids: [...selected_category_ids, id],
          callOnFilterUpdate: (updated_quires) => {
            onUpdateQuires(updated_quires);
          },
        })
      );
    } else {
      const filter = selected_category_ids?.filter((ct) => ct != id);
      dispatch(
        onFilterParamsUpdate({
          category_ids: filter?.length > 0 ? filter : null,
          callOnFilterUpdate: (updated_quires) => {
            onUpdateQuires(updated_quires);
          },
        })
      );
    }
  };

  const onUpdateQuires = (updated_quires) => {
    if (is_depend_on_search === false) {
      router.push({
        pathname: getMainPath(router.asPath),
        query: updated_quires ?? queries,
      });
    }
  };

  return (
    <div style={get_dynamic_styles(styles?.filter_component_style)}>
      {title && is_show_title && (
        <p style={get_dynamic_styles(styles?.filter_title_style)}>
          {get_data_by_current_language({
            data_list: translated_data,
            key_name: 'title',
            default_data: title,
          })}
        </p>
      )}

      {categories?.length > 0 &&
        category_ids?.map((category_id) => {
          return (
            <p
              className="flex"
              style={get_dynamic_styles(styles?.filter_category_menu_style)}
              key={Math.random()}
            >
              <input
                className=" form-check-input appearance-none h-3 w-3 border border-gray-300 rounded-sm bg-white checked:bg-primary checked:border-primary focus:outline-none focus:ring-primary checked:hover:bg-primary  checked:focus:ring-primary checked:focus:bg-primary  transition duration-200   align-top bg-no-repeat bg-center bg-contain  cursor-pointer"
                type="checkbox"
                checked={
                  selected_category_ids?.includes(`${category_id}`)
                    ? true
                    : false
                }
                onChange={() => filter_by_category(category_id)}
              />
              <span className=" ">{get_category(category_id)?.name}</span>
            </p>
          );
        })}
    </div>
  );
};

export default ListingCategoryFilter;
