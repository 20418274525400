import React from 'react';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { dynamic_text_convert } from '../../utils/dynamicTextConvert';

const WishWIthShare = ({ data, styles, listing_details, like }) => {
  return (
    <div style={get_dynamic_styles(styles?.main_styles)}>
      <div
        className="flex items-center   "
        style={{
          display: styles?.box_styles?.display,
          gridTemplateColumns: dynamic_text_convert({
            main_text: styles?.box_styles?.columns_box_percentage,
            check_by: '-1%',
            value: '',
          }),
          ...get_dynamic_styles(styles?.box_styles),
        }}
      >
        {' '}
        <button
          className="     rounded-button  flex items-center justify-center  gap-2 "
          type="button"
          onClick={() => {
            navigator
              .share({
                title: `${listing_details?.title}`,
                url: `${window.location.href}`,
              })
              .then(() => {
                // alert('Thanks for sharing!');
              })
              .catch();
          }}
          target={data?.button_target === '_blank' ? '_blank' : '_self'}
          style={get_dynamic_styles(styles?.button_styles)}
        >
          <img
            className="w-auto object-cover  "
            style={get_dynamic_styles(styles?.icon_styles)}
            src={data?.icon}
            alt="image"
          />
        </button>
        <div className="w-auto h-auto flex items-center gap-1">
          <button
            className="     rounded-button  flex items-center justify-center  gap-2 "
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              like(listing_details.id, listing_details.liked);
            }}
            target={data?.button_target === '_blank' ? '_blank' : '_self'}
            style={get_dynamic_styles(styles?.button_styles)}
          >
            {listing_details?.liked ? (
              <img
                className="w-auto object-cover  "
                style={get_dynamic_styles(styles?.icon_styles)}
                src={data?.liked_icon}
                alt="image"
              />
            ) : (
              <img
                className="w-auto object-cover  "
                style={get_dynamic_styles(styles?.icon_styles)}
                src={data?.unlike_icon}
                alt="image"
              />
            )}
          </button>
          <span className="description-caption">{listing_details?.likes}</span>
        </div>
      </div>
    </div>
  );
};

export default WishWIthShare;
