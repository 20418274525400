import React from 'react';
import {
  getFormatBySelectedCurrency,
  getFormatOFSelectedCurrency,
} from '../../../utils/CurrencyFunctions';
import Heading1 from '../../../UI/Text/Headers/Heading1';
import { Wallet_text } from '../../../constant/Translation/wallet';
import {
  amountPayInByMangoPayCalc,
  amountWithdrawByMangoPayCalc,
} from '../../../store/feature/wallet/walletSlice';
import { useDispatch } from 'react-redux';
import OtpInput from 'react-otp-input';
import Paragraph from '../../../UI/Text/Paragraph/Paragraph';

const VerifyWithdraw = ({ code, setCode }) => {
  return (
    <div className=" max-w-lg mx-auto bg-white flex flex-col gap-4 p-6">
      <div className="my-6 space-y-6">
        <Heading1 styles=" md:text-[32px] ">
          {Wallet_text.wallet.verify_withdraw}
        </Heading1>
        <Paragraph styles=" text-center">
          {Wallet_text.wallet.verify_withdraw_description}
        </Paragraph>
      </div>
      <OtpInput
        value={code}
        onChange={(otp) => setCode(otp)}
        className=" otp-input  w-[45px] h-[45px] xs:w-[50px] xs:h-[50px]    md:w-[54px] md:h-[54px]  lg:w-[64px]  lg:h-[64px] mb-6 bg-transparent border  border-primary  rounded-full   !text-primary outline-none placeholder-primary  text-center text-xl font-semibold  mr-2 xs:mr-4 focus:border-primary focus:ring-0    "
        numInputs={6}
        inputStyle="block w-full p-0 !text-primary"
        containerStyle="flex items-center justify-center"
        separator={<span></span>}
        isInputNum={true}
      />
    </div>
  );
};

export default VerifyWithdraw;
