export var Shipping_text = {
  shipping: {
    checkout: 'CHECK OUT',
    refreshing: 'Refreshing',
    selec_shipping_method: 'Select Shipping Method',
    select_shipping_address: 'Select Shipping address',
    select_shipment_method: 'Select Shipment Method',
    shipment_option: 'Shipment Option',
    you_pay: 'YOU PAY',
  },
  shipment: {
    alert_message_choose_shipment: 'Please choose preferred shipping',
    change: 'Change',
    continue: 'Continue',
    delivery_address: 'Delivery Address',
    delivery_fee: 'Delivery Fee',
    exact_address: 'Exact Address',
    header_title: 'Shipment',
    pickup_address: 'Pickup Address',
    price: 'Price',
    price_details: 'Price Details',
    prices: 'Prices',
    total_amount: 'Total Amount',
  },
};
