import { ArrowDownIcon, ChevronDownIcon } from '@heroicons/react/outline';
import { ChevronUpIcon } from '@heroicons/react/solid';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/Redux';
import {
  getSubscriptionList,
  subscribeBYMangoPay,
  SubscriptionSelector,
} from '../../store/feature/subscription/subscriptionSlice';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import {
  onCloseSidebar,
  onOpenSidebar,
} from '../../store/feature/SidebarSlice';
import Card from '../Wallet/Cards/Card';
import Cards from '../Cards/Cards';
import { useRouter } from 'next/router';
import { getCookie } from 'cookies-next';
import { TYPE_CONSTANT } from '../../constant/Web_constant';

const SubscriptionByCard = ({ data, styles, extra_data }) => {
  const dispatch = useAppDispatch();
  const router = useRouter();

  //
  const { subscription_products, isFetching, isSubscribeFetching } =
    useAppSelector(SubscriptionSelector);
  const [selected_subscription, setSelectedSubscription] = useState(null);
  const account_id = getCookie(`${TYPE_CONSTANT.DOMAIN}_storeAccountID`);

  const [selected_card, setSelectedCard] = useState(null);

  useEffect(() => {
    if (subscription_products === null) {
      if (account_id) {
        dispatch(getSubscriptionList({ account_id }));
      } else {
        router.push('/a/create-account');
      }
    }
  }, [subscription_products, account_id]);

  useEffect(() => {
    if (data?.subscription_id && subscription_products !== null) {
      setSelectedSubscription(
        subscription_products.find(
          (product) => product.id == data?.subscription_id
        )
      );
    }
  }, [data?.subscription_id, subscription_products]);

  //
  const handle_pay_click = (selected_subscription, card_id) => {
    dispatch(
      // @ts-ignore
      subscribeBYMangoPay({
        subscription_info: {
          amount: selected_subscription?.amount,
          card_id: selected_card?.id,
          subscription_product_id: selected_subscription?.id,
          currency: selected_subscription?.currency?.code,
          card_id,
          account_id: getCookie(`${TYPE_CONSTANT.DOMAIN}_storeAccountID`),
        },
        window_info: {
          color_depth: window.screen.colorDepth,
          screen_height: window.innerHeight,
          screen_width: window.innerWidth,
          time_zone_offset: new Date().getTimezoneOffset(),
        },
      })
    ).then((res) => {
      if (res?.payload?.redirect_url) {
        router.push(res?.payload?.redirect_url);
      }
    });
  };

  // on start buy plan
  const onStartBuyPlan = (e) => {
    e.stopPropagation();

    dispatch(
      onOpenSidebar({
        sidebar_id: 'buy-plan-sidepan',
        modal_section_styles:
          'fixed  ltr:right-0 rtl:left-0 bottom-0 lg:top-0 lg:my-auto   z-[60]  block flex items-center  justify-center  transform bg-white rounded shadow-lg   max-w-full md:max-w-4xl w-full   rounded-t-[40px]   lg:rounded-card  overflow-hidden',
        modal_inside_section_styles:
          'w-full   max-h-[80VH] md:max-h-full  h-full lg:scrollbar-thin   lg:scrollbar-track-transparent overflow-auto  lg:scrollbar-thumb-transparent  ',
        is_close_by_outer_click: true,

        sidebar_inside_component: (
          <Cards
            closeSidebar={() => dispatch(onCloseSidebar())}
            amount_formatted={selected_subscription?.currency_amount?.formatted}
            type="subscription"
            onPayClick={(card_id) =>
              handle_pay_click(selected_subscription, card_id)
            }
            currency={selected_subscription?.currency?.code}
          />
        ),
      })
    );
  };

  return (
    <div
      style={get_dynamic_styles(styles?.main_styles)}
      className={isFetching && 'animate-pulse'}
    >
      {!isFetching && (
        <div
          className="flex flex-col"
          style={get_dynamic_styles(styles?.box_styles)}
        >
          <h1 style={get_dynamic_styles(styles?.title_styles)}>
            {selected_subscription?.title}
          </h1>
          <p style={get_dynamic_styles(styles?.description_styles)}>
            {selected_subscription?.description?.length > 140
              ? selected_subscription?.description?.slice(0, 140) + '...'
              : selected_subscription?.description}
          </p>
          <p style={get_dynamic_styles(styles?.price_styles)}>
            {selected_subscription?.currency_amount?.formatted}
          </p>
          <button
            disabled={selected_subscription?.subscription_status}
            style={get_dynamic_styles(styles?.button_styles)}
            className={
              selected_subscription?.subscription_status &&
              '!bg-gray-300 !text-gray-500 !bg-opacity-50 cursor-not-allowed'
            }
            onClick={
              !selected_subscription?.subscription_status && onStartBuyPlan
            }
          >
            {selected_subscription?.subscription_status
              ? 'Current Plan'
              : ' Buy Plan'}
          </button>
        </div>
      )}
    </div>
  );
};

export default SubscriptionByCard;
