/* eslint-disable react/prop-types */
import React from 'react';
import { Listing_text } from '../../../constant/Translation/Listing';
import { rating_icon } from '../../Shared/Constant/Icons/AllIcons';
import { get_dynamic_styles_with_default_value } from '../../../constant/DynamicStylesWithDefaultValue';
import {
  CardBoxDefaultStyles,
  CardMainDefaultStyles,
} from '../../../constant/default_styles/ProductDetailsStyles';

const RatingBox = ({ rating_data, styles }) => {
  return (
    <div
      className=" w-full "
      style={get_dynamic_styles_with_default_value(
        styles?.main_styles,
        CardMainDefaultStyles
      )}
    >
      <div
        className=" w-full m "
        style={get_dynamic_styles_with_default_value(
          styles?.box_styles,
          CardBoxDefaultStyles
        )}
      >
        <h2 className="heading-h2">{Listing_text.product.ratings}</h2>
        <div className=" mt-4   grid    grid-cols-1   lg:grid-cols-[35%,65%] xl:grid-cols-2  items-center justify-center  ">
          <div className="flex flex-col justify-end items-center">
            <p className=" text-black  font-semibold text-6xl  my-4">
              {rating_data.rating_average}
            </p>
            <p className=" description-caption  ">
              {rating_data.rating_count} {Listing_text.product.ratings}
            </p>
          </div>
          <div className="mt-5 lg:mt-0 max-w-md w-full mx-auto">
            {/* rating 1 */}
            <div className="flex items-center justify-center gap-3">
              <p className=" flex-none flex justify-end items-center gap-1 w-32">
                {rating_icon}
                {rating_icon}
                {rating_icon}
                {rating_icon}
                {rating_icon}
              </p>
              <p className=" relative  flex-grow   block  w-full   bg-gray-300 h-2   rounded-full ">
                <span
                  className={` absolute top-0 left-0  bg-primary h-2 w-[${
                    (rating_data.rating_count_data.rating_5 /
                      (rating_data.rating_count > 0
                        ? rating_data.rating_count
                        : 1)) *
                    (rating_data.rating_count > 0 &&
                    rating_data.rating_count_data.rating_5 > 0
                      ? 100
                      : 5)
                  }%] rounded-full `}
                  // style={{width:'10%'}}
                />
              </p>
              <p className="flex-none  px-3">
                {rating_data.rating_count_data.rating_5}
              </p>
            </div>
            {/* rating 4 */}
            <div className="flex items-center justify-center gap-3">
              <p className=" flex-none flex justify-end items-center   gap-1 w-32">
                {rating_icon}
                {rating_icon}
                {rating_icon}
                {rating_icon}
              </p>
              <p className=" relative  flex-grow   block  w-full   bg-gray-300 h-2   rounded-full ">
                <span
                  className={` absolute top-0  bg-primary h-2 w-[${
                    (rating_data.rating_count_data.rating_4 /
                      (rating_data.rating_count > 0
                        ? rating_data.rating_count
                        : 1)) *
                    (rating_data.rating_count > 0 &&
                    rating_data.rating_count_data.rating_4 > 0
                      ? 100
                      : 5)
                  }%] rounded-full `}
                />
              </p>
              <p className="flex-none  px-3">
                {rating_data.rating_count_data.rating_4}
              </p>
            </div>
            {/* rating 3 */}
            <div className="flex items-center justify-center gap-3">
              <p className="flex-none flex justify-end items-center   gap-1 w-32">
                {rating_icon}
                {rating_icon}
                {rating_icon}
              </p>
              <p className=" relative  flex-grow   block  w-full   bg-gray-300 h-2   rounded-full ">
                <span
                  className={` absolute top-0  bg-primary h-2 w-[${
                    (rating_data.rating_count_data.rating_3 /
                      (rating_data.rating_count > 0
                        ? rating_data.rating_count
                        : 1)) *
                    (rating_data.rating_count > 0 &&
                    rating_data.rating_count_data.rating_3 > 0
                      ? 100
                      : 5)
                  }%] rounded-full `}
                />
              </p>
              <p className="flex-none  px-3">
                {rating_data.rating_count_data.rating_3}
              </p>
            </div>
            {/* rating 2 */}
            <div className="flex items-center  justify-center gap-3">
              <p className="flex-none flex items-center justify-end gap-1 w-32">
                {rating_icon}
                {rating_icon}
              </p>
              <p className=" relative  flex-grow   block  w-full   bg-gray-300 h-2   rounded-full ">
                <span
                  className={` absolute top-0  bg-primary h-2 w-[${
                    (rating_data.rating_count_data.rating_2 /
                      (rating_data.rating_count > 0
                        ? rating_data.rating_count
                        : 1)) *
                    (rating_data.rating_count > 0 &&
                    rating_data.rating_count_data.rating_2 > 0
                      ? 100
                      : 5)
                  }%] rounded-full `}
                />
              </p>
              <p className="flex-none  px-3">
                {rating_data.rating_count_data.rating_2}
              </p>
            </div>
            {/* rating 1 */}
            <div className="flex items-center justify-center gap-3">
              <p className="flex-none flex items-center gap-1 justify-end w-32">
                {rating_icon}
              </p>
              <p className=" relative  flex-grow   block  w-full   bg-gray-300 h-2   rounded-full ">
                <span
                  className={` absolute top-0  bg-primary h-2 w-[${
                    (rating_data.rating_count_data.rating_1 /
                      (rating_data.rating_count > 0
                        ? rating_data.rating_count
                        : 1)) *
                    (rating_data.rating_count > 0 &&
                    rating_data.rating_count_data.rating_1 > 0
                      ? 100
                      : 5)
                  }%] rounded-full `}
                />
              </p>
              <p className="flex-none  px-3">
                {rating_data.rating_count_data.rating_1}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RatingBox;
