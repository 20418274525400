import { getCookie } from 'cookies-next';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import ListingDescription from './Blocks/ListingDescription';
import ListingTitle from './Blocks/ListingTitle';
import ListingImage from './Blocks/ListingImage';
import ListingPrice from './Blocks/ListingPrice';
import ListingText from './Blocks/ListingText';
import { dynamic_text_convert } from '../../utils/dynamicTextConvert';
import { get_data_by_current_language } from '../../utils/GetDataByLanguage';
import ListingAttribute from './Blocks/ListingAttribute';
import ImageBlock from '../StaticBlocks/ImageBlock';
import CustomImage from './Blocks/CustomImage';
import ListingTitleWithAttribute from './Blocks/ListingTitleWithAttribute';
import HeadingBlock from '../StaticBlocks/HeadingBlock';
import ListingRating from './Blocks/ListingRating';
import AddToWishListButton from './Blocks/AddTowishListButton';
import { sell_button_helper } from '../../helpers/ListingDetails/SellButton';
import DynamicVariants from '../ListingDetails/Variants/DynamicVariants';
import CustomCtaWithEffect from '../StaticBlocks/CustomCtaWithEffect';

//  component_decide
export const dynamic_card_block_decide = ({
  order_number,
  block,
  block_code,
  block_data_key,
  fetch_by,
  data,
  isStatic,
  extra_data,
  styles,
  isShowInMobile,
  isShowInWeb,
  isShowUnauthorizedGuest,
  isShowAuthorizedPeople,
  screen_width: width,
  isEnabled = true,
  product,
  like,
  selectedVariant,
  setSelectedVariant,
  setAlert_type,
  setMessage,
  setOpenAlert,
}) => {
  const _styles = styles ?? data?.styles ?? {};
  const section = {
    order_number,
    block,
    block_data_key,
    fetch_by,
    data,
    isStatic,
    extra_data,
    styles,
  };
  const { enabled } = data || {};

  // check_unauthorized_guest
  const check_unauthorized_guest = (isShowUnauthorizedGuest) => {
    const isLoggedIN = getCookie(`${TYPE_CONSTANT.DOMAIN}_login`);
    return isShowUnauthorizedGuest ? true : isLoggedIN ? true : false;
  };

  // check_authorized_peop
  const check_authorized_people = (isShowAuthorizedPeople) => {
    const isLoggedIN = getCookie(`${TYPE_CONSTANT.DOMAIN}_login`);
    return isShowAuthorizedPeople ? true : isLoggedIN ? false : true;
  };

  switch (block) {
    case 'listing_title':
      return <ListingTitle title={product?.title} styles={styles} />;
    case 'listing_description':
      return (
        <ListingDescription
          description={product?.description}
          styles={styles}
          data={data}
        />
      );
    case 'listing_image':
      return (
        <ListingImage images={product?.images} styles={styles} data={data} />
      );
    case 'listing_account_image':
      return (
        <ListingImage
          images={product?.account?.images}
          styles={styles}
          data={data}
        />
      );
    case 'listing_offer_price':
      return (
        <ListingPrice
          price={product?.offer_price?.formatted}
          styles={styles}
          data={data}
        />
      );
    case 'listing_shipping_charges':
      return (
        <ListingPrice
          price={product?.shipping_charges?.formatted}
          styles={styles}
          data={data}
        />
      );
    case 'listing_list_price':
      return (
        <ListingPrice
          price={product?.list_price?.formatted}
          styles={styles}
          data={data}
        />
      );
    case 'listing_stock':
      return (
        <ListingText
          text={`${product?.stock} ${data?.stock_text}`}
          styles={styles}
          data={data}
        />
      );
    case 'listing_account_name':
      return (
        enabled !== false && (
          <ListingText
            text={product?.account?.name}
            styles={styles}
            data={data}
          />
        )
      );

    case 'listing_account_owner_name':
      return (
        <ListingText
          text={`${product?.account?.user?.first_name} ${product?.account?.user?.last_name}`}
          styles={styles}
          data={data}
        />
      );

    case 'listing_attribute':
      return (
        enabled !== false && (
          <ListingAttribute
            attributes={product?.attributes}
            styles={styles}
            data={data}
          />
        )
      );
    case 'listing_title_wih_attribute':
      return (
        <ListingTitleWithAttribute
          title={product?.title}
          attributes={product?.attributes}
          styles={styles}
          data={data}
        />
      );
    case 'image_block':
      return (
        data?.enabled && (
          <CustomImage data={data} styles={_styles} key={order_number} />
        )
      );
    case 'heading_title':
      return (
        data?.enabled && (
          <HeadingBlock data={data} styles={_styles} key={order_number} />
        )
      );
    case 'listing_rating':
      return (
        data?.enabled && (
          <ListingRating
            data={data}
            styles={_styles}
            key={order_number}
            product={product}
          />
        )
      );
    case 'add_to_wishlist_button':
      return (
        <AddToWishListButton
          data={data}
          styles={_styles}
          key={order_number}
          product={product}
          like={like}
          isShowCount={data?.isShowCount}
        />
      );
    case 'sell_button':
      return (
        isEnabled && (
          <div className=" fixed bottom-0 w-full p-4 sm:p-0 left-0 right-0 bg-white sm:static md:bg-transparent z-[60] sm:z-10 sm:    ">
            {sell_button_helper({
              block_code,
              listing_details: product,
              selectedVariant,
              setError_message: setMessage,
              setShowError: setOpenAlert,
              styles,
              data,
            })}
          </div>
        )
      );
    case 'variants':
      return (
        isEnabled && (
          <DynamicVariants
            data={data}
            variants={product?.variants}
            setSelectedVariant={setSelectedVariant}
            selectedVariant={selectedVariant}
            listing_details={product}
            styles={styles}
            key={order_number}
          />
        )
      );
    case 'variants_matrix':
      return (
        isEnabled &&
        product?.variants?.length > 0 && (
          <VariantsMatrix
            data={data}
            variants={product?.variants}
            setSelectedVariant={setSelectedVariant}
            selectedVariant={selectedVariant}
            listing_details={product}
            styles={styles}
            key={order_number}
          />
        )
      );
    case 'custom_cta':
      return isEnabled && <CustomCtaWithEffect data={data} styles={styles} />;

    default:
      break;
  }
};

const keys = [
  'listing_title',
  'listing_description',
  'listing_image',
  'listing_account_image',
  'listing_offer_price',
  'listing_shipping_charges',
  'listing_list_price',
  'listing_stock',
  'listing_account_name',
  'listing_account_owner_name',
  'listing_attribute',
  'heading_title',
  'listing_rating',
  'add_to_wishlist_button',
];
