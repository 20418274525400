import { get_dynamic_styles } from '../../../constant/DynamicStyles';
import { get_data_by_current_language } from '../../../utils/GetDataByLanguage';
import { changeDateFormatFromTimeStamp } from '../../../utils/date';
import { dynamic_text_convert } from '../../../utils/dynamicTextConvert';

const EndAt = ({ data, styles, listingDetails }) => {
  //  { label: 'February 28th 2024, 5:16:36 pm', value: 'MMMM Do YYYY, h:mm:ss a' },
  //         { label: 'Feb 28th 24', value: 'MMM Do YY' },
  //         { label: '...in 7 hours', value: 'end_date_from_now' },

  let date = changeDateFormatFromTimeStamp(
    listingDetails?.end_at,
    data?.date_format
  );

  return (
    <div className="   " style={get_dynamic_styles(styles?.main_styles)}>
      <p style={get_dynamic_styles(styles?.text)}>
        {dynamic_text_convert({
          main_text: get_data_by_current_language({
            data_list: data?.translated_data,
            key_name: 'date_structure',
            default_data: data?.date_structure,
          }),
          value: changeDateFormatFromTimeStamp(
            listingDetails?.end_at,
            data?.date_format
          ),
          check_by: `{date}`,
        })}
      </p>
    </div>
  );
};

export default EndAt;
