import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';
import { replaceSpecificStyles } from '../../../utils/replaceSpacificStyles';
import { useAppDispatch, useAppSelector } from '../../../hooks/Redux';
import {
  getWallets,
  WalletSelector,
} from '../../../store/feature/wallet/walletSlice';
import { getAuthKey } from '../../../constant/functions';
import { getMainPath } from '../../../utils/getMainPath';

const MultiWalletCurrencies = ({ styles }) => {
  const router = useRouter();
  const { pathname, query } = router;
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getWallets());
    // console.log(getAuthKey());
  }, []);

  const { isWalletsFetching, wallets_list } = useAppSelector(WalletSelector);

  useEffect(() => {
    if (wallets_list?.length > 0 && router?.query?.code == undefined) {
      router.push({
        pathname: getMainPath(router.asPath),
        query: { ...router.query, code: wallets_list[0].balance.currency },
      });
    }
  }, [wallets_list]);

  return (
    <div style={get_dynamic_styles(styles?.main_styles)}>
      <div
        style={{
          display: styles?.box_styles?.display,
          ...get_dynamic_styles(styles?.box_styles),
        }}
      >
        {/* Tabs */}
        {wallets_list?.map((wallet) => {
          return (
            <button
              key={wallet?.id}
              onClick={() =>
                router.push({
                  pathname: getMainPath(router.asPath),
                  query: { ...router.query, code: wallet.balance.currency },
                })
              }
              style={{
                display: styles?.tab_style?.display,
                ...get_dynamic_styles(
                  query?.code == wallet.balance.currency
                    ? styles?.tab_style
                    : replaceSpecificStyles({
                        styles: styles?.tab_style,
                        remove_keys: {
                          background: 'transparent',
                          text_color: styles?.button_styles?.background,
                        },
                        initial_values: {
                          border_color: styles?.button_styles?.background,
                        },
                      })
                ),
              }}
            >
              {/* Title */}
              <span
                style={{
                  ...get_dynamic_styles(
                    query?.code == wallet.balance.currency
                      ? replaceSpecificStyles({
                          styles: styles?.tab_title_style,
                          remove_keys: {
                            text_color: '#ffffff',
                          },
                          initial_values: {},
                        })
                      : styles?.tab_title_style
                  ),
                }}
              >
                {wallet.balance.currency}
              </span>

              {/* Blance */}
              <span
                style={{
                  ...get_dynamic_styles(
                    query?.code == wallet.balance.currency
                      ? replaceSpecificStyles({
                          styles: styles?.tab_balance_style,
                          remove_keys: {
                            text_color: '#ffffff',
                          },
                          initial_values: {},
                        })
                      : styles?.tab_balance_style
                  ),
                }}
              >
                {wallet.balance.formatted}
              </span>
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default MultiWalletCurrencies;
