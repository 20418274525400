import React from 'react';

import { useState } from 'react';
import { useEffect } from 'react';
import { useRouter } from 'next/dist/client/router';
import { useDispatch } from 'react-redux';
import { listingLike } from '../../../store/feature/listingSlice';
import Link from 'next/link';
import { check_login } from '../../../constant/check_auth';
import axios from 'axios';
import { Listing_text } from '../../../constant/Translation/Listing';
import ListingsCarousel from '../../Listings/ListingsCarousel';
import { get_dynamic_styles_with_default_value } from '../../../constant/DynamicStylesWithDefaultValue';
import {
  CardBoxDefaultStyles,
  CardMainDefaultStyles,
} from '../../../constant/default_styles/ProductDetailsStyles';
import ListingsCarouselSkeleton from '../../Shared/skeletonUI/Listing/ListingsCarouselSkeleton';

const RelatedListings = ({ listing_details, styles }) => {
  const router = useRouter();
  const dispatch = useDispatch();

  const [related_listings, setRelated_listings] = useState(null);
  useEffect(() => {
    if (listing_details) {
      axios
        .get('/api/l/similar', {
          params: {
            page: 1,
            per_page: 30,
            id: listing_details?.id,
          },
        })
        .then((res) => {
          setRelated_listings(res.data.listings);
        });
    }
  }, [listing_details]);

  //Like  Button Handle
  const like = (id, isLiked) => {
    if (check_login(router)) {
      dispatch(
        listingLike({
          id: id,
          isLiked: isLiked,
        })
      ).then((res) => {
        if (!res.payload.code) {
          axios
            .get('/api/l/similar', {
              params: {
                page: 1,
                per_page: 30,
                id: listing_details?.id,
              },
            })
            .then((res) => {
              setRelated_listings(res.data.listings);
            });
        }
      });
    }
  };

  return (
    <>
      {related_listings !== null ? (
        related_listings?.length > 0 && (
          <div
            className=" w-full "
            style={get_dynamic_styles_with_default_value(
              styles?.main_styles,
              CardMainDefaultStyles
            )}
          >
            <div
              className="w-full  "
              style={get_dynamic_styles_with_default_value(
                styles?.box_styles,
                CardBoxDefaultStyles
              )}
            >
              <div className=" flex justify-between items-center">
                <h2 className="heading-h2">
                  {Listing_text.product.similar_product_title}
                </h2>
                <Link
                  href={{
                    pathname: `/l/similar/${listing_details?.id}`,
                  }}
                >
                  <a className=" btn-primary-fill">
                    {Listing_text.product.see_all}
                  </a>
                </Link>
              </div>
              <div className="mt-4 ">
                <ListingsCarousel
                  Products={related_listings}
                  like_listing={like}
                />
              </div>
            </div>
          </div>
        )
      ) : (
        <div className="w-full  min-h-min p-4 bg-[#FFFFFF] rounded-card   shadow-c-sm">
          {' '}
          <ListingsCarouselSkeleton />
        </div>
      )}
    </>
  );
};

export default RelatedListings;
