import Link from 'next/link';
import React from 'react';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { get_dynamic_styles_with_default_value } from '../../constant/DynamicStylesWithDefaultValue';
import {
  CardBoxDefaultStyles,
  CardMainDefaultStyles,
} from '../../constant/default_styles/ProductDetailsStyles';
import { get_data_by_current_language } from '../../utils/GetDataByLanguage';

const TextWithIcon = ({ data, styles }) => {
  return (
    <div style={get_dynamic_styles(styles?.main_styles)}>
      <div className="flex" style={get_dynamic_styles(styles?.box_styles)}>
        {data?.icon && (
          <img
            className="w-auto object-cover  "
            style={get_dynamic_styles(styles?.icon_styles)}
            src={data?.icon}
            alt="image"
          />
        )}
        {data?.text && (
          <p
            className="text-lg font-semibold text-gray-700"
            style={get_dynamic_styles(styles?.text_styles)}
          >
            {get_data_by_current_language({
              data_list: data?.translated_data,
              key_name: 'text',
              default_data: data?.text,
            })}
          </p>
        )}
      </div>
    </div>
  );
};

export default TextWithIcon;
