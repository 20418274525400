import React, { useState } from 'react';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { MinusIcon, PlusIcon } from '@heroicons/react/outline';
import { cn } from '../../utils/classNames';
import { dynamic_text_convert } from '../../utils/dynamicTextConvert';
import { get_data_by_current_language } from '../../utils/GetDataByLanguage';

const Accordion = ({ data, styles }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleButton = (index) => {
    // @ts-ignore
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div style={get_dynamic_styles(styles?.main_styles)}>
      <div
        style={{
          display: styles?.box_styles?.display,
          gridTemplateColumns: dynamic_text_convert({
            main_text: styles?.box_styles?.columns_box_percentage,
            check_by: '-1%',
            value: '',
          }),
          ...get_dynamic_styles(styles?.box_styles),
        }}
      >
        {data?.list?.map((item, index) => {
          const item_style =
            index === openIndex
              ? styles?.open_card_styles
              : styles?.close_card_styles;

          return (
            <div
              key={index}
              style={{
                display: item_style?.display,
                gridTemplateColumns: dynamic_text_convert({
                  main_text: item_style?.columns_box_percentage,
                  check_by: '-1%',
                  value: '',
                }),
                ...get_dynamic_styles(item_style),
              }}
            >
              <button
                onClick={() => toggleButton(index)}
                style={{
                  display: styles?.title_button_styles?.display,
                  gridTemplateColumns: dynamic_text_convert({
                    main_text:
                      styles?.title_button_styles?.columns_box_percentage,
                    check_by: '-1%',
                    value: '',
                  }),
                  ...get_dynamic_styles(styles?.title_button_styles),
                }}
              >
                {!data?.right_icon && (
                  <>
                    {openIndex === index ? (
                      <img
                        className="w-auto object-cover  "
                        style={get_dynamic_styles(styles?.icon_styles)}
                        src={data?.close_button_icon}
                        alt="image"
                      />
                    ) : (
                      <img
                        className="w-auto object-cover  "
                        style={get_dynamic_styles(styles?.icon_styles)}
                        src={data?.open_button_icon}
                        alt="image"
                      />
                    )}
                  </>
                )}

                {/* Title */}
                {get_data_by_current_language({
                  data_list: item?.translated_data,
                  key_name: 'title',
                  default_data: item?.title,
                })}

                {/* icon in  */}
                {data?.right_icon && (
                  <>
                    {openIndex === index ? (
                      <img
                        className="w-auto object-cover  "
                        style={get_dynamic_styles(styles?.icon_styles)}
                        src={data?.close_button_icon}
                        alt="image"
                      />
                    ) : (
                      <img
                        className="w-auto object-cover  "
                        style={get_dynamic_styles(styles?.icon_styles)}
                        src={data?.open_button_icon}
                        alt="image"
                      />
                    )}
                  </>
                )}
              </button>

              {openIndex === index && (
                <div style={get_dynamic_styles(styles?.description_styles)}>
                  {get_data_by_current_language({
                    data_list: item?.translated_data,
                    key_name: 'description',
                    default_data: item?.description,
                  })}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Accordion;
