/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';
import {
  CardBoxDefaultStyles,
  CardMainDefaultStyles,
} from '../../../constant/default_styles/ProductDetailsStyles';
import { get_dynamic_styles_with_default_value } from '../../../constant/DynamicStylesWithDefaultValue';

const DonationMainBox = ({ listing_details, rating_data, like, styles }) => {
  const { goal_price, donation_analytics } = listing_details || {};

  const funded_percentage = Math.round(
    (donation_analytics?.total_amount?.amount / goal_price?.amount) * 100
  )
    ? Math.round(
        (donation_analytics?.total_amount?.amount / goal_price?.amount) * 100
      )
    : 0;

  return listing_details !== null ? (
    <div
      className=" w-full "
      style={get_dynamic_styles_with_default_value(
        styles?.main_styles,
        CardMainDefaultStyles
      )}
    >
      <div
        className=" flex flex-col gap-6"
        style={get_dynamic_styles_with_default_value(
          styles?.box_styles,
          CardBoxDefaultStyles
        )}
      >
        <div className=" w-5/6 ">
          <h1 className="  title-h1">{listing_details?.title}</h1>
        </div>

        <div className=" relative  w-[100%] h-[32px] overflow-hidden border-[0.5px]  border-primary   rounded-card     flex   items-center justify-start">
          <p className=" absolute  left-0 right-0  mx-auto my-auto text-center text-[15px] font-normal text-white   ">
            {funded_percentage}% Funded
          </p>
          <div
            className={`h-full bg-primary rounded-card  text-center flex items-center justify-center`}
            style={{
              width: `${funded_percentage === 0 ? 2 : funded_percentage}%`,
            }}
          />
        </div>

        <div className="  flex items-center flex-wrap gap-2">
          <p className="description-paragraph">
            {donation_analytics?.total_amount?.formatted ?? 0}
          </p>
          <p className=" description-caption">
            Raised from {goal_price?.formatted}
          </p>
        </div>
      </div>
    </div>
  ) : (
    <div className=" min-h-min border bg-[#3B3269] bg-opacity-[10%] shadow rounded-card p-4   w-full mx-auto">
      <div className="animate-pulse flex space-x-4">
        <div className="flex-1 space-y-4 py-1">
          <div className="h-4 bg-[#3B3269] bg-opacity-[20%] rounded-card  w-3/4"></div>
          <div className="space-y-2">
            <div className="h-4 bg-[#3B3269] bg-opacity-[20%] rounded"></div>
            <div className="h-4 bg-[#3B3269] bg-opacity-[20%] rounded-card  w-5/6"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DonationMainBox;
