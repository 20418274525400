import React, { memo } from 'react';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { get_dynamic_styles_with_default_value } from '../../constant/DynamicStylesWithDefaultValue';
import { HomeCardBoxDefaultStyles } from '../../constant/default_styles/HomeDefaultStyles';
import { dynamic_text_convert } from '../../utils/dynamicTextConvert';
import { get_data_by_current_language } from '../../utils/GetDataByLanguage';

const WelcomeHeadingTitle = ({
  data,
  styles,
  dynamic_text_value,
  check_by,
}) => {
  let userDetails = JSON.parse(localStorage.getItem('user_details'));

  return (
    <div className="   " style={get_dynamic_styles(styles?.main_styles)}>
      <div
        style={get_dynamic_styles_with_default_value(
          styles?.box_styles,
          HomeCardBoxDefaultStyles
        )}
      >
        <h1 className="  " style={get_dynamic_styles(styles?.text_styles)}>
          {dynamic_text_convert({
            main_text: get_data_by_current_language({
              data_list: data?.translated_data,
              key_name: 'title',
              default_data: data?.title,
            }),
            value: userDetails?.first_name
              ? `${userDetails?.first_name} ${userDetails?.last_name}`
              : data?.default_value
              ? get_data_by_current_language({
                  data_list: data?.translated_data,
                  key_name: 'default_value',
                  default_data: data?.default_value,
                })
              : '',
            check_by: check_by ? check_by : '',
          })}
        </h1>
      </div>
    </div>
  );
};

export default memo(WelcomeHeadingTitle);
