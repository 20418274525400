/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { storeSelector } from '../../../store/feature/storeSlice';
import { MultiSelect } from 'react-multi-select-component';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

import { get_dynamic_styles } from '../../../constant/DynamicStyles';
import { cartAttributes, cartSelector } from '../../../store/feature/cartSlice';
import AttributesList from './AttributesList';
import { dynamic_text_convert } from '../../../utils/dynamicTextConvert';
import { authSelector } from '../../../store/feature/authSlice';
import {
  get_account_attributes_data,
  get_listing_attributes_data,
  get_user_default_data,
} from './cartAttributesDefaultDataSetup';

const CartAttributes = ({
  styles,
  item_attributes,
  setItemAttributes,
  cart_details,
  data,
  isEnabled,
}) => {
  const dispatch = useDispatch();
  const { user_details } = useSelector(authSelector);

  // functions
  useEffect(() => {
    if (cart_details?.[0]?.quantity > 0 && isEnabled) {
      setItemAttributes(
        Array.from({ length: cart_details?.[0]?.quantity }, () => [])
      );
    }

    dispatch(
      cartAttributes({
        prams: {
          type: 'cart_attributes',
        },
      })
    );
  }, [cart_details]);

  const { cart_attributes } = useSelector(cartSelector);
  const [displayColorPicker, setdisplayColorPicker] = useState(null);

  // setup prefill data
  const getDefaultValue = ({ metadata, index_of_item }) => {
    if (metadata?.prefill_for !== 'all_items' && index_of_item !== 0) {
      return undefined;
    }
    if (metadata?.prefill_type?.value === 'listings') {
      return get_listing_attributes_data({
        prefill_type: metadata?.field_list?.label,
        listing_attributes: cart_details?.[0]?.listing?.attributes,
      });
    } else if (metadata?.prefill_type?.value === 'accounts') {
      return get_account_attributes_data({
        prefill_type: metadata?.field_list?.label,
        listing_attributes: cart_details?.[0]?.listing?.account?.attributes,
      });
    } else if (metadata?.prefill_type?.value === 'user') {
      return get_user_default_data({
        prefill_type: metadata?.field_list?.value,
        user_info: user_details,
      });
    }

    return '';
  };

  const setup_prefill_data = ({ attributes, index_of_item }) => {
    const list_of_pr_setup = attributes?.filter(
      (a) => a?.metadata && Object.keys(a.metadata)?.length >= 3
    );

    const fill_default_data = list_of_pr_setup?.map((item) => {
      const get_value = getDefaultValue({
        metadata: item?.metadata,
        index_of_item,
      });
      return { values: get_value ? [get_value] : '', id: item?.id };
    });

    setItemAttributes((prev) =>
      prev.map((it, ind) => (ind !== index_of_item ? it : fill_default_data))
    );
  };

  // setup default data
  useEffect(() => {
    if (cart_attributes?.length > 0) {
      item_attributes?.forEach((element, index) => {
        setup_prefill_data({
          attributes: cart_attributes,
          index_of_item: index,
        });
      });
    }
  }, [cart_attributes]);

  return (
    cart_attributes?.length > 0 && (
      <div className="w-full " style={get_dynamic_styles(styles?.main_styles)}>
        {cart_details &&
          item_attributes?.length > 0 &&
          item_attributes?.map((item_attr, index) => {
            return (
              <>
                <p className="text-primary text-xl leading-6 font-medium ">
                  {dynamic_text_convert({
                    main_text: data?.title,
                    value: index + 1,
                    check_by: `{index}`,
                  })}
                </p>
                <AttributesList
                  cart_attributes={cart_attributes}
                  data={data}
                  styles={styles}
                  attributeData={item_attributes[index]}
                  setAttributeData={(data) =>
                    setItemAttributes((prev) =>
                      prev.map((it, ind) => (ind !== index ? it : data))
                    )
                  }
                />
              </>
            );
          })}
      </div>
    )
  );
};

export default CartAttributes;
