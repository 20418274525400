import React, { useEffect } from 'react';
import TextInput from '../../UI/FormFields/TextInput';
import SelectFromList from '../../UI/FormFields/SelectFromList';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from '../../store/feature/authSlice';
import DateInputPicker from '../../UI/DatePicker/DateInputPicker';
import { useRouter } from 'next/router';
import {
  KYC_LEGAL_PROFILE_DETAILS_FORMAT,
  KYC_PROFILE_DETAILS_FORMAT,
} from './types/KYCTypes';
import {
  get_data_from_local_storage,
  set_data_in_local_storage,
} from '../../utils/localStorage';
import { Wallet_text } from '../../constant/Translation/wallet';
import {
  countriesSelector,
  getAllCountriesList,
} from '../../store/feature/countrySlice';

const KYCLegalProfile = ({
  profile_details,
  setProfileDetails,
  group_name,
}: {
  profile_details: KYC_LEGAL_PROFILE_DETAILS_FORMAT;
  setProfileDetails: React.Dispatch<
    React.SetStateAction<KYC_LEGAL_PROFILE_DETAILS_FORMAT>
  >;
  group_name: string;
}) => {
  const router = useRouter();
  const dispatch = useDispatch();

  //  itialization
  useEffect(() => {
    // @ts-ignore

    if (
      get_data_from_local_storage({
        key_name: group_name,
        isEncrypt: true,
      })
    ) {
      setProfileDetails((arg: any) => ({
        ...arg,
        ...get_data_from_local_storage({
          key_name: group_name,
          isEncrypt: true,
        }),
      }));
    }
  }, [router]);

  //  value change handler
  const valueChangeHandler = <
    K extends keyof KYC_LEGAL_PROFILE_DETAILS_FORMAT
  >({
    value,
    key_name,
  }: {
    value: KYC_LEGAL_PROFILE_DETAILS_FORMAT[K];
    key_name: K;
  }) => {
    setProfileDetails((arg: any) => {
      // set data in local storage for later retrieval
      set_data_in_local_storage({
        key_name: group_name,
        value: { ...arg, [key_name]: value },
        isEncrypt: true,
      });
      // return profile with new value
      return { ...arg, [key_name]: value };
    });
  };

  //legal_types

  const legal_types = [
    { title: 'Business', value: 'BUSINESS' },
    { title: 'Partnership', value: 'PARTNERSHIP' },
    { title: 'Organization', value: 'ORGANIZATION' },
    { title: 'Sole Trader', value: 'SOLETRADER' },
  ];

  //all_countries_list
  const { all_countries_list } = useSelector(countriesSelector);

  return (
    <div className="space-y-6">
      <h2 className="text-center text-2xl font-bold">
        {Wallet_text?.kyc.company_details}
      </h2>
      <div className="   space-y-4">
        {/* legal_representative_first_name */}
        <TextInput
          title={Wallet_text?.kyc.legal_name_of_the_entity}
          current_value={profile_details.legal_name_of_the_entity}
          set_new_value={(vl) =>
            valueChangeHandler({
              value: vl,
              key_name: 'legal_name_of_the_entity',
            })
          }
        />
        {/* legal_type_of_the_entity  */}
        <SelectFromList
          title={Wallet_text?.kyc.legal_type_of_the_entity}
          current_value={profile_details?.legal_type_of_the_entity}
          default_option_text={Wallet_text?.kyc.select_legal_type_of_the_entity}
          drop_down_items={legal_types?.map((legal_type) => {
            return { label: legal_type.title, value: legal_type.value };
          })}
          styles={{
            popover_style: ' h-[250px]',
          }}
          set_new_value={(value: string) =>
            valueChangeHandler({
              value: value,
              key_name: 'legal_type_of_the_entity',
            })
          }
        />
        {/* legal_representative_first_name */}
        <TextInput
          title={Wallet_text?.kyc.legal_representative_first_name}
          current_value={profile_details.legal_representative_first_name}
          set_new_value={(vl) =>
            valueChangeHandler({
              value: vl,
              key_name: 'legal_representative_first_name',
            })
          }
        />
        {/* legal_representative_last_name */}
        <TextInput
          title={Wallet_text?.kyc.legal_representative_last_name}
          current_value={profile_details.legal_representative_last_name}
          set_new_value={(vl) =>
            valueChangeHandler({
              value: vl,
              key_name: 'legal_representative_last_name',
            })
          }
        />

        {/* legal_representative_birthday */}
        <DateInputPicker
          current_value={profile_details.legal_representative_birthday}
          set_new_value={(value: string) => {
            valueChangeHandler({
              value: value,
              key_name: 'legal_representative_birthday',
            });
          }}
          placeholder=""
          title={Wallet_text?.kyc.legal_representative_birthday}
        />
        {/* Nationality  */}
        <SelectFromList
          title={Wallet_text?.kyc.legal_representative_nationality}
          current_value={profile_details?.legal_representative_nationality}
          default_option_text={Wallet_text?.kyc.select_country}
          drop_down_items={all_countries_list?.map((country) => {
            return { label: country.name, value: country.code2 };
          })}
          styles={{
            popover_style: ' h-[250px]',
          }}
          set_new_value={(value: string) =>
            valueChangeHandler({
              value: value,
              key_name: 'legal_representative_nationality',
            })
          }
        />
        {/* Country of residence  */}
        <SelectFromList
          title={Wallet_text?.kyc.legal_representative_country_of_residence}
          current_value={
            profile_details?.legal_representative_country_of_residence
          }
          default_option_text={Wallet_text?.kyc.select_country}
          drop_down_items={all_countries_list?.map((country) => {
            return { label: country.name, value: country.code2 };
          })}
          styles={{
            popover_style: ' h-[250px]',
          }}
          set_new_value={(value: string) =>
            valueChangeHandler({
              value: value,
              key_name: 'legal_representative_country_of_residence',
            })
          }
        />

        {/* legal_representative_last_name */}
        <TextInput
          title={Wallet_text?.kyc.company_registration_number}
          current_value={profile_details.company_registration_number}
          set_new_value={(vl) =>
            valueChangeHandler({
              value: vl,
              key_name: 'company_registration_number',
            })
          }
        />
        {/* legal_representative_last_name */}
        <TextInput
          title={Wallet_text?.kyc.email_address_of_the_company}
          current_value={profile_details.email_address_of_the_company}
          set_new_value={(vl) =>
            valueChangeHandler({
              value: vl,
              key_name: 'email_address_of_the_company',
            })
          }
        />
      </div>
      <h2 className="text-center text-2xl font-bold">
        {Wallet_text?.kyc.headquarters_address}
      </h2>
      <div className="   space-y-4">
        {/* Address 1 */}
        <TextInput
          current_value={profile_details.address_1}
          set_new_value={(vl) =>
            valueChangeHandler({
              value: vl,
              key_name: 'address_1',
            })
          }
          title={Wallet_text?.kyc.address_1}
        />
        {/* Address 2 */}
        <TextInput
          current_value={profile_details.address_2}
          set_new_value={(vl) =>
            valueChangeHandler({
              value: vl,
              key_name: 'address_2',
            })
          }
          title={Wallet_text?.kyc.address_2}
        />

        {/* City & state */}
        <div className=" grid  grid-cols-2 gap-4">
          <TextInput
            current_value={profile_details.city}
            set_new_value={(vl) =>
              valueChangeHandler({
                value: vl,
                key_name: 'city',
              })
            }
            title={Wallet_text?.kyc.city}
          />
          <TextInput
            current_value={profile_details.state}
            set_new_value={(vl) =>
              valueChangeHandler({
                value: vl,
                key_name: 'state',
              })
            }
            title={Wallet_text?.kyc.state}
          />
        </div>
        {/* City & state */}
        <div className=" grid  grid-cols-2 gap-4">
          <TextInput
            current_value={profile_details.postal_code}
            set_new_value={(vl) =>
              valueChangeHandler({
                value: vl,
                key_name: 'postal_code',
              })
            }
            title={Wallet_text?.kyc.postal_code}
          />
          <SelectFromList
            title={Wallet_text?.kyc.country}
            current_value={profile_details?.country}
            drop_down_items={all_countries_list?.map((country) => {
              return { label: country.name, value: country.code2 };
            })}
            styles={{
              popover_style: ' h-[250px]',
            }}
            set_new_value={(value: string) =>
              valueChangeHandler({
                value: value,
                key_name: 'country',
              })
            }
            default_option_text={Wallet_text?.kyc.select_country}
          />
        </div>
      </div>
    </div>
  );
};

export default KYCLegalProfile;
