import React, { memo } from 'react';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { get_dynamic_styles_with_default_value } from '../../constant/DynamicStylesWithDefaultValue';
import { HomeCardBoxDefaultStyles } from '../../constant/default_styles/HomeDefaultStyles';
import Link from 'next/link';

const ImageDestinationBlock = ({ data, styles }) => {
  return (
    <div className="  mx-auto " style={get_dynamic_styles(styles?.main_styles)}>
      <div
        style={get_dynamic_styles_with_default_value(
          styles?.box_styles,
          HomeCardBoxDefaultStyles
        )}
      >
        <Link href={data?.url}>
          <a
            className="w-full h-full"
            target={data?.target}
            rel="noopener noreferrer"
          >
            <img
              style={get_dynamic_styles(styles?.image_styles)}
              src={data.image_src}
              alt="image"
            />
          </a>
        </Link>
      </div>
    </div>
  );
};

export default memo(ImageDestinationBlock);
