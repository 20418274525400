import React, { useEffect } from 'react';
import TextInput from '../../UI/FormFields/TextInput';
import SelectFromList from '../../UI/FormFields/SelectFromList';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from '../../store/feature/authSlice';
import DateInputPicker from '../../UI/DatePicker/DateInputPicker';
import { useRouter } from 'next/router';
import { KYC_PROFILE_DETAILS_FORMAT } from './types/KYCTypes';
import {
  get_data_from_local_storage,
  set_data_in_local_storage,
} from '../../utils/localStorage';
import { Wallet_text } from '../../constant/Translation/wallet';
import {
  countriesSelector,
  getAllCountriesList,
} from '../../store/feature/countrySlice';

const KYCProfile = ({
  profile_details,
  setProfileDetails,
  group_name,
}: {
  profile_details: KYC_PROFILE_DETAILS_FORMAT;
  setProfileDetails: React.Dispatch<
    React.SetStateAction<KYC_PROFILE_DETAILS_FORMAT>
  >;
  group_name: string;
}) => {
  const router = useRouter();
  const dispatch = useDispatch();

  //  itialization
  useEffect(() => {
    // @ts-ignore

    if (
      get_data_from_local_storage({
        key_name: group_name,
        isEncrypt: true,
      })
    ) {
      setProfileDetails((arg: any) => ({
        ...arg,
        ...get_data_from_local_storage({
          key_name: group_name,
          isEncrypt: true,
        }),
      }));
    }
  }, [router]);

  //  value change handler
  const valueChangeHandler = <K extends keyof KYC_PROFILE_DETAILS_FORMAT>({
    value,
    key_name,
  }: {
    value: KYC_PROFILE_DETAILS_FORMAT[K];
    key_name: K;
  }) => {
    setProfileDetails((arg: any) => {
      // set data in local storage for later retrieval
      set_data_in_local_storage({
        key_name: group_name,
        value: { ...arg, [key_name]: value },
        isEncrypt: true,
      });
      // return profile with new value
      return { ...arg, [key_name]: value };
    });
  };

  //
  const { all_countries_list } = useSelector(countriesSelector);

  return (
    <div className="space-y-6">
      <h2 className="text-center text-2xl font-bold">
        {Wallet_text?.kyc.personal_details}
      </h2>
      <div className="   space-y-4">
        {/* Birthday */}
        <DateInputPicker
          current_value={profile_details.birth_date}
          set_new_value={(value: string) => {
            valueChangeHandler({
              value: value,
              key_name: 'birth_date',
            });
          }}
          placeholder=""
          title={Wallet_text?.kyc.birth_date}
        />

        {/* Nationality  */}
        <SelectFromList
          title={Wallet_text?.kyc.nationality}
          current_value={profile_details?.nationality_country_code}
          default_option_text={Wallet_text?.kyc.select_country}
          drop_down_items={all_countries_list?.map((country) => {
            return { label: country.name, value: country.code2 };
          })}
          styles={{
            popover_style: ' h-[250px]',
          }}
          set_new_value={(value: number) =>
            valueChangeHandler({
              value: value,
              key_name: 'nationality_country_code',
            })
          }
        />
        {/* Country of residence  */}
        <SelectFromList
          title={Wallet_text?.kyc.country_of_residence}
          current_value={profile_details?.residence_country_code}
          default_option_text={Wallet_text?.kyc.select_country}
          drop_down_items={all_countries_list?.map((country) => {
            return { label: country.name, value: country.code2 };
          })}
          styles={{
            popover_style: ' h-[250px]',
          }}
          set_new_value={(value: number) =>
            valueChangeHandler({
              value: value,
              key_name: 'residence_country_code',
            })
          }
        />
      </div>
    </div>
  );
};

export default KYCProfile;
