import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  filterSelector,
  getAttributesForFilter,
  getCategoriesForFilter,
  onFilterParamsUpdate,
} from '../../store/feature/filter/filterSlice';
import { get_data_by_current_language } from '../../utils/GetDataByLanguage';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { useRouter } from 'next/router';
import { getMainPath } from '../../utils/getMainPath';

const FlexibleDateRange = ({ filer_item, styles }) => {
  const {
    filter_type,
    title,
    translated_data,
    attribute_id,
    is_show_title,
    is_depend_on_search,
  } = filer_item;
  const router = useRouter();
  const dispatch = useDispatch();
  const { attributes, filter_params, queries } = useSelector(filterSelector);

  useEffect(() => {
    if (attributes === null) {
      dispatch(getAttributesForFilter({}));
    }
  }, [0]);

  const filter_attribute = attributes?.find((item) => item.id == attribute_id);
  const values_ids = filter_attribute?.values?.map((vl) => vl.id) ?? [];

  //
  const onUpdateQuires = (updated_quires) => {
    if (is_depend_on_search === false) {
      if (Object.keys(updated_quires).length === 0) {
        router.push({
          pathname: getMainPath(router.asPath),
          query: { page: router.query?.page ? router.query?.page : 1 },
        });
      }
      router.push({
        pathname: getMainPath(router.asPath),
        query: updated_quires ?? queries,
      });
    }
  };

  //
  const filter_by_attribute_value = () => {
    const current_value = filter_params?.[`flexible_date_range`] ?? undefined;

    if (current_value === undefined) {
      dispatch(
        onFilterParamsUpdate({
          [`flexible_date_range`]: filer_item?.range,
          callOnFilterUpdate: (updated_quires) => {
            onUpdateQuires(updated_quires);
          },
        })
      );
    } else {
      dispatch(
        onFilterParamsUpdate({
          [`flexible_date_range`]: null,
          callOnFilterUpdate: (updated_quires) => {
            onUpdateQuires(updated_quires);
          },
        })
      );
    }
  };

  const current_value = filter_params?.[`flexible_date_range`] ?? undefined;

  return (
    <div style={get_dynamic_styles(styles?.filter_component_style)}>
      {title && is_show_title && (
        <p style={get_dynamic_styles(styles?.filter_title_style)}>
          {get_data_by_current_language({
            data_list: translated_data,
            key_name: 'title',
            default_data: title,
          })}
        </p>
      )}

      {/* All Button */}
      <p
        className="flex"
        style={get_dynamic_styles(styles?.filter_single_select_radio_style)}
        key={Math.random()}
      >
        <input
          className=" form-check-input appearance-none h-3 w-3 border border-gray-300   bg-white checked:bg-primary checked:border-primary focus:outline-none focus:ring-primary checked:hover:bg-primary  checked:focus:ring-primary checked:focus:bg-primary  transition duration-200   align-top bg-no-repeat bg-center bg-contain cursor-pointer"
          type="radio"
          checked={current_value ? true : false}
          onChange={() => filter_by_attribute_value()}
        />
        <span className=" ">Yes</span>
      </p>
      {/* All Button */}
      <p
        className="flex"
        style={get_dynamic_styles(styles?.filter_single_select_radio_style)}
        key={Math.random()}
      >
        <input
          className=" form-check-input appearance-none h-3 w-3 border border-gray-300   bg-white checked:bg-primary checked:border-primary focus:outline-none focus:ring-primary checked:hover:bg-primary  checked:focus:ring-primary checked:focus:bg-primary  transition duration-200   align-top bg-no-repeat bg-center bg-contain cursor-pointer"
          type="radio"
          checked={!current_value ? true : false}
          onChange={() => filter_by_attribute_value()}
        />
        <span className=" ">No</span>
      </p>
    </div>
  );
};

export default FlexibleDateRange;
