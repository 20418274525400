import axios from 'axios';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { authSelector } from '../../../store/feature/authSlice';
import DropdownMore from '../Modal/DropDownMore';
import bwipjs from 'bwip-js';
import SidePan from '../Modal/SidePan';
import Cookies from 'js-cookie';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import { Listing_text } from '../../../constant/Translation/Listing';

const Edit_listing_button = ({ listing_details }) => {
  const { user_details } = useSelector(authSelector);
  const router = useRouter();

  const [sidePanOpen, setSidePanOpen] = useState(false);

  const [isOwner, setIsOwner] = useState();
  useEffect(() => {
    if (
      Cookies.get(`${TYPE_CONSTANT.DOMAIN}_isStoreAccount`) &&
      JSON.parse(Cookies.get(`${TYPE_CONSTANT.DOMAIN}_isStoreAccount`))
    ) {
      if (
        Cookies.get(`${TYPE_CONSTANT.DOMAIN}_storeAccountID`) &&
        JSON.parse(Cookies.get(`${TYPE_CONSTANT.DOMAIN}_storeAccountID`)) ==
          listing_details.account_id
      ) {
        setIsOwner(true);
      } else {
        setIsOwner(false);
      }
    }
  }, [listing_details]);

  const generate_barcode = async (id) => {
    try {
      // The return value is the canvas element
      let canvas = await bwipjs.toCanvas(id, {
        bcid: 'code128', // Barcode type
        text: listing_details?.barcode
          ? listing_details?.barcode
          : listing_details?.title, // Text to encode
        scale: 3, // 3x scaling factor
        height: 10, // Bar height, in millimeters
        includetext: true, // Show human-readable text,

        textxalign: 'center', // Always good to set this
      });
    } catch (e) {
      // `e` may be a string or Error object
    }
  };

  // Save Image
  const save_image = (type, id_name) => {
    const download = document.getElementById(id_name);

    const link = document.createElement('a');
    link.download = `download.${type}`;
    link.href = download.toDataURL();
    link.click();
    link.delete;
  };

  return isOwner ? (
    <div className=" flex items-center gap-3">
      <button
        className="btn-sm bg-primary border border-transparent text-white  transition duration-300 hover:bg-transparent hover:border-primary hover:text-primary flex items-center gap-2  "
        onClick={() => {
          if (listing_details.active) {
            router.push({
              pathname: '/a/edit-listing',
              query: {
                product_id: listing_details.id,
                account_id: listing_details.account_id,
              },
            });
          } else {
            // console.log('Listing is not active');
          }
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
          />
        </svg>{' '}
        {Listing_text.product.edit}
      </button>
      {/* {!TYPE_CONSTANT.LISTINGS_CONFIGS?.show_more_button_in_listing_details && ( */}
      <button
        className={`w-10 h-10 flex items-center justify-center bg-gray-100 hover:bg-gray-200 transition duration-150 rounded-full ${
          sidePanOpen && 'bg-gray-200'
        }`}
        onClick={(e) => {
          e.stopPropagation();
          setSidePanOpen(true);
          generate_barcode(
            `barcode-canvas-${
              listing_details.slug ? listing_details.slug : listing_details.id
            }`
          );
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
          />
        </svg>
      </button>
      {/* )} */}
      <SidePan
        id={`listing-details-sidepan-${
          listing_details.slug ? listing_details.slug : listing_details.id
        }`}
        sidePanOpen={sidePanOpen}
        setSidePanOpen={setSidePanOpen}
      >
        <div className="h-screen   py-5 px-5">
          <div className=" flex items-center justify-end">
            <button
              className="text-gray-400 hover:text-gray-500"
              onClick={(e) => {
                e.stopPropagation();
                setSidePanOpen(false);
              }}
            >
              <div className="sr-only">Close</div>
              <svg className="w-4 h-4 fill-current">
                <path d="M7.95 6.536l4.242-4.243a1 1 0 111.415 1.414L9.364 7.95l4.243 4.242a1 1 0 11-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 01-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 011.414-1.414L7.95 6.536z" />
              </svg>
            </button>
          </div>

          <div className="space-y-5  divide-y-2">
            {/* Barcode Preview */}
            <section className="flex flex-col space-y-3 mb-5">
              <h4 className="text-lg font-semibold text-black">
                {Listing_text.product.barcode_preview}
              </h4>
              <canvas
                className="w-4/5"
                id={`barcode-canvas-${
                  listing_details.slug
                    ? listing_details.slug
                    : listing_details.id
                }`}
              ></canvas>
              <div className="flex items-center justify-start gap-10">
                <button
                  className="btn-sm bg-primary border border-transparent text-white  transition duration-300 hover:bg-transparent hover:border-primary hover:text-primary flex items-center gap-2  "
                  onClick={(e) => {
                    save_image(
                      'png',
                      `barcode-canvas-${
                        listing_details.slug
                          ? listing_details.slug
                          : listing_details.id
                      }`
                    );
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 13.5l3 3m0 0l3-3m-3 3v-6m1.06-4.19l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z"
                    />
                  </svg>
                  {Listing_text.product.download_png}
                </button>
                <button
                  className="btn-sm bg-primary border border-transparent text-white  transition duration-300 hover:bg-transparent hover:border-primary hover:text-primary flex items-center gap-2  "
                  onClick={(e) => {
                    save_image(
                      'jpg',
                      `barcode-canvas-${
                        listing_details.slug
                          ? listing_details.slug
                          : listing_details.id
                      }`
                    );
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 13.5l3 3m0 0l3-3m-3 3v-6m1.06-4.19l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z"
                    />
                  </svg>
                  {Listing_text.product.download_jpeg}
                </button>
              </div>
            </section>

            {/* UR: */}
            <section className=" pt-5 flex flex-col space-y-3 mb-5">
              <h4 className="text-lg font-semibold text-black">
                {Listing_text.product.barcode_url}
              </h4>

              <a
                href={`/barcode?barcode=${
                  listing_details?.barcode
                    ? listing_details?.barcode
                    : listing_details?.title
                }&title=${listing_details?.title}&slug=${
                  listing_details.slug
                    ? listing_details.slug
                    : `${listing_details.id}-${listing_details.title.replace(
                        /[ /?,#=]+/g,
                        '-'
                      )}?id=true`
                }`}
                target="_blank"
                rel="noreferrer"
              >
                https://{window?.location?.host}
                {`/barcode?barcode=${
                  listing_details?.barcode
                    ? listing_details?.barcode
                    : listing_details?.title
                }`}
              </a>
            </section>

            {/* SKU Section */}
            {listing_details?.sku && (
              <section className=" pt-5 flex flex-col space-y-3 mb-5">
                <h4 className="text-lg font-semibold text-black">
                  {Listing_text.product.sku}
                </h4>

                <div className="flex items-center gap-2 justify-start">
                  <p>{listing_details?.sku}</p>
                  <button
                    className=""
                    onClick={() => {
                      navigator.clipboard.writeText(listing_details?.sku);
                      alert('Copied');
                    }}
                  >
                    <svg
                      width="20"
                      height="22"
                      viewBox="0 0 19 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.5 18H5.5C4.70435 18 3.94129 17.6839 3.37868 17.1213C2.81607 16.5587 2.5 15.7956 2.5 15V5C2.5 4.73478 2.39464 4.48043 2.20711 4.29289C2.01957 4.10536 1.76522 4 1.5 4C1.23478 4 0.98043 4.10536 0.792893 4.29289C0.605357 4.48043 0.5 4.73478 0.5 5V15C0.5 16.3261 1.02678 17.5979 1.96447 18.5355C2.90215 19.4732 4.17392 20 5.5 20H13.5C13.7652 20 14.0196 19.8946 14.2071 19.7071C14.3946 19.5196 14.5 19.2652 14.5 19C14.5 18.7348 14.3946 18.4804 14.2071 18.2929C14.0196 18.1054 13.7652 18 13.5 18ZM18.5 6.94C18.4896 6.84813 18.4695 6.75763 18.44 6.67V6.58C18.3919 6.47718 18.3278 6.38267 18.25 6.3L12.25 0.3C12.1673 0.222216 12.0728 0.158081 11.97 0.11H11.88L11.56 0H7.5C6.70435 0 5.94129 0.316071 5.37868 0.87868C4.81607 1.44129 4.5 2.20435 4.5 3V13C4.5 13.7956 4.81607 14.5587 5.37868 15.1213C5.94129 15.6839 6.70435 16 7.5 16H15.5C16.2956 16 17.0587 15.6839 17.6213 15.1213C18.1839 14.5587 18.5 13.7956 18.5 13V7C18.5 7 18.5 7 18.5 6.94ZM12.5 3.41L15.09 6H13.5C13.2348 6 12.9804 5.89464 12.7929 5.70711C12.6054 5.51957 12.5 5.26522 12.5 5V3.41ZM16.5 13C16.5 13.2652 16.3946 13.5196 16.2071 13.7071C16.0196 13.8946 15.7652 14 15.5 14H7.5C7.23478 14 6.98043 13.8946 6.79289 13.7071C6.60536 13.5196 6.5 13.2652 6.5 13V3C6.5 2.73478 6.60536 2.48043 6.79289 2.29289C6.98043 2.10536 7.23478 2 7.5 2H10.5V5C10.5 5.79565 10.8161 6.55871 11.3787 7.12132C11.9413 7.68393 12.7044 8 13.5 8H16.5V13Z"
                        fill="var(--primary_color)"
                      />
                    </svg>
                  </button>
                </div>
              </section>
            )}
          </div>
        </div>
      </SidePan>
    </div>
  ) : (
    ''
  );
};

export default Edit_listing_button;
