/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { configsSelector } from '../../../store/feature/configsSlice';
import { Listing_text } from '../../../constant/Translation/Listing';
import { get_dynamic_styles_with_default_value } from '../../../constant/DynamicStylesWithDefaultValue';
import {
  CardBoxDefaultStyles,
  CardMainDefaultStyles,
} from '../../../constant/default_styles/ProductDetailsStyles';
import GridCombination from './GridCombination';
import GridCombination2 from './GridCombination2';
import DropDownVariant from './DropDownVariant';
import { listingSelector } from '../../../store/feature/listingSlice';
import { get_data_by_current_language } from '../../../utils/GetDataByLanguage';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';

const VariantsMatrix = ({
  variants,
  setSelectedVariant,
  selectedVariant,
  listing_details,
  styles,
  data,
}) => {
  const { listings_configs } = useSelector(configsSelector);
  const { listings_variants_list } = useSelector(listingSelector);

  const [selected_combination, setSelectedCombination] = useState(null);

  //
  useEffect(() => {
    if (listings_variants_list?.length && variants?.length > 0) {
      setSelectedVariant(variants[0]?.id);
    }
  }, [listings_variants_list, variants]);

  //
  useEffect(() => {
    if (selectedVariant) {
      setSelectedCombination(
        Object.fromEntries(
          variants
            ?.filter((find_vr) => find_vr?.id == selectedVariant)[0]
            ?.variant_values.map((vr) => [
              vr.variant_type_id,
              vr?.variant_type_value_id,
            ])
        )
      );
    }
  }, [selectedVariant]);

  //
  useEffect(() => {}, [selected_combination, variants]);

  console.log(styles?.clear_button_style);

  return (
    <div
      className="w-full relative"
      style={get_dynamic_styles_with_default_value(
        styles?.main_styles,
        CardMainDefaultStyles
      )}
    >
      <div
        className=""
        style={get_dynamic_styles_with_default_value(
          styles?.box_styles,
          CardBoxDefaultStyles
        )}
      >
        <h2 className="heading-h2">
          {data?.title
            ? get_data_by_current_language({
                data_list: data?.translated_data,
                key_name: 'title',
                default_data: data?.title,
              })
            : Listing_text.product.select_variant}
        </h2>
        <div className="  mt-4 grid  grid-cols-1 md:grid-cols-2 gap-4 ">
          {/* By default list type variant will show  */}
          {listings_variants_list?.map((item) => {
            return (
              <GridCombination
                variant_details={item}
                variant_values={item?.values}
                title={item?.name}
                selected_combination={selected_combination}
                setSelectedCombination={setSelectedCombination}
                setSelectedVariant={setSelectedVariant}
                variants={variants}
              />
            );
          })}
        </div>
      </div>

      {/*  */}
      <button
        style={get_dynamic_styles(styles?.clear_button_style)}
        onClick={() => {
          setSelectedVariant(null);
          setSelectedCombination(null);
        }}
      >
        {get_data_by_current_language({
          data_list: data?.translated_data,
          key_name: 'clear_button_title',
          default_data: data?.clear_button_title,
        })}
      </button>
    </div>
  );
};

export default VariantsMatrix;
