export const currency_list = [
  {
    code: 'AED',
    name: 'United Arab Emirates Dirham',
    format: 'د.إ. {amount}',
    precision: 2,
    country_code: 'AE',
  },
  {
    code: 'AFN',
    name: 'Afghan Afghani',
    format: 'Af {amount}',
    precision: 2,
  },
  {
    code: 'ALL',
    name: 'Albanian Lek',
    format: 'L {amount}',
    precision: 2,
  },
  {
    code: 'AMD',
    name: 'Armenian Dram',
    format: '֏ {amount}',
    precision: 2,
  },
  {
    code: 'ANG',
    name: 'Netherlands Antillean Guilder',
    format: 'ƒ {amount}',
    precision: 2,
  },
  {
    code: 'AOA',
    name: 'Angolan Kwanza',
    format: 'Kz {amount}',
    precision: 2,
  },
  {
    code: 'ARS',
    name: 'Argentine Peso',
    format: 'AR$ {amount}',
    precision: 2,
  },
  {
    code: 'AUD',
    name: 'Australian Dollar',
    format: 'AU$ {amount}',
    precision: 2,
  },
  {
    code: 'AWG',
    name: 'Aruban Florin',
    format: 'ƒ {amount}',
    precision: 2,
  },
  {
    code: 'AZN',
    name: 'Azerbaijani Manat',
    format: 'ман {amount}',
    precision: 2,
  },
  {
    code: 'BAM',
    name: 'Bosnia and Herzegovina Convertible Mark',
    format: 'KM {amount}',
    precision: 2,
  },
  {
    code: 'BBD',
    name: 'Barbadian Dollar',
    format: 'BBD$ {amount}',
    precision: 2,
  },
  {
    code: 'BDT',
    name: 'Bangladeshi Taka',
    format: '৳ {amount}',
    precision: 2,
  },
  {
    code: 'BGN',
    name: 'Bulgarian Lev',
    format: 'лв. {amount}',
    precision: 2,
  },
  {
    code: 'BHD',
    name: 'Bahraini Dinar',
    format: 'BD {amount}',
    precision: 3,
  },
  {
    code: 'BIF',
    name: 'Burundian Franc',
    format: 'FBu {amount}',
    precision: 2,
  },
  {
    code: 'BMD',
    name: 'Bermudian Dollar',
    format: '$ {amount}',
    precision: 2,
  },
  {
    code: 'BND',
    name: 'Brunei Dollar',
    format: 'B$ {amount}',
    precision: 2,
  },
  {
    code: 'BOB',
    name: 'Bolivian Boliviano',
    format: 'Bs. {amount}',
    precision: 2,
  },
  {
    code: 'BRL',
    name: 'Brazilian Real',
    format: 'R$ {amount}',
    precision: 2,
  },
  {
    code: 'BSD',
    name: 'Bahamian Dollar',
    format: '$ {amount}',
    precision: 2,
  },
  {
    code: 'BTN',
    name: 'Bhutanese Ngultrum',
    format: 'Nu. {amount}',
    precision: 2,
  },
  {
    code: 'BWP',
    name: 'Botswana Pula',
    format: 'P {amount}',
    precision: 2,
  },
  {
    code: 'BYN',
    name: 'Belarusian Ruble',
    format: 'Br {amount}',
    precision: 2,
  },
  {
    code: 'BZD',
    name: 'Belize Dollar',
    format: 'BZ$ {amount}',
    precision: 2,
  },
  {
    code: 'CAD',
    name: 'Canadian Dollar',
    format: 'CA$ {amount}',
    precision: 2,
  },
  {
    code: 'CDF',
    name: 'Congolese Franc',
    format: 'FC {amount}',
    precision: 2,
  },
  {
    code: 'CHF',
    name: 'Swiss Franc',
    format: 'Fr. {amount}',
    precision: 2,
  },
  {
    code: 'CKD',
    name: 'Cook Islands Dollar',
    format: '$ {amount}',
    precision: 2,
  },
  {
    code: 'CLP',
    name: 'Chilean Peso',
    format: 'CL$ {amount}',
    precision: 0,
  },
  {
    code: 'CNY',
    name: 'Chinese Yuan',
    format: 'CN¥ {amount}',
    precision: 2,
  },
  {
    code: 'COP',
    name: 'Colombian Peso',
    format: 'CO$ {amount}',
    precision: 2,
  },
  {
    code: 'CRC',
    name: 'Costa Rican Colon',
    format: '₡ {amount}',
    precision: 2,
  },
  {
    code: 'CUC',
    name: 'Cuban convertible Peso',
    format: 'CUC$ {amount}',
    precision: 2,
  },
  {
    code: 'CUP',
    name: 'Cuban Peso',
    format: '$MN {amount}',
    precision: 2,
  },
  {
    code: 'CVE',
    name: 'Cabo Verdean Escudo',
    format: 'CV$ {amount}',
    precision: 2,
  },
  {
    code: 'CZK',
    name: 'Czech Koruna',
    format: 'Kč {amount}',
    precision: 2,
  },
  {
    code: 'DJF',
    name: 'Djiboutian Franc',
    format: 'Fdj {amount}',
    precision: 2,
  },
  {
    code: 'DKK',
    name: 'Danish Krone',
    format: 'kr. {amount}',
    precision: 2,
  },
  {
    code: 'DOP',
    name: 'Dominican Peso',
    format: 'RD$ {amount}',
    precision: 2,
  },
  {
    code: 'DZD',
    name: 'Algerian Dinar',
    format: 'DA {amount}',
    precision: 2,
  },
  {
    code: 'EGP',
    name: 'Egyptian Pound',
    format: 'E£ {amount}',
    precision: 2,
  },
  {
    code: 'EHP',
    name: 'Sahrawi Peseta',
    format: 'Ptas. {amount}',
    precision: 2,
  },
  {
    code: 'ERN',
    name: 'Eritrean Nakfa',
    format: 'Nkf {amount}',
    precision: 2,
  },
  {
    code: 'ETB',
    name: 'Ethiopian Birr',
    format: 'Br {amount}',
    precision: 2,
  },
  {
    code: 'EUR',
    name: 'Euro',
    format: '€ {amount}',
    precision: 2,
  },
  {
    code: 'FJD',
    name: 'Fijian Dollar',
    format: 'FJ$ {amount}',
    precision: 2,
  },
  {
    code: 'FKP',
    name: 'Falkland Islands Pound',
    format: 'FK£ {amount}',
    precision: 2,
  },
  {
    code: 'FOK',
    name: 'Faroese Króna',
    format: 'kr {amount}',
    precision: 2,
  },
  {
    code: 'GBP',
    name: 'Pound Sterling',
    format: '£ {amount}',
    precision: 2,
  },
  {
    code: 'GEL',
    name: 'Georgian Lari',
    format: '₾ {amount}',
    precision: 2,
  },
  {
    code: 'GGP',
    name: 'Guernsey Pound',
    format: '£ {amount}',
    precision: 2,
  },
  {
    code: 'GHS',
    name: 'Ghanaian Cedi',
    format: 'GH₵ {amount}',
    precision: 2,
  },
  {
    code: 'GIP',
    name: 'Gibraltar Pound',
    format: '£ {amount}',
    precision: 2,
  },
  {
    code: 'GMD',
    name: 'Gambian Dalasi',
    format: 'D {amount}',
    precision: 2,
  },
  {
    code: 'GNF',
    name: 'Guinean Franc',
    format: 'FG {amount}',
    precision: 2,
  },
  {
    code: 'GTQ',
    name: 'Guatemalan Quetzal',
    format: 'Q {amount}',
    precision: 2,
  },
  {
    code: 'GYD',
    name: 'Guyanese Dollar',
    format: 'G$ {amount}',
    precision: 2,
  },
  {
    code: 'HKD',
    name: 'Hong Kong Dollar',
    format: 'HK$ {amount}',
    precision: 2,
  },
  {
    code: 'HNL',
    name: 'Honduran Lempira',
    format: 'L {amount}',
    precision: 2,
  },
  {
    code: 'HRK',
    name: 'Croatian Kuna',
    format: 'kn {amount}',
    precision: 2,
  },
  {
    code: 'HTG',
    name: 'Haitian Gourde',
    format: 'G {amount}',
    precision: 2,
  },
  {
    code: 'HUF',
    name: 'Hungarian Forint',
    format: 'Ft {amount}',
    precision: 2,
  },
  {
    code: 'IDR',
    name: 'Indonesian Rupiah',
    format: 'Rp {amount}',
    precision: 2,
  },
  {
    code: 'ILS',
    name: 'Israeli new Shekel',
    format: '₪ {amount}',
    precision: 2,
  },
  {
    code: 'IMP',
    name: 'Manx Pound',
    format: '£ {amount}',
    precision: 2,
  },
  {
    code: 'INR',
    name: 'Indian Rupee',
    format: 'Rs. {amount}',
    precision: 2,
  },
  {
    code: 'IQD',
    name: 'Iraqi Dinar',
    format: 'د.ع. {amount}',
    precision: 3,
  },
  {
    code: 'IRR',
    name: 'Iranian Rial',
    format: '﷼ {amount}',
    precision: 2,
  },
  {
    code: 'ISK',
    name: 'Icelandic Krona',
    format: 'kr {amount}',
    precision: 2,
  },
  {
    code: 'JEP',
    name: 'Jersey Pound',
    format: '£ {amount}',
    precision: 2,
  },
  {
    code: 'JMD',
    name: 'Jamaican Dollar',
    format: 'J$ {amount}',
    precision: 2,
  },
  {
    code: 'JOD',
    name: 'Jordanian Dinar',
    format: 'JD {amount}',
    precision: 3,
  },
  {
    code: 'JPY',
    name: 'Japanese Yen',
    format: '¥ {amount}',
    precision: 2,
  },
  {
    code: 'KES',
    name: 'Kenyan Shilling',
    format: 'KSh {amount}',
    precision: 2,
  },
  {
    code: 'KGS',
    name: 'Kyrgyzstani Som',
    format: 'с {amount}',
    precision: 2,
  },
  {
    code: 'KHR',
    name: 'Cambodian Riel',
    format: '៛ {amount}',
    precision: 2,
  },
  {
    code: 'KID',
    name: 'Kiribati Dollar',
    format: '$ {amount}',
    precision: 2,
  },
  {
    code: 'KMF',
    name: 'Comorian Franc',
    format: 'CF {amount}',
    precision: 2,
  },
  {
    code: 'KPW',
    name: 'North Korean Won',
    format: '₩ {amount}',
    precision: 2,
  },
  {
    code: 'KRW',
    name: 'South Korean Won',
    format: '₩ {amount}',
    precision: 2,
  },
  {
    code: 'KWD',
    name: 'Kuwaiti Dinar',
    format: 'KD {amount}',
    precision: 3,
  },
  {
    code: 'KYD',
    name: 'Cayman Islands Dollar',
    format: 'CI$ {amount}',
    precision: 2,
  },
  {
    code: 'KZT',
    name: 'Kazakhstani Tenge',
    format: '₸ {amount}',
    precision: 2,
  },
  {
    code: 'LAK',
    name: 'Lao Kip',
    format: '₭N {amount}',
    precision: 2,
  },
  {
    code: 'LBP',
    name: 'Lebanese Pound',
    format: 'LL. {amount}',
    precision: 2,
  },
  {
    code: 'LKR',
    name: 'Sri Lankan Rupee',
    format: 'Rs. {amount}',
    precision: 2,
  },
  {
    code: 'LRD',
    name: 'Liberian Dollar',
    format: 'L$ {amount}',
    precision: 2,
  },
  {
    code: 'LSL',
    name: 'Lesotho Loti',
    format: 'L {amount}',
    precision: 2,
  },
  {
    code: 'LYD',
    name: 'Libyan Dinar',
    format: 'LD {amount}',
    precision: 3,
  },
  {
    code: 'MAD',
    name: 'Moroccan Dirham',
    format: 'DH {amount}',
    precision: 2,
  },
  {
    code: 'MDL',
    name: 'Moldovan Leu',
    format: 'L {amount}',
    precision: 2,
  },
  {
    code: 'MGA',
    name: 'Malagasy Ariary',
    format: 'Ar {amount}',
    precision: 0,
  },
  {
    code: 'MKD',
    name: 'Macedonian Denar',
    format: 'den {amount}',
    precision: 2,
  },
  {
    code: 'MMK',
    name: 'Myanmar Kyat',
    format: 'Ks {amount}',
    precision: 2,
  },
  {
    code: 'MNT',
    name: 'Mongolian Tögrög',
    format: '₮ {amount}',
    precision: 2,
  },
  {
    code: 'MOP',
    name: 'Macanese Pataca',
    format: 'MOP$ {amount}',
    precision: 2,
  },
  {
    code: 'MRU',
    name: 'Mauritanian Ouguiya',
    format: 'UM {amount}',
    precision: 0,
  },
  {
    code: 'MUR',
    name: 'Mauritian Rupee',
    format: 'Rs. {amount}',
    precision: 2,
  },
  {
    code: 'MVR',
    name: 'Maldivian Rufiyaa',
    format: 'MRf {amount}',
    precision: 2,
  },
  {
    code: 'MWK',
    name: 'Malawian Kwacha',
    format: 'MK {amount}',
    precision: 2,
  },
  {
    code: 'MXN',
    name: 'Mexican Peso',
    format: 'MX$ {amount}',
    precision: 2,
  },
  {
    code: 'MYR',
    name: 'Malaysian Ringgit',
    format: 'RM {amount}',
    precision: 2,
  },
  {
    code: 'MZN',
    name: 'Mozambican Metical',
    format: 'MTn {amount}',
    precision: 2,
  },
  {
    code: 'NAD',
    name: 'Namibian Dollar',
    format: 'N$ {amount}',
    precision: 2,
  },
  {
    code: 'NGN',
    name: 'Nigerian Naira',
    format: '₦ {amount}',
    precision: 2,
  },
  {
    code: 'NIO',
    name: 'Nicaraguan Córdoba',
    format: 'C$ {amount}',
    precision: 2,
  },
  {
    code: 'NOK',
    name: 'Norwegian Krone',
    format: 'kr {amount}',
    precision: 2,
  },
  {
    code: 'NPR',
    name: 'Nepalese Rupee',
    format: 'Rs. {amount}',
    precision: 2,
  },
  {
    code: 'NZD',
    name: 'New Zealand Dollar',
    format: 'NZ$ {amount}',
    precision: 2,
  },
  {
    code: 'OMR',
    name: 'Omani Rial',
    format: 'OR {amount}',
    precision: 3,
  },
  {
    code: 'PAB',
    name: 'Panamanian Balboa',
    format: 'B/. {amount}',
    precision: 2,
  },
  {
    code: 'PEN',
    name: 'Peruvian Sol',
    format: 'S/. {amount}',
    precision: 2,
  },
  {
    code: 'PGK',
    name: 'Papua New Guinean Kina',
    format: 'K {amount}',
    precision: 2,
  },
  {
    code: 'PHP',
    name: 'Philippine Peso',
    format: '₱ {amount}',
    precision: 2,
  },
  {
    code: 'PKR',
    name: 'Pakistani Rupee',
    format: 'Rs. {amount}',
    precision: 2,
  },
  {
    code: 'PLN',
    name: 'Polish Zloty',
    format: 'zł {amount}',
    precision: 2,
  },
  {
    code: 'PND',
    name: 'Pitcairn Islands Dollar',
    format: '$ {amount}',
    precision: 2,
  },
  {
    code: 'PRB',
    name: 'Transnistrian Ruble',
    format: 'р. {amount}',
    precision: 2,
  },
  {
    code: 'PYG',
    name: 'Paraguayan Guaraní',
    format: '₲ {amount}',
    precision: 2,
  },
  {
    code: 'QAR',
    name: 'Qatari Riyal',
    format: 'QR {amount}',
    precision: 2,
  },
  {
    code: 'RON',
    name: 'Romanian Leu',
    format: 'L {amount}',
    precision: 2,
  },
  {
    code: 'RSD',
    name: 'Serbian Dinar',
    format: 'din {amount}',
    precision: 2,
  },
  {
    code: 'RUB',
    name: 'Russian Ruble',
    format: '₽ {amount}',
    precision: 2,
  },
  {
    code: 'RWF',
    name: 'Rwandan Franc',
    format: 'FRw {amount}',
    precision: 2,
  },
  {
    code: 'SAR',
    name: 'Saudi Riyal',
    format: 'SR {amount}',
    precision: 2,
  },
  {
    code: 'SBD',
    name: 'Solomon Islands Dollar',
    format: 'SI$ {amount}',
    precision: 2,
  },
  {
    code: 'SCR',
    name: 'Seychellois Rupee',
    format: 'Rs. {amount}',
    precision: 2,
  },
  {
    code: 'SDG',
    name: 'Sudanese Pound',
    format: '£SD {amount}',
    precision: 2,
  },
  {
    code: 'SEK',
    name: 'Swedish Krona',
    format: 'kr {amount}',
    precision: 2,
  },
  {
    code: 'SGD',
    name: 'Singapore Dollar',
    format: 'S$ {amount}',
    precision: 2,
  },
  {
    code: 'SHP',
    name: 'Saint Helena Pound',
    format: '£ {amount}',
    precision: 2,
  },
  {
    code: 'SLL',
    name: 'Sierra Leonean Leone',
    format: 'Le {amount}',
    precision: 2,
  },
  {
    code: 'SLS',
    name: 'Somaliland Shilling',
    format: 'Sl {amount}',
    precision: 2,
  },
  {
    code: 'SOS',
    name: 'Somali Shilling',
    format: 'Sh.So. {amount}',
    precision: 2,
  },
  {
    code: 'SRD',
    name: 'Surinamese Dollar',
    format: 'Sr$ {amount}',
    precision: 2,
  },
  {
    code: 'SSP',
    name: 'South Sudanese Pound',
    format: 'SS£ {amount}',
    precision: 2,
  },
  {
    code: 'STN',
    name: 'Sao Tome and Príncipe Dobra',
    format: 'Db {amount}',
    precision: 2,
  },
  {
    code: 'SVC',
    name: 'Salvadoran Colón',
    format: '₡ {amount}',
    precision: 2,
  },
  {
    code: 'SYP',
    name: 'Syrian Pound',
    format: 'LS {amount}',
    precision: 2,
  },
  {
    code: 'SZL',
    name: 'Swazi Lilangeni',
    format: 'L {amount}',
    precision: 2,
  },
  {
    code: 'THB',
    name: 'Thai Baht',
    format: '฿ {amount}',
    precision: 2,
  },
  {
    code: 'TJS',
    name: 'Tajikistani Somoni',
    format: 'SM {amount}',
    precision: 2,
  },
  {
    code: 'TMT',
    name: 'Turkmenistan Manat',
    format: 'm. {amount}',
    precision: 2,
  },
  {
    code: 'TND',
    name: 'Tunisian Dinar',
    format: 'DT {amount}',
    precision: 3,
  },
  {
    code: 'TOP',
    name: 'Tongan Paʻanga',
    format: 'T$ {amount}',
    precision: 2,
  },
  {
    code: 'TRY',
    name: 'Turkish Lira',
    format: 'TL {amount}',
    precision: 2,
  },
  {
    code: 'TTD',
    name: 'Trinidad and Tobago Dollar',
    format: 'TT$ {amount}',
    precision: 2,
  },
  {
    code: 'TVD',
    name: 'Tuvaluan Dollar',
    format: '$ {amount}',
    precision: 2,
  },
  {
    code: 'TWD',
    name: 'New Taiwan Dollar',
    format: 'NT$ {amount}',
    precision: 2,
  },
  {
    code: 'TZS',
    name: 'Tanzanian Shilling',
    format: 'TSh {amount}',
    precision: 2,
  },
  {
    code: 'UAH',
    name: 'Ukrainian Hryvnia',
    format: '₴ {amount}',
    precision: 2,
  },
  {
    code: 'UGX',
    name: 'Ugandan Shilling',
    format: 'USh {amount}',
    precision: 2,
  },
  {
    code: 'USD',
    name: 'United States Dollar',
    format: '$ {amount}',
    precision: 2,
  },
  {
    code: 'UYU',
    name: 'Uruguayan Peso',
    format: '$U {amount}',
    precision: 2,
  },
  {
    code: 'UZS',
    name: 'Uzbekistani Som',
    format: 'сум {amount}',
    precision: 2,
  },
  {
    code: 'VED',
    name: 'Venezuelan bolívar digital',
    format: 'Bs. {amount}',
    precision: 2,
  },
  {
    code: 'VES',
    name: 'Venezuelan Bolívar Soberano',
    format: 'Bs.F {amount}',
    precision: 2,
  },
  {
    code: 'VND',
    name: 'Vietnamese Dong',
    format: '₫ {amount}',
    precision: 2,
  },
  {
    code: 'VUV',
    name: 'Vanuatu Vatu',
    format: 'VT {amount}',
    precision: 0,
  },
  {
    code: 'WST',
    name: 'Samoan Tala',
    format: 'T {amount}',
    precision: 2,
  },
  {
    code: 'XAF',
    name: 'Central African CFA Franc BEAC',
    format: 'Fr {amount}',
    precision: 2,
  },
  {
    code: 'XCD',
    name: 'East Caribbean Dollar',
    format: '$ {amount}',
    precision: 2,
  },
  {
    code: 'XOF',
    name: 'West African CFA Franc BCEAO',
    format: '₣ {amount}',
    precision: 2,
  },
  {
    code: 'XPF',
    name: 'CFP Franc (Franc Pacifique)',
    format: '₣ {amount}',
    precision: 0,
  },
  {
    code: 'YER',
    name: 'Yemeni Rial',
    format: 'YR {amount}',
    precision: 2,
  },
  {
    code: 'ZAR',
    name: 'South African Rand',
    format: 'R {amount}',
    precision: 2,
  },
  {
    code: 'ZMW',
    name: 'Zambian Kwacha',
    format: 'ZK {amount}',
    precision: 2,
  },
  {
    code: 'ZWB',
    name: 'RTGS Dollar',
    format: ' {amount}',
    precision: 0,
  },
  {
    code: 'ZWL',
    name: 'Zimbabwean Dollar',
    format: 'Z$ {amount}',
    precision: 2,
  },
];
