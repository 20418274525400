import { useSelector } from 'react-redux';
import AllIcons from '../Shared/Constant/Icons/AllIcons';
import AddCard from './AddCard';
import Card from './Card';
import { CardSelector } from '../../store/feature/card/cardSlice';
import { useEffect } from 'react';

const CardsList = ({ selected_card, setSelectedCard }) => {
  const { cards } = useSelector(CardSelector);

  // useEffect
  useEffect(() => {
    if (cards?.length > 0) {
      setSelectedCard(cards[0]);
    }
  }, [cards]);

  return (
    <div className=" py-10 flex items-center justify-center gap-6 flex-wrap">
      {cards?.map((card) => {
        return (
          <Card
            card_details={card}
            selected_card={selected_card}
            setSelectedCard={setSelectedCard}
          />
        );
      })}

      <AddCard />
    </div>
  );
};

export default CardsList;
