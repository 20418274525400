// NOTE : This common component for attribute address search

/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from '../../store/feature/authSlice';
import {
  getAddressSearch,
  getAttributeAddressSearch,
  storeSelector,
} from '../../store/feature/storeSlice';
import axios from 'axios';
import { Address_text } from '../../constant/Translation/Address';
import { General_text } from '../../constant/Translation/General';

const Address_search = ({ coordinates, setCoordinates }) => {
  const [addressSearchKey, setAddressSearchKey] = useState('');
  const [showAddresses, setShowAddresses] = useState(false);
  const dispatch = useDispatch();
  const { auth_key, first_name } = useSelector(authSelector);
  const onChangeSearchKey = (e) => {
    dispatch(
      getAttributeAddressSearch({
        searchKey: addressSearchKey,
        authKey: auth_key,
      })
    );
    setCoordinates(null);
    setShowAddresses(true);
  };

  // coordinates
  useEffect(() => {
    if (coordinates?.latitude) {
      //   axios
      //     .get('/api/address/reverseGeoCodingAddress', {
      //       params: { lat: coordinates?.latitude, long: coordinates?.longitude },
      //     })
      //     .then((res) => {
      //       console.log(res);
      //     })
      //     .catch((error) => {
      //       console.log(error.response.data);
      //     });
      setAddressSearchKey(
        `latitude:${coordinates?.latitude},longitude:${coordinates?.longitude}`
      );
    }
  }, [coordinates]);

  const { attribute_search_addresses, attribute_addressFetching } =
    useSelector(storeSelector);

  return (
    <>
      <div className=" relative">
        <input
          value={addressSearchKey}
          type="text"
          className="
                    mt-0
                    block
                    w-full
                    px-0.5 
                    border-0 border-b-2 border-gray-200  bg-transparent transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
          placeholder="Search Address"
          onChange={(e) => setAddressSearchKey(e.target.value)}
          onKeyDown={(e) => {
            if (
              e.key === 'Enter' &&
              addressSearchKey !== '' &&
              !attribute_addressFetching &&
              !coordinates
            ) {
              onChangeSearchKey();
            }
          }}
          readOnly={coordinates && true}
        />
        <div className=" absolute bottom-0 ltr:right-0 rtl:left-0 mb-2  ">
          {addressSearchKey === '' ? (
            <span>
              {attribute_addressFetching ? (
                <svg
                  className="animate-spin -ml-1 ltr:mr-3 rtl:ml-3 h-5 w-5 text-primary"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-7 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              )}
            </span>
          ) : (
            <div className="flex flex-row-reverse items-center gap-2">
              {!coordinates && (
                <button
                  className={[
                    'p-2 rounded-md hover:shadow-md bg-gray-100  transition duration-200  ',
                    attribute_addressFetching
                      ? 'cursor-not-allowed '
                      : 'cursor-pointer',
                  ].join(' ')}
                  disabled={attribute_addressFetching}
                  onClick={() => onChangeSearchKey()}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-4 h-4 text-primary"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                    />
                  </svg>
                </button>
              )}
              {attribute_addressFetching ? (
                <svg
                  className="animate-spin -ml-1 ltr:mr-3 rtl:ml-3 h-5 w-5 text-primary"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                <button
                  className=" flex  text-primary  items-end"
                  onClick={() => {
                    setAddressSearchKey('');
                    setCoordinates(null);
                    setShowAddresses(false);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              )}
            </div>
          )}
        </div>
        {showAddresses && !attribute_addressFetching && (
          <div className=" w-full h-[200px] bg-gray-200 overflow-y-scroll border  rounded-button mt-2 absolute ring ring-offset-2 ring-primary ring-opacity-60 z-10">
            {attribute_search_addresses?.length > 0 ? (
              attribute_search_addresses?.map((address) => {
                return (
                  <div
                    key={Math.random()}
                    className=" flex  text-black p-2 cursor-pointer border border-transparent rounded-button hover:border-primary mt-2 "
                    onClick={() => {
                      return (
                        setAddressSearchKey(''),
                        setCoordinates({
                          latitude: address.latitude,
                          longitude: address.longitude,
                        }),
                        setShowAddresses(false)
                      );
                    }}
                  >
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                    <span className=" text-sm  font-normal ltr:ml-2 rtl:mr-2">
                      {address.formatted_address}
                    </span>
                  </div>
                );
              })
            ) : (
              <div
                className=" bg-yellow-400   text-white px-4 py-3 rounded-card  relative grid grid-cols-[10%,80%,10%]"
                role="alert"
              >
                <div className="flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                    />
                  </svg>
                </div>
                <div>
                  <span className="  ltr:ml-2 rtl:mr-2">
                    {Address_text.address.no_address_found}
                  </span>
                </div>
                <div
                  className="flex items-center justify-center"
                  onClick={() => {
                    setAddressSearchKey('');
                    setCoordinates(null);
                    setShowAddresses(false);
                  }}
                >
                  <svg
                    className="fill-current h-6 w-6 text-white"
                    role="button"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <title>{General_text.general.close}</title>
                    <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                  </svg>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Address_search;
