import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/Redux';
import {
  getSubscriptionDetails,
  SubscriptionSelector,
} from '../../store/feature/subscription/subscriptionSlice';
import { getThumbnailImage } from '../Shared/Constant/Constant';
import SubscriptionDetailsSkeleton from '../Shared/skeletonUI/SubscriptionDetailsSkeleton';

const SubscriptionDetails = ({ subscription_id }) => {
  const dispatch = useAppDispatch();
  const { subscription_details, isDetailsLoading } =
    useAppSelector(SubscriptionSelector);

  useEffect(() => {
    dispatch(getSubscriptionDetails({ subscription_id: subscription_id }));
  }, []);

  return (
    <>
      {!isDetailsLoading && (
        <div className=" w-full max-w-lg mx-auto  bg-white shadow space-y-4">
          <img
            src={subscription_details?.image_path}
            alt=""
            className="w-full h-40   object-cover rounded-t-md"
          />
          <div className=" space-y-2  px-6 py-6">
            <h4 className="  text-base font-semibold ">
              {subscription_details?.title}
            </h4>
            <p className=" text-lg font-bold">
              {subscription_details?.currency_amount?.formatted}
            </p>
          </div>
          <p className=" text-base text-gray-500  px-6 pb-6">
            {subscription_details?.description}
          </p>
        </div>
      )}
      {/*  */}
      {isDetailsLoading && <SubscriptionDetailsSkeleton />}{' '}
    </>
  );
};

export default SubscriptionDetails;
