import Link from 'next/link';
import React from 'react';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { get_dynamic_styles_with_default_value } from '../../constant/DynamicStylesWithDefaultValue';
import {
  CardBoxDefaultStyles,
  CardMainDefaultStyles,
} from '../../constant/default_styles/ProductDetailsStyles';
import { get_data_by_current_language } from '../../utils/GetDataByLanguage';

const CustomCta = ({ data, styles }) => {
  return (
    <div style={get_dynamic_styles(styles?.main_styles)}>
      <div
        className="flex items-center   "
        style={get_dynamic_styles(styles?.box_styles)}
      >
        <Link href={data?.url ?? '#'}>
          <a
            className="     rounded-button  flex items-center justify-center  gap-2 "
            type="button"
            target={data?.button_target === '_blank' ? '_blank' : '_self'}
            style={get_dynamic_styles(styles?.button_styles)}
          >
            {data?.icon && data?.is_icon_in_right != true && (
              <img
                className="w-auto object-cover  "
                style={get_dynamic_styles(styles?.icon_styles)}
                src={data?.icon}
                alt="image"
              />
            )}
            {data?.button_title && (
              <p
                className="text-lg font-semibold text-gray-700"
                style={get_dynamic_styles(styles?.text_styles)}
              >
                {get_data_by_current_language({
                  data_list: data?.translated_data,
                  key_name: 'button_title',
                  default_data: data?.button_title,
                })}
              </p>
            )}

            {data?.icon && data?.is_icon_in_right == true && (
              <img
                className="w-auto object-cover  "
                style={get_dynamic_styles(styles?.icon_styles)}
                src={data?.icon}
                alt="image"
              />
            )}
          </a>
        </Link>
      </div>
    </div>
  );
};

export default CustomCta;
