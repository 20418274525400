import React from 'react';

const SubscriptionDetailsSkeleton = () => {
  return (
    <div className=" w-full max-w-lg mx-auto  bg-white px-6 py-6 shadow space-y-4">
      <div className="w-full h-40 bg-gray-400 rounded-md object-cover" />
      <div className=" space-y-2 ">
        <div className="w-[90%] h-7 bg-gray-300 rounded-md object-cover" />
        <div className="w-full h-4 bg-gray-200 rounded-md object-cover" />
      </div>
      <div className="w-full mt-4 h-4 bg-gray-200 rounded-md object-cover" />
      <div className="w-full h-4 bg-gray-200 rounded-md object-cover" />
      <div className="w-full h-4 bg-gray-200 rounded-md object-cover" />
      <div className="w-full h-4 bg-gray-200 rounded-md object-cover" />
      <div className="w-full h-4 bg-gray-200 rounded-md object-cover" />
      <div className="w-full h-4 bg-gray-200 rounded-md object-cover" />
    </div>
  );
};

export default SubscriptionDetailsSkeleton;
