export function convertSchedule(startAt, endAt) {
  // Parse the start and end times into Date objects
  const startDate = new Date(startAt);
  const endDate = new Date(endAt);

  // Extract year, month, day, hour, and minute from the start date
  const start = [
    startDate.getUTCFullYear(),
    startDate.getUTCMonth(), // Note: getUTCMonth() returns 0-11, so January is 0 and December is 11
    startDate.getUTCDate(),
    startDate.getUTCHours(),
    startDate.getUTCMinutes(),
  ];

  // Calculate the duration in hours and minutes
  const durationMs = endDate - startDate;
  const durationMinutes = Math.floor(durationMs / (1000 * 60));
  const duration = {
    hours: Math.floor(durationMinutes / 60),
    minutes: durationMinutes % 60,
  };

  return { start, duration };
}
