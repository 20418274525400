import React from 'react';
import { Orders_text } from '../../../constant/Translation/OrdersText';

const DownloadImage = ({ downloadImage, addToCalendar }) => {
  return (
    <div className=" flex flex-wrap items-center justify-between mt-2">
      <button className=" btn-primary-outline " onClick={downloadImage}>
        {Orders_text.orderdetail.download_as_image}
      </button>
      <button
        className="btn-primary-outline "
        onClick={() => downloadImage({ type: 'pdf' })}
      >
        {Orders_text.orderdetail.download_as_pdf}
      </button>
      <button className="btn-primary-outline " onClick={() => addToCalendar()}>
        {Orders_text.orderdetail.save_to_calender}
      </button>
    </div>
  );
};

export default DownloadImage;
