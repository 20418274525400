import React from 'react';

import { useState } from 'react';
import { useEffect } from 'react';
import { useRouter } from 'next/dist/client/router';
import { useDispatch } from 'react-redux';
import { listingLike } from '../../../store/feature/listingSlice';
import Link from 'next/link';
import { check_login } from '../../../constant/check_auth';
import axios from 'axios';
import { Listing_text } from '../../../constant/Translation/Listing';
import ListingsList from '../../Listings/ListingsList';
import ListingListSkeleton from '../../Shared/skeletonUI/Listing/ListingsListSkeleton';
import { get_dynamic_styles_with_default_value } from '../../../constant/DynamicStylesWithDefaultValue';
import {
  CardBoxDefaultStyles,
  CardMainDefaultStyles,
} from '../../../constant/default_styles/ProductDetailsStyles';

const AccountListings = ({ account_id, account, styles }) => {
  const router = useRouter();
  const dispatch = useDispatch();

  const [account_listings, setAccount_listings] = useState(null);
  useEffect(() => {
    if (account_id) {
      axios
        .get('/api/l', {
          params: { per_page: 8, account_id: account_id, status: 2 },
        })
        .then((res) => {
          setAccount_listings(res.data.listings);
        });
    }
  }, [account_id]);

  // Button Handle
  const like = (id, isLiked) => {
    if (check_login(router)) {
      dispatch(
        listingLike({
          id: id,
          isLiked: isLiked,
        })
      ).then((res) => {
        if (!res.payload.code) {
          axios
            .get('/api/l', {
              params: {
                page: 1,
                per_page: 30,
                account_id: account_id,
                status: 2,
              },
            })
            .then((res) => {
              setAccount_listings(res.data.listings);
            });
        }
      });
    }
  };

  return (
    <>
      {account_listings !== null ? (
        account_listings?.length > 0 && (
          <div
            className=" w-full "
            style={get_dynamic_styles_with_default_value(
              styles?.main_styles,
              CardMainDefaultStyles
            )}
          >
            <div
              className=" w-full "
              style={get_dynamic_styles_with_default_value(
                styles?.box_styles,
                CardBoxDefaultStyles
              )}
            >
              <div className=" flex justify-between items-center">
                <h2 className="heading-h2">
                  {Listing_text.product.more_listing_from_account_title}
                </h2>
                <Link
                  href={
                    account?.slug
                      ? `/a/${account?.slug}`
                      : `/a/${account?.id}-${account?.name.replace(
                          /[ /?,#=]+/g,
                          '-'
                        )}?id=true`
                  }
                >
                  <a className="  btn-primary-fill">
                    {Listing_text.product.see_all}
                  </a>
                </Link>
              </div>
              <div className="mt-4  ">
                <ListingsList Products={account_listings} like_listing={like} />
              </div>
            </div>
          </div>
        )
      ) : (
        <div className=" w-[100%] ms:w-full min-h-min p-4 bg-[#FFFFFF] rounded-card   shadow-c-sm">
          {' '}
          <ListingListSkeleton per_page={10} />
        </div>
      )}
    </>
  );
};

export default AccountListings;
