/* eslint-disable react/prop-types */
import React from 'react';

import ReadingComposer from '../../Shared/Composer/ReadingComposer';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';

const TicketAttributeDetails = ({ styles, data, attr }) => {
  return (
    <div className=" w-full   " style={get_dynamic_styles(styles?.main_styles)}>
      <div
        style={{
          display: styles?.box_styles?.display,
          ...get_dynamic_styles(styles?.box_styles),
        }}
      >
        {/* Icon path */}
        {attr?.icon_path && data?.icon_enabled && (
          <img
            style={get_dynamic_styles(styles?.image_styles)}
            src={attr?.icon_path}
            alt=""
          />
        )}

        {/* name value */}
        {attr.name && data?.title_enabled && (
          <p style={get_dynamic_styles(styles?.title_styles)}>{attr.name}</p>
        )}

        {/*  */}
        {data?.value_enabled && (
          <>
            {attr.field_type === 6 ? (
              <ReadingComposer contents={attr.values[0]} isReadOnly={true} />
            ) : (
              <p
                style={get_dynamic_styles(styles?.value_styles)}
                className="  whitespace-pre-line  overflow-auto  scrollbar-none"
              >
                {attr.values
                  .map((item) => {
                    if (attr.field_type === 1 || attr.field_type === 2) {
                      return item.name;
                    } else if (attr.field_type === 3 || attr.field_type === 4) {
                      return item;
                    } else if (attr.field_type === 12) {
                      return `latitude:${item?.latitude}, longitude:${item?.longitude}`;
                    } else {
                      return item;
                    }
                  })
                  .join(', ')}
              </p>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default TicketAttributeDetails;
