import AppointmentButton from '../../components/ListingDetails/EventsButtons/AppointmentButton';
import ProductButtons from '../../components/ListingDetails/ProductButtons/ProductButtons';
import EventButtons2 from '../../components/ListingDetails/EventsButtons/EventButtons2';
import DonationButton from '../../components/ListingDetails/DonationButtons/DonationButton';
import NegotiateButton from '../../components/ListingDetails/NegotiateButtons/NegotiateButton';

export const sell_button_helper = ({
  block_code,
  listing_details,
  selectedVariant,
  setError_message,
  setShowError,
  styles,
  data,
}) => {
  switch (block_code) {
    case 1:
      return (
        <ProductButtons
          listing_details={listing_details}
          selectedVariant={selectedVariant}
          setError_message={setError_message}
          setShowError={setShowError}
          styles={styles}
          data={data}
        />
      );
    case 2:
      return (
        <EventButtons2
          listing_details={listing_details}
          selectedVariant={selectedVariant}
          setError_message={setError_message}
          setShowError={setShowError}
          styles={styles}
        />
      );
    case 3:
      return (
        <AppointmentButton
          listing_details={listing_details}
          selectedVariant={selectedVariant}
          setError_message={setError_message}
          setShowError={setShowError}
          styles={styles}
        />
      );
    case 4:
      return (
        <DonationButton
          listing_details={listing_details}
          selectedVariant={selectedVariant}
          setError_message={setError_message}
          setShowError={setShowError}
          styles={styles}
          data={data}
        />
      );
    case 5:
      return (
        <NegotiateButton
          listing_details={listing_details}
          selectedVariant={selectedVariant}
          setError_message={setError_message}
          setShowError={setShowError}
        />
      );

    default:
      return <></>;
  }
};
