import Link from 'next/link';
import React from 'react';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { get_dynamic_styles_with_default_value } from '../../constant/DynamicStylesWithDefaultValue';
import {
  CardBoxDefaultStyles,
  CardMainDefaultStyles,
} from '../../constant/default_styles/ProductDetailsStyles';
import { get_data_by_current_language } from '../../utils/GetDataByLanguage';
import { dynamic_text_convert } from '../../utils/dynamicTextConvert';

const FeatureCheckList = ({ data, styles }) => {
  return (
    <div style={get_dynamic_styles(styles?.main_styles)}>
      <div
        className="flex"
        style={{
          display: styles?.box_styles?.display,
          gridTemplateColumns: dynamic_text_convert({
            main_text: styles?.box_styles?.columns_box_percentage,
            check_by: '-1%',
            value: '',
          }),
          ...get_dynamic_styles(styles?.box_styles),
        }}
      >
        {data?.list?.map((item) => {
          return (
            <div
              style={{
                display: styles?.list_styles?.display,
                gridTemplateColumns: dynamic_text_convert({
                  main_text: styles?.list_styles?.columns_box_percentage,
                  check_by: '-1%',
                  value: '',
                }),
                ...get_dynamic_styles(styles?.list_styles),
              }}
            >
              {item?.icon && (
                <img
                  className="w-auto object-cover  "
                  style={get_dynamic_styles(styles?.icon_styles)}
                  src={item?.icon}
                  alt="image"
                />
              )}
              {item?.text && (
                <p
                  className="text-lg font-semibold text-gray-700"
                  style={get_dynamic_styles(styles?.text_styles)}
                >
                  {get_data_by_current_language({
                    data_list: item?.translated_data,
                    key_name: 'text',
                    default_data: item?.text,
                  })}
                </p>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FeatureCheckList;
