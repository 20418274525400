import React, { useEffect, useState } from 'react';
import { Wallet_text } from '../../constant/Translation/wallet';
import { useDispatch, useSelector } from 'react-redux';
import {
  WalletSelector,
  getWalletTransitions,
} from '../../store/feature/wallet/walletSlice';
import {
  walletTransitionStatus,
  walletTransitionType,
} from '../../constant/wallet/WalletStatus';
import {
  changeDateFormatFromDate,
  changeDateFormatFromTimeStamp,
} from '../../utils/date';
import Pagination from '../Shared/Pagination/Pagination';

const WalletTransitions = () => {
  const dispatch = useDispatch();
  // selector
  const { mangopay_transactions_list } = useSelector(WalletSelector);

  //
  // const [pageCount, setPageCount] = useState(0);
  // useEffect(() => {
  //   const totalpage = Math.ceil(transitions_total_records / 30);
  //   if (Number(transitions_total_records) > 30) {
  //     setPageCount(totalpage);
  //   }
  // }, [transitions_total_records]);

  return (
    <div className=" mt-4 px-4 pb-10">
      <div>
        <div className=" grid  grid-cols-[45%,35%,20%]  xs:grid-cols-[35%,25%,15%,25%]   c-lg:grid-cols-[30%,20%,20%,10%,20%]  px-2">
          <p className=" text-lg text-[#000000] text-opacity-40">
            {Wallet_text.wallet.history}
          </p>
          <p className="hidden c-lg:block text-lg text-[#000000] text-opacity-40 text-center">
            {Wallet_text.wallet.date}
          </p>
          <p className=" text-lg text-[#000000] text-opacity-40 text-center">
            {Wallet_text.wallet.amount}
          </p>
          <p className=" text-lg text-[#000000] text-opacity-40 text-center">
            {Wallet_text.wallet.fee}
          </p>
          <p className=" hidden xs:block text-lg text-[#000000] text-opacity-40 text-center">
            {Wallet_text.wallet.type}
          </p>
        </div>

        <div className=" mt-8">
          {mangopay_transactions_list?.map((transition) => {
            return (
              <div className=" grid  grid-cols-[45%,35%,20%]  xs:grid-cols-[35%,25%,15%,25%]   c-lg:grid-cols-[30%,20%,20%,10%,20%] items-center bg-white shadow-c-sm rounded-card   mb-3 py-3 px-2 cursor-pointer">
                <div className=" ltr:pl-4 rtl:pr-4 ">
                  <div>
                    <p className=" text-base md:text-lg font-semibold text-primary break-words	">
                      {transition?.id}
                    </p>

                    <p className="  text-sm text-[#77869E] leading-4 flex flex-col mt-1 space-y-1">
                      <span className="">{transition?.description}</span>
                      <span className=" whitespace-pre-wrap">
                        {`${
                          Wallet_text.wallet.status
                        }:  ${walletTransitionStatus(transition?.status)}`}
                      </span>
                      <span className="  c-lg:hidden">
                        {changeDateFormatFromTimeStamp(
                          transition.created_at,
                          'MMM Do YY'
                        )}
                      </span>
                      <span className="  xs:hidden">
                        {walletTransitionType(transition?.type)}
                      </span>
                    </p>
                  </div>
                </div>
                <div className=" hidden c-lg:flex justify-center items-center">
                  <p className=" text-sm text-[#77869E]  leading-4 text-center">
                    {changeDateFormatFromTimeStamp(
                      transition.created_at,
                      'MMM Do YY'
                    )}
                  </p>
                </div>
                <div className="flex justify-center items-center">
                  <p
                    className={[
                      ' text-center   flex flex-col justify-center items-center ',
                      transition?.status === 1
                        ? 'text-primary'
                        : 'text-red-500',
                    ].join(' ')}
                  >
                    <span className="text-lg  ">
                      {transition?.status === 1 ? '+ ' : '- '}
                      {transition?.amount?.formatted}
                    </span>
                  </p>
                </div>
                <div className="flex justify-center items-center">
                  <p
                    className={[
                      ' text-center   flex flex-col justify-center items-center ',
                      transition?.status === 1
                        ? 'text-primary'
                        : 'text-red-500',
                    ].join(' ')}
                  >
                    <span className=" ">
                      {transition?.status === 1 ? '+ ' : '- '}
                      {transition?.fees?.formatted}
                    </span>
                  </p>
                </div>
                <div className="hidden xs:flex justify-center items-center text-sm text-[#77869E]  leading-4 text-center">
                  {walletTransitionType(transition?.type)}
                </div>
              </div>
            );
          })}
        </div>

        {/* <div className="flex items-center justify-center mt-10">
          <Pagination
            moreData={(data) => {
              dispatch(
                getWalletTransitions({
                  page: data,
                  per_page: 30,
                })
              );
            }}
            pageCount={pageCount}
            current_page={transitions_current_page}
          />
        </div> */}
      </div>
    </div>
  );
};

export default WalletTransitions;
