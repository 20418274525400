import React from 'react';
import { get_dynamic_styles_with_default_value } from '../../constant/DynamicStylesWithDefaultValue';
import { CardBoxDefaultStyles } from '../../constant/default_styles/ProductDetailsStyles';

const FundedPercentage = ({ listing_details, styles }) => {
  const { goal_price, donation_analytics } = listing_details || {};

  const funded_percentage = Math.round(
    (donation_analytics?.total_amount?.amount / goal_price?.amount) * 100
  )
    ? Math.round(
        (donation_analytics?.total_amount?.amount / goal_price?.amount) * 100
      )
    : 0;

  return (
    <div
      className=" relative  w-[100%] h-[25px] overflow-hidden border-[0.5px]  border-primary   rounded-card     flex   items-center justify-start"
      style={get_dynamic_styles_with_default_value(
        styles?.box_styles,
        CardBoxDefaultStyles
      )}
    >
      <p className=" absolute top-0 bottom-0 left-0 right-0 mx-auto text-center text-[15px] font-normal text-white  invert">
        {funded_percentage}% Funded
      </p>
      <div
        className={`h-full bg-primary rounded-card  text-center flex items-center justify-center`}
        style={{ width: `${funded_percentage === 0 ? 2 : funded_percentage}%` }}
      />
    </div>
  );
};

export default FundedPercentage;
