import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  filterSelector,
  getAttributesForFilter,
  onFilterParamsUpdate,
} from '../../store/feature/filter/filterSlice';
import { get_data_by_current_language } from '../../utils/GetDataByLanguage';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { useRouter } from 'next/router';
import Address_search from '../Shared/Address_search';
import DynamicAddressSearch from '../Shared/DynamicAddressSearch';
import SelectFromListDropDown from '../../DynamicUI/FormFields/SelectFromListDropdown';
import { getMainPath } from '../../utils/getMainPath';

const SortingFilter = ({ filer_item, styles }) => {
  const {
    filter_type,
    title,
    translated_data,
    attribute_id,
    is_show_title,
    is_depend_on_search,
  } = filer_item;
  const router = useRouter();
  const dispatch = useDispatch();
  const { attributes, filter_params, queries } = useSelector(filterSelector);

  //
  const onUpdateQuires = (updated_quires) => {
    if (is_depend_on_search === false) {
      router.push({
        pathname: getMainPath(router.asPath),
        query: updated_quires ?? queries,
      });
    }
  };
  //
  const filter_by_attribute_value = (value) => {
    dispatch(
      onFilterParamsUpdate({
        [`sort`]: value,
        callOnFilterUpdate: (updated_quires) => {
          onUpdateQuires(updated_quires);
        },
      })
    );
  };

  return (
    <div
      className="flex"
      style={get_dynamic_styles(styles?.filter_component_style)}
    >
      {title && is_show_title && (
        <p style={get_dynamic_styles(styles?.filter_title_style)}>
          {get_data_by_current_language({
            data_list: translated_data,
            key_name: 'title',
            default_data: title,
          })}
        </p>
      )}
      <SelectFromListDropDown
        default_option_text={'Sort By'}
        drop_down_items={[
          { label: 'Relevance', value: 'relevance' },
          { label: 'Price low to high', value: 'price_low_to_high' },
          { label: 'Price high to low', value: 'price_high_to_low' },
          { label: 'Newest first', value: 'newest_first' },
        ]}
        styles={styles}
        set_new_value={(value) => filter_by_attribute_value(value)}
        current_value={filter_params?.sort ?? ''}
      />
    </div>
  );
};

export default SortingFilter;
