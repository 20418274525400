import React from 'react';
import SelectDropDown2 from '../../Shared/SelectDropDown2';
import * as constant from '../../Shared/Constant/TextConstant/listingDetailsConstant';
import { Listing_text } from '../../../constant/Translation/Listing';

const DropdownTypeVariants = ({
  variants,
  selectedVariant,
  setSelectedVariant,
}) => {
  // selectedItem
  const selectedItem = () => {
    if (selectedItem) {
      let selected_variant = variants.filter(
        (varint) => varint.id == selectedVariant
      )[0];

      return { label: selected_variant?.title, value: selected_variant?.id };
    }
    return null;
  };

  return (
    <SelectDropDown2
      drop_down_values={variants?.map((variant) => {
        return { label: variant.title, value: variant.id };
      })}
      setSelectedItem={(item) => {
        if (item) {
          setSelectedVariant(item.value);
        } else {
          setSelectedVariant(null);
        }
      }}
      selectedItem={selectedItem()}
      placeholder_text={Listing_text.product.select_variant}
    />
  );
};

export default DropdownTypeVariants;
