/* eslint-disable react/no-unescaped-entities */

import Heading1 from '../../../UI/Text/Headers/Heading1';
import Paragraph from '../../../UI/Text/Paragraph/Paragraph';
import { useSelector } from 'react-redux';
import PrimaryButton from '../../../UI/Buttons/PrimaryButton';
import {
  bank_initial_step,
  bank_verification_failed_step,
  bank_verified_step,
} from '../../Shared/Constant/Icons/BankStatusIcons';
import { OPPSelector } from '../../../store/feature/OPP/OPPSlice';

const BankVerification = () => {
  const { merchant_details, isError, errorMessage } = useSelector(OPPSelector);

  //
  let bank_current_icon = null;

  switch (merchant_details?.bank_account?.status) {
    case 'approved':
      bank_current_icon = bank_verified_step;
      break;

    default:
      bank_current_icon = bank_initial_step;
      break;
  }

  return (
    <div className=" max-w-lg mx-auto bg-white flex flex-col gap-4 p-6">
      <span className="mx-auto">{bank_current_icon}</span>
      <Heading1 styles=" md:text-[32px] my-6">Bank Verification</Heading1>
      <Paragraph>{merchant_details?.bank_account?.status_message}</Paragraph>

      <div className=" flex flex-col gap-4 items-center justify-center">
        <div className="  w-full flex items-center justify-between bg-white shadow-md p-4">
          <p className=" text-lg font-bold col-span-4">IBAN:</p>
          <p className=" text-base my-auto col-span-6 capitalize">
            {merchant_details?.bank_account?.bank_iban}
          </p>
        </div>
        <div className=" w-full flex items-center justify-between bg-white shadow-md p-4">
          <p className=" text-lg font-bold col-span-4">Verification Status :</p>
          <p className=" text-base my-auto col-span-6 capitalize">
            {' '}
            {merchant_details?.bank_account?.status}
          </p>
        </div>
        {merchant_details?.bank_account?.status !== 'approved' && (
          <PrimaryButton
            className=" max-w-[200px] w-full mx-auto mt-5"
            onClick={() =>
              window.open(merchant_details?.bank_account?.verification_url)
            }
          >
            Submit Bank Details
          </PrimaryButton>
        )}
      </div>
    </div>
  );
};

export default BankVerification;
