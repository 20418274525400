import React, { useEffect } from 'react';
import {
  getFormatBySelectedCurrency,
  getFormatOFSelectedCurrency,
} from '../../../utils/CurrencyFunctions';
import Heading1 from '../../../UI/Text/Headers/Heading1';
import { Wallet_text } from '../../../constant/Translation/wallet';
import {
  WalletSelector,
  amountPayInByMangoPayCalc,
  amountWithdrawByMangoPayCalc,
} from '../../../store/feature/wallet/walletSlice';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '../../Shared/PopUp/Alert';
import { dynamic_translation_set } from '../../../constant/Translation/translation_set';
import { replaceMultipleStrings } from '../../../utils/StringTranformation';
import Paragraph from '../../../UI/Text/Paragraph/Paragraph';
import { useRouter } from 'next/router';
import { currency_list } from '../../../data/CurrencyData';
import { getCookie } from 'cookies-next';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';

const WithdrawAmountPicker = ({ amount, setAmountValue }) => {
  const router = useRouter();
  const fixed_donation = [100, 150, 200, 250];
  const dispatch = useDispatch();
  const currency = router.query.code
    ? router.query.code
    : getCookie(`${TYPE_CONSTANT.DOMAIN}_default_currency`) ?? '';

  // selector
  const { withdraw_calculation, wallets_list } = useSelector(WalletSelector);

  const selected_wallet = wallets_list?.find(
    (wallet: any) => wallet.currency === router?.query?.code
  );

  //
  const amountChange = (value) => {
    setAmountValue(value);
  };

  // Debounce function for call calculation
  React.useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      if (Number(amount) > 0) {
        let data: { amount: Number; currency?: string } = {
          amount: Number(amount),
        };
        if (currency) {
          data.currency = currency as string;
        }

        //
        dispatch(
          // @ts-ignore
          amountWithdrawByMangoPayCalc(data)
        );
      }
    }, 500);
    return () => clearTimeout(delayInputTimeoutId);
  }, [amount, 500]);

  return (
    <div className=" max-w-lg mx-auto bg-white flex flex-col gap-4 p-6">
      <div className="my-6 space-y-6">
        <Heading1 styles=" md:text-[32px] ">
          {Wallet_text.wallet.withdraw_amount}
        </Heading1>
        <Paragraph styles=" text-center">
          {Wallet_text.wallet.withdraw_description}
        </Paragraph>
      </div>

      <div className="flex  items-center justify-start flex-wrap gap-4">
        {fixed_donation.map((item) => {
          return (
            <button
              className={
                item !== amount
                  ? ' min-w-[100px] h-5 px-6 py-4 border border-gray-200 flex items-center justify-center rounded-button    transition duration-700    hover:border-primary hover:text-primary text-center'
                  : ' min-w-[100px] h-5 px-6 py-4 border   flex items-center justify-center    border-transparent bg-primary text-white rounded-button cursor-pointer  ring-2 ring-primary ring-offset-2 text-center '
              }
              key={item}
              onClick={() => amountChange(item)}
            >
              {getFormatBySelectedCurrency(
                item,
                currency_list?.find(
                  (item) => item.code == selected_wallet?.currency
                )?.format
              )}
            </button>
          );
        })}
      </div>

      <div
        className="
                    mt-0
                    block
                    w-full    px-4
                    flex items-center gap-2 justify-start
                    rounded-input focus:border-primary
                    border-2 border-gray-200 transition  duration-700
                  "
      >
        <p className="text-base   font-semibold   text-center leading-none ">
          {router?.query?.code}
        </p>

        <input
          value={amount}
          className=" focus:ring-0   border-0  flex-grow"
          type="number"
          placeholder="1"
          onChange={(e) => {
            //  @ts-ignore
            if (e.target.value >= 0) {
              amountChange(e.target.value);
            }
          }}
          onWheel={(event) => event.currentTarget.blur()}
        />
      </div>

      {/*  */}
      <div className=" max-w-lg mx-auto w-full flex flex-col gap-4 items-center justify-center mt-6">
        {withdraw_calculation && (
          <Alert
            className={`w-full    `}
            type={'warning'}
            open={true}
            isOpenFunction={false}
            funcCallOnClose={undefined}
            setOpen={undefined}
          >
            <p>
              {replaceMultipleStrings(
                Wallet_text.wallet.withdraw_fee_alert_message,
                {
                  '{fee_amount}': withdraw_calculation?.fees?.formatted,
                  '{total_amount}':
                    withdraw_calculation?.total_amount?.formatted,
                  '{withdraw_amount}': withdraw_calculation?.amount?.formatted,
                }
              )}
            </p>
          </Alert>
        )}
      </div>
    </div>
  );
};

export default WithdrawAmountPicker;
