import { getAuthKey } from 'constant/functions';
import React, { useEffect, useState } from 'react';
import PopOver from '../../../../UI/PopOver/PopOver';
import { image_icon } from '../../Constant/Icons/ComoserIcons';
import { cn } from '../../../../utils/classNames';
import AllIcons from '../../Constant/Icons/AllIcons';
import { UploadImages } from '../../../../functions/image/UploadImage';
import { useAppDispatch } from '../../../../hooks/Redux';
import { onCloseSidebar } from '../../../../store/feature/SidebarSlice';

const AddImageForm = ({ editor }) => {
  const dispatch = useAppDispatch();

  const [alt, setAltL] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState([]);

  const saveImage = async (e) => {
    try {
      setIsLoading(true);
      let image_resp = await UploadImages({
        all_files: [
          {
            name: image.file.name,
            type: image.file.type,
            full_file: image.file,
          },
        ],
      });

      if (image_resp?.length > 0) {
        editor.chain().focus().setImage({ src: image_resp[0], alt: alt }).run();
      } else {
        // editor.chain().focus().extendMarkRange('link').unsetLink().run()
      }

      setIsLoading(false);
      setImage({});
      setAltL('');

      dispatch(onCloseSidebar());
    } catch (error) {
      setIsLoading(false);
      setImage([]);
      setAltL('');
      e.stopPropagation();
    }
  };

  return (
    <div className="w-full right-0 mt-3 rounded-md shadow-md   h-[340px]    overflow-hidden">
      <div className="shadow-sm bg-white rounded-lg  h-full   flex  flex-col   justify-start  ">
        <h3 className="text-2xl text-black font-medium text-center w-full bg-[#f5f5f5] border border-b-[#cccccc] h-14 py-2   px-4">
          Upload Image
        </h3>
        {/*  */}
        <div className="  w-full flex  flex-grow   px-3  flex-col gap-3">
          {/* Sidebar */}

          <div className="block space-y-1  ">
            <span className="text-base font-bold text-gray-700">
              Alter text
            </span>
            <input
              value={alt}
              type="text"
              className="
             
                    mt-0
                    block
                    w-full
                     px-1 py-1.5
                    border-2 border-gray-400 transition  duration-700
                   
                    rounded-md
                  "
              placeholder="Alter text"
              onChange={(e) => setAltL(e.target.value)}
            />
          </div>

          <div className=" flex-grow space-y-1  ">
            <span className="text-base font-bold text-gray-700">Image</span>
            {image?.path ? (
              <div className=" relative w-full mt-4">
                <img
                  src={image?.path}
                  alt=""
                  className="w-full  h-[100px] rounded  object-cover shadow-md"
                />
                <button
                  className=" absolute -top-2 -right-2 text-primary "
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    setImage({});
                  }}
                >
                  {AllIcons.close_icon}
                </button>
              </div>
            ) : (
              <div className=" ">
                <input
                  required
                  id="upload_new_image"
                  type="file"
                  value={''}
                  className=" hidden"
                  accept=".png , .jpg ,.svg , .ico"
                  placeholder=""
                  onChange={(e) => {
                    return (
                      e.target.files.length > 0 &&
                      setImage({
                        title: e.target.files[0]?.name,
                        id: 1,
                        path: URL.createObjectURL(e.target.files[0]),
                        file: e.target.files[0],
                      })
                    );
                  }}
                />

                <button
                  className=" w-full h-10 flex flex-col justify-center items-center  mt-3    text-sm    border border-primary border-dashed rounded-lg"
                  type="button"
                  onClick={() =>
                    document.getElementById('upload_new_image').click()
                  }
                >
                  <span className=" "> Add Image</span>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      {/*  */}
      <div className="bg-[#f5f5f5] border border-t-[#cccccc] h-14 w-full py-2  sticky  bottom-0  flex items-center justify-end gap-4  px-4">
        <button
          className=" btn-primary-outline  gap-2"
          onClick={(e) => {
            saveImage(e);
          }}
        >
          Save {isLoading && AllIcons.loading_icon}
        </button>
      </div>
    </div>
    // <PopOver
    //   close_by_click_on_children={false}
    //   className={cn(
    //     '   '
    //   )}
    //   popOverButtonClassName={cn(
    //     '   py-2 px-2   justify-between   text-base font-medium rounded-sm   ',
    //     'bg-transparent text-primary hover:bg-gray-50 '
    //   )}
    //   button={

    //   }
    // >

    // </PopOver>
  );
};

export default AddImageForm;
