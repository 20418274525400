import React from 'react';

import Heading1 from '../../../UI/Text/Headers/Heading1';
import Paragraph from '../../../UI/Text/Paragraph/Paragraph';
import PrimaryButton from '../../../UI/Buttons/PrimaryButton';
import { useSelector } from 'react-redux';
import { OPPSelector } from '../../../store/feature/OPP/OPPSlice';

const PhoneVerification = ({}) => {
  const { merchant_details, isError, errorMessage } = useSelector(OPPSelector);

  return (
    <div className=" max-w-lg mx-auto bg-white flex flex-col gap-4 p-6">
      <Heading1 styles=" md:text-[32px] my-6">Phone verification</Heading1>

      <div className=" flex flex-col gap-4 items-center justify-center">
        <div className=" w-full flex items-center justify-between bg-white shadow-md p-4">
          <p className=" text-lg font-bold col-span-4">Phone number Status :</p>
          <p className=" text-base my-auto col-span-6 capitalize">
            {merchant_details?.phone_number_status}
          </p>
        </div>

        {merchant_details?.phone_number_status !==
          'merchant_details?.phone_number_status' && (
          <PrimaryButton
            className=" max-w-[200px] w-full mx-auto mt-5"
            onClick={() =>
              window.open(merchant_details?.phone_number_redirect_url)
            }
          >
            Verify
          </PrimaryButton>
        )}
      </div>
    </div>
  );
};

export default PhoneVerification;
