import React from 'react';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';

const ListingRating = ({ styles, data, product }) => {
  return (
    <div className="   " style={get_dynamic_styles(styles?.main_styles)}>
      <div
        style={{
          display: styles?.box_styles?.display,
          ...get_dynamic_styles(styles?.box_styles),
        }}
      >
        {data?.icon && (
          <img
            className="w-auto object-cover  "
            style={get_dynamic_styles(styles?.icon_styles)}
            src={data?.icon}
            alt="image"
          />
        )}
        <p
          className="  "
          style={get_dynamic_styles(styles?.rating_text_styles)}
        >
          {product?.rating_data?.rating_average}
        </p>
      </div>
    </div>
  );
};

export default ListingRating;
