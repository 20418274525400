import React from 'react';
import dynamic from 'next/dynamic';
const JSXParser = dynamic(() => import('react-jsx-parser'), { ssr: false });

const CustomDynamicCodeBlock = ({ data, styles }) => {
  return (
    <>
      <JSXParser className="w-full" jsx={`${data.code} `} />
    </>
  );
};

export default CustomDynamicCodeBlock;
