/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Address_text } from '../../../constant/Translation/Address';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import { useDispatch, useSelector } from 'react-redux';
import {
  countriesSelector,
  getCountriesStates,
  getTenantCountriesList,
} from '../../../store/feature/countrySlice';
import SelectFromList from '../../../UI/FormFields/SelectFromList';
import { Controller } from 'react-hook-form';

const AddressForm = ({
  onSubmit,
  handleSubmit,
  register,
  setShowShippingAddressForm,
  isNewAddress,
  selectShippingAddress,
  setValue,
  control,
}) => {
  let userDetails = JSON.parse(localStorage.getItem('user_details'));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTenantCountriesList());
  }, []);

  //
  useEffect(() => {
    setValue(
      'name',
      isNewAddress
        ? userDetails
          ? `${userDetails?.first_name} ${userDetails?.last_name}`
          : ''
        : selectShippingAddress.name
    );
    setValue(
      'phone_number',
      isNewAddress
        ? userDetails?.mobile
          ? `${userDetails?.dial_code}${userDetails?.mobile}`
          : ''
        : selectShippingAddress.phone_number
    );
    setValue(
      'address_line_1',
      isNewAddress ? '' : selectShippingAddress.address_line_1
    );
    setValue(
      'address_line_2',
      isNewAddress ? '' : selectShippingAddress?.address_line_2
    );
    setValue('post_code', isNewAddress ? '' : selectShippingAddress.post_code);
    setValue('country', isNewAddress ? '' : selectShippingAddress.country);
    setValue('state', isNewAddress ? '' : selectShippingAddress.state);
    setValue(
      'house_number',
      isNewAddress ? '' : selectShippingAddress?.house_number
    );
    setValue('city', isNewAddress ? '' : selectShippingAddress?.city);
    setValue(
      'state_code',
      isNewAddress ? '' : selectShippingAddress?.state_code
    );
    setValue(
      'country_code',
      isNewAddress ? '' : selectShippingAddress?.country_code
    );
  }, [isNewAddress]);
  //
  const { tenant_countries_list, states } = useSelector(countriesSelector);

  // state data load
  useEffect(() => {
    dispatch(
      getCountriesStates({
        country_id: tenant_countries_list?.filter(
          (cn) => cn?.code2 === selectShippingAddress?.country_code
        )[0]?.id,
      })
    );
  }, [selectShippingAddress?.country_code, tenant_countries_list]);

  return (
    <form
      className=" w-screen xs:w-[500px] mt-7 mb-7 bg-[#FEFEFE] rounded-card   p-[31px] relative"
      onSubmit={handleSubmit(onSubmit)}
    >
      <button
        className=" absolute top-0 ltr:right-0 rtl:left-0 mt-5 ltr:mr-5 rtl:ml-5 text-primary "
        onClick={() => setShowShippingAddressForm(false)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 "
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
      <h2 className="text-2xl font-bold">
        {Address_text.address.shipping_address}
      </h2>
      <div className="mt-8  w-full">
        <div className="grid grid-cols-1 gap-6">
          <label className="block">
            <span className="text-gray-700">{Address_text.address.name}</span>
            <input
              type="text"
              className="
                    mt-1
                    block
                    w-full
                    rounded-input
                    border-gray-300
                    shadow-sm
                    focus:border-primary 
                  "
              placeholder=""
              {...register('name', {
                required: true,
              })}
            />
          </label>
          <label className="block">
            <span className="text-gray-700">{Address_text.address.phone}</span>
            <input
              type="number"
              className="
                    mt-1
                    block
                    w-full
                    rounded-input
                    border-gray-300
                    shadow-sm
                    focus:border-primary 
                  "
              placeholder=""
              {...register('phone_number', {
                required: true,
              })}
            />
          </label>
          {TYPE_CONSTANT.ADDRESS_CONFIGS?.address_show_house_number && (
            <label className="block">
              <span className="text-gray-700">
                {Address_text.address.house_number}
              </span>
              <input
                type="text"
                className="
                    mt-1
                    block
                    w-full
                    rounded-input
                    border-gray-300
                    shadow-sm
                    focus:border-primary 
                  "
                placeholder={Address_text.address.house_number}
                {...register('house_number', {
                  required: true,
                })}
              />
            </label>
          )}
          <label className="block">
            <span className="text-gray-700">
              {Address_text.address.address_one}
            </span>
            <input
              type="text"
              className="
                    mt-1
                    block
                    w-full
                    rounded-input
                    border-gray-300
                    shadow-sm
                    focus:border-primary 
                  "
              placeholder="Address"
              {...register('address_line_1', {
                required: true,
              })}
            />
          </label>

          {TYPE_CONSTANT.ADDRESS_CONFIGS?.address_show_address2 != false && (
            <label className="block">
              <span className="text-gray-700">
                {Address_text.address.address_two}
              </span>
              <input
                type="text"
                className="
                    mt-1
                    block
                    w-full
                    rounded-input
                    border-gray-300
                    shadow-sm
                    focus:border-primary 
                  "
                placeholder="Address 2"
                {...register('address_line_2', {
                  required: true,
                })}
              />
            </label>
          )}
          {TYPE_CONSTANT.ADDRESS_CONFIGS?.address_show_city && (
            <label className="block">
              <span className="text-gray-700">{Address_text.address.city}</span>
              <input
                type="text"
                className="
                    mt-1
                    block
                    w-full
                    rounded-input
                    border-gray-300
                    shadow-sm
                    focus:border-primary 
                  "
                placeholder={Address_text.address.city}
                {...register('city', {
                  required: true,
                })}
              />
            </label>
          )}
          <div className="grid grid-cols-[100%] sm:grid-cols-[30%,30%,30%] sm:gap-[3.33%] ">
            {/* country */}
            {TYPE_CONSTANT.ADDRESS_CONFIGS?.address_show_country == true && (
              <Controller
                control={control}
                name="country_code"
                rules={{ required: true }}
                render={({ field }) => (
                  <SelectFromList
                    title={Address_text.address.country}
                    current_value={field?.value ? field?.value : ''}
                    drop_down_items={tenant_countries_list?.map((country) => {
                      return {
                        label: country.name,
                        value: country.code2,
                      };
                    })}
                    styles={{
                      popover_style: '  h-auto max-h-[250px]',
                      title_styles: 'text-gray-700 text-base',
                      component_styles: 'gap-1',
                      field_styles: `  
                    block
                    w-full
                    !rounded-input
                    border-gray-300
                    shadow-sm
                    focus:border-primary  py-2 truncate `,
                    }}
                    set_new_value={(value) => {
                      // state apis call
                      dispatch(
                        getCountriesStates({
                          country_id: tenant_countries_list?.filter(
                            (cn) => cn?.code2 === value
                          )[0]?.id,
                        })
                      );

                      field.onChange(value);
                      setValue(
                        'country',
                        tenant_countries_list?.filter(
                          (cn) => cn.code2 == value
                        )[0].name
                      );
                      setValue('state_code', '');
                    }}
                    default_option_text={Address_text.address.country}
                  />
                )}
              />
            )}

            {TYPE_CONSTANT.ADDRESS_CONFIGS?.address_show_state == true && (
              <Controller
                control={control}
                name="state_code"
                rules={{ required: true }}
                render={({ field }) => (
                  <SelectFromList
                    title={Address_text.address.state}
                    current_value={field?.value ? field?.value : ''}
                    drop_down_items={states?.map((st) => {
                      return {
                        label: st.name,
                        value: st.code,
                      };
                    })}
                    styles={{
                      popover_style: '  h-auto max-h-[250px]',
                      title_styles: 'text-gray-700 text-base',
                      component_styles: 'gap-1',
                      field_styles: `  
                    block
                    w-full
                    !rounded-input
                    border-gray-300
                    shadow-sm
                    focus:border-primary  py-2 truncate `,
                    }}
                    set_new_value={(value) => {
                      field.onChange(value);
                      setValue(
                        'state',
                        states?.filter((st) => st.code == value)[0].name
                      );
                    }}
                    default_option_text={Address_text.address.state}
                  />
                )}
              />
            )}
            {TYPE_CONSTANT.ADDRESS_CONFIGS?.address_show_zipcode != false && (
              <label className="block">
                <span className="text-gray-700">
                  {Address_text.address.zipcode}
                </span>
                <input
                  type="text"
                  className="
                    mt-1
                    block
                    w-full
                    rounded-input
                    border-gray-300
                    shadow-sm
                    focus:border-primary 
                  "
                  placeholder="123456"
                  {...register('post_code', {
                    pattern: /^[A-Za-z0-9 ]+$/i,
                    required: true,
                  })}
                />
              </label>
            )}
          </div>
        </div>
      </div>
      <div className="mt-4 flex justify-center">
        <button
          className=" bg-primary rounded-button px-4 py-2 text-white text-base font-semibold"
          type="submit"
        >
          {isNewAddress
            ? Address_text.address.save
            : Address_text.address.edit_address}
        </button>
      </div>
    </form>
  );
};

export default AddressForm;
