import axios from 'axios';

export const UploadImages = async ({ all_files }) => {
  let upload_files = [];
  let upload_full_files = [];
  for (let i = 0; i < all_files.length; i++) {
    const element = all_files[i];
    let file_data = { name: element.name, type: element.type };
    upload_files.push(file_data);
    upload_full_files.push(element.full_file);
  }

  return axios
    .post('/api/generateS3ImageURL', {
      data: {
        files: upload_files,
      },
    })
    .then((response) => {
      const responseFiles = response.data.result;

      return Promise.all(
        responseFiles.map((res_file, index) => {
          const path = res_file.signedUrl;
          const fileURI = res_file.fileUri;

          return fetch(path, {
            method: 'PUT',
            headers: {
              'Content-Type': upload_files[index].type, // Fix the typo in ContentType
            },
            body: upload_full_files[index],
          }).then((image_final_resp) => {
            if (image_final_resp.ok) {
              return fileURI;
            } else {
              throw new Error('Failed to upload image');
            }
          });
        })
      );
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
};
