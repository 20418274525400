import AttributeDetails from '../../../components/ListingDetails/AttributeDetails/AttributeDetails';
import AddressBox from '../../../components/ListingDetails/AddressBox/AddressBox';
import Variants from '../../../components/ListingDetails/Variants/Variants';
import StoreNameBox from '../../../components/ListingDetails/StoreNameBox/StoreNameBox';
import RatingBox from '../../../components/ListingDetails/RatingBox/RatingBox';
import RelatedListings from '../../../components/ListingDetails/RelatedListing/RelatedListings';
import AccountListings from '../../../components/ListingDetails/AccountListings/AccountListings';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import Edit_listing_button from '../../../components/Shared/Buttons/Edit_listing_button';
import ShareButtons from '../../../components/ListingDetails/ShareButtons/ShareButtons';
import Category from '../../../components/ListingDetails/categories/Category';
import QNA_section from '../../../components/ListingDetails/QNA/QNA_section';
import Cookies from 'js-cookie';
import NotFound from '../../../components/Shared/Default UI/NotFound';
import { Listing_text } from '../../../constant/Translation/Listing';
import ReviewBox3 from '../../../components/ListingDetails/ReviewBox/ReviewBox3';
import { useScreenSize } from '../../../hooks/useScreenSize';
import Breadcrumb2 from '../../../components/Shared/Breadcrumb2';
import ReportButton from '../../../components/ListingDetails/report/ReportButton';
import SuccessPopUp from '../../../components/Shared/PopUp/Success';
import CustomCta from '../../../components/StaticBlocks/CustomCta';
import Heading2Block from '../../../components/StaticBlocks/Heading2Block';
import { dynamic_styles_set } from '../../../constant/dynamic_styles_variable_set';
import { sell_button_helper } from '../../../helpers/ListingDetails/SellButton';
import { main_box_helper } from '../../../helpers/ListingDetails/MainBox';
import Update_section from '../../../components/ListingDetails/Updates/Updates_section';
import FundedPercentage from '../../../components/StaticBlocks/FundedPercentage';
import TitleBox from '../../../components/ListingDetails/MainBox/TitleBox';
import SellerNegotiatePart from '../../../components/ListingDetails/Negotiate/SellerNegotiatePart/SellerNegotiatePart';
import DividerBlock from '../../../components/StaticBlocks/DividerBlock';
import CustomCTAWithAttribute from '../../../components/StaticBlocks/CustomCTAWithAttribute';
import DynamicImagePart from '../../../components/ListingDetails/ImagePart/DynamicImagePart';
import DynamicDescriptionPart from '../../../components/ListingDetails/DescriptionPart/DynamicDescriptionPart';
import DynamicVariants from '../../../components/ListingDetails/Variants/DynamicVariants';
import EmptyBlock from '../../../components/StaticBlocks/EmptyBlock';
import CustomCTAWithAccountAttribute from '../../../components/StaticBlocks/CustomCTAWithAccountAttribute';
import DemandTranslation from '../../../components/Translation/DemandTranslation';
import VideoBlock from '../../../components/StaticBlocks/VideoBlock';
import AudioBlock from '../../../components/StaticBlocks/AudioBlock';
import Schedule2 from '../../../components/ListingDetails/SchedulePart/Schedule2';
import ListingTags from '../../../components/ListingDetails/Tags/ListingTags';
import DynamicImagePart2 from '../../../components/ListingDetails/ImagePart/DynamicImagePart2';
import SingleAttributeDetails from '../../../components/ListingDetails/AttributeDetails/SingleAttributeDetails';
import VariantsMatrix from '../../../components/ListingDetails/VariantMatrix/VariantsMatrix';
import OPPSetup from '../../../components/Opp/OPPSetup';
import StripeSetup from '../../../components/Stripe/StripeSetup';
import EndAt from '../../../components/ListingDetails/Date/EndAt';
import CountDownByEndTime from '../../../components/ListingDetails/Date/CountDownByEndTime';
import DonnerList from '../../../components/ListingDetails/Donations/DonnerList';
import { common_blocks_cases } from './CommonBlockCase';
import AnimatedTextBlock from '../../../components/StaticBlocks/AnimatedTextBlock';
import PhoneCTAWithAccountAttribute from '../../../components/StaticBlocks/PhoneCTAWithAccountAttribute';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';
import AttributesCard from '../../../components/ListingDetails/AttributeDetails/AttributesCard';
import DynamicStoreNameBox from '../../../components/ListingDetails/StoreNameBox/DynamicStoreNameBox';
import AttributesByGroup from '../../../components/ListingDetails/AttributeDetails/AttributesByGroup';
import AdvanceBookNowSection from '../../../components/ListingDetails/ProductButtons/AdvanceBookNowSection';
import DynamicListingMapByAttribute from '../../../components/ListingDetails/ListingDetailsMap/ListingMap';
import NormalDescription from '../../../components/ListingDetails/DescriptionPart/NormalDescription';
import AttributesByGroupWithImage from '../../../components/ListingDetails/AttributeDetails/AttributesByGroupWithImage';
import { slug as SlugChecker } from 'github-slugger';
import OldBreadcrumb from '../../../components/ListingDetails/Breadcrumb/OldBreadcrumb';
import ListingBreadcrumb from '../../../components/ListingDetails/Breadcrumb/ListingBreadcrumb';
import ReportUI from '../../../components/ListingDetails/report/ReportUI';
import ListingTaxDetails from '../../../components/ListingDetails/ListingTax/ListingTaxDetails';
import AddToWishListButton from '../../../components/DynamicListingCard/Blocks/AddTowishListButton';
import SingleShareButton from '../../../components/ListingDetails/ShareButtons/SingleShareButton';
import CommentSection from '../../../components/ListingDetails/Comment/CommentSection';
import WishWIthShare from '../../../components/StaticBlocks/WishWIthShare';
import AddReviewForm from '../../../components/ListingDetails/ReviewBox/AddReviewForm';
import UnlikeButton from '../../../components/DynamicListingCard/Blocks/UnlikeButton';

// component decide
export const listing_details_component_decide = ({
  order_number,
  block,
  block_data_key,
  block_name,
  block_code,
  isStatic,
  data,
  styles,
  isEnabled = true,
  fetch_by,
  extra_data,
  listing_details,
  rating_data,
  reviews,
  my_review,
  like,
  isOwner,
  selectedVariant,
  setSelectedVariant,
  setShowError,
  setError_message,
  unLike,
}) => {
  const section = {
    order_number,
    block,
    block_data_key,
    fetch_by,
    data,
    isStatic,
    extra_data,
    styles,
  };

  // listing type check (it will return in bool value )
  const check_listing_type = (data) => {
    const current_listing_type = listing_details?.order_type
      ? listing_details?.order_type
      : 'listings';
    if (data?.order_type) {
      return data?.order_type.includes(current_listing_type) ? true : false;
    } else {
      return true;
    }
  };

  //
  switch (block) {
    case 'image':
      return (
        isEnabled &&
        check_listing_type(data) && (
          <>
            <DynamicImagePart
              images={
                selectedVariant
                  ? listing_details?.variants?.filter(
                      (vr) => vr.id == selectedVariant
                    )[0]?.images.length > 0
                    ? listing_details?.variants?.filter(
                        (vr) => vr.id == selectedVariant
                      )[0]?.images
                    : listing_details?.images
                  : listing_details?.images
              }
              listing_details={listing_details}
              styles={styles}
              key={order_number}
            />
          </>
        )
      );
    case 'image_gallery':
      return (
        isEnabled &&
        check_listing_type(data) && (
          <>
            <DynamicImagePart2
              images={
                selectedVariant
                  ? listing_details?.variants?.filter(
                      (vr) => vr.id == selectedVariant
                    )[0]?.images.length > 0
                    ? listing_details?.variants?.filter(
                        (vr) => vr.id == selectedVariant
                      )[0]?.images
                    : listing_details?.images
                  : listing_details?.images
              }
              listing_details={listing_details}
              styles={styles}
              key={order_number}
            />
          </>
        )
      );

    case 'video':
      let video_url = data?.attribute_id
        ? listing_details?.attributes?.filter(
            (it) => it.id == data?.attribute_id
          )[0]?.values[0] ?? undefined
        : undefined;

      return (
        isEnabled &&
        check_listing_type(data) && (
          <VideoBlock
            data={data}
            styles={styles}
            video_url={video_url}
            key={order_number}
          />
        )
      );
    case 'audio':
      let audio_url = data?.attribute_id
        ? listing_details?.attributes?.filter(
            (it) => it.id == data?.attribute_id
          )[0]?.values[0] ?? '#'
        : undefined;

      return (
        isEnabled &&
        check_listing_type(data) && (
          <>
            <AudioBlock
              data={{
                url: 'https://media.tradly.app/audio/beauty/12884/file_example_MP3_1MG-6xCC9QiR.mp3',
              }}
              styles={styles}
              audio_url={audio_url}
              key={order_number}
            />
          </>
        )
      );

    case 'description':
      return (
        isEnabled &&
        check_listing_type(data) &&
        listing_details?.description !== '' && (
          <DynamicDescriptionPart
            description={listing_details?.description}
            styles={styles}
            key={order_number}
          />
        )
      );
    case 'normal_description':
      return (
        isEnabled &&
        check_listing_type(data) &&
        listing_details?.description !== '' && (
          <NormalDescription
            description={listing_details?.description}
            styles={styles}
            key={order_number}
          />
        )
      );
    case 'listing_title':
      return (
        isEnabled &&
        check_listing_type(data) && (
          <TitleBox
            listing_details={listing_details}
            styles={styles}
            key={order_number}
          />
        )
      );
    case 'qna':
      return (
        isEnabled &&
        check_listing_type(data) && (
          <>
            <QNA_section
              listing_details={listing_details}
              styles={styles}
              key={order_number}
            />
          </>
        )
      );
    case 'comment_section':
      return (
        isEnabled &&
        check_listing_type(data) && (
          <>
            <CommentSection
              listing_details={listing_details}
              styles={styles}
              key={order_number}
            />
          </>
        )
      );
    case 'updates':
      return (
        isEnabled &&
        check_listing_type(data) && (
          <Update_section
            listing_details={listing_details}
            styles={styles}
            key={order_number}
          />
        )
      );
    case 'listing_tag':
      return (
        isEnabled &&
        check_listing_type(data) && (
          <ListingTags
            listing_details={listing_details}
            styles={styles}
            data={data}
            key={order_number}
          />
        )
      );
    case 'main_box':
      return (
        isEnabled &&
        check_listing_type(data) &&
        main_box_helper({
          block_code,
          listing_details,
          rating_data,
          like,
          styles,
          data,
        })
      );
    case 'add_to_wishlist_button':
      return (
        <AddToWishListButton
          data={data}
          styles={styles}
          key={order_number}
          product={listing_details}
          like={like}
          isShowCount={true}
        />
      );
    case 'add_to_unliked_list':
      return (
        <UnlikeButton
          data={data}
          styles={styles}
          key={order_number}
          product={listing_details}
          unLike={unLike}
        />
      );
    case 'variants':
      return (
        isEnabled &&
        check_listing_type(data) &&
        listing_details?.variants?.length > 0 && (
          <DynamicVariants
            data={data}
            variants={listing_details?.variants}
            setSelectedVariant={setSelectedVariant}
            selectedVariant={selectedVariant}
            listing_details={listing_details}
            styles={styles}
            key={order_number}
          />
        )
      );
    case 'variants_matrix':
      return (
        isEnabled &&
        check_listing_type(data) &&
        listing_details?.variants?.length > 0 && (
          <VariantsMatrix
            data={data}
            variants={listing_details?.variants}
            setSelectedVariant={setSelectedVariant}
            selectedVariant={selectedVariant}
            listing_details={listing_details}
            styles={styles}
            key={order_number}
          />
        )
      );
    case 'sell_button':
      return (
        isEnabled &&
        check_listing_type(data) && (
          <div className=" fixed bottom-0 w-full p-4 sm:p-0 left-0 right-0 bg-white sm:static md:bg-transparent z-[60] sm:z-10 sm:    ">
            {sell_button_helper({
              block_code,
              listing_details,
              selectedVariant,
              setError_message,
              setShowError,
              styles,
              isOwner,
              data,
            })}
          </div>
        )
      );

    case 'advance_book_now':
      return (
        isEnabled &&
        check_listing_type(data) && (
          <AdvanceBookNowSection
            block_code={block_code}
            listing_details={listing_details}
            selectedVariant={selectedVariant}
            setError_message={setError_message}
            setShowError={setShowError}
            styles={styles}
            isOwner={isOwner}
            data={data}
          />
        )
      );

    case 'fund_rise':
      return (
        isEnabled &&
        check_listing_type(data) && (
          <FundedPercentage
            listing_details={listing_details}
            styles={styles}
            key={order_number}
          />
        )
      );
    case 'translate_cta':
      return (
        isEnabled &&
        check_listing_type(data) && (
          <DemandTranslation data={data} styles={styles} key={order_number} />
        )
      );
    case 'cta':
      return (
        isEnabled &&
        check_listing_type(data) && (
          <CustomCta data={data} styles={styles} key={order_number} />
        )
      );
    case 'cta_with_attribute':
      return (
        isEnabled &&
        check_listing_type(data) && (
          <CustomCTAWithAttribute
            data={data}
            styles={styles}
            listing_details={listing_details}
            key={order_number}
          />
        )
      );
    case 'cta_with_account_attribute':
      return (
        isEnabled &&
        check_listing_type(data) && (
          <CustomCTAWithAccountAttribute
            data={data}
            styles={styles}
            listing_details={listing_details}
            key={order_number}
          />
        )
      );
    case 'phone_cta_with_account_attribute':
      return (
        isEnabled &&
        check_listing_type(data) && (
          <PhoneCTAWithAccountAttribute
            data={data}
            styles={styles}
            listing_details={listing_details}
            key={order_number}
          />
        )
      );
    case 'heading2_block':
      return (
        isEnabled &&
        check_listing_type(data) && (
          <Heading2Block data={data} styles={styles} key={order_number} />
        )
      );
    case 'account_details':
      return (
        isEnabled &&
        check_listing_type(data) &&
        listing_details?.account && (
          <StoreNameBox
            account={listing_details?.account}
            styles={styles}
            key={order_number}
          />
        )
      );
    case 'dynamic_account_details':
      return (
        isEnabled &&
        check_listing_type(data) &&
        listing_details?.account && (
          <DynamicStoreNameBox
            account={listing_details?.account}
            styles={styles}
            data={data}
            key={order_number}
          />
        )
      );
    case 'schedule':
      return (
        isEnabled &&
        check_listing_type(data) &&
        listing_details?.schedules &&
        listing_details?.schedules?.length > 0 && (
          <Schedule2
            listing_details={listing_details}
            schedules={listing_details?.schedules}
            styles={styles}
            key={order_number}
          />
        )
      );
    case 'address':
      return (
        isEnabled &&
        check_listing_type(data) &&
        Object?.keys(listing_details?.location ?? {})?.length > 0 && (
          <AddressBox
            location={listing_details?.location}
            styles={styles}
            key={order_number}
          />
        )
      );
    case 'share':
      return (
        isEnabled &&
        check_listing_type(data) && (
          <ShareButtons
            listing_details={listing_details}
            styles={styles}
            key={order_number}
          />
        )
      );
    case 'single_share':
      return (
        isEnabled &&
        check_listing_type(data) && (
          <SingleShareButton
            listing_details={listing_details}
            styles={styles}
            key={order_number}
            data={data}
          />
        )
      );
    case 'category':
      return (
        isEnabled &&
        check_listing_type(data) && (
          <>
            <Category
              listing_details={listing_details}
              styles={styles}
              key={order_number}
            />
          </>
        )
      );
    case 'listing_taxes_list':
      return (
        isEnabled &&
        check_listing_type(data) && (
          <>
            <ListingTaxDetails
              listing_details={listing_details}
              styles={styles}
              key={order_number}
            />
          </>
        )
      );
    case 'related_listings':
      return (
        check_listing_type(data) &&
        isEnabled && (
          <RelatedListings
            listing_details={listing_details}
            styles={styles}
            key={order_number}
          />
        )
      );
    case 'account_listings':
      return (
        isEnabled &&
        check_listing_type(data) &&
        TYPE_CONSTANT.MARKETPLACE_FLAVOURS === 1 && (
          <AccountListings
            account_id={listing_details?.account_id}
            account={listing_details?.account}
            styles={styles}
            key={order_number}
          />
        )
      );

    case 'ratings':
      return (
        isEnabled &&
        check_listing_type(data) &&
        rating_data &&
        Object?.keys(rating_data)?.length > 0 && (
          <RatingBox
            rating_data={rating_data}
            styles={styles}
            key={order_number}
          />
        )
      );
    case 'edit_listing_button':
      return (
        isEnabled &&
        check_listing_type(data) &&
        Cookies.get(`${TYPE_CONSTANT.DOMAIN}_storeAccountID`) &&
        JSON.parse(Cookies.get(`${TYPE_CONSTANT.DOMAIN}_storeAccountID`)) ==
          listing_details?.account_id && (
          <div
            className=" flex items-center justify-end    "
            style={get_dynamic_styles(styles?.main_styles)}
            key={order_number}
          >
            <Edit_listing_button
              listing_details={listing_details}
              styles={styles}
            />
          </div>
        )
      );
    case 'attributes_details':
      return (
        isEnabled &&
        check_listing_type(data) &&
        listing_details?.attributes &&
        listing_details?.attributes.length > 0 && (
          <AttributeDetails
            attributes={listing_details?.attributes}
            data={data}
            styles={styles}
            key={order_number}
          />
        )
      );
    case 'single_attribute':
      let selected_attr = data?.attribute_id
        ? listing_details?.attributes?.filter(
            (it) => it.id == data?.attribute_id
          )[0]
        : null;
      return (
        isEnabled &&
        check_listing_type(data) &&
        listing_details?.attributes &&
        listing_details?.attributes.length > 0 &&
        selected_attr && (
          <SingleAttributeDetails
            attributes={listing_details?.attributes}
            data={data}
            styles={styles}
            attr={selected_attr}
            key={order_number}
          />
        )
      );
    case 'attributes_by_group':
      let selected_attributes = data?.attribute_group_id
        ? listing_details?.attributes?.filter(
            (it) => it.attribute_group_id == data?.attribute_group_id
          )
        : null;

      return (
        isEnabled &&
        check_listing_type(data) &&
        listing_details?.attributes &&
        listing_details?.attributes.length > 0 && (
          <AttributesByGroup
            attributes={listing_details?.attributes}
            data={data}
            styles={styles}
            selected_attributes={selected_attributes}
            key={order_number}
          />
        )
      );
    case 'attributes_by_group_with_image':
      let selected_attributes_with_image = data?.attribute_group_id
        ? listing_details?.attributes?.filter(
            (it) => it.attribute_group_id == data?.attribute_group_id
          )
        : null;

      return (
        isEnabled &&
        check_listing_type(data) &&
        listing_details?.attributes &&
        listing_details?.attributes.length > 0 && (
          <AttributesByGroupWithImage
            attributes={listing_details?.attributes}
            data={data}
            styles={styles}
            selected_attributes={selected_attributes_with_image}
            key={order_number}
            product_images={listing_details?.images}
          />
        )
      );
    case 'attributes_card':
      return (
        isEnabled &&
        check_listing_type(data) &&
        listing_details?.attributes &&
        listing_details?.attributes.length > 0 && (
          <AttributesCard
            attributes={listing_details?.attributes}
            data={data}
            styles={styles}
            key={order_number}
          />
        )
      );
    case 'bread_crumb':
      return (
        isEnabled &&
        check_listing_type(data) &&
        (data?.version ? (
          <ListingBreadcrumb
            data={data}
            styles={styles}
            listing_details={listing_details}
            order_number={order_number}
          />
        ) : (
          <OldBreadcrumb
            data={data}
            styles={styles}
            listing_details={listing_details}
            order_number={order_number}
          />
        ))
      );
    case 'report_button':
      return (
        isEnabled &&
        check_listing_type(data) && (
          <ReportUI
            data={data}
            styles={styles}
            listing_details={listing_details}
            order_number={order_number}
          />
        )
      );
    case 'reviews':
      return (
        isEnabled &&
        check_listing_type(data) &&
        (my_review || reviews?.length > 0) && (
          <>
            <ReviewBox3 styles={styles} key={order_number} />
          </>
        )
      );
    case 'add_review_form':
      return (
        isEnabled &&
        check_listing_type(data) && (
          <>
            <AddReviewForm
              styles={styles}
              key={order_number}
              listing_details={listing_details}
            />
          </>
        )
      );
    case 'divider_block':
      return (
        check_listing_type(data) && (
          <DividerBlock styles={styles} key={order_number} />
        )
      );
    case 'empty_block':
      return (
        check_listing_type(data) && (
          <EmptyBlock data={data} styles={styles} key={order_number} />
        )
      );
    case 'animated_paragraph':
      return (
        <AnimatedTextBlock data={data} styles={styles} key={order_number} />
      );
    // case 'seller_negotiation_part':
    case 'seller_negotiation':
      return (
        check_listing_type(data) &&
        isOwner && (
          <SellerNegotiatePart
            styles={styles}
            account={listing_details?.account}
            listing_details={listing_details}
            key={order_number}
            dynamic_data={data}
          />
        )
      );
    case 'opp_setup_button':
      return (
        isEnabled && <OPPSetup data={data} styles={styles} key={order_number} />
      );
    case 'stripe_setup_button':
      return (
        isEnabled && (
          <StripeSetup data={data} styles={styles} key={order_number} />
        )
      );
    case 'end_at_section':
      return (
        isEnabled && (
          <EndAt
            data={data}
            styles={styles}
            listingDetails={listing_details}
            key={order_number}
          />
        )
      );
    case 'count_down_by_end_time':
      return (
        isEnabled && (
          <CountDownByEndTime
            data={data}
            styles={styles}
            listingDetails={listing_details}
            key={order_number}
          />
        )
      );
    case 'donner_list':
      return (
        isEnabled && (
          <DonnerList
            data={data}
            styles={styles}
            listing_details={listing_details}
            key={order_number}
          />
        )
      );
    case 'dynamic_map_with_coordinates':
      return (
        isEnabled &&
        check_listing_type(data) && (
          <DynamicListingMapByAttribute
            data={data}
            styles={styles}
            listing_details={listing_details}
            key={order_number}
            attributes={listing_details?.attributes}
          />
        )
      );
    case 'share_with_wish':
      return (
        isEnabled &&
        check_listing_type(data) && (
          <WishWIthShare
            data={data}
            styles={styles}
            listing_details={listing_details}
            key={order_number}
            like={like}
          />
        )
      );

    default:
      return common_blocks_cases({ section });
  }
};
