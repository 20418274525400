export var Auth_text = {
  login: {
    or: 'OR',
    google_login: 'Sign in with Google',
    facebook_login: 'Sign in with Facebook',

    'android.please_select_country': 'Please select country',
    alert_error_title: 'Error',
    alert_message_select_country: 'Please select country',
    alert_ok: 'OK',
    dont_have_an_account: "Don't have an account?",
    email: 'Email',
    enter_valid_emailid: 'Please Enter Valid Email ID',
    enter_valid_phoneno: 'Please Enter Valid Phone number',
    fill_allfields: 'Please fill all the fields',
    forgot_your_password: 'Forgot your password?',
    invalid_credentials: 'Invalid Credentials',
    invalid_emailid: 'Invalid EmailID',
    invalid_phoneno: 'Invalid PhoneNumber',
    login: 'Login',
    login_to_your_account: 'Login to your account',
    mobile_number: 'Mobile Number',
    no_user_found: 'No User Found',
    password: 'Password',
    sign_up: 'Sign up',
    login_to_continue: 'Login to continue',
    welcome_to_app: 'Welcome to {value}',
    required: 'Required',
  },
  signup: {
    or: 'OR',
    google_login: 'Signup with Google',
    facebook_login: 'Signup with Facebook',
    signup_agreement:
      'By signing up an account, you agree to the ${value} , and our ${value}.',
    country_selection: 'Country Selection',

    // CSV
    'ios.country_selection': 'Country Selection',
    accept_terms_condition: 'Please Accept Terms & condition',
    agree_terms_and_conditions: 'Agree to <b>Terms & Conditions</b>',
    agree_to: 'Agree to',
    alert_error_title: 'Error',
    alert_ok: 'OK',
    create: 'Create',
    create_your_account: 'Create your account',
    email: 'Email',
    enter_same_password: 'Enter the Same password',
    enter_valid_emailid: 'Please Enter Valid Email ID',
    enter_valid_phoneno: 'Please Enter Valid Phone number',
    fill_allfields: 'Please fill all the fields',
    first_name: 'First Name',
    invalid_email: 'Invalid Email',
    invalid_emailid: 'Invalid EmailID',
    invalid_phoneno: 'Invalid PhoneNumber',
    last_name: 'Last Name',
    min_password_validation: 'Password must contains atleast 6 characters',
    mobile_number: 'Mobile Number',
    password: 'Password',
    password_min_char: 'Password must contains atleast 6 characters',
    password_mismatch: 'Password Mismatch',
    re_enter_password: 'Re-enter Password',
    required: 'Required',
    terms_and_conditions: 'Terms & Conditions',
    user_already_registered: 'User already registered.',
    welcome_to_appname: 'Welcome to {value}',
    already_have_an_account: 'Already have an account?',
    login: 'Login',
    alert_message_select_country: 'Please Select Country',
  },
  otp: {
    Invalid_otp_code: 'Invalid otp code',
    could_not_set_password:
      "Something went wrong. Couldn't able to set password at the moment.Try again later",
    did_not_receive_code: "Didn't receive a code?",
    email_verification: 'Email Verification',
    enter_verification_code_here: 'Enter verification code here',
    invalid_verification_code: 'Invalid verification code',
    otp_expired: 'Otp expired',
    otp_send_info:
      "Please enter OTP here, we've sent verification code to {value}",
    otp_verifying: 'Otp verifying',
    password_changed_success_msg: 'Password has been changed successfully',
    phone_verification: 'Phone Verification',
    please_enter_otp: 'Please enter otp',
    resend_code: 'Resend Code',
    resend_message: 'Otp code has been sent Successfully',
    verify: 'Verify',
  },
  passwordreset: {
    alert_error_title: 'Error',
    alert_ok: 'OK',
    enter_new_password: 'Enter new password',
    enter_reset_mailid:
      'Enter your registered mail id to receive OTP and reset password',
    enter_reset_phoneno:
      'Enter your registered phone number to receive OTP and reset password',
    enter_same_password: 'Enter the Same password',
    enter_valid_emailid: 'Please Enter Valid Email ID',
    enter_valid_phoneno: 'Please Enter Valid Phone number',
    fill_allfields: 'Please fill all the fields',
    invalid_emailid: 'Invalid EmailID',
    invalid_phoneno: 'Invalid PhoneNumber',
    otp: 'OTP',
    password_min_char: 'Password must contains atleast 6 characters',
    password_updated: 'Password updated successfully',
    reset: 'Reset',
    update: 'Update',
  },
  forgotpassword: {
    otp_send_info:
      "Please enter OTP here. we've sent verification code to {value}",
    alert_message_select_country: 'Please select country',
    confirm_new_password: 'Confirm New Password',
    email: 'Email',
    enter_your_email: 'Enter your Email',
    enter_your_mobile_number: 'Enter your Mobile number',
    forgot_your_password: 'Forgot your password?',
    mobile_number: 'Mobile number',
    new_password: 'New Password',
    verification_password: 'Verification Code',
    verify: 'Verify',
    required: 'Required',
    invalid_email: 'Invalid Email',
    invalid_no: 'Invalid MobileNo',
    invalid_otp_code: 'Invalid otp code',
    password_mismatch: 'Password mismatch',
    password_changed_successfully: 'Password has been changed successfully',
  },
};
