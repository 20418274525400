import { useSelector } from 'react-redux';
import { authSelector } from '../../store/feature/authSlice';

import { changeDateFormat } from '../Shared/Constant/Constant';
import { useRouter } from 'next/dist/client/router';

import { useDispatch } from 'react-redux';
import Link from 'next/link';
import { Menu, Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useState } from 'react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import axios from 'axios';
import { dynamic_translation_set } from '../../constant/Translation/translation_set';
import { Notification_text } from '../../constant/Translation/Notification';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import { getCookie } from 'cookies-next';
import { order_notification_text } from '../Shared/Constant/TextConstant/NotificationsText';
import {
  getMoreNotifications,
  getNotifications,
  notificationsSelector,
} from '../../store/feature/notificationSlice';
import { useScreenSize } from '../../hooks/useScreenSize';
import { cn } from '../../utils/classNames';
import { get_dynamic_styles } from '../../constant/DynamicStyles';

const NotificationsList = ({ styles }) => {
  const router = useRouter();
  const dispatch = useDispatch();

  //
  const { notifications, page, total_records } = useSelector(
    notificationsSelector
  );

  const fetch_more = () => {
    dispatch(getMoreNotifications({ page: page + 1 }));
  };

  return (
    <>
      <div style={get_dynamic_styles(styles?.main_styles)}>
        <div
          className="flex items-center   "
          style={get_dynamic_styles(styles?.box_styles)}
        >
          {notifications !== null && notifications?.length !== 0 ? (
            <div className="  max-w-[245px]  md:max-w-[350px] h-[80vh]     ">
              {notifications?.map((nt, index) => {
                if (nt.type == 1) {
                  return (
                    <Link href={'/a/my-account'}>
                      <a
                        key={Math.random()}
                        className="px-2 py-3 w-full min-h-[60px] my-2  flex gap-3  items-start cursor-pointer"
                        // onClick={() => router.push('a/my-account?page=1')}
                      >
                        <div className="  w-[26px]   h-[26px] relative rounded-full overflow-hidden ">
                          {nt?.account?.images.length > 0 && (
                            <img
                              src={nt.account.images[0]}
                              className="h-full w-full object-cover object-left-top"
                              alt="image"
                            />
                          )}
                        </div>
                        <div className="flex flex-col gap-1 items-start">
                          <h2 className="  flex-none text-sm font-semibold text-[#050505] break-words">
                            {dynamic_translation_set(
                              Notification_text.notification.account_message,
                              nt?.user?.first_name
                            )}
                          </h2>
                          <p className=" flex-shrink text-sm font-normal text-primary  ">
                            {changeDateFormat(
                              nt.created_at,
                              'DD-MM-YYYY, h:mm:ss a'
                            )}
                          </p>
                        </div>
                      </a>
                    </Link>
                  );
                }
                if (nt.type == 2) {
                  return (
                    <Link
                      href={`/l/${nt?.listing?.id}-${nt?.listing?.title.replace(
                        /[ /?,#=]+/g,
                        '+'
                      )}`}
                    >
                      <a
                        key={Math.random()}
                        className="px-2 py-3 w-full min-h-[60px] my-2  flex gap-3  items-start cursor-pointer"
                      >
                        <div className="  w-[26px]  h-[26px] relative rounded-full overflow-hidden ">
                          {nt?.listing?.images.length > 0 && (
                            <img
                              src={nt.listing.images[0]}
                              className="h-full w-full object-cover object-left-top"
                              alt="image"
                            />
                          )}
                        </div>
                        <div div className="flex flex-col gap-1 items-start">
                          <h2 className=" flex-none text-sm font-semibold text-[#050505] break-words">
                            {dynamic_translation_set(
                              Notification_text.notification.liked_your_listing,
                              nt?.user?.first_name
                            )}
                          </h2>
                          <p className="  text-sm font-normal text-primary ">
                            {changeDateFormat(
                              nt.created_at,
                              'DD-MM-YYYY, h:mm:ss a'
                            )}
                          </p>
                        </div>
                      </a>
                    </Link>
                  );
                }

                if (nt.type === 3) {
                  if (
                    getCookie(`${TYPE_CONSTANT.DOMAIN}_isStoreAccount`) ==
                      true &&
                    getCookie(`${TYPE_CONSTANT.DOMAIN}_storeAccountID`) ==
                      nt?.metadata.account_id
                  ) {
                    return (
                      <Link
                        href={`/a/orders/${nt?.metadata.reference_number}?account_id=${nt?.metadata.account_id}`}
                      >
                        <a
                          key={Math.random()}
                          className="px-2 py-3 w-full min-h-[60px] my-2  flex gap-3  items-start cursor-pointer"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className=" w-[26px]  sm:h-[26px] relative  overflow-hidden    text-[#6e686e]  flex items-center justify-center  "
                            fill="none"
                            viewBox=" 0 26 26 "
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                            />
                          </svg>
                          <div>
                            <h2 className=" flex-none text-sm font-semibold text-[#050505]  break-words">
                              {order_notification_text(
                                nt?.metadata?.order_status
                              )}
                            </h2>
                            <p className="text-sm font-normal text-primary mt-1">
                              {changeDateFormat(
                                nt.created_at,
                                'DD-MM-YYYY, h:mm:ss a'
                              )}
                            </p>
                          </div>
                        </a>
                      </Link>
                    );
                  } else if (
                    getCookie(`${TYPE_CONSTANT.DOMAIN}_isStoreAccount`) !=
                      true ||
                    getCookie(`${TYPE_CONSTANT.DOMAIN}_storeAccountID`) !=
                      nt?.metadata.account_id
                  ) {
                    return (
                      <Link href={`/orders/${nt?.metadata.reference_number}`}>
                        <a
                          key={Math.random()}
                          className="  px-2 py-3 w-full min-h-[60px] my-2  flex gap-3  items-start cursor-pointer"
                          // onClick={() => order_link(nt?.reference_id)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className=" w-[16px] h-[16px]   sm:w-[26px]  sm:h-[26px] relative  overflow-hidden    text-[#6e686e]  flex items-center justify-center "
                            fill="none"
                            viewBox=" 0 26 26 "
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                            />
                          </svg>
                          <div>
                            <h2 className=" text-sm font-semibold text-[#050505]">
                              {order_notification_text(
                                nt?.metadata?.order_status
                              )}
                            </h2>
                            <p className="text-sm font-normal text-primary mt-1">
                              {changeDateFormat(
                                nt.created_at,
                                'DD-MM-YYYY, h:mm:ss a'
                              )}
                            </p>
                          </div>
                        </a>
                      </Link>
                    );
                  }
                }
              })}
              {Number(total_records) != notifications?.length && (
                <div className="py-3 flex justify-center">
                  <p
                    className=" font-semibold text-xs text-primary cursor-pointer"
                    onClick={() => fetch_more()}
                  >
                    {Notification_text.notification.load_more}
                  </p>
                </div>
              )}
            </div>
          ) : (
            <div className="  max-w-[320px] h-[100px] overflow-auto    scrollbar-thin flex justify-center items-center mx-auto">
              <h2 className="px-3 py-4 text-center text-base font-medium text-primary">
                {Notification_text.notification.no_notification_at_the_moment}
              </h2>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default React.memo(NotificationsList);
