import DonationMainBox from '../../components/ListingDetails/MainBox/DonationMainBox';
import MainBox from '../../components/ListingDetails/MainBox/MainBox';
import TitleBox from '../../components/ListingDetails/MainBox/TitleBox';

export const main_box_helper = ({
  block_code,
  listing_details,
  rating_data,
  like,
  styles,
  data,
}) => {
  switch (block_code) {
    case 1:
      return (
        <MainBox
          listing_details={listing_details}
          rating_data={rating_data}
          like={like}
          styles={styles}
          data={data}
        />
      );
    case 2:
      return (
        <TitleBox
          listing_details={listing_details}
          rating_data={rating_data}
          like={like}
          styles={styles}
        />
      );
    case 3:
      return (
        <DonationMainBox
          listing_details={listing_details}
          rating_data={rating_data}
          like={like}
          styles={styles}
          data={data}
        />
      );

    default:
      return <></>;
  }
};
