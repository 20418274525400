export function calculateDayDifference(startTime, endTime) {
  // Assuming the date part is the same for both times
  const datePart = new Date().toISOString().split('T')[0];

  // Create Date objects for both times
  const startDateTime = new Date(`${datePart}T${startTime}`);
  let endDateTime = new Date(`${datePart}T${endTime}`);

  // If the end time is smaller than start time, it means it has moved to the next day
  if (endDateTime < startDateTime) {
    endDateTime.setDate(endDateTime.getDate() + 1);
  }

  // Calculate the difference in milliseconds
  const diffInMs = endDateTime - startDateTime;

  // Convert the difference from milliseconds to days
  const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

  // Return the difference in days
  return Math.round(diffInDays);
}
