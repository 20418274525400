import React, { useEffect } from 'react';
import TextInput from '../../UI/FormFields/TextInput';
import SelectFromList from '../../UI/FormFields/SelectFromList';
import { useSelector } from 'react-redux';
import { authSelector } from '../../store/feature/authSlice';
import DateInputPicker from '../../UI/DatePicker/DateInputPicker';
import { useRouter } from 'next/router';
import { KYC_PROFILE_DETAILS_FORMAT } from './types/KYCTypes';
import {
  get_data_from_local_storage,
  set_data_in_local_storage,
} from '../../utils/localStorage';
import Alert from '../Shared/PopUp/Alert';
import { kycAlertTypeByStatus } from '../../constant/wallet/KYCStatus';
import { Wallet_text } from '../../constant/Translation/wallet';

const KYCMessage = ({
  message,
  mangopay_kyc_status,
}: {
  message: string;
  mangopay_kyc_status: string;
}) => {
  const router = useRouter();
  const { tenant_countries_list } = useSelector(authSelector);

  return (
    <div className="space-y-6 max-w-lg  mx-auto">
      <h2 className="text-center text-2xl font-bold">
        {Wallet_text?.kyc.kyc_status}
      </h2>
      <Alert
        type={kycAlertTypeByStatus(mangopay_kyc_status)}
        open={true}
        isOpenFunction={false}
        setOpen={undefined}
        className=""
        funcCallOnClose={undefined}
      >
        <p>{message}</p>
      </Alert>
    </div>
  );
};

export default KYCMessage;
