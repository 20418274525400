import { ArrowDownIcon, ChevronDownIcon } from '@heroicons/react/outline';
import { ChevronUpIcon } from '@heroicons/react/solid';
import React from 'react';

const SubscriptionCard = ({
  selected_id,
  setSelectedID,
  see_details_id,
  setSeeDetailsID,
  product,
}) => {
  return (
    <div className=" p-6 rounded-md bg-white border border-gray-300 w-full max-w-[200px]   flex flex-col items-center gap-2  ">
      <input
        className=" form-check-input appearance-none h-3 w-3 border border-gray-300 rounded-sm bg-white checked:bg-primary checked:border-primary focus:outline-none focus:ring-primary checked:hover:bg-primary  checked:focus:ring-primary checked:focus:bg-primary  transition duration-200   align-top bg-no-repeat bg-center bg-contain   mr-2 cursor-pointer"
        type="checkbox"
        checked={selected_id === product?.id ? true : false}
        onChange={() => setSelectedID(product.id)}
      />
      <p className=" text-lg font-semibold text-center ">
        {product?.currency_amount?.formatted}
      </p>
      <p className=" text-sm  text-gray-500 text-center">{product?.title}</p>
      {see_details_id !== product?.id ? (
        <button
          onClick={() => setSeeDetailsID(product?.id)}
          className="text-primary flex items-center justify-center gap-2"
        >
          See Details <ChevronDownIcon className="h-5 w-5" />
        </button>
      ) : (
        <button
          onClick={() => setSeeDetailsID(null)}
          className="text-error flex items-center justify-center gap-2"
        >
          Close Details <ChevronUpIcon className="h-5 w-5" />
        </button>
      )}
    </div>
  );
};

export default SubscriptionCard;
