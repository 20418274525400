import React, { useEffect, useState } from 'react';
import WithdrawAmountPicker from './WithdrawAmountPicker';
import StepsUI from '../../../UI/Steps/StepsUI';
import PrimaryButton from '../../../UI/Buttons/PrimaryButton';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import {
  WalletSelector,
  amountPayInByMangoPay,
  amountPayInByMangoPayCalc,
  amountWithdrawByMangoPayConfirm,
  amountWithdrawByMangoPayINIT,
  clearWalletError,
  clearWithdraw,
  getMangoPayWalletBalance,
  getMangopayWalletTransactions,
  getWallets,
} from '../../../store/feature/wallet/walletSlice';
import Cards from '../Cards/Cards';
import SecondaryButton from '../../../UI/Buttons/SecondaryButton';
import { Wallet_text } from '../../../constant/Translation/wallet';
import WithdrawAmountSuccess from './WithdrawAmountSuccess';
import VerifyWithdraw from './VerifyWithdraw';
import Alert from '../../Shared/PopUp/Alert';
import { useAppDispatch } from '../../../hooks/Redux';
import AllIcons from '../../Shared/Constant/Icons/AllIcons';
import { getCookie } from 'cookies-next';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';

const WithdrawAmountSteps = ({ sidePanOpen, setSidePanOpen }) => {
  //
  const router = useRouter();
  const dispatch = useAppDispatch();
  const currency = router.query.code
    ? router.query.code
    : getCookie(`${TYPE_CONSTANT.DOMAIN}_default_currency`) ?? '';

  //
  const [step_info, setStepInfo] = useState({
    current_step: 1,
    next_step: 2,
    prev_step: 0,
  });

  //* effect at first time
  useEffect(() => {
    // dispatch(getSavedCards());
  }, [0]);

  //
  const changeStep = ({ current_step, next_step, prev_step }) => {
    setStepInfo({
      current_step: current_step,
      next_step: next_step ?? current_step + 1,
      prev_step: prev_step ?? current_step - 1,
    });
  };

  //* selectors
  const {
    isWithdrawCalcLoading,
    withdraw_calculation,
    withdraw_init,
    withdrawInitLoading,
    withdrawInitSuccess,
    withdrawConfirmSuccess,
    withdrawConfirmLoading,
    isError,
    errorMessage,
  } = useSelector(WalletSelector);

  //* step 1 :
  const [amount_for_withdraw, setWithdrawAmount] = useState<number | string>(
    ''
  );
  const changeAmountStep = () => {
    // data
    let data: { amount: Number; currency?: string } = {
      amount: Number(amount_for_withdraw),
    };
    if (currency) {
      data.currency = currency as string;
    }
    //
    dispatch(
      amountWithdrawByMangoPayINIT(
        // @ts-ignore
        data
      )
    );
  };

  useEffect(() => {
    if (withdraw_init && !withdrawInitLoading && withdrawInitSuccess) {
      changeStep({
        current_step: 2,
        next_step: 3,
        prev_step: 1,
      });
    }
  }, [withdrawInitLoading, withdrawInitSuccess, withdraw_init]);

  //* step 2 :
  //
  const [code, setCode] = useState(null);
  const changeVerifyStep = () => {
    // data
    let data: { code: String; verify_id: String; currency?: string } = {
      code: code,
      verify_id: withdraw_init?.verify_id,
    };
    if (currency) {
      data.currency = currency as string;
    }
    dispatch(
      amountWithdrawByMangoPayConfirm(
        // @ts-ignore
        data
      )
    );
  };

  //
  useEffect(() => {
    if (withdrawConfirmSuccess && !withdrawConfirmLoading) {
      changeStep({
        current_step: 3,
        next_step: 0,
        prev_step: 0,
      });
      setCode(null);
      setWithdrawAmount(0);
      dispatch(clearWithdraw());
      // dispatch(getMangoPayWalletBalance());
      dispatch(getWallets());
      dispatch(
        // @ts-ignore
        getMangopayWalletTransactions({ currency: currency })
      );
    }
  }, [withdrawConfirmSuccess, withdrawConfirmLoading]);

  // * step 3 :

  // Alert
  const [open_alert, setOpenAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [alert_type, setAlert_type] = useState('success');

  // Error handling
  useEffect(() => {
    if (isError) {
      setAlert_type('error');
      setOpenAlert(true);
      setMessage(errorMessage);
    }
  }, [isError]);

  return (
    <>
      {/* Alert */}
      <Alert
        className={` fixed z-50 top-0 left-0 right-0  w-full  xs:w-[500px] mx-auto mt-5 `}
        type={alert_type}
        open={open_alert}
        setOpen={setOpenAlert}
        isOpenFunction={undefined}
        funcCallOnClose={() => {
          dispatch(clearWalletError());
        }}
      >
        <p>{message}</p>
      </Alert>

      {/* close button */}
      <button
        className=" absolute right-3 md:left-3 w-10 h-10 flex items-center justify-center top-3 p-3 rounded-full shadow-md bg-white  z-20 text-primary hover:shadow-xl duration-200 "
        onClick={() => {
          setCode(null);
          setWithdrawAmount(0);
          dispatch(clearWithdraw());
          // dispatch(getMangoPayWalletBalance());
          dispatch(getWallets());
          dispatch(
            // @ts-ignore
            getMangopayWalletTransactions({ currency: currency })
          );
          setSidePanOpen(false);
          changeStep({ current_step: 1, next_step: 2, prev_step: 0 });
        }}
      >
        {AllIcons.close_icon}
      </button>

      {/* Steps UI */}
      <StepsUI
        steps={[
          { title: Wallet_text?.wallet.amount, link: undefined, step_code: 1 },
          { title: Wallet_text?.wallet.verify, link: undefined, step_code: 2 },
          { title: Wallet_text?.wallet.success, link: undefined, step_code: 3 },
        ]}
        steps_components={[
          {
            step_code: 1,
            component: (
              <WithdrawAmountPicker
                amount={amount_for_withdraw}
                setAmountValue={setWithdrawAmount}
              />
            ),
          },
          {
            step_code: 2,
            component: <VerifyWithdraw code={code} setCode={setCode} />,
          },
          {
            step_code: 3,
            component: <WithdrawAmountSuccess />,
          },
        ]}
        steps_buttons={[
          // * step 1 button
          {
            button_component: (
              <div className=" max-w-md mt-20 w-full flex item-center justify-between">
                <SecondaryButton
                  onClick={() => {
                    changeStep({ current_step: 1, next_step: 2, prev_step: 0 });
                    setSidePanOpen(false);
                  }}
                  className=" px-10"
                >
                  {Wallet_text?.wallet.cancel}
                </SecondaryButton>
                <PrimaryButton
                  onClick={(e) => {
                    changeAmountStep();
                  }}
                  className=" px-10"
                  isDisabled={
                    amount_for_withdraw == '' ||
                    amount_for_withdraw == 0 ||
                    isWithdrawCalcLoading ||
                    withdrawInitLoading
                  }
                  isLoading={withdrawInitLoading}
                >
                  {Wallet_text?.wallet.next}
                </PrimaryButton>
              </div>
            ),
            step_code: 1,
          },
          // * step 2 button
          {
            button_component: (
              <div className=" max-w-xl mt-20 w-full flex item-center justify-between">
                <SecondaryButton
                  onClick={() =>
                    changeStep({ current_step: 1, next_step: 2, prev_step: 0 })
                  }
                  className=" px-10"
                >
                  {Wallet_text?.wallet.back}
                </SecondaryButton>
                <PrimaryButton
                  onClick={(e) => {
                    changeVerifyStep();
                  }}
                  className=" px-10"
                  isLoading={withdrawConfirmLoading}
                  isDisabled={
                    withdrawConfirmLoading ||
                    code == null ||
                    code?.length !== 6 ||
                    !withdraw_init?.verify_id
                  }
                >
                  {Wallet_text.wallet.withdraw}{' '}
                  {withdraw_calculation?.total_amount?.formatted}
                </PrimaryButton>
              </div>
            ),
            step_code: 2,
          },

          // * step 3 buttons
          {
            button_component: (
              <div className=" max-w-md mt-20 w-full flex item-center justify-end">
                <PrimaryButton
                  onClick={() => {
                    setSidePanOpen(false);
                    changeStep({ current_step: 1, next_step: 2, prev_step: 0 });
                  }}
                  className=" px-10"
                >
                  {Wallet_text?.wallet.finish}
                </PrimaryButton>
              </div>
            ),
            step_code: 3,
          },
        ]}
        current_step={step_info?.current_step}
        next_step={step_info?.next_step}
        prev_step={step_info?.prev_step}
      />
    </>
  );
};

export default WithdrawAmountSteps;
