import React from 'react';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';

const SingleShareButton = ({ data, styles, listing_details }) => {
  return (
    <div style={get_dynamic_styles(styles?.main_styles)}>
      <div
        className="flex items-center   "
        style={get_dynamic_styles(styles?.box_styles)}
      >
        <button
          className="     rounded-button  flex items-center justify-center  gap-2 "
          type="button"
          onClick={() => {
            navigator
              .share({
                title: `${listing_details?.title}`,
                url: `${window.location.href}`,
              })
              .then(() => {
                // alert('Thanks for sharing!');
              })
              .catch();
          }}
          target={data?.button_target === '_blank' ? '_blank' : '_self'}
          style={get_dynamic_styles(styles?.button_styles)}
        >
          <img
            className="w-auto object-cover  "
            style={get_dynamic_styles(styles?.icon_styles)}
            src={data?.icon}
            alt="image"
          />
        </button>
      </div>
    </div>
  );
};

export default SingleShareButton;
