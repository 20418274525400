import { set_line_clamp } from './set_lineclamp';

export const get_dynamic_styles_with_default_value = (
  styles,
  default_value
) => {
  const getStyleWithFallback = (styleProp) => {
    switch (styleProp) {
      case 'font_family':
        return styles?.[styleProp]
          ? `var(--${styles?.[styleProp]})`
          : default_value?.[styleProp]
          ? `var(--${default_value?.[styleProp]})`
          : '';
      case 'grid_column':
        return styles?.[styleProp]
          ? `span ${styles?.[styleProp]}`
          : default_value?.[styleProp]
          ? `span ${default_value?.[styleProp]}`
          : 'auto';
      case 'text_color':
        return styles?.[styleProp]?.startsWith('--')
          ? `var(${styles?.[styleProp]})`
          : styles?.[styleProp]
          ? styles?.[styleProp]
          : default_value?.[styleProp]
          ? default_value?.[styleProp]
          : '';
      case 'border_color':
        return styles?.[styleProp]?.startsWith('--')
          ? `var(${styles?.[styleProp]})`
          : styles?.[styleProp]
          ? styles?.[styleProp]
          : default_value?.[styleProp]
          ? default_value?.[styleProp]
          : '';
      case 'background':
        return styles?.[styleProp]?.startsWith('--')
          ? `var(${styles?.[styleProp]})`
          : styles?.[styleProp]
          ? styles?.[styleProp]
          : default_value?.[styleProp]
          ? default_value?.[styleProp]
          : '';

      default:
        return styles?.[styleProp]
          ? styles?.[styleProp]
          : default_value?.[styleProp] ?? '';
    }
  };

  let style_generate = {
    fontSize: getStyleWithFallback('text_size'),
    lineHeight: getStyleWithFallback('line_height'),
    fontWeight: getStyleWithFallback('font_weight'),
    fontFamily: getStyleWithFallback('font_family'),
    color: getStyleWithFallback('text_color'),
    minHeight: getStyleWithFallback('min_height') ?? '',
    minWidth: getStyleWithFallback('min_width') ?? '',
    maxWidth: getStyleWithFallback('max_width') ?? '',
    maxHeight: getStyleWithFallback('max_height') ?? '',
    width: getStyleWithFallback('width') ?? 'auto',
    height: getStyleWithFallback('height') ?? 'auto',
    borderRadius: getStyleWithFallback('border_radius') ?? '',
    borderTopLeftRadius: getStyleWithFallback('top_left_border_radius') ?? '',
    borderTopRightRadius: getStyleWithFallback('top_right_border_radius') ?? '',
    borderBottomLeftRadius:
      getStyleWithFallback('bottom_left_border_radius') ?? '',
    borderBottomRightRadius:
      getStyleWithFallback('bottom_right_border_radius') ?? '',
    borderColor: getStyleWithFallback('border_color') ?? '',
    position: getStyleWithFallback('position') ?? '',
    zIndex: getStyleWithFallback('z_index') ?? '',
    top: getStyleWithFallback('top') ?? '',
    bottom: getStyleWithFallback('bottom') ?? '',
    right: getStyleWithFallback('right') ?? '',
    left: getStyleWithFallback('left') ?? '',
    paddingRight:
      getStyleWithFallback('padding_right') !== '0px' &&
      getStyleWithFallback('padding_right') !== undefined
        ? getStyleWithFallback('padding_right')
        : getStyleWithFallback('padding_x') ?? '',
    paddingLeft:
      getStyleWithFallback('padding_left') !== '0px' &&
      getStyleWithFallback('padding_left') !== undefined
        ? getStyleWithFallback('padding_left')
        : getStyleWithFallback('padding_x') ?? '',
    paddingTop:
      getStyleWithFallback('padding_top') !== '0px' &&
      getStyleWithFallback('padding_top') !== undefined
        ? getStyleWithFallback('padding_top')
        : getStyleWithFallback('padding_y') ?? '',
    paddingBottom:
      getStyleWithFallback('padding_bottom') !== '0px' &&
      getStyleWithFallback('padding_bottom') !== undefined
        ? getStyleWithFallback('padding_bottom')
        : getStyleWithFallback('padding_y') ?? '',
    marginTop:
      getStyleWithFallback('margin_top') !== '0px' &&
      getStyleWithFallback('margin_top') !== undefined
        ? getStyleWithFallback('margin_top')
        : getStyleWithFallback('margin_y') ?? '',
    marginBottom:
      getStyleWithFallback('margin_bottom') !== '0px' &&
      getStyleWithFallback('margin_bottom') !== undefined
        ? getStyleWithFallback('margin_bottom')
        : getStyleWithFallback('margin_y') ?? '',
    marginLeft:
      getStyleWithFallback('margin_left') !== '0px' &&
      getStyleWithFallback('margin_left') !== undefined
        ? getStyleWithFallback('margin_left')
        : getStyleWithFallback('margin_left') === '0px'
        ? 'auto'
        : getStyleWithFallback('margin_x')
        ? getStyleWithFallback('margin_x') === '0px'
          ? 'auto'
          : getStyleWithFallback('margin_x')
        : '',
    marginRight:
      getStyleWithFallback('margin_right') !== '0px' &&
      getStyleWithFallback('margin_right') !== undefined
        ? getStyleWithFallback('margin_right')
        : getStyleWithFallback('margin_right') === '0px'
        ? 'auto'
        : getStyleWithFallback('margin_x')
        ? getStyleWithFallback('margin_x') === '0px'
          ? 'auto'
          : getStyleWithFallback('margin_x')
        : '',
    textAlign: getStyleWithFallback('text_align') ?? '',
    backgroundImage: getStyleWithFallback('background_image_url')
      ? `url('${getStyleWithFallback('background_image_url')}')`
      : '',
    background: getStyleWithFallback('background') ?? '',
    gridColumn: getStyleWithFallback('grid_column') ?? '',
    boxShadow: getStyleWithFallback('box_shadow') ?? '',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    flexDirection: getStyleWithFallback('flex_direction') ?? '',
    flexWrap: getStyleWithFallback('flex_wrap') ?? '',
    alignItems: getStyleWithFallback('align_items') ?? '',
    alignContent: getStyleWithFallback('align_content') ?? '',
    justifyContent: getStyleWithFallback('justify_content') ?? '',
    gap: getStyleWithFallback('gap') ?? '',
    rowGap: getStyleWithFallback('row_gap') ?? '',
    columnGap: getStyleWithFallback('column_gap') ?? '',
    objectFit: getStyleWithFallback('object_fit') ?? '',
    ...set_line_clamp(styles?.line_clamp),
  };

  for (let key in style_generate) {
    if (style_generate[key] === '' && style_generate[key]?.length === 0) {
      delete style_generate[key];
    }
  }

  return style_generate;
};
