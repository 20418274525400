import QRCode from 'react-qr-code';
import { getThumbnailImage } from '../../Shared/Constant/Constant';
import React, { useCallback, useRef } from 'react';
import jsPDF from 'jspdf';
import { toPng } from 'html-to-image';
import { createEvent } from 'ics';
import { saveAs } from 'file-saver';
import { get_dynamic_styles_with_default_value } from '../../../constant/DynamicStylesWithDefaultValue';
import {
  responsive_column_styles,
  responsive_design_styles,
  responsive_row_styles,
} from '../../../utils/ResponsiveDesign';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';
import { useScreenSize } from '../../../hooks/useScreenSize';
import { ticket_ui_component_decide } from '../../../themes/blank/BlockDecide/TicketUIBlockDecide';
import { TagListingsDefaultColumnStyles } from '../../../constant/default_styles/TagListingsDefaultStyles';
import DownloadImage from './DownloadImage';
import { convertSchedule } from '../../../utils/convertSchedule';

const BookingTicket = ({
  order_details,
  ticket_attribute,
  order_item,
  isShowButtons = true,
}) => {
  const { width, height } = useScreenSize();
  const ticket_ui = TYPE_CONSTANT.STYLES_CONFIGS?.ticket_ui;

  const ticketRef = useRef();
  const downloadImage = useCallback(
    ({ type }) => {
      if (ticketRef.current === null) {
        return;
      }

      toPng(ticketRef.current, { cacheBust: true })
        .then((dataUrl) => {
          const link = document.createElement('a');
          link.download = 'ticket.png';
          link.href = dataUrl;
          if (type === 'pdf') {
            const pdf = new jsPDF();
            pdf.addImage(dataUrl, 'PNG', 0, 0);
            pdf.save('ticket.pdf');
          } else {
            link.click();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    [ticketRef]
  );
  const addToCalendar = () => {
    const { start, duration } = convertSchedule(
      order_item?.schedule_start_at,
      order_item?.schedule_end_at
    );
    const event = {
      start: start, // [year, month, day, hour, minute]
      duration: duration, // duration of the event
      title: order_item?.listing?.title,
      description: order_item?.listing?.description,
      location: order_details?.account?.name ?? '',
      url: window.location.href, // URL for the event, if any
      status: 'CONFIRMED',
      busyStatus: 'BUSY',
    };

    createEvent(event, (error, value) => {
      if (error) {
        console.error('Error creating calendar event:', error);
        return;
      }

      const blob = new Blob([value], { type: 'text/calendar;charset=utf-8' });
      saveAs(blob, 'event.ics');
    });
  };

  return (
    <>
      {/*  */}
      {/* {(TAG_LISTINGS_PAGE?.schema?.length === 0 ||
        TAG_LISTINGS_PAGE?.schema === undefined) && (
        <Warning
          className={' max-w-md px-2 mx-auto mt-10'}
          message={'The setup for the tag listing page is not yet complete.'}
        />
      )} */}

      {/* Rows */}

      <div
        ref={ticketRef}
        style={get_dynamic_styles(
          responsive_design_styles({
            width,
            parent_group: ticket_ui?.styles,
            child_group_name: 'main_styles',
          })
        )}
      >
        <div
          className="flex flex-col  "
          style={get_dynamic_styles(
            responsive_design_styles({
              width,
              parent_group: ticket_ui?.styles,
              child_group_name: 'box_styles',
            })
          )}
        >
          {ticket_ui?.schema?.map((row) => {
            const {
              columns,
              current_editor_version = '',
              ...column_styles
            } = row || {};
            const row_styles = responsive_row_styles({
              parent_group: row,
              width,
            });
            return (
              <div
                className={``}
                style={{
                  ...get_dynamic_styles(row_styles),
                  display:
                    row_styles?.isShowInMobile === false ? 'none' : 'grid',
                  gap: row_styles?.column_gap,
                  gridTemplateColumns:
                    width > 768
                      ? row_styles?.columns_box_percentage
                        ? row_styles?.columns_box_percentage
                        : `repeat(${row.columns?.length ?? 1}, minmax(0, 1fr))`
                      : row?.['768_row_styles']
                      ? row_styles?.columns_box_percentage
                      : '100%',
                }}
              >
                {/* Columns */}
                {columns?.map((column, index) => {
                  const current_column_style = responsive_column_styles({
                    parent_group: column_styles,
                    index,
                    width,
                  });

                  return (
                    <div
                      className="flex   "
                      style={{
                        ...get_dynamic_styles_with_default_value(
                          current_column_style,
                          TagListingsDefaultColumnStyles
                        ),
                      }}
                    >
                      {column?.map((item) => {
                        return (
                          ticket_ui_component_decide({
                            ...item,
                            cart_attributes: ticket_attribute?.attributes,
                            order_details,
                            unique_id: ticket_attribute?.unique,
                          }) &&
                          ticket_ui_component_decide({
                            ...item,
                            cart_attributes: ticket_attribute?.attributes,
                            downloadImage,
                            order_details,
                            unique_id: ticket_attribute?.unique,
                          })
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
      {ticket_ui && isShowButtons && (
        <DownloadImage
          downloadImage={downloadImage}
          addToCalendar={addToCalendar}
        />
      )}
    </>
  );
};

export default BookingTicket;
