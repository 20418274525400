import React from 'react';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';
import Link from 'next/link';
import { dynamic_text_convert } from '../../../utils/dynamicTextConvert';

const ListingTags = ({ styles, listing_details, data }) => {
  return (
    <div style={get_dynamic_styles(styles?.box_styles)}>
      <div className="flex " style={get_dynamic_styles(styles?.box_styles)}>
        {listing_details?.tags?.map((tag) => {
          return (
            <Link href={`/t/${tag?.name}`}>
              <button style={get_dynamic_styles(styles?.button_styles)}>
                {dynamic_text_convert({
                  main_text: data?.tag_name_structure,
                  value: tag.name,
                  check_by: `{tag}`,
                })}
              </button>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default ListingTags;
