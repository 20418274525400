import React from 'react';
import { get_dynamic_styles } from '../../constant/DynamicStyles';

const AnimatedTextBlock = ({ data, styles }) => {
  return (
    <p
      className="animate-character"
      style={get_dynamic_styles({
        ...styles?.text,
        background_repeat: 'repeat',
      })}
    >
      {data.text}
    </p>
  );
};

export default AnimatedTextBlock;
