import React, { useEffect, useState } from 'react';

import Paragraph from '../../../UI/Text/Paragraph/Paragraph';

import PrimaryButton from '../../../UI/Buttons/PrimaryButton';
import SecondaryButton from '../../../UI/Buttons/SecondaryButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  KYCSelector,
  clearKYCError,
  clearKYCSubmission,
  getKYCDetails,
  kycBankFormSubmission,
  kycFullFOrmSubmission,
} from '../../../store/feature/KYC/kycSlice';
import { changeDateFormatFromDate } from '../../../utils/date';
import { delete_item_from_local_storage } from '../../../utils/localStorage';
import { local_storage_keys } from '../../Localstorage/LocalStorageKeys';
import {
  BANK_DETAILS_FORMAT,
  DOCUMENT_DETAILS_FORMAT,
  KYC_LEGAL_PROFILE_DETAILS_FORMAT,
  KYC_PROFILE_DETAILS_FORMAT,
} from '../../KYC/types/KYCTypes';
import StepsUI from '../../../UI/Steps/StepsUI';
import KYCProfile from '../../KYC/KYCProfile';
import KYCBankDetails from '../../KYC/BankDetails';
import KYCDocument from '../../KYC/KYCDocument';
import KYCMessage from '../../KYC/KYCMessage';
import Alert from '../../Shared/PopUp/Alert';
import { useAppDispatch } from '../../../hooks/Redux';
import { Wallet_text } from '../../../constant/Translation/wallet';
import { getAllCountriesList } from '../../../store/feature/countrySlice';
import AllIcons from '../../Shared/Constant/Icons/AllIcons';
import KYCLegalProfile from '../../KYC/KYCLegalProfile';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import { getCookie } from 'cookies-next';
import { useRouter } from 'next/router';
import { kycBankFormSubmissionCheckup } from '../functions/bankFormSubmissionCheckup';

const BankSteps = ({ setSidePanOpen, handleCloseBankKYC }) => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const selectedWalletCurrency = router?.query?.code;
  const currency = router.query.code
    ? router.query.code
    : getCookie(`${TYPE_CONSTANT.DOMAIN}_default_currency`) ?? '';

  //
  const {
    isSubmissionLoading,
    isSubmissionSuccess,
    isError,
    kyc_details,
    errorMessage,
  } = useSelector(KYCSelector);

  //
  const [step_info, setStepInfo] = useState({
    current_step: 1,
    next_step: 2,
    prev_step: 0,
  });

  //
  const changeStep = ({ current_step, next_step, prev_step }) => {
    setStepInfo({
      current_step: current_step,
      next_step: next_step ?? current_step + 1,
      prev_step: prev_step ?? current_step - 1,
    });
  };

  // bank details
  const [bank_details, setBankDetails] = useState<BANK_DETAILS_FORMAT>({
    owner_name: null,
    iban: null,
    address_1: null,
    address_2: null,
    city: null,
    state: null,
    country: null,
    postal_code: null,
  });

  //
  const clearBankDetails = () => {
    setBankDetails({
      owner_name: null,
      iban: null,
      address_1: null,
      address_2: null,
      city: null,
      state: null,
      country: null,
      postal_code: null,
    });
  };

  //form_submission_handler
  const form_submission_handler = () => {
    const bank_details_data: {
      iban?: string;
      short_code?: string;
      account_number?: string;
      owner_name: string;
      address: any;
      currency?: string;
    } = {
      owner_name: bank_details?.owner_name,
      address: {
        address_line_1: bank_details?.address_1,
        address_line_2: bank_details?.address_2,
        state: bank_details?.state,
        city: bank_details?.city,
        post_code: bank_details?.postal_code,
        country: bank_details?.country,
      },
    };

    if (currency) {
      bank_details_data.currency = currency as string;
    }
    if (bank_details?.iban) {
      bank_details_data.iban = bank_details?.iban;
    }
    if (bank_details?.short_code && bank_details?.account_number) {
      bank_details_data.short_code = bank_details?.short_code;
      bank_details_data.account_number = bank_details?.account_number;
    }

    dispatch(
      // @ts-ignore
      kycBankFormSubmission({
        bank_details: bank_details_data,
      })
    );
  };

  //
  useEffect(() => {
    if (isSubmissionSuccess) {
      dispatch(clearKYCSubmission());
      delete_item_from_local_storage({
        key_name: 'bank_details',
      });
      clearBankDetails();

      dispatch(
        // @ts-ignore
        getKYCDetails({ currency: currency })
      ).then((res) => {
        if (res?.payload?.bank_account_setup == true) {
          handleCloseBankKYC();
        } else {
          setSidePanOpen(false);
        }
      });
    }
  }, [isSubmissionSuccess]);

  //
  // Alert
  const [open_alert, setOpenAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [alert_type, setAlert_type] = useState('success');

  // Error handling
  useEffect(() => {
    if (isError) {
      setAlert_type('error');
      setOpenAlert(true);
      setMessage(errorMessage);
    }
  }, [isError]);
  useEffect(() => {
    // @ts-ignore
    dispatch(getAllCountriesList({}));
  }, [0]);
  return (
    <>
      {/* Alert */}
      <Alert
        className={` fixed z-50 top-0 left-0 right-0  w-full  xs:w-[500px] mx-auto mt-5 `}
        type={alert_type}
        open={open_alert}
        setOpen={setOpenAlert}
        isOpenFunction={undefined}
        funcCallOnClose={() => {
          dispatch(clearKYCError());
        }}
      >
        <p>{message}</p>
      </Alert>
      {/* Form note */}

      {/* close button */}
      <button
        className=" absolute right-3 md:left-3 w-10 h-10 flex items-center justify-center top-3 p-3 rounded-full shadow-md bg-white  z-20 text-primary hover:shadow-xl duration-200 "
        onClick={() => {
          dispatch(
            // @ts-ignore
            getKYCDetails({ currency: currency })
          );
          dispatch(clearKYCSubmission());
          changeStep({ current_step: 1, next_step: 2, prev_step: 0 });
          setSidePanOpen(false);
        }}
      >
        {AllIcons.close_icon}
      </button>

      {/* Steps UI */}
      <StepsUI
        styles={{
          components_section_style: 'max-w-lg mx-auto px-4',
          steps_style: 'grid grid-cols-1',
          description_text_style: 'max-w-lg mx-auto px-4 my-8',
        }}
        description_text={Wallet_text.kyc.kyc_description}
        steps={[
          {
            title: Wallet_text.kyc.bank_details,
            link: undefined,
            step_code: 1,
          },
        ]}
        steps_components={[
          {
            step_code: 1,
            component: (
              <KYCBankDetails
                bank_details={bank_details}
                setBankDetails={setBankDetails}
                group_name="bank_details"
              />
            ),
          },
        ]}
        steps_buttons={[
          // * step 1 button
          {
            button_component: (
              <div className=" max-w-md mt-20 w-full flex item-center justify-between">
                <SecondaryButton
                  onClick={() => {
                    changeStep({ current_step: 1, next_step: 2, prev_step: 0 });
                    setSidePanOpen(false);
                  }}
                  className=" px-10"
                >
                  {Wallet_text?.kyc.cancel}
                </SecondaryButton>
                <PrimaryButton
                  onClick={() => {
                    if (
                      kycBankFormSubmissionCheckup(
                        setMessage,
                        setOpenAlert,
                        bank_details,
                        setAlert_type,
                        selectedWalletCurrency
                      )
                    ) {
                      form_submission_handler();
                    }
                  }}
                  className=" px-10"
                  isLoading={isSubmissionLoading}
                  isDisabled={isSubmissionLoading}
                >
                  {Wallet_text?.kyc.submit}
                </PrimaryButton>
              </div>
            ),
            step_code: 1,
          },
        ]}
        current_step={step_info?.current_step}
        next_step={step_info?.next_step}
        prev_step={step_info?.prev_step}
      />
    </>
  );
};

export default BankSteps;
