export const kycAlertTypeByStatus = (status) => {
  switch (status) {
    case 'VALIDATED':
      return 'success';
      break;
    case 'VALIDATION_ASKED':
      return 'warning';
      break;
    case 'REFUSED':
      return 'error';
      break;

    default:
      break;
  }
};
