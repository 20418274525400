export const set_line_clamp = (line) => {
  return line === undefined || line === ''
    ? {}
    : line == 'none'
    ? {
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitBoxOrient: 'horizontal',
        WebkitLineClamp: 'none',
      }
    : {
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: line,
      };
};
