import React from 'react';

const SubscriptionCardsSkeleton = () => {
  return (
    <div className="w-full flex items-start justify-center flex-wrap  gap-6 animate-pulse">
      <div className=" p-6 rounded-md    bg-gray-200 border border-gray-300 w-full max-w-[200px]  h-[200px]   flex flex-col items-center gap-2  " />

      <div className=" p-6 rounded-md    bg-gray-200 border border-gray-300 w-full max-w-[200px]  h-[200px]   flex flex-col items-center gap-2  " />

      <div className=" p-6 rounded-md    bg-gray-200 border border-gray-300 w-full max-w-[200px]  h-[200px]   flex flex-col items-center gap-2  " />

      <div className=" p-6 rounded-md    bg-gray-200 border border-gray-300 w-full max-w-[200px]  h-[200px]   flex flex-col items-center gap-2  " />
    </div>
  );
};

export default SubscriptionCardsSkeleton;
