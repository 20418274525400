import { Node } from '@tiptap/core';

export default Node.create({
  name: 'iframe',

  group: 'block',

  atom: true,

  addOptions() {
    return {
      allowFullscreen: true,
      HTMLAttributes: {
        class: 'w-full min-h-[200px] ',
      },
    };
  },

  addAttributes() {
    return {
      src: {
        default: null,
      },
      frameborder: {
        default: 0,
      },
      allowfullscreen: {
        default: this.options.allowFullscreen,
        parseHTML: () => this.options.allowFullscreen,
      },
      height: {
        default: '300',
      },
      title: {
        default: '',
      },
      width: {
        default: '100%',
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'iframe',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', this.options.HTMLAttributes, ['iframe', HTMLAttributes]];
  },

  addNodeView() {
    return ({ editor, node }) => {
      const div = document.createElement('div');
      div.className = `w-full min-h-[${node.attrs.height}px] p-1 `;
      const iframe = document.createElement('iframe');

      iframe.width = node.attrs.width;
      iframe.height = node.attrs.height;
      iframe.frameborder = '0';
      iframe.allowfullscreen = '';
      iframe.src = node.attrs.src;
      div.append(iframe);
      return {
        dom: div,
      };
    };
  },

  addCommands() {
    return {
      setIframe:
        (options) =>
        ({ tr, dispatch }) => {
          const { selection } = tr;
          const node = this.type.create(options);

          if (dispatch) {
            tr.replaceRangeWith(selection.from, selection.to, node);
          }

          return true;
        },
    };
  },
});
