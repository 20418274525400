import Link from 'next/link';
import React from 'react';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { get_dynamic_styles_with_default_value } from '../../constant/DynamicStylesWithDefaultValue';
import {
  CardBoxDefaultStyles,
  CardMainDefaultStyles,
} from '../../constant/default_styles/ProductDetailsStyles';
import { get_data_by_current_language } from '../../utils/GetDataByLanguage';

const PhoneCTAWithAccountAttribute = ({ data, styles, listing_details }) => {
  let url =
    listing_details?.account?.attributes?.filter(
      (it) => it.id == data?.attribute_id
    )[0]?.values[0] ?? '#';

  return (
    <div
      style={get_dynamic_styles_with_default_value(
        styles?.box_styles,
        CardMainDefaultStyles
      )}
    >
      {' '}
      <div
        className=" "
        style={get_dynamic_styles_with_default_value(
          styles?.box_styles,
          CardBoxDefaultStyles
        )}
      >
        <Link href={url ? `tel:${url}` : '#'}>
          <a
            className="     rounded-button  flex items-center justify-center  gap-2 "
            type="button"
            target={data?.button_target ?? '_blank'}
            style={get_dynamic_styles(styles?.button_styles)}
          >
            {data?.icon && (
              <img
                className="w-auto object-cover  "
                style={get_dynamic_styles(styles?.icon_styles)}
                src={data?.icon}
                alt="image"
              />
            )}
            {data?.button_title && (
              <p
                className="text-lg font-semibold text-gray-700"
                style={get_dynamic_styles(styles?.text_styles)}
              >
                {get_data_by_current_language({
                  data_list: data?.translated_data,
                  key_name: 'button_title',
                  default_data: data?.button_title,
                })}
              </p>
            )}
          </a>
        </Link>
      </div>
    </div>
  );
};

export default PhoneCTAWithAccountAttribute;
