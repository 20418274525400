/* eslint-disable no-shadow */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/dist/client/router';
import { useDispatch, useSelector } from 'react-redux';

import { listingLike } from '../../store/feature/listingSlice';
import { check_login } from '../../constant/check_auth';
import { socialFeedListings } from '../../store/feature/storeSlice';
import DynamicListingCard from '../Shared/Cards/DynamicListingCard';

// * Swiper imports
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/bundle';
import SwiperCore, { Navigation, Pagination } from 'swiper';
SwiperCore.use([Navigation, Pagination]);
//*

const ListingsCarousel = ({
  Products,
  like_listing,
  is_hide_like_btn = false,
}) => {
  const dispatch = useDispatch();
  const router = useRouter();

  const like = (id, isLiked) => {
    if (check_login(router)) {
      if (like_listing) {
        like_listing(id, isLiked);
      } else {
        dispatch(
          listingLike({
            id,
            isLiked,
          })
        ).then((res) => {
          if (!res.payload.code) {
            dispatch(
              socialFeedListings({
                prams: { ...router.query, status: 2 },
              })
            );
          }
        });
      }
    }
  };
  return (
    <>
      <Swiper
        slidesPerView="auto"
        slidesPerGroup={1}
        spaceBetween={16}
        loop={false}
        navigation={false}
      >
        {Products?.map((item) => (
          <SwiperSlide
            className="  overflow-hidden"
            key={Math.random() * 3000000}
            style={{
              width:
                'calc(var(--listingCardMinWidth)*var(--listingCardPerRow)/var(--listingCardPerRow))',
              minHeight: '210px',
              paddingBottom: '5px',
            }}
          >
            <DynamicListingCard product={item} like={like} />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default ListingsCarousel;
