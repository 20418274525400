import Script from 'next/script';
import React, { useEffect } from 'react';
import NotFoundAlert2 from '../Shared/Default UI/NotFoundAlert2';
import { get_dynamic_styles } from '../../constant/DynamicStyles';

const HubspotContactForm = ({ data, styles }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: data?.region,
          portalId: data?.portalId,
          formId: data?.formId,
          target: '#hubspotForm',
        });
      }
    });
  }, []);

  return (
    <div id="hubspotForm" style={get_dynamic_styles(styles?.main_styles)}></div>
  );
};

export default HubspotContactForm;
