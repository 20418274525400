import { getCookie } from 'cookies-next';
import { TYPE_CONSTANT } from '../constant/Web_constant';

export const get_data_by_current_language = ({
  data_list,
  key_name,
  default_data,
}): string | undefined => {
  const current_language = getCookie(
    `${TYPE_CONSTANT.DOMAIN}_default_language`
  ) as string;

  return data_list?.[current_language]?.[key_name] ?? default_data;
};
