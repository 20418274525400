/* eslint-disable react/prop-types */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from '../../../store/feature/authSlice';
import { get_all_following_accounts } from '../../../store/feature/storeSlice';
import { useRouter } from 'next/dist/client/router';
import AccountCard from '../../Shared/Cards/AccountCard';
import { check_login } from '../../../constant/check_auth';
import axios from 'axios';
import DynamicAccountCard from '../../Shared/Cards/DynamicAccountCard';

const DynamicAccounts = ({
  accounts,
  setIsDataLoading,
  account_follow,
  isHideFollowButton = false,
}) => {
  const { login, auth_key } = useSelector(authSelector);
  const router = useRouter();
  const dispatch = useDispatch();
  const follow = (id, isFollow) => {
    if (account_follow) {
      account_follow(id, isFollow);
    } else {
      if (check_login(router)) {
        setIsDataLoading(true);
        axios
          .post('/api/a/follow_account', { id, isFollow })
          .then((res) => {
            setIsDataLoading(false);
            dispatch(
              get_all_following_accounts({
                bodyParam: {
                  page: router.query?.page ? router.query?.page : 1,
                },
              })
            );
          })
          .catch((error) => {
            setIsDataLoading(false);
            alert(error.response.data.message);
          });
      }
    }
  };

  return (
    <div className="grid  grid-cols-1  xxs:grid-cols-2  md:grid-cols-[repeat(auto-fill,minmax(calc(var(--accountCardMinWidth)*var(--accountCardPerRow)/var(--accountCardPerRow)),1fr))]    gap-5 justify-center">
      {accounts?.map((item) => {
        return (
          <div className="   " key={item.id}>
            <DynamicAccountCard
              item={item}
              follow={isHideFollowButton ? undefined : follow}
              isHideFollowButton={isHideFollowButton}
            />
          </div>
        );
      })}
    </div>
  );
};

export default DynamicAccounts;
