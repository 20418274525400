import React, { memo } from 'react';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';
import { get_dynamic_styles_with_default_value } from '../../../constant/DynamicStylesWithDefaultValue';
import { HomeCardBoxDefaultStyles } from '../../../constant/default_styles/HomeDefaultStyles';

const ListingPrice = ({ styles, price }) => {
  return (
    <div className="   " style={get_dynamic_styles(styles?.main_styles)}>
      <div
        style={get_dynamic_styles_with_default_value(
          styles?.box_styles,
          HomeCardBoxDefaultStyles
        )}
      >
        <p className="  " style={get_dynamic_styles(styles?.text_styles)}>
          {price}
        </p>
      </div>
    </div>
  );
};

export default memo(ListingPrice);
