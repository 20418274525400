import React from 'react';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { get_data_by_current_language } from '../../utils/GetDataByLanguage';

const Paragraph = ({ data, styles }) => {
  return (
    <p style={get_dynamic_styles(styles?.text)}>
      {get_data_by_current_language({
        data_list: data?.translated_data,
        key_name: 'text',
        default_data: data?.text,
      })}
    </p>
  );
};

export default Paragraph;
