import React from 'react';
import QRCode from 'react-qr-code';

const TicketQRCOde = ({ order_details, unique_id }) => {
  return (
    <QRCode
      size={100}
      style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
      value={unique_id}
      viewBox={`0 0 256 256`}
    />
  );
};

export default TicketQRCOde;
