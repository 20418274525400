import React, { useEffect, useState } from 'react';
import AllIcons from '../Shared/Constant/Icons/AllIcons';
import NewCardPicker from './NewCardPicker';
import SidePan from '../Shared/Modal/SidePan';
import { useDispatch, useSelector } from 'react-redux';
import {
  CardSelector,
  clearCardRegData,
  cardSubmission,
  getSavedCards,
  clearCardError,
} from '../../store/feature/card/cardSlice';
import { NEW_CARD_FORMAT } from './Types/CardType';
import { changeDateFormatFromDate } from '../../utils/date';
import Alert from '../Shared/PopUp/Alert';
import { useAppDispatch } from '../../hooks/Redux';
import { Wallet_text } from '../../constant/Translation/wallet';
import { useRouter } from 'next/router';
import { getCookie } from 'cookies-next';
import { TYPE_CONSTANT } from '../../constant/Web_constant';

const AddCard = ({}) => {
  const router = useRouter();
  //
  const [cardSidePanOpen, setCardSidePanOpen] = useState(false);
  const [cardFormState, setCardFormState] = useState<NEW_CARD_FORMAT>({
    card_type: null,
    card_number: null,
    expiry_date: null,
    cvx_number: null,
  });

  const dispatch = useAppDispatch();
  const currency = router.query.code
    ? router.query.code
    : getCookie(`${TYPE_CONSTANT.DOMAIN}_default_currency`) ?? '';
  // Alert
  const [open_alert, setOpenAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [alert_type, setAlert_type] = useState('success');

  // selector;
  const {
    card_reg_data,
    cardSubmissionLoading,
    cardSubmissionSuccess,
    isError,
    errorMessage,
  } = useSelector(CardSelector);

  //handleSidepanOpen
  const handleCardSidepanOpen = (e) => {
    e.stopPropagation();
    setCardSidePanOpen(true);
  };

  //  handle card submission
  const handle_card_submission = async (e) => {
    // requirements checking for card submission
    if (!card_reg_data?.CardRegistrationURL) {
      setAlert_type('error');
      setOpenAlert(true);
      setMessage(Wallet_text.wallet.card_type_required);

      return false;
    }
    if (!cardFormState?.card_number) {
      setAlert_type('error');
      setOpenAlert(true);
      setMessage(Wallet_text.wallet.card_number_required);
      return false;
    }
    if (
      !cardFormState?.card_number ||
      !cardFormState?.expiry_date ||
      !cardFormState?.cvx_number
    ) {
      setAlert_type('error');
      setOpenAlert(true);
      const error_text = !cardFormState?.card_number
        ? Wallet_text.wallet.card_number_required
        : !cardFormState?.expiry_date
        ? Wallet_text.wallet.card_exp_required
        : Wallet_text.wallet.card_cvc_required;
      setMessage(error_text);
      return false;
    }

    // data
    let req_data: {
      data: Object;
      path: Object;
      id: Object;
      currency?: string;
    } = {
      path: card_reg_data.CardRegistrationURL,
      data: {
        accessKeyRef: card_reg_data?.AccessKey,
        data: card_reg_data?.PreregistrationData,
        cardNumber: cardFormState?.card_number,
        cardExpirationDate: changeDateFormatFromDate(
          cardFormState.expiry_date,
          'MMYY'
        ),
        cardCvx: cardFormState?.cvx_number,
      },
      id: card_reg_data.Id,
    };

    if (currency) {
      req_data.currency = currency as string;
    }

    //
    dispatch(
      // @ts-ignore
      cardSubmission(req_data)
    );
  };
  // effect for card submission success
  useEffect(() => {
    if (cardSubmissionSuccess) {
      setCardSidePanOpen(false);
      dispatch(clearCardRegData());
      setCardFormState({
        card_type: '',
        card_number: '',
        expiry_date: '',
        cvx_number: '',
      });
      dispatch(getSavedCards());
    }
  }, [cardSubmissionSuccess]);

  // Error handling
  useEffect(() => {
    if (isError) {
      setAlert_type('error');
      setOpenAlert(true);
      setMessage(errorMessage);
    }
  }, [isError]);

  return (
    <>
      {/* Alert */}
      <Alert
        className={` fixed z-50 top-0 left-0 right-0  w-full  xs:w-[500px] mx-auto mt-5 `}
        type={'error'}
        open={open_alert}
        setOpen={setOpenAlert}
        isOpenFunction={undefined}
        funcCallOnClose={() => {
          dispatch(clearCardError());
        }}
      >
        <p>{message}</p>
      </Alert>

      {/*  */}
      <div
        onClick={(e) => handleCardSidepanOpen(e)}
        className=" cursor-pointer w-full p-4   bg-gray-100  shadow-md rounded-lg min-h-[160px] max-w-[280px] flex flex-col  gap-2 items-center justify-center"
      >
        {AllIcons.plus_icon}
        <p className=" text-sm  font-semibold">
          {Wallet_text.wallet.add_new_card}
        </p>
      </div>
      {/*  */}
      <SidePan
        id={`amount-sidepan}`}
        sidePanOpen={cardSidePanOpen}
        setSidePanOpen={setCardSidePanOpen}
        modal_section_styles={
          'fixed  right-0 left-0 bottom-0 lg:top-0 lg:my-auto   z-[60] overflow-hidden flex items-center  justify-center   rounded-t-[40px]   lg:rounded-card transform '
        }
        modal_inside_section_styles="bg-white rounded shadow-lg overflow-auto  max-w-full md:max-w-xl w-full max-h-full  lg:scrollbar-thin   lg:scrollbar-track-transparent  lg:scrollbar-thumb-transparent"
        modal_width={'max-w-xl w-full'}
        justify_align=""
        close_function={() => dispatch(clearCardRegData())}
      >
        <div className=" relative max-h-[75vh] ">
          <button
            className=" fixed  lg:hidden  ltr:right-3 rtl:left-3 w-10 h-10 flex items-center justify-center top-3 p-3 rounded-full shadow-md bg-white  z-40 text-primary"
            onClick={() => {
              setCardSidePanOpen(false);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-8 h-8"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>

          <NewCardPicker
            card_form={cardFormState}
            setCardForm={setCardFormState}
            cancelClick={() => {
              setCardSidePanOpen(false);
              dispatch(clearCardRegData());
            }}
            submitClick={(e) => handle_card_submission(e)}
            isLoading={cardSubmissionLoading}
          />
        </div>
      </SidePan>
    </>
  );
};

export default AddCard;
