/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';
import { cn } from '../../utils/classNames';
import Heading1 from '../Text/Headers/Heading1';
import Paragraph from '../Text/Paragraph/Paragraph';

const StepsUI = ({
  steps,
  steps_components,
  steps_buttons,
  styles,
  current_step,
  prev_step,
  next_step,
  header_text,
  description_text,
}: {
  steps: Array<{
    title: string;
    link?: string;
    step_code?: number;
  }>;
  steps_components: Array<{
    component: any;
    step_code?: number;
  }>;
  steps_buttons: Array<{
    button_component: any;
    step_code?: number;
  }>;
  styles?: {
    component_style?: string;
    steps_section_style?: string;
    steps_style?: string;
    step_style?: string;
    components_section_style?: string;
    component_div_style?: string;
    step_buttons_style?: string;
    step_button_style?: string;
    header_text_style?: string;
    description_text_style?: string;
  };
  current_step: number;
  next_step: number;
  prev_step: number;
  header_text?: string;
  description_text?: string;
}) => {
  return (
    <div
      className={cn('w-full h-full relative pb-20', styles?.component_style)}
    >
      {/* Step header  */}
      {steps?.length > 1 && (
        <div
          className={cn(
            ' w-full  px-10   pb-4 pt-8  borderer-b bg-gray-100   sticky top-0 z-10',
            styles?.steps_section_style
          )}
        >
          <div
            className={cn(
              ' relative flex   max-w-2xl mx-auto       ',
              styles?.steps_style
            )}
          >
            {steps?.length > 1 &&
              steps?.map((step, index, array) => {
                return (
                  <button
                    className={cn(
                      'flex-grow px-4 relative  py-2 ',
                      'after:h-4 after:w-4 after:rounded-full     after:absolute  after:-top-4 after:left-0   after:right-0  after:mx-auto  ',
                      index == array?.length - 1
                        ? ''
                        : 'before:h-1.5 before:w-full  before:rounded-full     before:absolute  before:-top-[11px] before:left-[50%]   before:right-0  before:mx-auto ',
                      current_step > step?.step_code
                        ? 'before:bg-success after:bg-success'
                        : 'before:bg-gray-300 after:bg-gray-300',
                      current_step == step?.step_code
                        ? 'after:bg-success    '
                        : ''
                    )}
                  >
                    {step?.title}
                  </button>
                );
              })}
          </div>
        </div>
      )}

      {/* step header title */}
      {header_text && (
        <Heading1 styles={cn('text-center', styles?.header_text_style)}>
          {header_text}
        </Heading1>
      )}

      {/*description text   */}
      {description_text && (
        <Paragraph
          styles={cn(
            'text-sm italic text-center',
            styles?.description_text_style
          )}
        >
          {description_text}
        </Paragraph>
      )}

      {/* Step component  */}

      <div className={cn(' w-full h-auto ', styles?.components_section_style)}>
        {steps_components?.map((step_comp) => {
          return step_comp?.step_code === current_step && step_comp?.component;
        })}
      </div>

      {/* steps buttons */}
      {steps_buttons?.map((step_button) => {
        return (
          <div
            className={`  max-w-lg p-4 mx-auto    w-full  items-center justify-center gap-10 pb-10  ${
              current_step == step_button?.step_code ? 'flex' : 'hidden'
            }
            }`}
          >
            {step_button?.button_component}
          </div>
        );
      })}
    </div>
  );
};

export default StepsUI;
