import { TYPE_CONSTANT } from '../constant/Web_constant';

export const getSelectedCurrencyDetails = () => {
  const availableCurrencies =
    (localStorage.getItem('available_currencies') != undefined &&
      JSON.parse(localStorage.getItem('available_currencies'))) ||
    TYPE_CONSTANT.CURRENCIES ||
    [];

  // current currency details
  const currency_details =
    availableCurrencies?.filter(
      (currency) => currency.code == TYPE_CONSTANT.CURRENCY
    )?.length > 0
      ? availableCurrencies?.filter(
          (currency) => currency.code == TYPE_CONSTANT.CURRENCY
        )?.[0]
      : availableCurrencies?.filter((currency) => currency.default)?.[0];

  return currency_details;
};

// getFormatBySelectedCurrency
export const getFormatBySelectedCurrency = (
  value: number | number,
  selected_format?: string | undefined | null
): string | number => {
  const format = selected_format ?? getSelectedCurrencyDetails()?.format;
  const is_format_available = format?.includes('{amount}');

  if (is_format_available) {
    return format.replace('{amount}', value);
  } else {
    return value;
  }
};

// getFormatICON
export const getFormatOFSelectedCurrency = (): string => {
  const format = getSelectedCurrencyDetails()?.format;
  const is_format_available = format?.includes('{amount}');

  if (is_format_available) {
    return format.split('{amount}')[0];
  } else {
    return '';
  }
};

// getFormatICON
export const getFormatOFCurrencyFromFormatted = (formatted: string): string => {
  // Define regular expression pattern to match currency format
  const pattern = /([\d,]+\.\d{2})/;

  // Search for the pattern in the formatted price string
  const match = formatted.match(pattern);

  console.log(match);

  if (match) {
    // Extract and return the matched currency format
    return match[0];
  } else {
    return null;
  }
};
