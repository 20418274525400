export const kycBankFormSubmissionCheckup = (
  setMessage,
  setOpenAlert,
  form_values,
  setAlert_type,
  selectedWalletCurrency
) => {
  setAlert_type('error');

  // owner name
  if (form_values.owner_name === null || form_values.owner_name === '') {
    setOpenAlert(true);
    setMessage('Owner Name is required');
    return false;
  }
  // IBAN Number
  if (
    selectedWalletCurrency !== 'GBP' &&
    (form_values.iban === null || form_values.iban === '')
  ) {
    setOpenAlert(true);
    setMessage('IBAN Number is required');
    return false;
  }

  // account number & short code
  if (selectedWalletCurrency === 'GBP') {
    if (
      form_values.account_number === null ||
      form_values.account_number === ''
    ) {
      setOpenAlert(true);
      setMessage('Account Number is required');
      return false;
    }
    if (form_values.short_code === null || form_values.short_code === '') {
      setOpenAlert(true);
      setMessage('Short Code is required');
      return false;
    }
  }
  // Address
  if (form_values.address_1 === null || form_values.address_1 === '') {
    setOpenAlert(true);
    setMessage('Address is required');
    return false;
  }

  // city
  if (form_values.city === null || form_values.city === '') {
    setOpenAlert(true);
    setMessage('City is required');
    return false;
  }
  // state
  if (form_values.state === null || form_values.state === '') {
    setOpenAlert(true);
    setMessage('State is required');
    return false;
  }
  // postal_code
  if (form_values.postal_code === null || form_values.postal_code === '') {
    setOpenAlert(true);
    setMessage('Postal Code is required');
    return false;
  }
  // country
  if (form_values.country === null || form_values.country === '') {
    setOpenAlert(true);
    setMessage('Country is required');
    return false;
  }

  return true;
};
