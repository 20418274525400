import React from 'react';
import NavItem from './NavItem';
import { get_dynamic_styles } from '../../constant/DynamicStyles';

const CustomNavbar = ({ data, styles }) => {
  console.log(styles?.box_styles);
  return (
    <div style={get_dynamic_styles(styles?.main_styles)}>
      <div
        style={{
          display: styles?.box_styles?.display,
          ...get_dynamic_styles(styles?.box_styles),
        }}
      >
        {data?.nav_items?.map((nav, index) => {
          return (
            <NavItem key={`${nav?.title}-{index}`} nav={nav} styles={styles} />
          );
        })}
      </div>
    </div>
  );
};

export default CustomNavbar;
