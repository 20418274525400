export var Review_text = {
  addreview: {
    reviewed_title: 'You already added your review',
    share_your_feedback: ' Share your feedback',
    select_one: 'Select  one product',
    order: 'Order',

    add_photos: 'Add your photos',
    back_to_review_page: 'Back to Review Page',
    cancel_review: 'Cancel Review',
    description_empty_error: 'Thank you note should not be empty',
    edit_review: 'Edit Review',
    opinion_four: 'Fast Response',
    opinion_one: 'Excellent Service',
    opinion_three: 'Good Packaging',
    opinion_two: 'Great Listing',
    please_provide_rating: 'Please select rating',
    rating_empty_error: 'Rating should not be empty',
    rating_field_title: 'What is your rating?',
    review: 'Review',
    review_all_product: 'Review All Listing',
    review_error_message:
      'Something went wrong while submitting review.Try again later',
    review_page: 'Review Page',
    review_sending: 'Sending',
    review_sent_success: 'Review sent successfully!',
    review_success_message: 'Review submitted successfully',
    send_review: 'Send Review',
    share_opinion_info: 'Please share your opinion about the Listing',
    title: 'Title...',
    title_empty_error: 'Title should not be empty',
    what_you_rate: 'What you rate?',
    write_your_review_note: 'Write your thank you note',
  },
};
