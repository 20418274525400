import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  filterSelector,
  getAttributesForFilter,
  onFilterParamsUpdate,
} from '../../store/feature/filter/filterSlice';
import { get_data_by_current_language } from '../../utils/GetDataByLanguage';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { useRouter } from 'next/router';
import { getMainPath } from '../../utils/getMainPath';

const ListingDateFilter = ({
  filer_item,
  styles,
  setListingsFilter,
  listings_filter,
}) => {
  const dispatch = useDispatch();
  const {
    filter_type,
    title,
    translated_data,
    category_ids,
    is_show_title,
    is_depend_on_search,
  } = filer_item;
  const { filter_params, queries } = useSelector(filterSelector);

  const onUpdateQuires = (updated_quires) => {
    if (is_depend_on_search === false) {
      router.push({
        pathname: getMainPath(router.asPath),
        query: updated_quires ?? queries,
      });
    }
  };

  return (
    <div style={get_dynamic_styles(styles?.filter_component_style)}>
      {title && is_show_title && (
        <p style={get_dynamic_styles(styles?.filter_title_style)}>
          {get_data_by_current_language({
            data_list: translated_data,
            key_name: 'title',
            default_data: title,
          })}
        </p>
      )}
      <input
        type="date"
        className=" ransition  duration-700
                    focus:ring-0 focus:border-primary
                  "
        style={get_dynamic_styles(styles?.filter_date_filed_style)}
        onChange={(e) => {
          dispatch(
            onFilterParamsUpdate({
              start_at: e.target.value,
              callOnFilterUpdate: (updated_quires) => {
                onUpdateQuires(updated_quires);
              },
            })
          );
        }}
        value={filter_params?.start_at}
      />
    </div>
  );
};

export default ListingDateFilter;
