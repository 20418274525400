import React, { useState, useRef, useEffect } from 'react';
import Transition from '../utils/Transition';

function DropdownClassic({ options, main_btn_class, dropdown_box_width }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const trigger = useRef(null);
  const dropdown = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  //
  const [openSide, setOpenSide] = useState('');

  useEffect(() => {
    function handleClickOutside(event) {
      if (trigger.current && !trigger.current.contains(event.target)) {
        // setOpenSide('')
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [trigger]);

  function handleButtonClick() {
    const buttonRect = trigger.current.getBoundingClientRect();
    const screenWidth = window.innerWidth;
    const spaceOnLeft = buttonRect.left;
    const spaceOnRight = screenWidth - buttonRect.right;

    if (spaceOnLeft > spaceOnRight) {
      setOpenSide('right');
    } else if (spaceOnRight > spaceOnLeft) {
      setOpenSide('left');
    } else {
      setOpenSide('right');
    }

    setDropdownOpen(!dropdownOpen);
  }

  return (
    <div className="relative inline-flex">
      <button
        ref={trigger}
        className={[
          main_btn_class
            ? main_btn_class
            : 'btn  flex items-center justify-end gap-1  bg-white border-gray-200 hover:border-gray-300 text-gray-500 hover:text-gray-600',
        ].join(' ')}
        aria-label="Select date range"
        aria-haspopup="true"
        onClick={() => handleButtonClick()}
        aria-expanded={dropdownOpen}
      >
        <span className="flex items-center">
          <span>
            {options.filter((option) => option?.selected === true).length > 0
              ? options.filter((option) => option?.selected === true)[0]?.value
                ? options.filter((option) => option?.selected === true)[0]
                    ?.value
                : options.filter((option) => option?.selected === true)[0]?.name
              : options[0]?.value
              ? options[0]?.value
              : options[0]?.name}
          </span>
        </span>
        <svg
          className="flex-shrink-0   fill-current  "
          width="11"
          height="7"
          viewBox="0 0 11 7"
        >
          <path d="M5.4 6.8L0 1.4 1.4 0l4 4 4-4 1.4 1.4z" />
        </svg>
      </button>
      <Transition
        show={dropdownOpen}
        tag="div"
        className={[
          ' z-[999999999] absolute top-full   bg-white border border-gray-200 py-1.5 rounded shadow-lg overflow-hidden mt-1',
          dropdown_box_width ? `${dropdown_box_width}` : ' w-full',
          `${openSide === 'right' ? 'right-0' : 'left-0'}`,
        ].join(' ')}
        enter="transition ease-out duration-100 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div
          ref={dropdown}
          className="font-medium text-sm text-gray-600"
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
          {options?.map((option) => {
            return (
              <button
                key={option.id}
                tabIndex="0"
                className={option.className}
                onClick={() => {
                  option.function();
                  setDropdownOpen(false);
                }}
              >
                <option.tag_name>{option.name}</option.tag_name>
              </button>
            );
          })}
        </div>
      </Transition>
    </div>
  );
}

export default DropdownClassic;
