import React, { memo } from 'react';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';
import { get_dynamic_styles_with_default_value } from '../../../constant/DynamicStylesWithDefaultValue';
import { HomeCardBoxDefaultStyles } from '../../../constant/default_styles/HomeDefaultStyles';
import ReadingComposer from '../../Shared/Composer/ReadingComposer';

const ListingDescription = ({ styles, description, data }) => {
  return (
    <div style={get_dynamic_styles(styles?.main_styles)}>
      {data?.is_show_normal ? (
        <p style={get_dynamic_styles(styles?.text_styles)}>
          {data?.max_words
            ? description?.slice(0, data?.max_words) + '...'
            : description}
        </p>
      ) : (
        <ReadingComposer
          contents={
            data?.max_words
              ? description?.slice(0, data?.max_words) + '...'
              : description
          }
        />
      )}
    </div>
  );
};

export default memo(ListingDescription);
