import Link from 'next/link';
import React from 'react';
import { Listing_text } from '../../../constant/Translation/Listing';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import { slug as SlugChecker } from 'github-slugger';
import { get_dynamic_styles_with_default_value } from '../../../constant/DynamicStylesWithDefaultValue';
import {
  CardBoxDefaultStyles,
  CardMainDefaultStyles,
} from '../../../constant/default_styles/ProductDetailsStyles';
import { useRouter } from 'next/router';

const Category = ({ listing_details, styles }) => {
  const router = useRouter();
  return listing_details ? (
    <div
      className=" w-full "
      style={get_dynamic_styles_with_default_value(
        styles?.main_styles,
        CardMainDefaultStyles
      )}
    >
      <div
        style={get_dynamic_styles_with_default_value(
          styles?.box_styles,
          CardBoxDefaultStyles
        )}
      >
        <h2 className="heading-h2">{Listing_text.product.category}</h2>
        <div className=" mt-4 flex  items-center justify-start  gap-4 flex-wrap ">
          {listing_details?.categories[0]?.hierarchy.map(
            (item, index, array) => {
              return (
                <div className="flex items-center gap-1">
                  <Link
                    key={Math.random()}
                    href={{
                      pathname: '/lc/[slug]',
                      query: {
                        slug: item.slug
                          ? item.slug
                          : `${item?.id}-${SlugChecker(item.name)}`,
                      },
                    }}
                    passHref
                  >
                    <a className="btn-primary-outline ">{item.name}</a>
                  </Link>

                  {array.length !== index + 1 && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-4 h-4 text-black"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d={
                          TYPE_CONSTANT.DIR === 'rtl'
                            ? 'M15.75 19.5L8.25 12l7.5-7.5'
                            : 'M8.25 4.5l7.5 7.5-7.5 7.5'
                        }
                      />
                    </svg>
                  )}
                </div>
              );
            }
          )}
        </div>
      </div>
    </div>
  ) : (
    <div>
      <div className="border bg-[#3B3269] bg-opacity-[10%] shadow rounded-card   p-4   w-full  mx-auto overflow-hidden">
        <div className="flex  justify-between">
          <div className="h-3 bg-[#3B3269] bg-opacity-[20%] rounded-full   w-28"></div>
        </div>
        <div className="animate-pulse mt-4  ">
          <div className="w-full  min-h-[10px] flex  items-center justify-center xs:justify-start  gap-5 flex-wrap">
            <div className="h-8 bg-[#3B3269] bg-opacity-[20%] rounded-card   w-28"></div>
            <div className="h-8 bg-[#3B3269] bg-opacity-[20%] rounded-card   w-28"></div>
            <div className="h-8 bg-[#3B3269] bg-opacity-[20%] rounded-card   w-28"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Category;
