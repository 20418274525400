const getSuggestionItems = (query) => {
  console.log('query', query.query);
  return [
    {
      title: 'Normal Text',
      command: ({ editor, range }) => {
        editor.chain().focus().deleteRange(range).setNode('paragraph').run();
      },
    },
    {
      title: 'Heading 1',
      command: ({ editor, range }) => {
        editor
          .chain()
          .focus()
          .deleteRange(range)
          .setNode('heading', { level: 1 })
          .run();
      },
    },
    {
      title: 'Heading 2',
      command: ({ editor, range }) => {
        editor
          .chain()
          .focus()
          .deleteRange(range)
          .setNode('heading', { level: 2 })
          .run();
      },
    },
    {
      title: 'Heading 3',
      command: ({ editor, range }) => {
        editor
          .chain()
          .focus()
          .deleteRange(range)
          .setNode('heading', { level: 3 })
          .run();
      },
    },
    {
      title: 'Heading 4',
      command: ({ editor, range }) => {
        editor
          .chain()
          .focus()
          .deleteRange(range)
          .setNode('heading', { level: 4 })
          .run();
      },
    },
    {
      title: 'Heading 5',
      command: ({ editor, range }) => {
        editor
          .chain()
          .focus()
          .deleteRange(range)
          .setNode('heading', { level: 5 })
          .run();
      },
    },
    {
      title: 'Heading 6',
      command: ({ editor, range }) => {
        editor
          .chain()
          .focus()
          .deleteRange(range)
          .setNode('heading', { level: 6 })
          .run();
      },
    },
    {
      title: 'bold',
      command: ({ editor, range }) => {
        editor.chain().focus().deleteRange(range).setMark('bold').run();
      },
    },
    {
      title: 'italic',
      command: ({ editor, range }) => {
        editor.chain().focus().deleteRange(range).setMark('italic').run();
      },
    },
    {
      title: 'Underline',
      command: ({ editor, range }) => {
        editor.chain().focus().deleteRange(range).setMark('underline').run();
      },
    },
    {
      title: 'Strikethrough',
      command: ({ editor, range }) => {
        editor.chain().focus().deleteRange(range).setMark('strike').run();
      },
    },
  ].filter((item) =>
    item.title.toLowerCase().startsWith(query?.query?.toLowerCase())
  );
};

export default getSuggestionItems;
