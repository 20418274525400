export var Sales_text = {
  sales: {
    date: 'Date',
    amount: 'Amount',
    status: 'Status',
    history: 'Transactions History',
    all_payouts: 'All Payouts',
    balance: 'Balance',
    cancelled: 'Sales canceled',
    commision_canceled: 'Commission canceled',
    commision_created: 'Commission created',
    no_payout: 'Sales',
    no_payout_reversal: 'Sales canceled',
    no_payouts_found: 'No payouts found',
    no_sales_found: 'No sales found',
    no_transaction_found: 'No Transaction found',
    payout: 'Sales',
    payout_reversal: 'Sales canceled',
    payout_sent: 'Payout sent',
    payouts: 'Payouts',
    processing_fee: 'Processing fee',
    processing_fee_canceled: 'Processing fee canceled',
    see_payouts: 'See Payouts',
    subscription_fee: 'Subscription fee',
    transactions: 'Transactions',
    transfer_canceled: 'Transfer canceled',
    transfer_created: 'Transfer created',
    your_balance: 'Your Balance',
    sales_sales: 'Sales',
  },
};
