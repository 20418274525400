import React from 'react';
import PopupUI from '../../Shared/PopUp/UI/PopupUI';
import { useRouter } from 'next/router';
import { loading_icon } from '../../Shared/Constant/Icons/AllIcons';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import {
  getFormatBySelectedCurrency,
  getFormatOFSelectedCurrency,
} from '../../../utils/CurrencyFunctions';
import Alert from '../../Shared/PopUp/Alert';
import { replace_special_characters } from '../../../utils/ReplaceSpecialChar';
import { Listing_text } from '../../../constant/Translation/Listing';
import { dynamic_translation_set } from '../../../constant/Translation/translation_set';

const DonationPicker = ({
  donation,
  setDonationValue,
  cancelClick,
  submitClick,
  isLoading,
  listing_details,
  max_donation_amount,
  hide_max_alert,
}) => {
  const fixed_donation = [100, 150, 200, 250];

  return (
    <PopupUI
      title="Donation Details"
      left_button={{
        title: 'Cancel',
        click: (e) => {
          cancelClick();
        },
      }}
      right_button={{
        title: `Donate ${getFormatBySelectedCurrency(donation)}`,
        click: (e) => {
          submitClick(e);
        },
        icon: isLoading && loading_icon,
      }}
    >
      <div className="bg-white flex flex-col gap-4 p-6">
        <div className="flex  items-center justify-start flex-wrap gap-4">
          {fixed_donation.map((item) => {
            return (
              <button
                className={
                  item !== donation
                    ? ' min-w-[100px] h-5 px-6 py-4 border border-default_gray flex items-center justify-between rounded-button    transition duration-700    hover:border-primary hover:text-primary'
                    : ' min-w-[100px] h-5 px-6 py-4 border   flex items-center justify-between    border-transparent bg-primary text-white rounded-button cursor-pointer  ring-2 ring-primary ring-offset-2 '
                }
                key={item}
                onClick={() => setDonationValue(item)}
              >
                <span className=" text-base   font-semibold ">
                  {getFormatBySelectedCurrency(item)} {}
                </span>
              </button>
            );
          })}
        </div>

        <div className=" relative  w-full    ">
          <div className="absolute   inset-y-0 px-2 flex items-center justify-center">
            <p className="text-base   font-semibold   text-center leading-none ">
              {getFormatOFSelectedCurrency}
            </p>
          </div>
          <input
            value={donation}
            type="number"
            className="
                    mt-0
                    block
                    w-full
                    px-6
                    rounded-input
                    border-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
            placeholder="1"
            onChange={(e) => {
              if (e.target.value >= 0) {
                setDonationValue(e.target.value);
              }
            }}
            onWheel={(event) => event.currentTarget.blur()}
          />
        </div>

        {hide_max_alert !== true && (
          <Alert
            className={`w-full    `}
            type={'warning'}
            open={true}
            isOpenFunction={false}
            funcCallOnClose={undefined}
            setOpen={undefined}
          >
            <p>
              {dynamic_translation_set(
                Listing_text.product.max_fund_amount,
                getFormatBySelectedCurrency(max_donation_amount)
              )}
            </p>
          </Alert>
        )}
      </div>
    </PopupUI>
  );
};

export default DonationPicker;
