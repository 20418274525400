/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/dist/client/router';
import {
  addToCart,
  cartList,
  cartSelector,
} from '../../../store/feature/cartSlice';
import { authSelector } from '../../../store/feature/authSlice';
import { Listing_text } from '../../../constant/Translation/Listing';
import { push_data_layer } from '../../../constant/GTMDatalayer';
import axios from 'axios';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import SidePan from '../../Shared/Modal/SidePan';
import { listingDetails } from '../../../store/feature/listingSlice';
import CheckoutPageLayout from '../../../themes/blank/CheckoutPageLayout';
import { loading_icon } from '../../Shared/Constant/Icons/AllIcons';
import DonationPicker from '../Donations/DonationPicker';
import { get_dynamic_styles_with_default_value } from '../../../constant/DynamicStylesWithDefaultValue';
import { CardBoxDefaultStyles } from '../../../constant/default_styles/ProductDetailsStyles';
import { dateToEpochInSeconds } from '../../Shared/Constant/Constant';
import { cn } from '../../../utils/classNames';

const DonationButton = ({
  listing_details,
  selectedVariant,
  setError_message,
  setShowError,
  styles,
  data,
}) => {
  const { login } = useSelector(authSelector);
  const { isFetching } = useSelector(cartSelector);
  const router = useRouter();
  const dispatch = useDispatch();

  // dispatch(cartList({}));
  const { cart_details } = useSelector(cartSelector);

  //
  const { goal_price, donation_analytics, end_at } = listing_details || {};
  let isDonationCompleted =
    donation_analytics?.total_amount?.amount >= goal_price?.amount &&
    data?.donate_rules !== true
      ? true
      : false;
  let isDateExpire = dateToEpochInSeconds(new Date()) > end_at ? true : false;
  let max_donation_amount =
    goal_price?.amount - donation_analytics?.total_amount?.amount;
  //
  const [donation_value, setDonationValue] = useState(0);
  const [isCartClearing, setIsCartClearing] = useState(false);
  const [sidePan1Open, setSidePan1Open] = useState(false);
  const [sidePan2Open, setSidePan2Open] = useState(false);

  // add to cart
  const add_to_Cart = (e) => {
    if (
      TYPE_CONSTANT.LISTINGS_CONFIGS?.enable_stock &&
      listing_details?.stock == 0
    ) {
      setShowError(true);
      setError_message(Listing_text.product.out_of_stock);
      setIsCartClearing(false);

      return false;
    }
    if (donation_value <= 0) {
      setShowError(true);
      setError_message(Listing_text.product.min_fund_amount_alert);
      setIsCartClearing(false);

      return false;
    }
    if (donation_value > max_donation_amount && data?.donate_rules !== true) {
      setShowError(true);
      setError_message(Listing_text.product.max_fund_amount_alert);
      setIsCartClearing(false);

      return false;
    }

    const cartData = {
      cart: {
        listing_id: listing_details?.id,
        quantity: 1,
        custom_price: donation_value,
        uuid: TYPE_CONSTANT.UUID,
      },
    };

    if (selectedVariant !== null) {
      cartData.cart['variant_id'] = selectedVariant;
    }
    dispatch(addToCart({ data: cartData })).then((res) => {
      if (!res.payload.code) {
        push_data_layer({
          event: 'donation',
          data: {
            name: listing_details?.title,
            slug: listing_details?.slug,
            price: listing_details?.offer_price.amount,
            stock: listing_details?.stock,
            category: {
              id: listing_details?.categories?.[0]?.id,
              name: listing_details?.categories?.[0]?.name,
            },
            image: listing_details?.images,
            url: window.location.href,
          },
        });
        dispatch(
          listingDetails({
            ...router?.query,
          })
        );
        dispatch(cartList({}));

        setIsCartClearing(false);
        handleSidepan2Open(e);
        setSidePan1Open(false);
      } else {
        if (res.payload.code === 489) {
          clear_cart(e);
        } else {
          setIsCartClearing(false);
          setShowError(true);
          setError_message(res.payload.message);
        }
      }
    });
  };

  // clear cart
  const clear_cart = (e) => {
    setIsCartClearing(true);
    if (cart_details && cart_details?.length > 0) {
      axios
        .delete('/api/cart/delete_all_cart')
        .then((res) => {
          setIsCartClearing(false);
          add_to_Cart(e);
        })
        .catch((error) => {
          setIsCartClearing(false);
          setShowError(true);
          setError_message(error?.response?.data?.message);
        });
    } else {
      add_to_Cart(e);
    }
  };

  //handleSidepanOpen
  const handleSidepan1Open = (e) => {
    e.stopPropagation();
    setSidePan1Open(true);
  };
  const handleSidepan2Open = (e) => {
    e.stopPropagation();
    setSidePan2Open(true);
  };

  return (
    <>
      <div
        className=" w-full flex justify-center items-center"
        style={get_dynamic_styles_with_default_value(
          styles?.box_styles,
          CardBoxDefaultStyles
        )}
      >
        <button
          className={cn(
            '  w-[100%]  h-[52px] bg-primary rounded-button flex justify-center items-center gap-3 text-xl text-white font-semibold  ',
            (isDateExpire || isDonationCompleted) &&
              'cursor-not-allowed  bg-gray-300'
          )}
          id="book_now"
          onClick={(e) => {
            listing_details?.variants.length > 0
              ? selectedVariant == null
                ? (setShowError(true), setError_message('Select one ticket'))
                : handleSidepan1Open(e)
              : handleSidepan1Open(e);
          }}
          disabled={isDateExpire || isDonationCompleted}
        >
          {isDonationCompleted
            ? Listing_text.product.goal_complete
            : isDateExpire
            ? Listing_text.product.listing_expired
            : Listing_text.product.fund_now}
        </button>
      </div>

      {/* Sidepan 1st step */}
      <SidePan
        id={`donation-sidepan}`}
        sidePanOpen={sidePan1Open}
        setSidePanOpen={setSidePan1Open}
        modal_section_styles={
          'fixed  right-0 left-0 bottom-0 lg:top-0 lg:my-auto   z-[60] overflow-hidden flex items-center  justify-center   rounded-t-[40px]   lg:rounded-card transform '
        }
        modal_inside_section_styles="bg-white rounded shadow-lg overflow-auto  max-w-full md:max-w-xl w-full max-h-full  lg:scrollbar-thin   lg:scrollbar-track-transparent  lg:scrollbar-thumb-transparent"
        modal_width={'max-w-xl w-full'}
      >
        <div className=" relative max-h-[75vh] ">
          <button
            className=" fixed  lg:hidden  ltr:right-3 rtl:left-3 w-10 h-10 flex items-center justify-center top-3 p-3 rounded-full shadow-md bg-white  z-40 text-primary"
            onClick={() => {
              setSidePan1Open(false);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-8 h-8"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>

          <DonationPicker
            donation={donation_value}
            setDonationValue={setDonationValue}
            cancelClick={() => {
              setSidePan1Open(false);
              setSidePan2Open(false);
            }}
            submitClick={(e) => {
              clear_cart(e);
            }}
            isLoading={isCartClearing}
            listing_details={listing_details}
            max_donation_amount={max_donation_amount}
            hide_max_alert={data?.donate_rules}
          />
        </div>
      </SidePan>

      {/* Sidepan 2nd step */}
      <SidePan
        id={`event-details-sidepan-${listing_details?.id}`}
        sidePanOpen={sidePan2Open}
        setSidePanOpen={setSidePan2Open}
        modal_section_styles={
          'fixed  right-0 left-0 bottom-0 lg:top-0 lg:my-auto   z-[60] overflow-hidden flex items-center  justify-center   rounded-t-[40px]   lg:rounded-card transform '
        }
        modal_inside_section_styles="bg-white rounded shadow-lg overflow-auto  max-w-full md:max-w-xl w-full max-h-full  lg:scrollbar-thin   lg:scrollbar-track-transparent  lg:scrollbar-thumb-transparent"
        modal_width={'max-w-xl w-full'}
      >
        <div className=" relative max-h-[75vh] ">
          <button
            className=" fixed  lg:hidden  ltr:right-3 rtl:left-3 w-10 h-10 flex items-center justify-center top-3 p-3 rounded-full shadow-md bg-white  z-40 text-primary"
            onClick={() => {
              setSidePan2Open(false);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-8 h-8"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>

          {sidePan2Open && <CheckoutPageLayout type={'donation'} />}
        </div>
      </SidePan>
    </>
  );
};

export default DonationButton;
