import React from 'react';
import ReactPlayer from 'react-player';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { HomeCardBoxDefaultStyles } from '../../constant/default_styles/HomeDefaultStyles';
import { get_dynamic_styles_with_default_value } from '../../constant/DynamicStylesWithDefaultValue';

const VideoBlock = ({ data, styles, video_url }) => {
  return data?.url?.startsWith('http') || video_url?.startsWith('http') ? (
    <div className=" " style={get_dynamic_styles(styles?.main_styles)}>
      <div
        style={get_dynamic_styles_with_default_value(
          styles?.box_styles,
          HomeCardBoxDefaultStyles
        )}
      >
        <ReactPlayer
          url={video_url ? video_url : data?.url ? data?.url : ''}
          width="100%"
          height="100%"
          playing={data?.autoplay ? true : false}
          loop={data?.loop ? data?.loop : false}
          playsinline={true}
          volume={0.5}
          muted={data?.muted ? true : false}
          controls={true}
          onReady={(e) => {
            const player = e.getInternalPlayer();
          }}
        />
      </div>
    </div>
  ) : (
    <></>
  );
};

export default VideoBlock;
