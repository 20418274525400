import React, { useEffect, useState } from 'react';
import IdentificationStatus from './Identification';
import StepsUI from '../../../UI/Steps/StepsUI';
import PrimaryButton from '../../../UI/Buttons/PrimaryButton';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';

import SecondaryButton from '../../../UI/Buttons/SecondaryButton';
import { Wallet_text } from '../../../constant/Translation/wallet';
import { General_text } from '../../../constant/Translation/General';
import BankVerification from './BankVerificatrion';
import { useAppDispatch } from '../../../hooks/Redux';
import AllIcons from '../../Shared/Constant/Icons/AllIcons';
import PhoneVerification from './PhoneVerification';
import { OPPSelector } from '../../../store/feature/OPP/OPPSlice';

const OPPSetupSteps = ({ closeSidebar }) => {
  //
  const [step_info, setStepInfo] = useState({
    current_step: 1,
    next_step: 2,
    prev_step: 0,
  });

  //
  const changeStep = ({ current_step, next_step, prev_step }) => {
    setStepInfo({
      current_step: current_step,
      next_step: next_step ?? current_step + 1,
      prev_step: prev_step ?? current_step - 1,
    });
  };

  //* selectors
  const { merchant_details, isError, errorMessage } = useSelector(OPPSelector);

  return (
    // @ts-ignore
    <>
      {/* close button */}
      <button
        className=" absolute right-3 md:left-3 w-10 h-10 flex items-center justify-center top-3 p-3 rounded-full shadow-md bg-white  z-20 text-primary hover:shadow-xl duration-200 "
        onClick={() => {
          changeStep({
            current_step: 3,
            next_step: 0,
            prev_step: 0,
          });
          closeSidebar();
        }}
      >
        {AllIcons.close_icon}
      </button>

      {/*  */}
      <StepsUI
        styles={{
          steps_style: 'grid grid-cols-3',
        }}
        steps={[
          { title: 'Identification', link: undefined, step_code: 1 },
          {
            title: 'Phone Number',
            link: undefined,
            step_code: 2,
          },
          { title: 'Bank', link: undefined, step_code: 3 },
        ]}
        steps_components={[
          {
            step_code: 1,
            component: <IdentificationStatus key={1} />,
          },
          {
            step_code: 2,
            component: <PhoneVerification key={2} />,
          },
          {
            step_code: 3,
            component: <BankVerification key={3} />,
          },
        ]}
        steps_buttons={[
          // * step 1 button
          {
            button_component: (
              <div
                className=" max-w-md mt-20 w-full flex item-center justify-between"
                key={1}
              >
                <SecondaryButton
                  onClick={() => {
                    changeStep({ current_step: 1, next_step: 2, prev_step: 0 });
                    closeSidebar();
                  }}
                  className=" px-10"
                >
                  {Wallet_text?.wallet.cancel}
                </SecondaryButton>
                <PrimaryButton
                  onClick={(e) => {
                    changeStep({
                      current_step: 2,
                      next_step: 3,
                      prev_step: 1,
                    });
                  }}
                  className=" px-10"
                >
                  Next
                </PrimaryButton>
              </div>
            ),
            step_code: 1,
          },
          // * step 2 button
          {
            button_component: (
              <div
                className=" max-w-xl mt-20 w-full flex item-center justify-between"
                key={2}
              >
                <SecondaryButton
                  onClick={() =>
                    changeStep({ current_step: 1, next_step: 2, prev_step: 0 })
                  }
                  className=" px-10"
                >
                  {General_text.general.back}
                </SecondaryButton>
                <PrimaryButton
                  onClick={(e) => {
                    changeStep({ current_step: 3, next_step: 0, prev_step: 2 });
                  }}
                  className=" px-10"
                >
                  Next
                </PrimaryButton>
              </div>
            ),
            step_code: 2,
          },

          // * step 3 buttons
          {
            button_component: (
              <div
                className=" max-w-xl mt-20 w-full flex item-center justify-between"
                key={2}
              >
                <SecondaryButton
                  onClick={() =>
                    changeStep({ current_step: 2, next_step: 0, prev_step: 1 })
                  }
                  className=" px-10"
                >
                  {General_text.general.back}
                </SecondaryButton>
                <PrimaryButton className=" px-10" isDisabled>
                  Next
                </PrimaryButton>
              </div>
            ),
            step_code: 3,
          },
        ]}
        current_step={step_info?.current_step}
        next_step={step_info?.next_step}
        prev_step={step_info?.prev_step}
      />
    </>
  );
};

export default OPPSetupSteps;
