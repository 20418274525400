import { DateINputType, TextINputType } from '../../types/FormFieldTypes';
import { cn } from '../../utils/classNames';

const DateInputPicker = ({
  title,
  current_value,
  set_new_value,
  note,
  placeholder,
  title_styles,
  field_styles,
  component_styles,
  is_required = true,
  is_disabled = false,
  onClick,
  onKeyDown,
  title_icon,
  type,
}: DateINputType) => {
  return (
    <div className={cn(' w-full flex flex-col gap-1 ', component_styles)}>
      {/* Title */}
      {title && (
        <p
          className={cn(
            ' text-gray-700  text-lg text-start flex items-center justify-start gap-2',
            title_styles
          )}
        >
          {title_icon && <span>{title_icon}</span>}
          {title}
        </p>
      )}

      <input
        className={cn(
          ' relative w-full px-4 py-3 border outline-none border-gray-200 rounded-md bg-transparent placeholder-[#6F767E] text-gray-800  ',
          field_styles
        )}
        value={current_value}
        onChange={(e) => set_new_value && set_new_value(e.target.value)}
        type={type ?? 'date'}
        placeholder={placeholder}
        disabled={is_disabled}
        required={is_required}
        onClick={onClick && onClick}
        onKeyDown={onKeyDown && ((e: any) => onKeyDown(e))}
      />
    </div>
  );
};

export default DateInputPicker;
