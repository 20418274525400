export const TSICONS = {
  angle_down: (
    <svg
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="var( --primary_color)"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.9997 1.17019C10.8123 0.983936 10.5589 0.879395 10.2947 0.879395C10.0305 0.879395 9.77707 0.983936 9.5897 1.17019L5.9997 4.71019L2.4597 1.17019C2.27234 0.983936 2.01889 0.879395 1.7547 0.879395C1.49052 0.879395 1.23707 0.983936 1.0497 1.17019C0.955976 1.26315 0.881582 1.37375 0.830813 1.49561C0.780044 1.61747 0.753906 1.74818 0.753906 1.88019C0.753906 2.0122 0.780044 2.1429 0.830813 2.26476C0.881582 2.38662 0.955976 2.49722 1.0497 2.59019L5.2897 6.83019C5.38267 6.92392 5.49327 6.99831 5.61513 7.04908C5.73699 7.09985 5.86769 7.12599 5.9997 7.12599C6.13172 7.12599 6.26242 7.09985 6.38428 7.04908C6.50614 6.99831 6.61674 6.92392 6.7097 6.83019L10.9997 2.59019C11.0934 2.49722 11.1678 2.38662 11.2186 2.26476C11.2694 2.1429 11.2955 2.0122 11.2955 1.88019C11.2955 1.74818 11.2694 1.61747 11.2186 1.49561C11.1678 1.37375 11.0934 1.26315 10.9997 1.17019Z" />
    </svg>
  ),
  language: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802"
      />
    </svg>
  ),
  stack: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"
      />
    </svg>
  ),
};
