import React from 'react';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { get_data_by_current_language } from '../../utils/GetDataByLanguage';
import { dynamic_text_convert } from '../../utils/dynamicTextConvert';

const GridCardBlock = ({ data, styles }) => {
  return (
    <div style={get_dynamic_styles(styles?.main_styles)}>
      <div className="flex" style={get_dynamic_styles(styles?.box_styles)}>
        {data?.cards?.map((item) => {
          return (
            <div style={get_dynamic_styles(styles?.card_styles)}>
              <div
                style={{
                  display: styles?.row_styles?.display,
                  gridTemplateColumns: dynamic_text_convert({
                    main_text: styles?.row_styles?.columns_box_percentage,
                    check_by: '-1%',
                    value: '',
                  }),
                  ...get_dynamic_styles(styles?.row_styles),
                }}
              >
                {/* image */}
                {item?.image && (
                  <img
                    style={get_dynamic_styles(styles?.image)}
                    src={item?.image}
                    alt="image"
                  />
                )}
                <div>
                  {/* Title */}
                  {item?.title && (
                    <p style={get_dynamic_styles(styles?.title)}>
                      {get_data_by_current_language({
                        data_list: item?.translated_data,
                        key_name: 'title',
                        default_data: item?.title,
                      })}
                    </p>
                  )}
                  {/* Sub Title */}
                  {item?.title && (
                    <p style={get_dynamic_styles(styles?.sub_title)}>
                      {get_data_by_current_language({
                        data_list: item?.translated_data,
                        key_name: 'sub_title',
                        default_data: item?.sub_title,
                      })}
                    </p>
                  )}
                </div>
              </div>
              {/* Desc */}
              {item?.desc && (
                <p style={get_dynamic_styles(styles?.desc)}>
                  {get_data_by_current_language({
                    data_list: item?.translated_data,
                    key_name: 'desc',
                    default_data: item?.desc,
                  })}
                </p>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default GridCardBlock;
