import React from 'react';
import AllIcons, { loading_icon } from '../Shared/Constant/Icons/AllIcons';
import { Wallet_text } from '../../constant/Translation/wallet';
import { TrashIcon } from '@heroicons/react/outline';
import { useDispatch, useSelector } from 'react-redux';
import {
  CardSelector,
  getSavedCards,
  removeCardFromSavedList,
} from '../../store/feature/card/cardSlice';

const Card = ({ card_details, selected_card, setSelectedCard }) => {
  const { isDeleteLoading, selected_card_id } = useSelector(CardSelector);
  const dispatch = useDispatch();

  const deleteCard = (id) => {
    // @ts-ignore
    dispatch(removeCardFromSavedList({ card_id: card_details?.id })).then(
      (res) => {
        if (!res.code) {
          // @ts-ignore
          dispatch(getSavedCards({}));
        }
      }
    );
  };

  return (
    <div
      className={[
        ' cursor-pointer w-full p-6    shadow-md rounded-lg min-h-[160px] max-w-[240px] flex flex-col  justify-between',
        selected_card?.id == card_details?.id
          ? ' border-transparent bg-primary text-white rounded-button cursor-pointer  ring-2 ring-primary ring-offset-2  '
          : ' border border-default_gray flex     transition duration-700    hover:border-primary hover:text-primary',
      ].join(' ')}
      onClick={() => setSelectedCard(card_details)}
    >
      {/*  */}
      <div className="flex  items-center justify-between gap-2 ">
        <p className=" text-lg font-semibold">{card_details?.brand}</p>

        <button
          className=" hover:text-red-500 duration-300    "
          type="button"
          onClick={(e) => {
            e.stopPropagation();
            // @ts-ignore
            deleteCard();
          }}
        >
          {isDeleteLoading && selected_card_id === card_details?.id ? (
            loading_icon
          ) : (
            <TrashIcon className="h-5" />
          )}
        </button>
      </div>

      {/*  */}
      <div className=" mt-4 space-y-1">
        {/*  */}
        <div className="flex items-center justify-start gap-2">
          <p className="  ">{card_details?.card_bin}</p>
          <p>{AllIcons.ellips_horizental}</p>
          <p>{AllIcons.ellips_horizental}</p>
          <p>{card_details?.card_last_four}</p>
        </div>
        <div className="flex items-center  justify-start gap-2">
          <p className=" text-sm">{Wallet_text?.wallet.expiry_date}</p>
          <p>
            {card_details?.expire_month}/{card_details?.expire_year}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Card;
