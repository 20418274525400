import React, { useEffect, useState } from 'react';
import Heading1 from '../../../UI/Text/Headers/Heading1';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import Cards from '../Cards/Cards';
import { paymentMethods } from '../../../store/feature/cartSlice';
import { Wallet_text } from '../../../constant/Translation/wallet';

const PaymentMethod = ({
  selected_amount,
  selected_card,
  setSelectedCard,
  selected_payment_method,
  setSelectedPaymentMethod,
  payment_methods_list,
  isShowApplePay,
}: {
  selected_amount: number;
  selected_card: any;
  setSelectedCard: any;
  selected_payment_method: any;
  setSelectedPaymentMethod: any;
  payment_methods_list: any;
  isShowApplePay: boolean;
}) => {
  const router = useRouter();
  const dispatch = useDispatch();

  return (
    <div className=" max-w-4xl mx-auto p-4 space-y-10">
      {/* Title */}
      <div className=" space-y-6">
        <Heading1 styles="">
          {Wallet_text?.wallet.select_payment_method}
        </Heading1>
        <div className="flex  items-center justify-center flex-wrap gap-4  ">
          {payment_methods_list.map((item) => {
            return (
              <button
                className={[
                  item?.id !== selected_payment_method?.id
                    ? ' min-w-[100px] h-5 px-6 py-4 border border-gray-200 flex items-center justify-center rounded-button    transition duration-700    hover:border-primary hover:text-primary text-center gap-2'
                    : ' min-w-[100px] h-5 px-6 py-4 border   flex items-center justify-center    border-transparent bg-primary text-white rounded-button cursor-pointer  ring-2 ring-primary ring-offset-2 text-center gap-2',
                  item?.id === 4 && isShowApplePay === false && '!hidden',
                ].join(' ')}
                key={item}
                onClick={() => setSelectedPaymentMethod(item)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={[
                    'h-5 w-5  mt-0.5   ',
                    item?.id !== selected_payment_method?.id ? 'hidden' : '',
                  ].join(' ')}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                {item?.value}
              </button>
            );
          })}
        </div>
      </div>

      {selected_payment_method?.id == 2 && (
        <Cards
          selected_amount={selected_amount}
          selected_card={selected_card}
          setSelectedCard={setSelectedCard}
        />
      )}
    </div>
  );
};

export default PaymentMethod;
