export const cards_type_list = [
  {
    label: 'Carte Bancaire / Visa / Mastercard',
    value: 'CB_VISA_MASTERCARD',
  },

  // {
  //   label: 'Amex',
  //   value: 'AMEX',
  // },
  {
    label: 'Maestro',
    value: 'MAESTRO',
  },
  {
    label: 'BCMC',
    value: 'BCMC',
  },
];
