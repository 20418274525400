import React, { useEffect, useState } from 'react';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';
import { changeDateFormatFromTimeStamp } from '../../../utils/date';
import { dateToEpochInSeconds } from '../../Shared/Constant/Constant';
import { dynamic_text_convert } from '../../../utils/dynamicTextConvert';

const CountDownByEndTime = ({ data, styles, listingDetails }) => {
  let targetDate = changeDateFormatFromTimeStamp(listingDetails?.end_at, '');
  let isDateExpire =
    dateToEpochInSeconds(new Date()) > listingDetails?.end_at ? true : false;

  const calculateTimeLeft = () => {
    const difference = new Date(targetDate) - new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        ),
        minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((difference % (1000 * 60)) / 1000),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      //   console.log({ timeLeft: calculateTimeLeft() });
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => {
      clearInterval(countdownInterval);
    };
  }, [targetDate]);

  return (
    !isDateExpire && (
      <div className="   " style={get_dynamic_styles(styles?.main_styles)}>
        <div
          style={{
            display: styles?.box_styles?.display,
            gridTemplateColumns: dynamic_text_convert({
              main_text: styles?.box_styles?.columns_box_percentage,
              check_by: '-1%',
              value: '',
            }),
            ...get_dynamic_styles(styles?.box_styles),
          }}
        >
          {timeLeft.days > 0 && (
            <div
              style={{
                display: styles?.count_block?.display,
                ...get_dynamic_styles(styles?.count_block),
              }}
            >
              <p style={get_dynamic_styles(styles?.time_styles)}>
                {timeLeft.days}
              </p>
              <p style={get_dynamic_styles(styles?.label_styles)}>days</p>
            </div>
          )}
          <div
            style={{
              display: styles?.count_block?.display,
              ...get_dynamic_styles(styles?.count_block),
            }}
          >
            <p style={get_dynamic_styles(styles?.time_styles)}>
              {timeLeft.hours}
            </p>
            <p style={get_dynamic_styles(styles?.label_styles)}>hours</p>
          </div>

          {/*  */}
          <div
            style={{
              display: styles?.count_block?.display,
              ...get_dynamic_styles(styles?.count_block),
            }}
          >
            <p style={get_dynamic_styles(styles?.time_styles)}>
              {timeLeft.minutes}
            </p>
            <p style={get_dynamic_styles(styles?.label_styles)}>minutes</p>
          </div>

          {/*  */}
          <div
            style={{
              display: styles?.count_block?.display,
              ...get_dynamic_styles(styles?.count_block),
            }}
          >
            <p style={get_dynamic_styles(styles?.time_styles)}>
              {timeLeft.seconds}
            </p>
            <p style={get_dynamic_styles(styles?.label_styles)}>seconds</p>
          </div>
        </div>
      </div>
    )
  );
};

export default CountDownByEndTime;
