export var Payment_text = {
  payment: {
    guest_info_error: 'You should complete guest information',

    cart_commission_alert: '{value} amount  is required',

    name: 'Name',
    email: 'Email',
    address: 'Address',
    pay_with_card_title: 'Pay with card',
    alert_enter_name: 'Name is required',
    alert_enter_email: 'Email is required',
    card_title: 'Stripe Card',
    alert_message_min_balance_checkout:
      'Your total cart amount should be more than {value} to proceed wit this order.',
    cancel_payment_prompt: 'Are you sure want you to exit payment?',
    go_to_my_order: 'Go to My Orders',
    header_title: 'Payment',
    no: 'No',
    not_able_to_checkout_msg:
      "Sorry.Couldn't able to checkout at the moment.Try again later",
    order: 'Order',
    order_now: 'Order Now',
    order_successfully_placed: 'Your Order has been placed successfully',
    pay_now: 'Pay Now',
    payment_option: 'Payment Option',
    placed_order_prompt_subtitle:
      'You can track the delivery in the "My Orders" section.',
    placed_order_prompt_title: 'Your order is placed successfully.',
    placing_order: 'Placing Order',
    price: 'Price',
    price_details: 'Price Details',
    prices: 'Prices',
    select_card_payment: 'Select card for payment',
    select_delivery_address: 'Please Select Delivery Address',
    select_payment: 'Please Select Payment',
    select_payment_option: 'Select payment option to proceed',
    wallet_alert:
      'Your wallet balance is insufficient to complete the checkout. Please add funds to your wallet before proceeding.',
    stripe_key_failed_error_message:
      'Some error has occurred. Please close the app and try again.',
    stripe_min_amount_prompt:
      'Your total cart amount should be more than {value} {value} to proceed with this order.',
    total_amount: 'Total Amount',
    yes: 'Yes',
  },
  payments: {
    continue_to_stripe_to_receive_payments:
      'Continue to Stripe to receive payments',
    stripe_suggest:
      'We suggest you to open new stripe connect through this button and come back to this page to authenticate.',
    stripe_waiting_message:
      'Your Stripe connect profile is under verification from Stripe.  If you want to change the information, view the dashboard to change.',
    verification_success_message:
      'Congratulations, your Stripe account has been connected successfully. Now you can receive payments to the bank account of your choice!',

    // csv
    'android.header_title': 'Configure Payout',
    complete_stripe_profile: 'Complete Stripe profile',
    connect_stripe_express_message:
      'Every user who creates a Account has a Stripe Connect account automatically created under their email address. Follow the below <a hr',
    connect_stripe_standard_message:
      'In order to receive payments, click the below button to set-up or login into your Stripe Connect account. Once you finish the account linkage, you will be redirected to this page.',
    connect_with_stripe: 'Connect with Stripe',
    disconnect_stripe: 'Disconnect from Stripe',
    disconnect_stripe_info:
      'Once you disconnect stripe from the below, you will not be able to see past payout history in app',
    disconnect_stripe_warning: 'Are you sure you want to disconnect Stripe?',
    failed: 'Payment failed. Try again later',
    header_title: 'Payments',
    redirected_to_stripe: 'You’ll be redirected to Stripe',
    setup_payout: 'Setup Payout to receive payments',
    setup_stripe_payout_info_express:
      'Every user will have stripe connect account automatically created under the Account email.',
    stripe_config_screen_connect_message_standard_fallback:
      'In order to receive payments, click the below button to set-up or login into your Stripe Connect account. Once you finish the account linkage, you will be redirected to this page.',
    stripe_config_screen_disconnect_message_express_fallback:
      'if you delete the account, you will no longer able to receive payments for future orders unless you connect stripe again.',
    stripe_config_screen_disconnect_message_standard_fallback:
      'if you disconnect the account, you will no longer able to receive payments for future orders unless you connect stripe again.',
    stripe_connected: 'Stripe Connected',
    stripe_connected_success: 'Stripe connected successfully',
    stripe_disconnected_success: 'Stripe disconnected successfully',
    stripe_disconnecting: 'Disconnecting',
    stripe_express_connect_failed:
      'Verification failed due to {value} Go to your dashboard and update the relevant details.',
    stripe_express_connect_message:
      'Every user who creates a Account has a Stripe Connect account automatically created under their email address. Click the below button to complete your account, so you can receive payments to the bank account of your choice!',
    stripe_express_connect_success:
      'Congratulations, your Stripe account has been connected successfully. Now you can receive payments to the bank account of your choice!',
    stripe_express_connect_waiting:
      'Your Stripe connect profile is under verification from Stripe. If you want to change the information, view the dashboard to change.',
    stripe_status_connect: 'Setup Payout to receive payments',
    stripe_status_connection_success: 'Stripe verification success',
    stripe_status_verification_failed: 'Stripe verification failed',
    stripe_status_waiting_message: 'Waiting for stripe verification',
    view_dashboard: 'View Dashboard',
    mangopay_kyc: 'Mangopay KYC',
    continue_to_mangopay_to_receive_payments:
      'Countinue to Mango Pay to receive payments',
    not_submitted_bank_account_kyc_detail_yet:
      'You have not submitted your Bank account and KYC details yet',
    submit: 'Submit',
  },
};
