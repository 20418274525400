import React, { useEffect, useState } from 'react';
import Heading1 from '../../UI/Text/Headers/Heading1';
import CardsList from './CardsList';
import PrimaryButton from '../../UI/Buttons/PrimaryButton';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import {
  CardSelector,
  getSavedCards,
} from '../../store/feature/card/cardSlice';
import {
  WalletSelector,
  amountPayInByMangoPay,
  amountPayInByMangoPayCalc,
  clearPayIN,
} from '../../store/feature/wallet/walletSlice';
import {
  delete_item_from_local_storage,
  get_data_from_local_storage,
} from '../../utils/localStorage';
import { local_storage_keys } from '../Localstorage/LocalStorageKeys';
import { getFormatBySelectedCurrency } from '../../utils/CurrencyFunctions';
import Alert from '../Shared/PopUp/Alert';
import { Wallet_text } from '../../constant/Translation/wallet';
import { dynamic_translation_set } from '../../constant/Translation/translation_set';
import { getCookie } from 'cookies-next';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import { SubscriptionSelector } from '../../store/feature/subscription/subscriptionSlice';

const Cards = ({
  type,
  onPayClick,
  amount_formatted,
}: {
  type: 'wallet' | 'subscription';
  onPayClick: (card_id: number) => {};
  amount_formatted: string;
  currency: string;
}) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const currency = router.query.code
    ? router.query.code
    : getCookie(`${TYPE_CONSTANT.DOMAIN}_default_currency`) ?? '';
  //
  const {
    isPayINLoaidng,
    isPayINSuccess,
    pay_in_calculation,
    pay_in_redirect_url,
  } = useSelector(WalletSelector);
  const { cards } = useSelector(CardSelector);

  //
  const { isSubscribeFetching, errorMessage, isError } =
    useSelector(SubscriptionSelector);

  //
  const [selected_card, setSelectedCard] = useState(null);
  const [selected_amount, setSelectedAmount] = useState(0);

  useEffect(() => {
    dispatch(
      // @ts-ignore
      getSavedCards({ currency: currency })
    );

    if (
      get_data_from_local_storage({
        key_name: local_storage_keys.PAY_IN_AMOUNT,
      }) &&
      type !== 'subscription'
    ) {
      setSelectedAmount(
        get_data_from_local_storage({
          key_name: local_storage_keys.PAY_IN_AMOUNT,
        })
      );
      dispatch(
        // @ts-ignore
        amountPayInByMangoPayCalc({
          amount: get_data_from_local_storage({
            key_name: local_storage_keys.PAY_IN_AMOUNT,
          }),
        })
      );
    } else {
      type !== 'subscription' && router.push('/wallet');
    }
  }, [router]);

  // handle_pay_in

  const handle_pay_in = () => {
    let data: {
      currency?: string;
      card_info: Object;
      window_info: Object;
    } = {
      card_info: {
        amount: Number(selected_amount),
        card_id: selected_card?.id,
      },
      window_info: {
        color_depth: window.screen.colorDepth,
        screen_height: window.innerHeight,
        screen_width: window.innerWidth,
        time_zone_offset: new Date().getTimezoneOffset(),
      },
    };
    if (currency) {
      data.currency = currency as string;
    }
    //
    dispatch(
      // @ts-ignore
      amountPayInByMangoPay(data)
    );
  };

  //* Alert
  const [open_alert, setOpenAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [alert_type, setAlert_type] = useState('success');

  // Error handling
  useEffect(() => {
    if (isError) {
      setAlert_type('error');
      setOpenAlert(true);
      setMessage(errorMessage);
    }
  }, [isError]);

  return (
    <>
      <Alert
        className={` fixed z-50 top-0 left-0 right-0  w-full  xs:w-[500px] mx-auto mt-5 `}
        type={alert_type}
        open={open_alert}
        setOpen={setOpenAlert}
        isOpenFunction={undefined}
        funcCallOnClose={() => {}}
      >
        <p>{message}</p>
      </Alert>

      {/*  */}
      <div className=" max-w-4xl mx-auto p-4">
        {/* Title */}
        <Heading1 styles="">{Wallet_text.wallet.cards}</Heading1>

        {/* Cards list  */}
        <CardsList
          selected_card={selected_card}
          setSelectedCard={setSelectedCard}
        />

        <div className=" max-w-lg mx-auto w-full flex flex-col gap-4 items-center justify-center">
          <PrimaryButton
            className=" max-w-[280px] w-full py-4  "
            isDisabled={
              isPayINLoaidng ||
              cards == null ||
              cards?.length == 0 ||
              isSubscribeFetching
            }
            isLoading={isPayINLoaidng || isSubscribeFetching}
            onClick={() =>
              onPayClick ? onPayClick(selected_card?.id) : handle_pay_in()
            }
          >
            {Wallet_text.wallet.pay}{' '}
            {type !== 'subscription'
              ? pay_in_calculation?.total_amount?.formatted
              : amount_formatted}
          </PrimaryButton>
        </div>
      </div>
    </>
  );
};

export default Cards;
