import React, { useEffect } from 'react';
import TextInput from '../../UI/FormFields/TextInput';
import SelectFromList from '../../UI/FormFields/SelectFromList';
import { BANK_DETAILS_FORMAT } from './types/KYCTypes';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { authSelector } from '../../store/feature/authSlice';
import {
  get_data_from_local_storage,
  set_data_in_local_storage,
} from '../../utils/localStorage';
import { Wallet_text } from '../../constant/Translation/wallet';
import { countriesSelector } from '../../store/feature/countrySlice';

const KYCBankDetails = ({
  bank_details,
  setBankDetails,
  group_name,
}: {
  bank_details: BANK_DETAILS_FORMAT;
  setBankDetails: React.Dispatch<React.SetStateAction<BANK_DETAILS_FORMAT>>;
  group_name: string;
}) => {
  const router = useRouter();
  const { all_countries_list } = useSelector(countriesSelector);
  const selectedWalletCurrency = router?.query?.code;

  //  itialization
  useEffect(() => {
    if (
      get_data_from_local_storage({
        key_name: group_name,
        isEncrypt: true,
      })
    ) {
      setBankDetails((arg: any) => ({
        ...arg,
        ...get_data_from_local_storage({
          key_name: group_name,
          isEncrypt: true,
        }),
      }));
    }
  }, [router]);

  //  value change handler
  const valueChangeHandler = <K extends keyof BANK_DETAILS_FORMAT>({
    value,
    key_name,
  }: {
    value: BANK_DETAILS_FORMAT[K];
    key_name: K;
  }) => {
    setBankDetails((arg: any) => {
      // set data in local storage for later retrieval
      set_data_in_local_storage({
        key_name: group_name,
        value: { ...arg, [key_name]: value },
        isEncrypt: true,
      });
      // return profile with new value
      return { ...arg, [key_name]: value };
    });
  };

  return (
    <div className=" space-y-6">
      <h2 className="text-center text-2xl font-bold">
        {Wallet_text?.kyc.bank_details}
      </h2>

      <div className="   space-y-4">
        {/* Owner Name */}
        <TextInput
          title={Wallet_text?.kyc.owner_name}
          current_value={bank_details.owner_name ?? ''}
          set_new_value={(vl) =>
            valueChangeHandler({
              value: vl,
              key_name: 'owner_name',
            })
          }
          is_required={true}
        />
        {/* IBAN */}
        {selectedWalletCurrency !== 'GBP' && (
          <TextInput
            current_value={bank_details.iban ?? ''}
            set_new_value={(vl) =>
              valueChangeHandler({
                value: vl,
                key_name: 'iban',
              })
            }
            title={Wallet_text?.kyc.iban}
            is_required={true}
          />
        )}
        {selectedWalletCurrency === 'GBP' && (
          <>
            {/* Account Number */}
            <TextInput
              type="number"
              max_length={'8'}
              current_value={bank_details.account_number ?? ''}
              set_new_value={(vl) =>
                valueChangeHandler({
                  value: vl,
                  key_name: 'account_number',
                })
              }
              title={Wallet_text?.kyc.account_number}
            />
            {/* short_code */}
            <TextInput
              type="number"
              max_length={'6'}
              current_value={bank_details.short_code ?? ''}
              set_new_value={(vl) =>
                valueChangeHandler({
                  value: vl,
                  key_name: 'short_code',
                })
              }
              title={Wallet_text?.kyc.short_code}
            />
          </>
        )}
        {/* Address 1 */}
        <TextInput
          current_value={bank_details.address_1 ?? ''}
          set_new_value={(vl) =>
            valueChangeHandler({
              value: vl,
              key_name: 'address_1',
            })
          }
          title={Wallet_text?.kyc.address_1}
        />
        {/* Address 2 */}
        <TextInput
          current_value={bank_details.address_2 ?? ''}
          set_new_value={(vl) =>
            valueChangeHandler({
              value: vl,
              key_name: 'address_2',
            })
          }
          title={Wallet_text?.kyc.address_2}
        />

        {/* City & state */}
        <div className=" grid  grid-cols-2 gap-4">
          <TextInput
            current_value={bank_details.city ?? ''}
            set_new_value={(vl) =>
              valueChangeHandler({
                value: vl,
                key_name: 'city',
              })
            }
            title={Wallet_text?.kyc.city}
          />
          <TextInput
            current_value={bank_details.state ?? ''}
            set_new_value={(vl) =>
              valueChangeHandler({
                value: vl,
                key_name: 'state',
              })
            }
            title={Wallet_text?.kyc.state}
          />
        </div>
        {/* City & state */}
        <div className=" grid  grid-cols-2 gap-4">
          <TextInput
            current_value={bank_details.postal_code ?? ''}
            set_new_value={(vl) =>
              valueChangeHandler({
                value: vl,
                key_name: 'postal_code',
              })
            }
            title={Wallet_text?.kyc.postal_code}
          />
          <SelectFromList
            title={Wallet_text?.kyc.country}
            current_value={bank_details?.country ?? ''}
            drop_down_items={all_countries_list?.map((country) => {
              return { label: country.name, value: country.code2 };
            })}
            styles={{
              popover_style: ' h-[250px]',
            }}
            set_new_value={(value: string) =>
              valueChangeHandler({
                value: value,
                key_name: 'country',
              })
            }
            default_option_text={Wallet_text?.kyc.select_country}
          />
        </div>
      </div>
    </div>
  );
};

export default KYCBankDetails;
