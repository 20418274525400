import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import CartIcon from '../../../assets/Images/Cart/Nocart.png';
import { Checkout_text } from '../../../constant/Translation/Cart_text';
import { General_text } from '../../../constant/Translation/General';

const NoCartItem = () => {
  return (
    <div className="     ">
      <div className=" mt-8 w-full  md:max-w-md min-h-[300px] py-10 bg-white shadow-c-sm  flex flex-col justify-center items-center mx-auto">
        <div>
          <Image
            src={CartIcon}
            width={250}
            height={250}
            alt="No Item"
            objectFit="contain"
          />
        </div>
        <p className="text-base font-semibold text-primary mt-4">
          {Checkout_text.cart.your_cart_is_empty}
        </p>
        <Link href="/" passHref={true}>
          <button className=" mt-4 px-6 py-2 bg-primary rounded-button text-white text-base font-semibold">
            {General_text.general.back_to_home}
          </button>
        </Link>
      </div>
    </div>
  );
};

export default NoCartItem;
