/* eslint-disable react/prop-types */
import React from 'react';

import ReadingComposer from '../../Shared/Composer/ReadingComposer';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';

const AttributesByGroupWithImage = ({
  styles,
  data,
  attributes,
  selected_attributes,
  product_images,
}) => {
  return (
    <div
      className="    w-full   "
      style={get_dynamic_styles(styles?.main_styles)}
    >
      {data?.header_title && (
        <p style={get_dynamic_styles(styles?.section_title_styles)}>
          {data?.header_title}
        </p>
      )}

      <div
        style={{
          display: styles?.box_styles?.display
            ? styles?.box_styles?.display
            : 'flex',
          ...get_dynamic_styles(styles?.box_styles),
        }}
      >
        {(data?.section_image || product_images?.length > 0) && (
          <div className="flex flex-col gap-0.5 ">
            <img
              style={get_dynamic_styles(styles?.section_image_styles)}
              src={
                data?.section_image ? data?.section_image : product_images[0]
              }
              alt=""
            />
            {data?.image_note && (
              <p className="description-caption">{data?.image_note}</p>
            )}
          </div>
        )}
        <div
          style={{
            display: styles?.attributes_box_styles?.display
              ? styles?.box_styles?.display
              : 'flex',
            ...get_dynamic_styles(styles?.attributes_box_styles),
          }}
          className="flex-grow"
        >
          {selected_attributes?.map((attr) => {
            return (
              <li
                className=" list-disc"
                style={{
                  display: styles?.attribute_group_styles?.display
                    ? styles?.attribute_group_styles?.display
                    : 'flex',
                  ...get_dynamic_styles(styles?.attribute_group_styles),
                }}
              >
                {/* Icon path */}
                {(attr?.icon_path || data?.icon) && data?.icon_enabled && (
                  <img
                    style={get_dynamic_styles(styles?.image_styles)}
                    src={data?.icon ? data?.icon : attr?.icon_path}
                    alt=""
                  />
                )}
                {/* name value */}
                {attr.name && data?.title_enabled && (
                  <p style={get_dynamic_styles(styles?.title_styles)}>
                    {attr.name}
                  </p>
                )}
                {/*  */}
                {data?.value_enabled && (
                  <>
                    {attr.field_type === 6 ? (
                      <ReadingComposer
                        contents={attr.values[0]}
                        isReadOnly={true}
                      />
                    ) : (
                      <p
                        style={get_dynamic_styles(styles?.value_styles)}
                        className="  whitespace-pre-line  overflow-auto  scrollbar-none"
                      >
                        {attr.values
                          .map((item) => {
                            if (
                              attr.field_type === 1 ||
                              attr.field_type === 2
                            ) {
                              return item.name;
                            } else if (
                              attr.field_type === 3 ||
                              attr.field_type === 4
                            ) {
                              return item;
                            } else if (attr.field_type === 12) {
                              return `latitude:${item?.latitude}, longitude:${item?.longitude}`;
                            } else {
                              return item;
                            }
                          })
                          .join(', ')}
                      </p>
                    )}
                  </>
                )}
              </li>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AttributesByGroupWithImage;
