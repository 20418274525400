import React from 'react';
import AudioPlayer from 'react-h5-audio-player';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { HomeCardBoxDefaultStyles } from '../../constant/default_styles/HomeDefaultStyles';
import { get_dynamic_styles_with_default_value } from '../../constant/DynamicStylesWithDefaultValue';
import 'react-h5-audio-player/lib/styles.css';

const AudioBlock = ({ data, styles, audio_url }) => {
  return data?.url ? (
    <div className=" " style={get_dynamic_styles(styles?.main_styles)}>
      <div
        style={get_dynamic_styles_with_default_value(
          styles?.box_styles,
          HomeCardBoxDefaultStyles
        )}
      >
        <AudioPlayer
          src={audio_url ? audio_url : data?.url ? data?.url : ''}
          width="100%"
          height="100%"
          autoPlay={data?.autoplay ? true : false}
          loop={data?.loop ? data?.loop : false}
          volume={data?.volume ? data?.volume : 0.5}
          muted={data?.muted ? true : false}
        />
      </div>
    </div>
  ) : (
    <></>
  );
};

export default AudioBlock;
