import React from 'react';
import { Listing_text } from '../../../constant/Translation/Listing';
import { dynamic_translation_set } from '../../../constant/Translation/translation_set';

const ListVariant = ({
  variants,
  selectedVariant,
  setSelectedVariant,
  listings_configs,
  listing_details,
}) => {
  return (
    <div className="flex flex-col gap-4 ">
      {variants.map((item) => {
        return (
          <div
            key={item.id}
            className={
              selectedVariant !== item.id
                ? 'w-full min-h-min relative rounded-card   overflow-hidden  flex items-center shadow-c-sm  border border-transparent cursor-pointer'
                : 'w-full min-h-min relative rounded-card   overflow-hidden  flex items-center  shadow-c-sm cursor-pointer transition duration-700 ring ring-primary ring-offset-1'
            }
            onClick={() => setSelectedVariant(item.id)}
          >
            <div className="h-24 w-24 relative">
              {item?.images?.length > 0 ? (
                <img
                  alt="image"
                  src={item.images[0]}
                  className="h-full w-full object-cover object-left-top"
                />
              ) : (
                <img
                  alt="image"
                  src={listing_details?.images[0]}
                  className="h-full w-full object-cover object-left-top"
                />
              )}
            </div>
            <div className=" ltr:ml-3 rtl:mr-3">
              {listings_configs?.enable_stock &&
                (item?.stock !== 0 ? (
                  <p className=" text-sm text-primary font-normal">
                    {dynamic_translation_set(
                      Listing_text.product.stock_text,
                      item?.stock
                    )}
                  </p>
                ) : (
                  <p className=" text-sm  text-warning font-normal">
                    {Listing_text.product.out_of_stock}
                  </p>
                ))}
              <p className="text-black font-semibold">{item.title}</p>
              <p className=" flex items-center  ">
                {item.offer_price.formatted}
              </p>
              {item?.offer_percent > 0 && (
                <p className="   flex items-center flex-wrap gap-2">
                  <span className="text-base leading-4 font-normal text-gray-600  line-through ">
                    {item.list_price.formatted}
                  </span>
                  <span className="text-error  ">{item?.offer_percent} %</span>
                </p>
              )}
            </div>
            <input
              className={
                selectedVariant !== item.id
                  ? ' absolute  ltr:right-0 rtl:left-0 ltr:mr-6 rtl:ml-6 cursor-pointer'
                  : 'absolute  ltr:right-0 rtl:left-0 ltr:mr-6 rtl:ml-6  cursor-pointer text-primary  focus:ring-primary focus:text-primary'
              }
              checked={selectedVariant === item.id ? true : false}
              type="radio"
              name=""
              id=""
            />
          </div>
        );
      })}
    </div>
  );
};

export default ListVariant;
