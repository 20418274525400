import React from 'react';
import { Shipping_text } from '../../../constant/Translation/Shipping_text';

const CheckOutButton = ({
  editor_versions_list,
  current_editor_version,
  clickCheckOut,
  isCheckoutFetching,
  data,
  styles,
}) => {
  return (
    <div
      className={[
        'flex justify-center   ',
        editor_versions_list?.includes(current_editor_version) && 'w-full',
      ].join(' ')}
    >
      <button
        className=" w-5/6 bg-primary  rounded-button py-[12px] text-center text-base  text-white flex justify-center items-center font-semibold gap-3"
        onClick={clickCheckOut}
      >
        {isCheckoutFetching && (
          <span>
            <svg
              className="animate-spin -ml-1   h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </span>
        )}
        <span className=" ">{Shipping_text.shipping.checkout}</span>
      </button>
    </div>
  );
};

export default CheckOutButton;
