export var Notification_text = {
  notification: {
    order_incomplete: 'Your order is incomplete',
    load_more: 'Load more..',

    account_message: '{value} started following you',
    header_title: 'Notifications',
    liked_your_listing: '{value} liked your listing',
    listing_message: '{value} likes your listings',
    no_notification_at_the_moment: 'No notifications available.',
    order_cancelled: 'Your order has been cancelled',
    order_customer_return_confirmed: 'Order return confirmed',
    order_customer_return_disputed: 'Order return disputed',
    order_customer_unreachable: 'Customer unreachable',
    order_deliver_returned: 'Order Delivery returned',
    order_delivery_return_confrmed: 'Order Delivery return confirmed',
    order_is_in_progress: 'Your order is in Progress',
    order_message: 'Your Order #{value} has been {value}',
    order_order_delivered: 'Your order has been delivered successfully',
    order_out_for_delivery: 'Your order is out for Delivery',
    order_picked_up: 'Your order has been picked up by courier partner',
    order_placed_success: 'Your Order #{value} has been {value}',
    order_return_initiated: 'Your order return process initiated',
    order_shipped: 'Your order has been shipped',
    started_following_your_account: '{value} started following you',
  },
};
