import React from 'react';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';

const UnlikeButton = ({ data, styles, product, unLike }) => {
  return (
    <div style={get_dynamic_styles(styles?.main_styles)}>
      <div
        className="flex items-center gap-1  "
        style={get_dynamic_styles(styles?.box_styles)}
      >
        <button
          className="     rounded-button  flex items-center justify-center  gap-2 "
          type="button"
          onClick={(e) => {
            e.stopPropagation();
            unLike(product.id, product.unliked);
          }}
          target={data?.button_target === '_blank' ? '_blank' : '_self'}
          style={get_dynamic_styles(styles?.button_styles)}
        >
          {product?.unliked ? (
            <img
              className="w-auto object-cover  "
              style={get_dynamic_styles(styles?.liked_icon_styles)}
              src={data?.liked_icon}
              alt="image"
            />
          ) : (
            <img
              className="w-auto object-cover  "
              style={get_dynamic_styles(styles?.unlike_icon_styles)}
              src={data?.unlike_icon}
              alt="image"
            />
          )}
        </button>
        <span className="description-caption">{product?.likes}</span>
      </div>
    </div>
  );
};

export default UnlikeButton;
