import React from 'react';

// right_button & left_button properties
// title;
// click;
// disabled;
// extra_styles;

const PopupUI = ({ children, title, right_button, left_button }) => {
  return (
    <div className="w-full h-full flex flex-col justify-between ">
      {/* Main body */}
      <div className=" flex-grow flex  flex-col justify-between">
        {/* Title */}
        {title && (
          <div className="flex flex-col  px-10   py-4  borderer-b bg-gray-100 ">
            <p className="title-h1  text-center  ">{title}</p>
          </div>
        )}

        {/* Children */}
        <div className="w-full flex-grow ">{children}</div>
      </div>

      {/* Buttons */}
      <div className=" bg-white sticky bottom-0 grid grid-cols-2 h-16   border-[#fafafa]">
        <button
          disabled={left_button?.disabled ?? false}
          onClick={(e) => {
            left_button.click(e);
          }}
          className={[
            'px-4 py-2 shadow   btn-primary-outline  text-base rounded-none border-none  border-r border-[#fafafa]   transition duration-200 flex items-center justify-center   ',
            left_button?.extra_styles ?? ' cursor-pointer',
          ].join()}
        >
          {left_button?.title}
        </button>
        <button
          onClick={(e) => right_button.click(e)}
          disabled={right_button?.disabled ?? false}
          className={[
            '   px-4 py-2 shadow btn-primary-fill   rounded-none border-none  border-r border-[#fafafa]    flex items-center justify-center gap-2  ',
            right_button?.extra_styles ?? ' cursor-pointer',
          ].join(' ')}
        >
          {right_button?.title}
          {right_button?.icon}
        </button>
      </div>
    </div>
  );
};

export default PopupUI;
