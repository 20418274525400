import { Popover, Transition } from '@headlessui/react';
import { Fragment } from 'react';

import { cn } from '../../../utils/classNames';

export default function PopOver({
  close_by_click_on_children,
  children,
  button,
  className: PopoverClass,
  popOverButtonClassName,
  is_disabled = false,
  component_style,
}) {
  return (
    <Popover as="div" className={cn('relative', component_style)}>
      {({ open }) => (
        <>
          <Popover.Button
            as="div"
            className={cn('outline-none ring-0', popOverButtonClassName)}
            disabled={is_disabled}
          >
            {button}
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className={cn(PopoverClass, 'absolute   z-20')}>
              {({ close }) => (
                <div
                  onClick={() => {
                    close_by_click_on_children && close();
                  }}
                >
                  {children}
                </div>
              )}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
