/* eslint-disable react/prop-types */
import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import tradly from 'tradly';
import { getThumbnailImage } from '../Constant/Constant';
import { useRouter } from 'next/dist/client/router';
import { Account_text } from '../../../constant/Translation/Account';
import ALink from '../../../constant/ALink';

const AccountCard = ({
  item,
  follow,
  follow_title,
  following_title,
  embed,
}) => {
  const router = useRouter();
  return (
    <div className=" relative">
      <ALink
        href={
          item?.slug
            ? `/a/${item?.slug}`
            : `/a/${item.id}-${item.name.replace(
                /[ /?,#!@#$%^&*=]+/g,
                '-'
              )}?id=true`
        }
        isEmbed={embed}
      >
        <a
          className=" block w-full  h-full p-2 bg-[#FEFEFE] rounded-card shadow-c-xsm cursor-pointer"
          // onClick={() =>
          //   router.push({
          //     pathname: '/a/[id]',
          //     query: {
          //       id: `${item.id}-${item.name.replace(/\s/g, '-')}`,
          //       page: 1,
          //     },
          //   })
          // }
        >
          <div className=" rounded-image  overflow-hidden relative mx-auto  aspect-w-1 aspect-h-1">
            {item?.images?.length > 0 && (
              <img
                src={getThumbnailImage(item.images[0])}
                alt={item.title}
                className="h-full w-full object-cover object-left-top"
              />
            )}
          </div>
          <div className="mt-2 mb-2">
            <p className="w-full text-[#000000] font-semibold text-[14px] leading-4   ">
              {item?.name?.length > 15
                ? item.name.substring(0, 13) + '..'
                : item.name}
            </p>
          </div>
          <div className="flex justify-around items-center mb-2 relative">
            <p className="w-full text-[#4F4F4F] font-medium text-[14px]   ">
              {item?.user?.first_name.length > 10
                ? item?.user?.first_name.substring(0, 8) + '..'
                : item?.user?.first_name}
            </p>
          </div>
        </a>
      </ALink>

      {!embed && follow && (
        <div className=" absolute bottom-0 ltr:right-0 rtl:left-0  mb-4 ltr:mr-2 rtl:ml-2">
          <button
            className={[
              ' px-2 py-1 text-[10px] leading-3 items-center rounded-full border border-primary ',
              item.following
                ? 'text-[#FFFFFF]  bg-primary'
                : 'text-primary   bg-transparent',
            ].join(' ')}
            onClick={() => follow(item.id, item.following)}
          >
            {item.following
              ? Account_text.storelist.following
              : Account_text.storelist.follow}
          </button>
        </div>
      )}
    </div>
  );
};

export default AccountCard;
