import { cn } from '../../utils/classNames';
import { TSICONS } from '../../constant/Icons/TSICONS';
import { SelectFromListType } from '../../types/FormFieldTypes';
import { Popover, Transition } from '@headlessui/react';
import { Fragment, useEffect, useRef, useState } from 'react';
import AllIcons, {
  loading_icon,
} from '../../components/Shared/Constant/Icons/AllIcons';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import NotFoundAlert from '../../components/Shared/Default UI/NotFoundAlert';
import NotFoundAlert2 from '../../components/Shared/Default UI/NotFoundAlert2';
import {
  ChevronDoubleDownIcon,
  ChevronDownIcon,
} from '@heroicons/react/outline';

const SelectFromListDropDown = ({
  current_value,
  set_new_value,
  drop_down_items,
  default_option_text,
  is_disabled,
  styles,
  default_icon_image,
  pop_over_max_height = 500,
  not_found_text,
  is_show_down_icon,
  is_loading,
}) => {
  const find_current_item = drop_down_items?.filter(
    (item) => item.value === current_value
  )[0];

  const buttonRef = useRef(null);
  const [dropdown_position, setDropdownPositionState] = useState('top-10');

  useEffect(() => {
    const getButtonPosition = () => {
      if (buttonRef.current) {
        const buttonRect = buttonRef.current.getBoundingClientRect();
        const innerHeight = window.innerHeight;
        const count_bottom_position =
          innerHeight - (buttonRect.top + buttonRect.height);
        if (count_bottom_position > pop_over_max_height) {
          setDropdownPositionState('top-10');
        } else {
          setDropdownPositionState('bottom-14');
        }
      }
    };

    // Call the function to get button position after the component has rendered
    getButtonPosition();
  }, []);

  return (
    <Popover
      as="div"
      className=" relative"
      style={{
        width: get_dynamic_styles(styles?.filter_single_select_filed_style)
          ?.width,
        maxWidth: get_dynamic_styles(styles?.filter_single_select_filed_style)
          ?.maxWidth,
      }}
    >
      {({ open }) => (
        <>
          <Popover.Button
            as="div"
            className={cn('outline-none ring-0 flex')}
            disabled={is_disabled}
            ref={buttonRef}
            style={get_dynamic_styles(styles?.filter_single_select_filed_style)}
          >
            {(find_current_item?.image || default_icon_image) && (
              <img
                src={find_current_item?.image ?? default_icon_image}
                alt="flag image"
                className={cn('h-6 w-6    object-cover  ')}
              />
            )}
            {find_current_item ? find_current_item?.label : default_option_text}
            {is_show_down_icon && is_loading ? (
              loading_icon
            ) : (
              <ChevronDownIcon className=" w-4 h-4" />
            )}
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className={cn('absolute    z-20 w-full')}>
              {({ close }) => (
                <div
                  className="overflow-y-auto  p-5  shadow-sm bg-white rounded-lg  h-full  min-h-[100px] w-full flex  flex-col   justify-start gap-2"
                  onClick={() => {
                    close();
                  }}
                >
                  {drop_down_items?.map((menu) => {
                    return (
                      <button
                        type="button"
                        key={menu.value}
                        onClick={() => {
                          set_new_value(menu.value);
                        }}
                        className={cn(
                          ` text-gray-800 hover:text-primary duration-500 text-base  flex items-center gap-4  text-start py-1 ${
                            current_value === menu.value && 'text-primary'
                          }`,

                          styles?.options_style
                        )}
                      >
                        {menu.image && (
                          <img
                            src={menu.image}
                            alt="flag image"
                            className={cn(
                              'h-7 w-7 rounded-full  object-cover shadow-sm',
                              styles?.option_image_style
                            )}
                          />
                        )}
                        {menu.label}
                      </button>
                    );
                  })}

                  {drop_down_items?.length == 0 && (
                    <NotFoundAlert2
                      alert_text={not_found_text ?? 'Not found'}
                    />
                  )}
                </div>
              )}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default SelectFromListDropDown;
