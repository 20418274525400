import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/Redux';
import {
  getListingDonnerList,
  listingSelector,
} from '../../../store/feature/listingSlice';
import { Listing_text } from '../../../constant/Translation/Listing';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';
import { dynamic_translation_set } from '../../../constant/Translation/translation_set';
import { changeDateFormatFromTimeStamp } from '../../../utils/date';
import moment from 'moment';
import { getRelativeTime } from '../../../utils/dateConverterMethods';

const DonnerList = ({ styles, data, listing_details }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (listing_details?.id) {
      dispatch(getListingDonnerList({ listing_id: listing_details?.id }));
    }
  }, [listing_details?.id]);

  const { listings_donner_list, donner_list_page, donner_list_total_records } =
    useAppSelector(listingSelector);

  return (
    listings_donner_list?.length > 0 && (
      <div className=" w-full " style={get_dynamic_styles(styles?.main_styles)}>
        <div
          className=" w-full  "
          style={get_dynamic_styles(styles?.box_styles)}
        >
          <h2 className="heading-h2">{Listing_text.product.donation_list}</h2>

          {listings_donner_list?.map((donner) => {
            return (
              <div
                style={{
                  display: styles.list_card?.display,
                  ...get_dynamic_styles(styles?.list_card),
                }}
              >
                <div style={get_dynamic_styles(styles?.amount_style)}>
                  {donner?.amount?.formatted}
                </div>
                <div
                  style={{
                    display: styles.infos_style?.display,
                    ...get_dynamic_styles(styles?.infos_style),
                  }}
                >
                  <p className="">
                    <span style={get_dynamic_styles(styles?.name_text)}>
                      {donner.user
                        ? `${donner.user.first_name} ${donner.user.last_name}`
                        : Listing_text.product?.anonymous_donner}
                    </span>{' '}
                    <span style={get_dynamic_styles(styles?.donation_text)}>
                      {dynamic_translation_set(
                        Listing_text.product?.donated,
                        donner?.amount?.formatted
                      )}
                    </span>
                  </p>
                  <p style={get_dynamic_styles(styles?.time_text)}>
                    {getRelativeTime(donner?.created_at)}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    )
  );
};

export default DonnerList;
