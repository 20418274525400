import Transition from '../utils/Transition';
import React, { useRef, useEffect } from 'react';

function SidePan({
  children,
  id,
  sidePanOpen,
  setSidePanOpen,
  justify_align,
  close_function,
  modal_section_styles,
  modal_inside_section_styles,
  modal_width,
  is_close_by_outside_click = true,
}) {
  const modalContent = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidePanOpen || modalContent.current.contains(target)) return;
      is_close_by_outside_click && setSidePanOpen(false);
      is_close_by_outside_click && close_function && close_function();
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidePanOpen || keyCode !== 27) return;
      is_close_by_outside_click && setSidePanOpen(false);
      is_close_by_outside_click && close_function && close_function();
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  return (
    <>
      {/* Modal backdrop */}
      <Transition
        className="fixed inset-0 bg-gray-900 bg-opacity-30  z-50 transition-opacity"
        show={sidePanOpen}
        enter="transition ease-in duration-400"
        enterStart="opacity-0"
        enterEnd="opacity-100"
        leave="transition ease-out duration-400 "
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
        aria-hidden="true"
      />
      {/* Modal dialog */}
      <Transition
        id={id}
        className={[
          modal_section_styles
            ? modal_section_styles
            : `fixed inset-0 z-[60] overflow-hidden flex items-center    transform  ${
                justify_align ? `justify-${justify_align}` : 'justify-end'
              }`,
          ,
        ].join(' ')}
        role="dialog"
        aria-modal="true"
        show={sidePanOpen}
        enter="transition ease-in-out duration-200"
        enterStart="opacity-0 translate-y-4"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-in-out duration-200"
        leaveStart="opacity-100 translate-y-0"
        leaveEnd="opacity-0 translate-y-4"
      >
        <div
          ref={modalContent}
          className={
            modal_inside_section_styles
              ? modal_inside_section_styles
              : [
                  'bg-white rounded shadow-lg overflow-auto   max-h-full  scrollbar-thin    scrollbar-track-transparent  scrollbar-thumb-transparent',
                  modal_width ? modal_width : 'max-w-lg w-full',
                ].join(' ')
          }
        >
          {children}
        </div>
      </Transition>
    </>
  );
}

export default SidePan;
