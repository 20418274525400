/* eslint-disable react/prop-types */
import React from 'react';
import { getThumbnailImage } from '../../Constant/Constant';
import { useRouter } from 'next/dist/client/router';
import { Account_text } from '../../../../constant/Translation/Account';
import ALink from '../../../../constant/ALink';
import { TYPE_CONSTANT } from '../../../../constant/Web_constant';

const ListTypeAccountCard = ({
  item,
  follow,
  follow_title,
  following_title,
  embed,
}) => {
  const router = useRouter();
  const account_card_styles = TYPE_CONSTANT.STYLES_CONFIGS?.account_card ?? {};
  return (
    <div className="block  relative account-card">
      <ALink
        href={
          item?.slug
            ? `/a/${item?.slug}`
            : `/a/${item.id}-${item.name.replace(
                /[ /?,#!@#$%^&*=]+/g,
                '-'
              )}?id=true`
        }
        isEmbed={embed}
      >
        <a className=" cursor-pointer">
          {account_card_styles?.image?.enabled !== false && (
            <div
              className={
                account_card_styles?.image !== undefined
                  ? ' account-card-image '
                  : 'aspect-w-1 aspect-h-1 '
              }
            >
              {item?.images?.length > 0 && (
                <img
                  src={getThumbnailImage(item.images[0])}
                  alt={item.title}
                  className="h-full w-full object-cover object-left-top"
                />
              )}
            </div>
          )}

          {account_card_styles?.account_name?.enabled !== false && (
            <p className="account-card-title  my-2">
              {item?.name?.length > 15
                ? item.name.substring(0, 13) + '..'
                : item.name}
            </p>
          )}

          {account_card_styles?.owner_name?.enabled !== false && (
            <p className="account-card-owner mb-2 ">
              {item?.user?.first_name.length > 10
                ? item?.user?.first_name.substring(0, 8) + '..'
                : item?.user?.first_name}
            </p>
          )}
        </a>
      </ALink>

      {!embed &&
        follow &&
        account_card_styles?.follow_button?.enabled !== false && (
          <div className=" absolute bottom-0 ltr:right-0 rtl:left-0  mb-4 ltr:mr-2 rtl:ml-2">
            <button
              className={[
                ' px-2 py-1 text-[10px] leading-3 items-center rounded-full border border-primary ',
                item.following
                  ? 'text-[#FFFFFF]  bg-primary'
                  : 'text-primary   bg-transparent',
              ].join(' ')}
              onClick={() => follow(item.id, item.following)}
            >
              {item.following
                ? Account_text.storelist.following
                : Account_text.storelist.follow}
            </button>
          </div>
        )}
    </div>
  );
};

export default ListTypeAccountCard;
