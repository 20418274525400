import React from 'react';

const AccountsMapViewSkeleton = ({ per_page = 30, row_styles }) => {
  const gridItems = [];

  for (let i = 0; i < per_page; i++) {
    gridItems.push(
      <div
        key={i}
        className="w-full  min-h-[150px] bg-[#3B3269] bg-opacity-[20%] rounded-card"
      ></div>
    );
  }

  return (
    <div className="    w-full mx-auto overflow-hidden">
      <div className="grid  grid-cols-1  xxs:grid-cols-2  md:grid-cols-[repeat(auto-fill,minmax(calc((var(--bodyStylesMaxWidth)/var(--accountCardPerRow)-40px)),1fr))]    gap-5 justify-center">
        {gridItems}
      </div>
    </div>
  );
};

export default AccountsMapViewSkeleton;
