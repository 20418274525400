export const set_root_translation = ({
  translation_values,
  group_names,
  translation_file,
}) => {
  group_names.forEach((item) => {
    const item_values = translation_values[item];
    // Item values (Object) Map (by foreach)
    item_values &&
      Object?.keys(item_values).forEach(function (key) {
        const element = item_values[key];

        translation_file.forEach((file) => {
          //console.log('>>>>>>>>', file);
          //console.log(`adding_new content >>>>  ${key}`, element);
          if (
            file[item]?.[
              `${
                key.split(`${item}.`)?.length > 1
                  ? key.split(`${item}.`)[1]
                  : key.split(`${item}.`)[0]
              }`
            ] !== undefined
          ) {
            file[item][
              `${
                key.split(`${item}.`)?.length > 1
                  ? key.split(`${item}.`)[1]
                  : key.split(`${item}.`)[0]
              }`
            ] = element;
          }
        });
      });
  });
};

export const dynamic_translation_set = (text, value) => {
  if (text) {
    const check = text.includes('{value}');
    if (check) {
      return text.replace('{value}', value);
    }
    return text;
  }
};
