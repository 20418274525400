import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import CartIcon from '../../../assets/Images/Cart/Nocart.png';
import { Checkout_text } from '../../../constant/Translation/Cart_text';
import { General_text } from '../../../constant/Translation/General';

const NoCartItem2 = () => {
  return (
    <div className=" w-full  py-10 bg-white    flex flex-col justify-center items-center mx-auto">
      <div>
        <Image
          src={CartIcon}
          width={150}
          height={150}
          alt="No Item"
          objectFit="contain"
        />
      </div>
      <p className="text-sm font-semibold text-primary mt-4">
        {Checkout_text.cart.your_cart_is_empty}
      </p>
    </div>
  );
};

export default NoCartItem2;
