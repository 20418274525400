import React from 'react';

const NegotiateSkeleton = () => {
  return (
    <div className="   w-full mx-auto overflow-hidden p-4 flex flex-col  gap-4">
      <div className="h-32 bg-[#3B3269] bg-opacity-[20%] rounded-card  w-4/5"></div>
      <div className="h-32 bg-[#3B3269] bg-opacity-[20%] rounded-card  w-4/5 self-end"></div>
      <div className="h-32 bg-[#3B3269] bg-opacity-[20%] rounded-card  w-4/5"></div>
      <div className="h-32 bg-[#3B3269] bg-opacity-[20%] rounded-card  w-4/5 self-end"></div>
    </div>
  );
};

export default NegotiateSkeleton;
