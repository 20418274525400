//responsive_design_styles
export const responsive_design_styles = ({
  width,
  parent_group,
  child_group_name,
}) => {
  if (width > 770) {
    return parent_group?.[child_group_name];
  } else if (width > 430 && width <= 770) {
    return parent_group?.[`768_${child_group_name}`]
      ? parent_group?.[`768_${child_group_name}`]
      : parent_group?.[child_group_name];
  } else if (width > 10 && width <= 430) {
    return parent_group?.[`425_${child_group_name}`]
      ? parent_group?.[`425_${child_group_name}`]
      : parent_group?.[`768_${child_group_name}`]
      ? parent_group?.[`768_${child_group_name}`]
      : parent_group?.[child_group_name];
  } else {
    return parent_group?.[child_group_name];
  }
};

// responsive_column_styles
export const responsive_column_styles = ({ index, parent_group, width }) => {
  if (width > 770) {
    return parent_group?.[`column_${index + 1}_styles`];
  } else if (width > 430 && width <= 770) {
    return parent_group?.[`768_column_${index + 1}_styles`]
      ? parent_group?.[`768_column_${index + 1}_styles`]
      : parent_group?.[`column_${index + 1}_styles`];
  } else if (width > 10 && width <= 430) {
    return parent_group?.[`425_column_${index + 1}_styles`]
      ? parent_group?.[`425_column_${index + 1}_styles`]
      : parent_group?.[`768_column_${index + 1}_styles`]
      ? parent_group?.[`768_column_${index + 1}_styles`]
      : parent_group?.[`column_${index + 1}_styles`];
  } else {
    return parent_group?.[`column_${index + 1}_styles`];
  }
};

// responsive_row_styles
export const responsive_row_styles = ({ parent_group, width }) => {
  if (width > 770) {
    return parent_group?.[`row_styles`];
  } else if (width > 430 && width <= 770) {
    return parent_group?.[`768_row_styles`]
      ? parent_group?.[`768_row_styles`]
      : parent_group?.[`row_styles`];
  } else if (width > 10 && width <= 430) {
    return parent_group?.[`425_row_styles`]
      ? parent_group?.[`425_row_styles`]
      : parent_group?.[`768_row_styles`]
      ? parent_group?.[`768_row_styles`]
      : parent_group?.[`row_styles`];
  } else {
    return parent_group?.[`row_styles`];
  }
};
