import axios from 'axios';
import { useRouter } from 'next/router';
import React, { memo } from 'react';
import Link from 'next/link';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { get_data_by_current_language } from '../../utils/GetDataByLanguage';

const TitleDescriptionButton = ({ data, styles }) => {
  return (
    <div
      className="w-full    flex  items-center justify-center"
      style={get_dynamic_styles(styles?.main_styles)}
    >
      <div
        className=" flex flex-col   "
        style={get_dynamic_styles(styles?.box_styles)}
      >
        <h2 className="heading-h2  text-center">
          {get_data_by_current_language({
            data_list: data?.translated_data,
            key_name: 'title',
            default_data: data?.title,
          })}
        </h2>
        <p className="paragraph text-center">
          {get_data_by_current_language({
            data_list: data?.translated_data,
            key_name: 'description',
            default_data: data?.description,
          })}
        </p>
        {data?.btn_title && data?.link && (
          <Link href={data?.link ?? '#'}>
            <a
              className=" btn-sm bg-primary border border-transparent text-white  transition duration-300 hover:bg-transparent hover:border-primary hover:text-primary "
              target={data?.link_opening ?? '_blank'}
            >
              {get_data_by_current_language({
                data_list: data?.translated_data,
                key_name: 'btn_title',
                default_data: data?.btn_title,
              })}
            </a>
          </Link>
        )}
      </div>
    </div>
  );
};

export default memo(TitleDescriptionButton);
