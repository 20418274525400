import React from 'react';
import { cn } from '../../../utils/classNames';
var lodash = require('lodash');

const GridCombination = ({
  title,
  variant_values,
  selected_combination,
  setSelectedCombination,
  variant_details,
  setSelectedVariant,
  variants,
}) => {
  const selectVariant = (variant_value) => {
    const temp_data = {
      ...selected_combination,
      [variant_details?.id]: variant_value?.id,
    };

    setSelectedCombination(temp_data);

    //selected variant update
    let check_variant = variants?.filter((vr_list) =>
      lodash.isEqual(
        Object.fromEntries(
          vr_list?.variant_values?.map((vr_vl) => [
            vr_vl.variant_type_id,
            vr_vl?.variant_type_value_id,
          ])
        ),
        temp_data
      )
    );
    if (check_variant.length > 0) {
      setSelectedVariant(check_variant[0]?.id);
    } else {
      setSelectedVariant(null);
    }
  };

  return (
    <div className=" flex items-start gap-6">
      <p className=" paragraph text-gray-500 min-w-14">{title}</p>
      <div className=" flex items-center justify-start gap-4 flex-wrap ">
        {variant_values?.map((variant_value) => {
          return (
            <button
              key={variant_value?.id}
              className={cn(
                selected_combination?.[variant_details?.id] ===
                  variant_value?.id
                  ? 'btn-primary-outline'
                  : 'btn-primary-outline border-gray-200 text-gray-500 '
              )}
              onClick={() => selectVariant(variant_value)}
            >
              {variant_value?.name}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default GridCombination;
