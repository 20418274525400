import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { General_text } from '../../../constant/Translation/General';
import notfoundImage from '../../../assets/Images/notfoundImage.png';

const NotFound = ({ message }) => {
  return (
    <div className="  min-h-screen    ">
      <div className=" mt-8 w-full  md:w-[600px] min-h-[300px] py-10 bg-white shadow-c-sm  flex flex-col justify-center items-center mx-auto">
        <div>
          <Image
            src={notfoundImage}
            width={250}
            height={250}
            alt="No Item"
            objectFit="contain"
          />
        </div>
        <p className="text-base font-semibold text-primary mt-4">{message}</p>
        <Link href="/" passHref={true}>
          <a className=" mt-4  btn-primary-fill  px-6 py-2   text-base font-semibold">
            {General_text.general.back_to_home}
          </a>
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
