import React, { useState } from 'react';
import { changeDateFormat } from '../../Shared/Constant/Constant';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';
import { dynamic_text_convert } from '../../../utils/dynamicTextConvert';
import {
  dislike,
  dislike_fill,
  like,
  like_fill,
  reply_icon,
} from '../../Shared/Constant/Icons/AllIcons';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import {
  getListingComments,
  likeUnlikeComment,
  listingSelector,
} from '../../../store/feature/listingSlice';
import { cn } from '../../../utils/classNames';
import CommentBox from './CommentBox';
import ReadingComposer from '../../Shared/Composer/ReadingComposer';

const CommentsList = ({ styles, listing_details }) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const { comments, isFetching, isCommentLikeUnlikeLoading } =
    useSelector(listingSelector);
  //
  const [selected_id, setSelectedId] = useState(0);
  const [selected_type, setSelectedType] = useState('');
  const [is_reply_open, setIsReplyOpen] = useState(false);
  const [selected_reply_id, setSelectedReplyId] = useState(0);

  //
  const onLike = ({ id, isDowngrade }) => {
    setSelectedType('like');
    setSelectedId(id);
    dispatch(
      likeUnlikeComment({
        id,
        isDowngrade,
        isLike: true,
      })
    ).then((res) => {
      if (!res.code) {
        dispatch(
          getListingComments({
            params: {
              reference_type: 'listings_comments',
              reference_id: listing_details?.id,
              page: 1,
              per_page: 10,
              parent: 0,
            },
          })
        );
        setSelectedId(0);
      }
    });
  };
  //
  const onUnLike = ({ id, isDowngrade }) => {
    setSelectedType('unlike');
    setSelectedId(id);
    dispatch(
      likeUnlikeComment({
        id,
        isDowngrade,
        isLike: false,
      })
    ).then((res) => {
      if (!res.code) {
        dispatch(
          getListingComments({
            params: {
              reference_type: 'listings_comments',
              reference_id: listing_details?.id,
              page: 1,
              per_page: 10,
              parent: 0,
            },
          })
        );
        setSelectedId(0);
      }
    });
  };

  //
  const onClickReply = (id) => {
    setIsReplyOpen(true);
    setSelectedReplyId(id);
  };

  return (
    comments?.length > 0 && (
      <div
        className=""
        style={{
          display: styles?.comments_list?.display,
          gridTemplateColumns: dynamic_text_convert({
            main_text: styles?.comments_list?.columns_box_percentage,
            check_by: '-1%',
            value: '',
          }),
          ...get_dynamic_styles(styles?.comments_list),
        }}
      >
        {comments?.map((comment) => {
          const {
            user,
            replies,
            message,
            created_at,
            id,
            liked,
            unliked,
            likes,
            unlikes,
          } = comment;
          return (
            <div className="w-full ">
              {/* image  */}

              <div className=" flex items-center justify-start gap-2 w-full">
                {user?.profile_pic ? (
                  <img
                    className=" rounded-full h-8 w-8"
                    src={user?.profile_pic}
                    alt=""
                  />
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-8 w-8 text-gray-700"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                )}

                <div className="flex  items-center gap-2 ">
                  <p className="  description-paragraph">
                    {user?.first_name} {user?.last_name}
                  </p>
                  <p className=" description-caption ">
                    {changeDateFormat(created_at, 'MMM DD, YYYY')}
                  </p>
                </div>
              </div>
              <div style={get_dynamic_styles(styles?.message_style)}>
                <ReadingComposer
                  mainClass={
                    'prose prose-sm !sm:prose-sm !lg:prose-sm !xl:prose-sm'
                  }
                  contents={message}
                />

                <div className="mt-2 flex items-center justify-start gap-2">
                  <div className=" flex items-center gap-0.5">
                    <button
                      onClick={() => {
                        onLike({ id, isDowngrade: liked });
                      }}
                      className={cn(
                        ' flex items-center gap-1  p-2   bg-gray-100 hover:bg-gray-200 rounded-full ',
                        liked && 'text-primary',
                        selected_id === id &&
                          isCommentLikeUnlikeLoading &&
                          selected_type === 'like' &&
                          ' animate-pulse text-primary'
                      )}
                    >
                      {liked ? like_fill : like}
                      <span className="description-caption">{likes}</span>
                    </button>
                  </div>
                  <div className=" flex items-center gap-0.5">
                    <button
                      onClick={() => {
                        onUnLike({ id, isDowngrade: unliked });
                      }}
                      className={cn(
                        ' flex items-center gap-1  p-2   bg-gray-100 hover:bg-gray-200 rounded-full ',
                        unliked && 'text-primary',
                        selected_id === id &&
                          isCommentLikeUnlikeLoading &&
                          selected_type === 'unlike' &&
                          ' animate-pulse text-primary'
                      )}
                    >
                      {unliked ? dislike_fill : dislike}{' '}
                      <span className="description-caption">{unlikes}</span>
                    </button>
                  </div>
                  <button
                    onClick={() => onClickReply(id)}
                    className=" flex items-center gap-1  px-2 py-1   bg-transparent hover:bg-gray-200 rounded-button "
                  >
                    {reply_icon}Reply
                  </button>
                </div>
                {is_reply_open && selected_reply_id === id && (
                  <CommentBox
                    listing_details={listing_details}
                    styles={styles}
                    onFinish={() => {
                      setSelectedReplyId(0);
                      setIsReplyOpen(false);
                    }}
                    parent={selected_reply_id}
                  />
                )}
              </div>

              {/* replies */}
              <div
                style={{
                  display: styles?.replies_style?.display,
                  gridTemplateColumns: dynamic_text_convert({
                    main_text: styles?.replies_style?.columns_box_percentage,
                    check_by: '-1%',
                    value: '',
                  }),
                  ...get_dynamic_styles(styles?.replies_style),
                }}
              >
                {replies?.map((reply) => {
                  const {
                    user,
                    message,
                    created_at,
                    id,
                    liked,
                    unliked,
                    likes,
                    unlikes,
                  } = reply;
                  return (
                    <div>
                      {/* image  */}
                      <div className=" flex items-center justify-start gap-2">
                        {user?.profile_pic ? (
                          <img
                            className=" rounded-full h-8 w-8"
                            src={user?.profile_pic}
                            alt=""
                          />
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-8 w-8 text-gray-700"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                        )}

                        <div className="flex  items-center gap-2 ">
                          <p className="  description-paragraph">
                            {user?.first_name} {user?.last_name}
                          </p>
                          <p className=" description-caption ">
                            {changeDateFormat(created_at, 'MMM DD, YYYY')}
                          </p>
                        </div>
                      </div>
                      <div style={get_dynamic_styles(styles?.message_style)}>
                        <ReadingComposer
                          mainClass={
                            'prose prose-sm !sm:prose-sm !lg:prose-sm !xl:prose-sm'
                          }
                          contents={message}
                        />

                        <div className="mt-2 flex items-center justify-start gap-2">
                          <div className=" flex items-center gap-0.5">
                            <button
                              onClick={() => {
                                onLike({ id, isDowngrade: liked });
                              }}
                              className={cn(
                                ' flex items-center gap-1  p-2   bg-gray-100 hover:bg-gray-200 rounded-full ',
                                liked && 'text-primary',
                                selected_id === id &&
                                  isCommentLikeUnlikeLoading &&
                                  selected_type === 'like' &&
                                  ' animate-pulse text-primary'
                              )}
                            >
                              {liked ? like_fill : like}
                              <span className="description-caption">
                                {likes}
                              </span>
                            </button>
                          </div>
                          <div className=" flex items-center gap-0.5">
                            <button
                              onClick={() => {
                                onUnLike({ id, isDowngrade: unliked });
                              }}
                              className={cn(
                                ' flex items-center gap-1  p-2   bg-gray-100 hover:bg-gray-200 rounded-full ',
                                unliked && 'text-primary',
                                selected_id === id &&
                                  isCommentLikeUnlikeLoading &&
                                  selected_type === 'unlike' &&
                                  ' animate-pulse text-primary'
                              )}
                            >
                              {unliked ? dislike_fill : dislike}{' '}
                              <span className="description-caption">
                                {unlikes}
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    )
  );
};

export default CommentsList;
