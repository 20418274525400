import React from 'react';
import { TYPE_CONSTANT } from '../../../../constant/Web_constant';

const ListingListSkeleton = ({
  per_page = 20,
  listing_card_type = TYPE_CONSTANT.STYLES_CONFIGS?.listing_card?.card_type
    ?.type || 'list_card',
}) => {
  const gridItems = [];

  for (let i = 0; i < per_page; i++) {
    switch (listing_card_type) {
      case 'table_card':
        gridItems.push(
          <div
            key={i}
            className="w-full h-[20px] bg-[#3B3269] bg-opacity-[20%] rounded-card"
          ></div>
        );
        break;

      default:
        gridItems.push(
          <div
            key={i}
            className="w-full min-h-[260px] bg-[#3B3269] bg-opacity-[20%] rounded-card"
          ></div>
        );
        break;
    }
  }

  return (
    <div className="    w-full mx-auto overflow-hidden">
      <div className="grid  grid-cols-1  xxs:grid-cols-2  md:grid-cols-[repeat(auto-fill,minmax(calc((var(--bodyStylesMaxWidth)/var(--listingCardPerRow)-40px)),1fr))]    gap-5 justify-center">
        {gridItems}
      </div>
    </div>
  );
};

export default ListingListSkeleton;
