/* eslint-disable @typescript-eslint/ban-types */

import React from 'react';
import { cn } from '../../utils/classNames';
import AllIcons from '../../components/Shared/Constant/Icons/AllIcons';

const PrimaryButton = ({
  children,
  className,
  onClick,
  isLoading,
  isDisabled,
  type,
}: {
  children: React.ReactNode;
  className: string;
  onClick?: Function;
  isLoading?: boolean;
  isDisabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
}) => {
  return (
    <button
      id="primary-button"
      className={cn(
        [
          'btn-primary-fill ',
          isDisabled ? 'cursor-not-allowed' : 'cursor-pointer',
        ].join(' '),
        className
      )}
      type={type ?? 'button'}
      onClick={(e) => onClick && onClick(e)}
      disabled={isDisabled}
    >
      {children}
      {isLoading && AllIcons.loading_icon}
    </button>
  );
};

export default PrimaryButton;
