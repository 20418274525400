import React, { useEffect, useState } from 'react';
import { Sales_text } from '../../constant/Translation/Sales';
import Withdraw from './Withdraw';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import {
  WalletSelector,
  clearWalletError,
  getMangoPayWalletBalance,
  getMangopayWalletTransactions,
  getWalletBalance,
  getWalletTransitions,
} from '../../store/feature/wallet/walletSlice';
import { Wallet_text } from '../../constant/Translation/wallet';
import {
  walletTransitionStatus,
  walletTransitionType,
} from '../../constant/wallet/WalletStatus';
import WalletTransitions from './WalletTransitions';
import AddAmountINWallet from './AddAmount';
import { getKYCDetails } from '../../store/feature/KYC/kycSlice';
import Alert from '../Shared/PopUp/Alert';
import { useAppSelector } from '../../hooks/Redux';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import { getCookie } from 'cookies-next';

const WalletDetails = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const currency = router.query.code
    ? router.query.code
    : getCookie(`${TYPE_CONSTANT.DOMAIN}_default_currency`) ?? '';

  // use
  useEffect(() => {
    dispatch(getMangoPayWalletBalance({ currency: currency }));
    dispatch(getKYCDetails({ currency: currency }));
    dispatch(getMangopayWalletTransactions({ currency: currency }));
  }, [router]);

  // selector
  const { isError, errorMessage, wallets_list } = useSelector(WalletSelector);
  //
  const selected_wallet = wallets_list?.find(
    (wallet) => wallet.currency === router?.query?.code
  );

  return (
    <>
      <div className=" w-full  min-h-[100px] shadow-sm rounded">
        <div className=" w-full p-4 bg-transparent min-h-[100px] shadow">
          <div className="flex flex-col gap-1     items-center">
            <h1 className="  tex-primary  text-center text-2xl">
              {Wallet_text.wallet.balance}
            </h1>
            <p>{Wallet_text.wallet.your_balance}</p>
            <p className="  tex-primary">
              {selected_wallet?.balance?.formatted}
            </p>

            <div className=" justify-end flex items-center gap-4 my-4">
              <Withdraw />
              <AddAmountINWallet />
            </div>
          </div>
        </div>

        {/* Transitions */}
        <WalletTransitions />
      </div>
    </>
  );
};

export default WalletDetails;
