import React from 'react';
import ReportButton from './ReportButton';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';

const ReportUI = ({ styles, data, listing_details }) => {
  return (
    <div
      className="flex items-center justify-between flex-wrap "
      style={get_dynamic_styles(styles?.main_styles)}
    >
      <ReportButton listing_details={listing_details} styles={styles} />
    </div>
  );
};

export default ReportUI;
