/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';
import PopupUI from '../../Shared/PopUp/UI/PopupUI';
import { useRouter } from 'next/router';
import { loading_icon } from '../../Shared/Constant/Icons/AllIcons';

import TextInput from '../../../UI/FormFields/TextInput';
import SelectFromList from '../../../UI/FormFields/SelectFromList';
import { cards_type_list } from '../../../constant/card/Card';
import { NEW_CARD_FORMAT } from './Types/CardType';
import { starCardRegistration } from '../../../store/feature/card/cardSlice';
import { useAppDispatch } from '../../../hooks/Redux';
import DateInputPicker from '../../../UI/DatePicker/DateInputPicker';
import { Wallet_text } from '../../../constant/Translation/wallet';
import Paragraph from '../../../UI/Text/Paragraph/Paragraph';
import { getCookie } from 'cookies-next';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';

const NewCardPicker = ({
  card_form,
  setCardForm,
  cancelClick,
  submitClick,
  isLoading,
}: {
  card_form: NEW_CARD_FORMAT;
  setCardForm: React.Dispatch<React.SetStateAction<NEW_CARD_FORMAT>>;
  cancelClick: Function;
  submitClick: Function;
  isLoading: boolean;
}) => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const currency = router.query.code
    ? router.query.code
    : getCookie(`${TYPE_CONSTANT.DOMAIN}_default_currency`) ?? '';

  //
  //  value change handler
  const valueChangeHandler = <K extends keyof NEW_CARD_FORMAT>({
    value,
    key_name,
  }: {
    value: NEW_CARD_FORMAT[K];
    key_name: K;
  }) => {
    setCardForm((arg: any) => {
      // return card with new value
      return { ...arg, [key_name]: value };
    });
  };

  return (
    <PopupUI
      title={Wallet_text?.wallet.add_new_card}
      left_button={{
        title: Wallet_text?.wallet.cancel,
        click: (e) => {
          cancelClick();
        },
      }}
      right_button={{
        title: Wallet_text?.wallet.save_card,
        click: (e) => {
          submitClick(e);
        },
        icon: isLoading && loading_icon,
      }}
    >
      <div className="bg-white flex flex-col gap-4 p-6">
        <div className="   space-y-4">
          {/* Nationality  */}
          <SelectFromList
            title={Wallet_text?.wallet.card_type}
            current_value={card_form?.card_type}
            default_option_text={Wallet_text?.wallet.select_card_type}
            drop_down_items={cards_type_list}
            styles={{
              popover_style: ' h-[250px]',
            }}
            set_new_value={(value: string) => {
              // instant start reg callback

              dispatch(
                // @ts-ignore
                starCardRegistration({
                  post_data: { card_type: value, currency: currency },
                })
              );
              //
              valueChangeHandler({
                value: value,
                key_name: 'card_type',
              });
            }}
          />
          {/*  */}
          {/* Owner Name */}
          <TextInput
            title={Wallet_text?.wallet.card_number}
            type="number"
            current_value={card_form?.card_number}
            set_new_value={(vl) =>
              valueChangeHandler({
                value: vl,
                key_name: 'card_number',
              })
            }
          />

          <div className="grid  grid-cols-2 gap-4 ">
            {/* Owner Name */}
            <DateInputPicker
              title={Wallet_text?.wallet.expiry_date}
              current_value={card_form?.expiry_date}
              type="month"
              set_new_value={(vl) =>
                valueChangeHandler({
                  value: vl,
                  key_name: 'expiry_date',
                })
              }
            />
            {/* CVC Number */}
            <TextInput
              title={Wallet_text?.wallet.cvc}
              current_value={card_form?.cvx_number}
              type="password"
              autoComplete="new-password"
              set_new_value={(vl) =>
                valueChangeHandler({
                  value: vl,
                  key_name: 'cvx_number',
                })
              }
            />
          </div>
        </div>

        <Paragraph styles="text-center mx-auto text-gray-500 ">
          {Wallet_text.wallet.add_card_info}
        </Paragraph>
      </div>
    </PopupUI>
  );
};

export default NewCardPicker;
