/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { findRepeatName, getDatesArray } from '../../Shared/Constant/Constant';
import moment from 'moment';
import { useRouter } from 'next/dist/client/router';
import Modal from '../../Shared/Modal/Modal';
import OutsideClickHandler from 'react-outside-click-handler';
import SchedulesModal from './SchedulesModal';
import axios from 'axios';
import { Listing_text } from '../../../constant/Translation/Listing';
import {
  CardBoxDefaultStyles,
  CardMainDefaultStyles,
} from '../../../constant/default_styles/ProductDetailsStyles';
import { get_dynamic_styles_with_default_value } from '../../../constant/DynamicStylesWithDefaultValue';
import AllIcons from '../../Shared/Constant/Icons/AllIcons';

const Schedule2 = ({ listing_details, schedules, styles }) => {
  const [isScheduleOpen, setIsScheduleOpen] = useState(false);

  let dates = getDatesArray();
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDateIndex, setSelectedDateIndex] = useState(null);

  const [scheduleArray, setScheduleArray] = useState(null);

  const router = useRouter();

  useEffect(() => {
    setSelectedDate(moment(dates[0]).format('YYYY-MM-DD'));
    setSelectedDateIndex(0);
  }, []);

  return (
    <>
      {isScheduleOpen && (
        <Modal>
          <OutsideClickHandler
            onOutsideClick={() => {
              setIsScheduleOpen(false);
            }}
          >
            <div className="relative   max-w-md md:max-w-2xl w-full">
              <button
                className="absolute top  right-0 text-primary font-semibold text-xl mt-5 mr-5"
                onClick={() => setIsScheduleOpen(false)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-7 w-7 "
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
              <SchedulesModal
                dates={dates}
                setSelectedDate={setSelectedDate}
                selectedDateIndex={selectedDateIndex}
                setSelectedDateIndex={setSelectedDateIndex}
                scheduleArray={scheduleArray}
              />
            </div>
          </OutsideClickHandler>
        </Modal>
      )}
      <div
        className="    w-full  "
        style={get_dynamic_styles_with_default_value(
          styles?.main_styles,
          CardMainDefaultStyles
        )}
      >
        <div
          className="flex"
          style={get_dynamic_styles_with_default_value(
            styles?.box_styles,
            CardBoxDefaultStyles
          )}
        >
          {/* Header */}
          <div className=" w-full  flex items-center justify-between">
            <h2 className="heading-h2">
              {Listing_text.product.schedule_title}
            </h2>
            <button
              className="btn-primary-outline"
              onClick={() => {
                setIsScheduleOpen(true);
                if (selectedDate) {
                  axios
                    .post('/api/schedules', {
                      id: `${listing_details?.id}`,
                      bodyParam: { days: 30, start_at: selectedDate },
                    })
                    .then((res) => {
                      setScheduleArray(res.data.schedules_per_day);
                    });
                }
              }}
            >
              {Listing_text.product.view_schedule}
            </button>
          </div>

          {/* body */}
          <div className=" w-full flex flex-col gap-4">
            {schedules?.map((schedule) => {
              return (
                <div className="flex gap-4">
                  <div className="flex justify-start items-start">
                    {AllIcons.calender_icon}
                  </div>
                  <div className="  space-y-1">
                    <p className="description-paragraph  ">
                      {moment(schedule?.start_date).format('DD MMM  YY')}{' '}
                      {schedule?.end_date &&
                        `- ${moment(schedule?.end_date).format('DD MMM  YY')}`}
                    </p>
                    <p className=" description-caption ">
                      {schedule?.start_time} - {schedules?.[0]?.end_time}
                    </p>
                    <p className="description-caption  ">
                      {findRepeatName(schedule?.repeat_days)}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Schedule2;
