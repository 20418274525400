/* eslint-disable react/no-unescaped-entities */

import Heading1 from '../../../UI/Text/Headers/Heading1';
import { Wallet_text } from '../../../constant/Translation/wallet';
import Paragraph from '../../../UI/Text/Paragraph/Paragraph';
import AllIcons from '../../Shared/Constant/Icons/AllIcons';
import { useSelector } from 'react-redux';
import { WalletSelector } from '../../../store/feature/wallet/walletSlice';
import { useEffect, useState } from 'react';

const AddAmountSuccess = ({
  selected_payment_method,
}: {
  selected_payment_method: any;
}) => {
  const { pay_in_bank_wire } = useSelector(WalletSelector);

  const [isCopiedItem, setIsCopiedItem] = useState(null);

  useEffect(() => {
    if (isCopiedItem) {
      const timer = setTimeout(() => {
        setIsCopiedItem(null);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [isCopiedItem]);

  return (
    <div className=" max-w-lg mx-auto bg-white flex flex-col gap-4 p-6 ">
      <Heading1 styles=" md:text-[32px] my-6">
        {Wallet_text.wallet.add_amount_success}
      </Heading1>
      <Paragraph>
        {selected_payment_method?.id === 1
          ? Wallet_text.wallet.add_amount_success_message_by_bank
          : Wallet_text.wallet.add_amount_success_message}
      </Paragraph>
      {/* Bank Details */}

      {selected_payment_method?.id === 1 && (
        <section className=" flex flex-col gap-1">
          {/*  */}
          <div className=" grid grid-cols-12 ">
            <p className=" text-lg font-bold col-span-4">
              {Wallet_text?.kyc.wire_reference}:
            </p>
            <p className=" text-base my-auto col-span-6 ">
              {pay_in_bank_wire?.wire_reference}
            </p>
            <button
              className="   col-span-2 text-gray-500 hover:text-primary place-self-end my-auto"
              onClick={() => {
                navigator.clipboard.writeText(pay_in_bank_wire?.wire_reference);
                setIsCopiedItem({ item: 'wire_reference' });
              }}
            >
              {isCopiedItem?.item === 'wire_reference'
                ? AllIcons.copy_done
                : AllIcons.copy_icon}
            </button>
          </div>
          {/*  */}
          <div className=" grid grid-cols-12 ">
            <p className=" text-lg font-bold col-span-4">
              {Wallet_text?.kyc.owner_name}:
            </p>
            <p className=" text-base my-auto col-span-6">
              {pay_in_bank_wire?.owner_name}
            </p>
            <button
              className="   col-span-2 text-gray-500 hover:text-primary place-self-end my-auto"
              onClick={() => {
                navigator.clipboard.writeText(pay_in_bank_wire?.owner_name);
                setIsCopiedItem({ item: 'owner_name' });
              }}
            >
              {isCopiedItem?.item === 'owner_name'
                ? AllIcons.copy_done
                : AllIcons.copy_icon}
            </button>
          </div>
          {/*  */}
          <div className=" grid grid-cols-12 ">
            <p className=" text-lg font-bold col-span-4">
              {Wallet_text?.kyc.address}:
            </p>
            <p className=" text-base my-auto col-span-6">
              {pay_in_bank_wire?.address}
            </p>
            <button
              className="   col-span-2 text-gray-500 hover:text-primary place-self-end my-auto"
              onClick={() => {
                navigator.clipboard.writeText(pay_in_bank_wire?.address);
                setIsCopiedItem({ item: 'address' });
              }}
            >
              {isCopiedItem?.item === 'address'
                ? AllIcons.copy_done
                : AllIcons.copy_icon}
            </button>
          </div>
          {/*  */}
          <div className=" grid grid-cols-12 ">
            <p className=" text-lg font-bold col-span-4">
              {Wallet_text?.kyc.iban}:
            </p>
            <p className=" text-base my-auto col-span-6">
              {' '}
              {pay_in_bank_wire?.iban}
            </p>
            <button
              className="   col-span-2 text-gray-500 hover:text-primary  place-self-end"
              onClick={() => {
                navigator.clipboard.writeText(pay_in_bank_wire?.iban);
                setIsCopiedItem({ item: 'iban' });
              }}
            >
              {isCopiedItem?.item === 'iban'
                ? AllIcons.copy_done
                : AllIcons.copy_icon}
            </button>
          </div>
          {/*  */}
          <div className=" grid grid-cols-12 ">
            <p className=" text-lg font-bold col-span-4">
              {Wallet_text?.kyc.bic}:
            </p>
            <p className=" text-base my-auto col-span-6">
              {' '}
              {pay_in_bank_wire?.bic}
            </p>
            <button
              className="   col-span-2 text-gray-500 hover:text-primary place-self-end "
              onClick={() => {
                navigator.clipboard.writeText(pay_in_bank_wire?.bic);
                setIsCopiedItem({ item: 'bic' });
              }}
            >
              {isCopiedItem?.item === 'bic'
                ? AllIcons.copy_done
                : AllIcons.copy_icon}
            </button>
          </div>
        </section>
      )}
    </div>
  );
};

export default AddAmountSuccess;
