/* eslint-disable react/prop-types */
import React from 'react';
import Image from 'next/image';
import { useSelector } from 'react-redux';
import { configsSelector } from '../../../store/feature/configsSlice';
import * as constant from '../../Shared/Constant/TextConstant/listingDetailsConstant';
const ButtonTypeVariant2 = ({
  variants,
  setSelectedVariant,
  selectedVariant,
  listing_details,
}) => {
  const { MARKETPLACE_MODULES, listings_configs } =
    useSelector(configsSelector);

  return (
    <div className="w-full flex  items-center gap-5   flex-wrap ">
      {variants.map((item) => {
        return (
          <div
            key={item.id}
            className={
              selectedVariant !== item.id
                ? ' min-w-[100px] px-4 py-2   rounded-card   overflow-hidden  flex items-center shadow-c-sm  border border-seceondary cursor-pointer'
                : ' min-w-[100px] px-4 py-2   rounded-card   overflow-hidden  flex items-center  first-of-type:shadow-c-sm cursor-pointer transition duration-700  border border-seceondary  ring ring-primary ring-offset-1'
            }
            onClick={() => setSelectedVariant(item)}
          >
            <p className="text-black font-semibold">{item.title}</p>
          </div>
        );
      })}
    </div>
  );
};

export default ButtonTypeVariant2;
