export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = function () {
      resolve(reader.result.toString().replace(/^data:(.*,)?/, ''));
    };

    reader.onerror = function (error) {
      reject('');
    };
  });
}
