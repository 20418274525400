import Script from 'next/script';
import React from 'react';
import NotFoundAlert2 from '../Shared/Default UI/NotFoundAlert2';

const TallyContactUSForm = ({ data, styles }) => {
  return (
    <>
      {!data?.tally_src && (
        <NotFoundAlert2
          alert_text={'Provide  tally form URL to enable the form!'}
          className={' max-w-sm mx-auto'}
        />
      )}
      {data?.tally_src && (
        <iframe
          data-tally-src={data?.tally_src}
          width="100%"
          height="284"
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
          title="Contact form"
        ></iframe>
      )}

      <Script
        id="tally-js"
        src="https://tally.so/widgets/embed.js"
        onLoad={() => {
          Tally.loadEmbeds();
        }}
      />
    </>
  );
};

export default TallyContactUSForm;
