import React, { useEffect, useState } from 'react';

import Paragraph from '../../../UI/Text/Paragraph/Paragraph';

import PrimaryButton from '../../../UI/Buttons/PrimaryButton';
import SecondaryButton from '../../../UI/Buttons/SecondaryButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  KYCSelector,
  clearKYCError,
  clearKYCSubmission,
  getKYCDetails,
  kycFullFOrmSubmission,
} from '../../../store/feature/KYC/kycSlice';
import { changeDateFormatFromDate } from '../../../utils/date';
import { delete_item_from_local_storage } from '../../../utils/localStorage';
import { local_storage_keys } from '../../Localstorage/LocalStorageKeys';
import {
  BANK_DETAILS_FORMAT,
  DOCUMENT_DETAILS_FORMAT,
  KYC_LEGAL_PROFILE_DETAILS_FORMAT,
  KYC_PROFILE_DETAILS_FORMAT,
} from '../../KYC/types/KYCTypes';
import StepsUI from '../../../UI/Steps/StepsUI';
import KYCProfile from '../../KYC/KYCProfile';
import KYCBankDetails from '../../KYC/BankDetails';
import KYCDocument from '../../KYC/KYCDocument';
import KYCMessage from '../../KYC/KYCMessage';
import Alert from '../../Shared/PopUp/Alert';
import { useAppDispatch } from '../../../hooks/Redux';
import { Wallet_text } from '../../../constant/Translation/wallet';
import { getAllCountriesList } from '../../../store/feature/countrySlice';
import AllIcons from '../../Shared/Constant/Icons/AllIcons';
import KYCLegalProfile from '../../KYC/KYCLegalProfile';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import { getCookie } from 'cookies-next';
import { useRouter } from 'next/router';
import KYCLegalDocument from '../../KYC/KYCLegalDocument';
import { General_text } from '../../../constant/Translation/General';

const KYCSteps = ({ sidePanOpen, setSidePanOpen, setISKYCOpen }) => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const currency = router.query.code
    ? router.query.code
    : getCookie(`${TYPE_CONSTANT.DOMAIN}_default_currency`) ?? '';

  //
  const account_category_id =
    getCookie(`${TYPE_CONSTANT.DOMAIN}_storeAccountCategoryID`) ?? '';
  const account_type = TYPE_CONSTANT.PAYMENT_CONFIGS?.[
    'mangopay_user_type_category_id'
  ] as string;

  const selected_acc_type = account_category_id
    ? // @ts-ignore
      account_type?.[account_category_id]
    : 'natural';

  const {
    isSubmissionLoading,
    isSubmissionSuccess,
    isError,
    kyc_details,
    errorMessage,
  } = useSelector(KYCSelector);

  //
  const [step_info, setStepInfo] = useState({
    current_step: 1,
    next_step: 2,
    prev_step: 0,
  });

  // initial step setup effect
  useEffect(() => {
    if (
      kyc_details?.mangopay_kyc_submission &&
      kyc_details?.mangopay_kyc_status_message
    ) {
      setStepInfo({
        current_step: 4,
        next_step: 0,
        prev_step: 0,
      });
    }
  }, [kyc_details]);

  //
  const changeStep = ({ current_step, next_step, prev_step }) => {
    setStepInfo({
      current_step: current_step,
      next_step: next_step ?? current_step + 1,
      prev_step: prev_step ?? current_step - 1,
    });
  };

  // Profile details
  const [profile_details, setProfileDetails] =
    useState<KYC_PROFILE_DETAILS_FORMAT>({
      birth_date: null,
      nationality_country_code: null,
      residence_country_code: null,
    });
  // Profile details
  const [legal_profile_details, setLegalProfileDetails] =
    useState<KYC_LEGAL_PROFILE_DETAILS_FORMAT>({
      legal_name_of_the_entity: null,
      legal_type_of_the_entity: null,
      legal_representative_first_name: null,
      legal_representative_last_name: null,
      legal_representative_birthday: null,
      legal_representative_nationality: null,
      legal_representative_country_of_residence: null,
      company_registration_number: null,
      email_address_of_the_company: null,
      address_1: null,
      address_2: null,
      city: null,
      state: null,
      postal_code: null,
      country: null,
    });

  // bank details
  const [bank_details, setBankDetails] = useState<BANK_DETAILS_FORMAT>({
    owner_name: null,
    iban: null,
    address_1: null,
    address_2: null,
    city: null,
    state: null,
    country: null,
    postal_code: null,
  });

  //document details
  const [document_details, setDocumentDetails] =
    useState<DOCUMENT_DETAILS_FORMAT>({
      file: null,
      file_url: null,
      file_name: null,
      base_64: null,
    });
  //registration_proof_details
  const [registration_proof_details, setRegistrationProofDetails] =
    useState<DOCUMENT_DETAILS_FORMAT>({
      file: null,
      file_url: null,
      file_name: null,
      base_64: null,
    });
  //articles_of_association
  const [articles_of_association_document, setArticlesOfAssociationDetails] =
    useState<DOCUMENT_DETAILS_FORMAT>({
      file: null,
      file_url: null,
      file_name: null,
      base_64: null,
    });
  //SHAREHOLDER_DECLARATION
  const [share_holder_declaration_document, setShareholderDeclarationDetails] =
    useState<DOCUMENT_DETAILS_FORMAT>({
      file: null,
      file_url: null,
      file_name: null,
      base_64: null,
    });

  //form_submission_handler
  const form_submission_handler = () => {
    // docs check
    if (
      document_details?.base_64 === null ||
      registration_proof_details?.base_64 === null ||
      articles_of_association_document?.base_64 === null
    ) {
      setOpenAlert(true);
      setMessage(Wallet_text.kyc.alert_doc_upload);
      setAlert_type('error');
      return;
    }

    // profile details

    //
    const personal_details = {
      birth_date: changeDateFormatFromDate(
        profile_details?.birth_date,
        'YYYY-MM-DD'
      ),
      nationality_country_code: profile_details?.nationality_country_code,
      residence_country_code: profile_details?.residence_country_code,
    };

    const document = {
      type: 'IDENTITY_PROOF',
      file_body: document_details?.base_64,
    };

    const registration_proof = {
      type: 'REGISTRATION_PROOF',
      file_body: registration_proof_details?.base_64,
    };
    const articles_of_association = {
      type: 'ARTICLES_OF_ASSOCIATION',
      file_body: articles_of_association_document?.base_64,
    };
    const shareholder_declaration = {
      type: 'SHAREHOLDER_DECLARATION',
      file_body: share_holder_declaration_document?.base_64,
    };

    const bank_details_data: {
      iban?: string;
      short_code?: string;
      account_number?: string;
      owner_name: string;
      address: any;
      currency?: string;
    } = {
      owner_name: bank_details?.owner_name,
      address: {
        address_line_1: bank_details?.address_1,
        address_line_2: bank_details?.address_2,
        state: bank_details?.state,
        city: bank_details?.city,
        post_code: bank_details?.postal_code,
        country: bank_details?.country,
      },
    };

    if (currency) {
      bank_details_data.currency = currency as string;
    }
    if (bank_details?.iban) {
      bank_details_data.iban = bank_details?.iban;
    }
    if (bank_details?.short_code && bank_details?.account_number) {
      bank_details_data.short_code = bank_details?.short_code;
      bank_details_data.account_number = bank_details?.account_number;
    }

    // dispatch function call
    dispatch(
      // @ts-ignore
      kycFullFOrmSubmission({
        personal_details:
          selected_acc_type === 'natural'
            ? { ...personal_details, currency: currency }
            : { ...legal_profile_details, currency: currency },
        bank_details: bank_details_data,
        document,
        registration_proof:
          selected_acc_type === 'legal' ? registration_proof : null,
        articles_of_association:
          selected_acc_type === 'legal' ? articles_of_association : null,
        shareholder_declaration:
          selected_acc_type === 'legal' ? shareholder_declaration : null,
      })
    );
  };

  //
  useEffect(() => {
    if (isSubmissionSuccess) {
      dispatch(
        // @ts-ignore
        getKYCDetails({ currency: currency })
      );
      dispatch(clearKYCSubmission());
      delete_item_from_local_storage({
        key_name: 'bank_details',
      });
      delete_item_from_local_storage({
        key_name: 'profile_details',
      });
      delete_item_from_local_storage({
        key_name: 'legal_profile_details',
      });
      changeStep({
        current_step: 4,
        next_step: 0,
        prev_step: 0,
      });
    }
  }, [isSubmissionSuccess]);

  //
  // Alert
  const [open_alert, setOpenAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [alert_type, setAlert_type] = useState('success');

  // Error handling
  useEffect(() => {
    if (isError) {
      setAlert_type('error');
      setOpenAlert(true);
      setMessage(errorMessage);
    }
  }, [isError]);
  useEffect(() => {
    // @ts-ignore
    dispatch(getAllCountriesList({}));
  }, [0]);
  return (
    <>
      {/* Alert */}
      <Alert
        className={` fixed z-50 top-0 left-0 right-0  w-full  xs:w-[500px] mx-auto mt-5 `}
        type={alert_type}
        open={open_alert}
        setOpen={setOpenAlert}
        isOpenFunction={undefined}
        funcCallOnClose={() => {
          dispatch(clearKYCError());
        }}
      >
        <p>{message}</p>
      </Alert>
      {/* Form note */}

      {/* close button */}
      <button
        className=" absolute right-3 md:left-3 w-10 h-10 flex items-center justify-center top-3 p-3 rounded-full shadow-md bg-white  z-20 text-primary hover:shadow-xl duration-200 "
        onClick={() => {
          dispatch(
            // @ts-ignore
            getKYCDetails({ currency: currency })
          );
          dispatch(clearKYCSubmission());
          changeStep({ current_step: 1, next_step: 2, prev_step: 0 });
          setSidePanOpen(false);
        }}
      >
        {AllIcons.close_icon}
      </button>

      {/* Steps UI */}
      <StepsUI
        styles={{
          components_section_style: 'max-w-lg mx-auto px-4',
          steps_style: 'grid grid-cols-4',
          description_text_style: 'max-w-lg mx-auto px-4 my-8',
        }}
        description_text={Wallet_text.kyc.kyc_description}
        steps={[
          {
            title:
              selected_acc_type === 'natural'
                ? Wallet_text.kyc.profile
                : Wallet_text.kyc.company_profile,
            link: undefined,
            step_code: 1,
          },
          { title: Wallet_text.kyc.bank, link: undefined, step_code: 2 },
          { title: Wallet_text.kyc.document, link: undefined, step_code: 3 },
          { title: Wallet_text.kyc.status, link: undefined, step_code: 4 },
        ]}
        steps_components={[
          {
            step_code: 1,
            component:
              selected_acc_type === 'natural' ? (
                <KYCProfile
                  profile_details={profile_details}
                  setProfileDetails={setProfileDetails}
                  group_name="profile_details"
                />
              ) : (
                <KYCLegalProfile
                  profile_details={legal_profile_details}
                  setProfileDetails={setLegalProfileDetails}
                  group_name="legal_profile_details"
                />
              ),
          },
          {
            step_code: 2,
            component: (
              <KYCBankDetails
                bank_details={bank_details}
                setBankDetails={setBankDetails}
                group_name="bank_details"
              />
            ),
          },
          {
            step_code: 3,
            component:
              selected_acc_type === 'natural' ? (
                <KYCDocument
                  kyc_document={document_details}
                  setDocumentDetails={setDocumentDetails}
                  group_name="document_details"
                />
              ) : (
                <KYCLegalDocument
                  kyc_document={document_details}
                  setDocumentDetails={setDocumentDetails}
                  registration_proof_details={registration_proof_details}
                  setRegistrationProofDetails={setRegistrationProofDetails}
                  articles_of_association_details={
                    articles_of_association_document
                  }
                  setArticlesOfAssociationDetails={
                    setArticlesOfAssociationDetails
                  }
                  share_holder_declaration_details={
                    share_holder_declaration_document
                  }
                  setShareholderDeclarationDetails={
                    setShareholderDeclarationDetails
                  }
                  group_name="document_details"
                />
              ),
          },
          {
            step_code: 4,
            component: (
              <KYCMessage
                message={kyc_details?.mangopay_kyc_status_message}
                mangopay_kyc_status={kyc_details?.mangopay_kyc_status}
              />
            ),
          },
        ]}
        steps_buttons={[
          // * step 1 button
          {
            button_component: (
              <div className=" max-w-md mt-20 w-full flex item-center justify-between">
                <SecondaryButton
                  onClick={() => {
                    changeStep({ current_step: 1, next_step: 2, prev_step: 0 });
                    setSidePanOpen(false);
                  }}
                  className=" px-10"
                >
                  {Wallet_text?.kyc.cancel}
                </SecondaryButton>
                <PrimaryButton
                  onClick={() => {
                    changeStep({
                      current_step: 2,
                      next_step: 3,
                      prev_step: 1,
                    });
                  }}
                  className=" px-10"
                >
                  {Wallet_text?.kyc.next}
                </PrimaryButton>
              </div>
            ),
            step_code: 1,
          },
          // * step 2 button
          {
            button_component: (
              <div className=" max-w-md mt-20 w-full flex item-center justify-between">
                <SecondaryButton
                  onClick={() => {
                    changeStep({ current_step: 1, next_step: 2, prev_step: 0 });
                  }}
                  className=" px-10"
                >
                  {Wallet_text?.kyc.back}
                </SecondaryButton>
                <PrimaryButton
                  onClick={() => {
                    changeStep({
                      current_step: 3,
                      next_step: 4,
                      prev_step: 2,
                    });
                  }}
                  className=" px-10"
                >
                  {Wallet_text?.kyc.next}
                </PrimaryButton>
              </div>
            ),
            step_code: 2,
          },

          // * step 3 buttons
          {
            button_component: (
              <div className=" max-w-xl mt-20 w-full flex item-center justify-between">
                <SecondaryButton
                  onClick={() => {
                    changeStep({
                      current_step: 1,
                      next_step: 2,
                      prev_step: 0,
                    });
                  }}
                  className=" px-10"
                >
                  {Wallet_text?.kyc.back}
                </SecondaryButton>
                <PrimaryButton
                  onClick={() => {
                    form_submission_handler();
                  }}
                  className=" px-10"
                  isLoading={isSubmissionLoading}
                  isDisabled={isSubmissionLoading}
                >
                  {Wallet_text?.kyc.submit}
                </PrimaryButton>
              </div>
            ),
            step_code: 3,
          },
          // * step 4 buttons
          {
            button_component: (
              <div className=" max-w-xl mt-20 w-full flex item-center justify-between">
                {kyc_details?.mangopay_kyc_submission &&
                kyc_details?.mangopay_kyc_check_failed ? (
                  <SecondaryButton
                    onClick={() => {
                      changeStep({
                        current_step: 1,
                        next_step: 2,
                        prev_step: 0,
                      });
                    }}
                    className=" px-10"
                  >
                    {Wallet_text?.kyc.re_submit}
                  </SecondaryButton>
                ) : (
                  <div></div>
                )}
                <PrimaryButton
                  onClick={() => {
                    dispatch(
                      // @ts-ignore
                      getKYCDetails({ currency: currency })
                    );
                    dispatch(clearKYCSubmission());
                    changeStep({ current_step: 1, next_step: 2, prev_step: 0 });
                    setSidePanOpen(false);
                  }}
                  className=" px-10"
                >
                  {Wallet_text?.kyc.close}
                </PrimaryButton>
              </div>
            ),
            step_code: 4,
          },
        ]}
        current_step={step_info?.current_step}
        next_step={step_info?.next_step}
        prev_step={step_info?.prev_step}
      />
    </>
  );
};

export default KYCSteps;
