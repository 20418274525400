import React, { useState } from 'react';
import SidePan from '../Shared/Modal/SidePan';
import { Wallet_text } from '../../constant/Translation/wallet';
import AddAmountSteps from './AddAmount/AddAmountSteps';
import { cn } from '../../utils/classNames';
import { useAppDispatch } from '../../hooks/Redux';
import {
  onCloseSidebar,
  onOpenSidebar,
} from '../../store/feature/SidebarSlice';

const AddAmountINWallet = ({ button_style }) => {
  const [sidePanOpen, setSidePanOpen] = useState(false);
  const dispatch = useAppDispatch();

  //handleSidepanOpen
  const handleSidepanOpen = (e) => {
    e.stopPropagation();
    setSidePanOpen(true);
  };

  const onClickButton = (e) => {
    e.stopPropagation();

    dispatch(
      onOpenSidebar({
        sidebar_id: 'amount-sidepan',
        modal_section_styles:
          'fixed  ltr:right-0 rtl:left-0 bottom-0 lg:top-0 lg:my-auto   z-[60]  block flex items-center  justify-center  transform bg-white rounded shadow-lg   max-w-full md:max-w-4xl w-full   rounded-t-[40px]   lg:rounded-card  overflow-hidden',
        modal_inside_section_styles:
          'w-full   max-h-[80VH] md:max-h-full  h-full lg:scrollbar-thin   lg:scrollbar-track-transparent overflow-auto  lg:scrollbar-thumb-transparent  ',
        is_close_by_outer_click: true,

        sidebar_inside_component: (
          <AddAmountSteps
            sidePanOpen={sidePanOpen}
            closeSidebar={() => dispatch(onCloseSidebar())}
          />
        ),
      })
    );
  };

  return (
    <>
      {/* button */}
      <button
        className={cn('btn-primary-fill ', button_style)}
        onClick={onClickButton}
      >
        {Wallet_text.wallet.add_amount}
      </button>
      {/* Sidepan 1st step */}
    </>
  );
};

export default AddAmountINWallet;
