/* eslint-disable react/prop-types */
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkout_text } from '../../../constant/Translation/Cart_text';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import {
  applyCouponToCart,
  cartList,
  cartSelector,
  deleteCouponFromCart,
} from '../../../store/feature/cartSlice';
import ItemQuantity from './ItemQuantity';
import OrderItems from './OrderItems';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';

const GlobalOrderSummary = ({ data, styles }) => {
  const [coupon_code, setCouponCode] = useState('');
  const dispatch = useDispatch();

  const { cart, cart_details } = useSelector(cartSelector);

  return cart_details ? (
    <div className=" w-full  " style={get_dynamic_styles(styles?.main_styles)}>
      {data?.show_title && (
        <p className="text-primary text-xl leading-6 font-medium mb-6  ">
          {Checkout_text.cart.order_summery}
        </p>
      )}

      {/* Price Details */}
      {cart_details && cart_details?.length !== 0 && (
        <div className=" w-full">
          {/* */}
          {cart.pricing_items.length > 0 ? (
            <>
              {cart.pricing_items.map((pricing_item) => {
                if (pricing_item?.display) {
                  return (
                    <p
                      className={[
                        ' flex justify-between items-center mb-2 text-base leading-6 font-semibold text-default_gray',
                      ].join(' ')}
                    >
                      <span>{pricing_item.name}</span>
                      <div className="flex items-center gap-2">
                        {pricing_item?.regular && (
                          <span className=" flex flex-wrap items-center    line-through">
                            <span
                              className={[
                                'text-base leading-6 font-semibold text-gray-600 ',
                              ].join(' ')}
                            >
                              {pricing_item?.regular?.formatted}
                            </span>
                          </span>
                        )}
                        <span className=" flex flex-wrap items-center">
                          <span
                            className={[
                              'text-base leading-6 font-semibold ',
                              pricing_item?.type === 'sub_total'
                                ? ' text-black'
                                : pricing_item?.type === 'customer_coupon'
                                ? 'text-success'
                                : 'text-gray-600 ',
                            ].join(' ')}
                          >
                            {pricing_item?.buying?.formatted}
                          </span>
                        </span>
                      </div>
                    </p>
                  );
                }
              })}
              <p
                className={[
                  ' flex justify-between items-center  text-xl leading-6 font-semibold text-black',
                ].join(' ')}
              >
                <span>{Checkout_text.cart.grand_total}</span>{' '}
                <span className=" flex flex-wrap items-center">
                  <span className="text-base leading-6 font-semibold text-black">
                    {cart.grand_total?.formatted}
                  </span>
                </span>
              </p>
            </>
          ) : (
            <>
              <p className="text-base leading-6 font-semibold text-default_gray flex justify-between items-center mb-2">
                <span>{Checkout_text.cart.total}</span>{' '}
                <span className=" flex flex-wrap items-center">
                  <span className="text-base leading-6 font-semibold text-gray-600">
                    {cart.list_total?.formatted}
                  </span>
                </span>
              </p>
              <p className="text-base leading-6 font-semibold text-default_gray flex justify-between items-center mb-2">
                <span>{Checkout_text.cart.shipping}</span>{' '}
                <span className=" flex flex-wrap items-center">
                  <span className="text-base leading-6 font-semibold text-gray-600">
                    {cart.shipping_total?.formatted}
                  </span>
                </span>
              </p>
              <p className="text-xl leading-6 font-semibold text-black flex justify-between items-center">
                <span>{Checkout_text.cart.subtotal}</span>{' '}
                <span className=" flex flex-wrap items-center">
                  <span className="text-xl leading-6 font-semibold text-black">
                    {cart.grand_total?.formatted}
                  </span>
                </span>
              </p>{' '}
            </>
          )}
        </div>
      )}
    </div>
  ) : (
    <div className="  border bg-[#3B3269] bg-opacity-[10%] shadow rounded-card   p-4   w-full mx-auto">
      <div className="animate-pulse flex space-x-4">
        <div className="flex-1 space-y-4 py-1">
          <div className="h-4 bg-[#3B3269] bg-opacity-[20%] rounded-card  w-3/4"></div>
          <div className="space-y-2">
            <div className="h-4 bg-[#3B3269] bg-opacity-[20%] rounded"></div>
            <div className="h-4 bg-[#3B3269] bg-opacity-[20%] rounded-card  w-5/6"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlobalOrderSummary;
