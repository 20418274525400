import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/dist/client/router';
import { useSelector } from 'react-redux';
import { authSelector } from '../../../store/feature/authSlice';
import {
  get_order_details,
  orderSelector,
} from '../../../store/feature/orderSlice';
import PopUp from '../../../components/Shared/PopUp/PopUp';
import OutsideClickHandler from 'react-outside-click-handler';
import SuccessPopUp from '../../../components/Shared/PopUp/Success';
import { add_review } from '.././../../components/AddReview/send_review';
import { Review_text } from '../../../constant/Translation/Review';
import RatingSelect from './RatingSelect';
import Feedback from './Feedback';
import { get_dynamic_styles_with_default_value } from '../../../constant/DynamicStylesWithDefaultValue';
import {
  CardBoxDefaultStyles,
  CardMainDefaultStyles,
} from '../../../constant/default_styles/ProductDetailsStyles';
import {
  getListingReviews,
  listingDetails,
} from '../../../store/feature/listingSlice';

const AddReviewForm = ({ styles, listing_details }) => {
  const dispatch = useDispatch();
  const router = useRouter();

  useState(false);
  const [rating_value, setRating_value] = useState(5);
  const [rating_title, setRating_title] = useState('Excellent Service');
  const [rating_description, setRating_description] = useState(null);
  const [imagePath, setImagePath] = useState([]);
  const [files, setFiles] = useState([]);
  const [fullFile, setFullFile] = useState([]);

  const [showError, setShowError] = useState(false);
  const [error_message, setError_message] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [success_message, setSuccess_message] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const { auth_key } = useSelector(authSelector);

  useEffect(() => {
    dispatch(
      listingDetails({
        ...router?.query,
      })
    );
    dispatch(
      getListingReviews({
        params: {
          type: 'listings',
          id: listing_details?.id,
          page: 1,
        },
      })
    );
  }, [showSuccess]);
  const { order_details } = useSelector(orderSelector);

  const closePopUP = () => {
    setShowError(false);
    setError_message('');
    setShowSuccess(false);
    setSuccess_message('');
  };

  // send review
  const send_review = () => {
    add_review(
      listing_details?.id,
      'listings',
      rating_value,
      rating_title,
      rating_description,
      imagePath,
      files,
      fullFile,
      setShowError,
      setError_message,
      setShowSuccess,
      setSuccess_message,
      setIsLoading
    );
  };

  return (
    <>
      {showError && (
        <OutsideClickHandler
          onOutsideClick={() => {
            showError && (setShowError(false), setError_message(''));
          }}
        >
          <div className="fixed z-50 top-0 left-0 right-0  w-full  xs:w-[500px] mx-auto mt-5">
            <div className="">
              <PopUp message={error_message} closePopUP={closePopUP} />
            </div>
          </div>
        </OutsideClickHandler>
      )}
      {showSuccess && (
        <OutsideClickHandler
          onOutsideClick={() => {
            showSuccess && (setShowSuccess(false), setSuccess_message(''));
          }}
        >
          <div className="fixed z-50 top-0 left-0 right-0  w-full  xs:w-[500px] mx-auto mt-5">
            <div className="">
              <SuccessPopUp message={success_message} closePopUP={closePopUP} />
            </div>
          </div>
        </OutsideClickHandler>
      )}
      {/* <CustomLoading/> */}
      {listing_details?.my_review?.id === undefined && (
        <div
          className=" w-full "
          style={get_dynamic_styles_with_default_value(
            styles?.main_styles,
            CardMainDefaultStyles
          )}
        >
          <div
            style={get_dynamic_styles_with_default_value(
              styles?.main_styles,
              CardBoxDefaultStyles
            )}
          >
            <RatingSelect setRating_value={setRating_value} />
            <Feedback
              rating_title={rating_title}
              setRating_title={setRating_title}
              rating_description={rating_description}
              setRating_description={setRating_description}
              imagePath={imagePath}
              setImagePath={setImagePath}
              files={files}
              setFiles={setFiles}
              fullFile={fullFile}
              setFullFile={setFullFile}
              send_review={send_review}
              isLoading={isLoading}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default AddReviewForm;
