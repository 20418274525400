import React, { useEffect, useState } from 'react';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { useDispatch, useSelector } from 'react-redux';
import { get_data_by_current_language } from '../../utils/GetDataByLanguage';
import {
  filterSelector,
  onFilterParamsUpdate,
} from '../../store/feature/filter/filterSlice';
import Slider from 'rc-slider';
import useGeoLocation from '../../hooks/useGeoLocation';
import { useRouter } from 'next/router';
import { getMainPath } from '../../utils/getMainPath';

const AreaRange = ({ data, styles, filer_item }) => {
  const dispatch = useDispatch();
  const {
    filter_type,
    title,
    translated_data,
    category_ids,
    is_show_title,
    is_depend_on_search,
    max_distance,
  } = filer_item;
  const { filter_params, queries } = useSelector(filterSelector);
  const router = useRouter();

  const current_location = useGeoLocation();

  const onSliderChange = (distance) => {
    dispatch(
      onFilterParamsUpdate({
        max_distance: distance,
        latitude: current_location?.lat,
        longitude: current_location?.lon,
        callOnFilterUpdate: (updated_quires) => {
          onUpdateQuires(updated_quires);
        },
      })
    );
  };

  useEffect(() => {
    dispatch(
      onFilterParamsUpdate({
        max_distance: max_distance,
        latitude: current_location?.lat,
        longitude: current_location?.lon,
        callOnFilterUpdate: (updated_quires) => {
          onUpdateQuires(updated_quires);
        },
      })
    );
  }, []);

  const onUpdateQuires = (updated_quires) => {
    if (is_depend_on_search === false) {
      router.push({
        pathname: getMainPath(router.asPath),
        query: updated_quires ?? queries,
      });
    }
  };

  return (
    <div style={get_dynamic_styles(styles?.filter_component_style)}>
      {title && is_show_title && (
        <p style={get_dynamic_styles(styles?.filter_title_style)}>
          {get_data_by_current_language({
            data_list: translated_data,
            key_name: 'title',
            default_data: title,
          })}
        </p>
      )}

      <Slider
        className="text-primary"
        allowCross={false}
        max={max_distance ?? 0}
        value={queries?.max_distance ?? max_distance}
        defaultValue={queries?.max_distance ?? max_distance}
        onChange={onSliderChange}
      />
    </div>
  );
};

export default AreaRange;
