/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { authSelector } from '../../../store/feature/authSlice';
import {
  addToCart,
  deleteCart,
  cartList,
  cartSelector,
  clearCartState,
  getCurrencies,
} from '../../../store/feature/cartSlice';
import PopUp from '../../Shared/PopUp/PopUp';
import OutsideClickHandler from 'react-outside-click-handler';
import { configsSelector } from '../../../store/feature/configsSlice';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import { Checkout_text } from '../../../constant/Translation/Cart_text';
import { dynamic_translation_set } from '../../../constant/Translation/translation_set';
import { getThumbnailImage } from '../../Shared/Constant/Constant';
import CustomLoading from '../../Shared/Loading/CustomLoading';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';
import NoCartItem2 from '../NoCartItem/NoCartItem2';

const GlobalCartItemBox = ({ styles, data }) => {
  const [showError, setShowError] = useState(false);
  const [error_message, setError_message] = useState('');
  const [isLoading, setISLoading] = useState(false);

  const { MARKETPLACE_MODULES, listings_configs } =
    useSelector(configsSelector);

  const dispatch = useDispatch();
  const {
    isError,
    isSuccess,
    errorMessage,
    currencies,
    cart,
    cart_details,
    isFetching,
  } = useSelector(cartSelector);

  //
  useEffect(() => {
    dispatch(getCurrencies({}));
  }, []);

  //
  const updateCartQuantity = (listing, quantity, increase) => {
    setISLoading(true);

    let cartData;
    if (increase) {
      if (quantity < listing.max_quantity) {
        if (
          (quantity === listing.stock || quantity > listing.stock) &&
          listings_configs?.enable_stock
        ) {
          setShowError(true);
          setError_message(Checkout_text.cart.alert_stock_available);

          return false;
        } else {
          cartData = {
            cart: {
              listing_id: listing.id,
              quantity: quantity + 1,
            },
          };
        }
      } else {
        setShowError(true);
        setError_message(
          `${Checkout_text.cart.max_quantity}: ${listing.max_quantity} `
        );
        return false;
      }
    } else {
      if (quantity > 1) {
        cartData = {
          cart: {
            listing_id: listing.id,
            quantity: quantity - 1,
          },
        };
      } else {
        delete_cart(listing.id);
        return false;
      }
    }
    dispatch(addToCart({ data: cartData })).then((res) => {
      if (!res.payload.code) {
        dispatch(cartList({ currency: currencies[0]?.code }));
        setISLoading(false);
      }
    });
  };

  const delete_cart = (id) => {
    setISLoading(true);
    dispatch(
      deleteCart({
        data: {
          cart: {
            listing_id: [id],
          },
        },
      })
    ).then((res) => {
      if (!res.payload.code) {
        dispatch(cartList({ currency: currencies[0]?.code }));
        setISLoading(false);
      }
    });
  };
  const closePopUP = () => {
    dispatch(clearCartState());
    setShowError(false);
    setError_message('');
  };

  return cart_details ? (
    <div style={get_dynamic_styles(styles?.main_styles)}>
      {isLoading && <CustomLoading />}
      {(showError || isError) && (
        <OutsideClickHandler
          onOutsideClick={() => {
            (showError || isError) &&
              (setShowError(false),
              setError_message(''),
              dispatch(clearCartState()));
          }}
        >
          <div className="fixed z-50 top-0 left-0 right-0  w-full  xs:w-[500px] mx-auto mt-5">
            <div className="">
              <PopUp
                message={error_message || errorMessage}
                closePopUP={closePopUP}
              />
            </div>
          </div>
        </OutsideClickHandler>
      )}

      {/*  */}
      <div className="flex flex-col ">
        {cart_details.map((cartItem) => {
          return cartItem.variant ? (
            <div
              className="   flex items-center "
              style={get_dynamic_styles(styles?.cart_item_styles)}
              key={cartItem.id}
            >
              {/* Image */}
              {data?.show_image && (
                <div className=" flex-none h-full  relative   ltr:mr-3 rtl:ml-3 ">
                  {(cartItem.listing?.images?.length > 0 ||
                    cartItem?.variant.images?.length > 0) && (
                    <img
                      src={
                        cartItem?.variant.images?.length > 0
                          ? cartItem?.variant.images[0]
                          : getThumbnailImage(cartItem.listing.images[0])
                      }
                      className="w-[100px] h-[100px]  rounded-image  object-cover"
                      alt="Order Items"
                    />
                  )}
                </div>
              )}
              <div className="flex-grow flex flex-col xs:flex-row xs:items-center justify-start flex-wrap sm:flex-nowrap gap-2">
                <div className="flex-grow">
                  {listings_configs?.enable_stock && data?.show_stock && (
                    <p className=" text-xs  font-semibold leading-6 text-primary">
                      {dynamic_translation_set(
                        Checkout_text.cart.in_stock,
                        cartItem?.variant.stock
                      )}
                    </p>
                  )}

                  {data?.show_title && (
                    <p className=" text-base text-black font-semibold mt-[2px]">
                      {cartItem?.variant?.title
                        ? cartItem?.variant?.title
                        : cartItem.listing.title}
                    </p>
                  )}

                  {data?.show_title && (
                    <div className="flex flex-wrap items-center justify-start gap-2">
                      {cartItem?.variant?.variant_values?.map(
                        (vr_value, index, array) => {
                          return (
                            <p
                              key={vr_value.variant_type.id}
                              className=" mt-[8px] text-default_gray text-xs font-medium flex flex-wrap items-center"
                            >
                              {vr_value.variant_type.name}:{' '}
                              {vr_value.variant_type_value.name}
                              {index !== array?.length - 1 && ','}
                            </p>
                          );
                        }
                      )}
                    </div>
                  )}

                  {TYPE_CONSTANT.MARKETPLACE_FLAVOURS !== 2 &&
                    cartItem?.listing?.account?.name &&
                    data?.show_account_name && (
                      <p className=" mt-[8px] text-default_gray text-xs font-medium flex flex-wrap items-center">
                        {Checkout_text.cart.by}{' '}
                        {cartItem.listing?.account?.name}
                      </p>
                    )}
                  {data?.show_price && (
                    <p className=" mt-[11px] text-primary text-xs font-bold flex flex-wrap items-center    ">
                      {cartItem?.variant?.list_price.formatted}
                    </p>
                  )}
                </div>
                {data?.show_action_buttons && (
                  <div className="  flex-none   flex   items-center  gap-3">
                    <div className="  min-w-[90px] h-[32px] border border-primary rounded-button  flex justify-between items-center ">
                      <button
                        onClick={() =>
                          updateCartQuantity(
                            cartItem.listing,
                            cartItem.quantity,
                            false
                          )
                        }
                        className=" w-[32px] h-[32px] bg-primary  ltr:rounded-l-button rtl:rounded-r-button flex justify-center items-center text-xl leading-6 font-medium text-white"
                      >
                        -
                      </button>
                      <span className=" text-sm leading-4 font-medium text-[#4A4A4A] mx-3">
                        {cartItem.quantity}
                      </span>
                      <button
                        onClick={() =>
                          updateCartQuantity(
                            cartItem.listing,
                            cartItem.quantity,
                            true
                          )
                        }
                        className="w-[32px] h-[32px] bg-primary  ltr:rounded-r-button rtl:rounded-l-button flex justify-center items-center text-xl leading-6 font-medium  text-white "
                      >
                        +
                      </button>
                    </div>
                    <div className=" ">
                      <button
                        className="w-[32px] h-[32px] bg-primary   flex justify-center items-center text-xl leading-6 font-medium  text-white  rounded"
                        onClick={() => delete_cart(cartItem.listing.id)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                )}
              </div>

              {TYPE_CONSTANT.SHIPPING_CONFIGS
                .fulfillment_by_platform_enabled && (
                <div
                  className="  absolute top-2  ltr:right-2 rtl:left-2"
                  onClick={() => like(item.id, item.liked)}
                >
                  {cartItem.listing?.fulfilled_by_platform &&
                    TYPE_CONSTANT.SHIPPING_CONFIGS
                      .fulfilled_by_platform_icon_path && (
                      <img
                        className="w-auto h-auto max-w-[80px]"
                        src={
                          TYPE_CONSTANT.SHIPPING_CONFIGS
                            .fulfilled_by_platform_icon_path
                        }
                        alt="fullfilled by platform icon"
                      />
                    )}
                  {!cartItem.listing?.fulfilled_by_platform &&
                    TYPE_CONSTANT.SHIPPING_CONFIGS
                      .fulfilled_by_seller_icon_path && (
                      <img
                        className="w-auto h-auto max-w-[80px]"
                        src={
                          TYPE_CONSTANT.SHIPPING_CONFIGS
                            .fulfilled_by_seller_icon_path
                        }
                        alt="fulfilled by seller icon"
                      />
                    )}
                </div>
              )}
            </div>
          ) : (
            <div
              className="   flex items-center "
              style={get_dynamic_styles(styles?.cart_item_styles)}
              key={cartItem.id}
            >
              {/* Image */}
              {data?.show_image && (
                <div className=" flex-none h-full  relative   ltr:mr-3 rtl:ml-3 ">
                  {cartItem.listing?.images?.length > 0 && (
                    <img
                      src={getThumbnailImage(cartItem.listing.images[0])}
                      className="w-[100px] h-[100px]  rounded-image  object-cover"
                      alt="Order Items"
                      onError={(e) => {
                        e.target.src = cartItem.listing.images[0];
                      }}
                    />
                  )}
                </div>
              )}

              <div className="flex-grow flex flex-col xs:flex-row xs:items-center justify-start flex-wrap sm:flex-nowrap gap-2">
                <div className="flex-grow">
                  {listings_configs?.enable_stock && data?.show_stock && (
                    <p className=" text-xs  font-semibold leading-6 text-primary">
                      {dynamic_translation_set(
                        Checkout_text.cart.in_stock,
                        cartItem.listing.stock
                      )}
                    </p>
                  )}
                  {data?.show_title && (
                    <p className=" text-base text-black font-semibold mt-[2px]">
                      {cartItem.listing.title}
                    </p>
                  )}
                  {TYPE_CONSTANT.MARKETPLACE_FLAVOURS !== 2 &&
                    cartItem?.listing?.account?.name &&
                    data?.show_account_name && (
                      <p className=" text-default_gray text-xs font-medium flex flex-wrap items-center">
                        {Checkout_text.cart.by}{' '}
                        {cartItem.listing?.account?.name}
                      </p>
                    )}
                  {data?.show_price && (
                    <p className=" mt-[11px] text-primary   font-medium flex flex-wrap items-center text-sm ">
                      {cartItem.listing.list_price.formatted}
                    </p>
                  )}
                  {/* tax */}
                  {data?.show_taxes && (
                    <div className=" mt-0 flex flex-col gap-2">
                      {cartItem?.listing?.listing_taxes?.map((it) => {
                        return (
                          <p className="  text-primary   font-medium flex flex-wrap items-center text-sm ">
                            +{it.amount.formatted} ({it.note})
                          </p>
                        );
                      })}
                    </div>
                  )}
                </div>
                {data?.show_action_buttons && (
                  <div className="  flex-none   flex   items-center  gap-3">
                    <div className="  min-w-[90px] h-[32px] border border-primary rounded-button  flex justify-between items-center ">
                      <button
                        onClick={() =>
                          updateCartQuantity(
                            cartItem.listing,
                            cartItem.quantity,
                            false
                          )
                        }
                        className=" w-[32px] h-[32px] bg-primary  ltr:rounded-l-button rtl:rounded-r-button flex justify-center items-center text-xl leading-6 font-medium text-white"
                      >
                        -
                      </button>
                      <span className=" text-sm leading-4 font-medium text-[#4A4A4A] mx-3">
                        {cartItem.quantity}
                      </span>
                      <button
                        onClick={() =>
                          updateCartQuantity(
                            cartItem.listing,
                            cartItem.quantity,
                            true
                          )
                        }
                        className="w-[32px] h-[32px] bg-primary  ltr:rounded-r-button rtl:rounded-l-button flex justify-center items-center text-xl leading-6 font-medium  text-white "
                      >
                        +
                      </button>
                    </div>
                    <div className=" ">
                      <button
                        className="w-[32px] h-[32px] bg-primary   flex justify-center items-center text-xl leading-6 font-medium  text-white  rounded"
                        onClick={() => delete_cart(cartItem.listing.id)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                )}
              </div>

              {TYPE_CONSTANT.SHIPPING_CONFIGS
                .fulfillment_by_platform_enabled && (
                <div
                  className="  absolute top-2  ltr:right-2 rtl:left-2"
                  onClick={() => like(item.id, item.liked)}
                >
                  {cartItem.listing?.fulfilled_by_platform &&
                    TYPE_CONSTANT.SHIPPING_CONFIGS
                      .fulfilled_by_platform_icon_path && (
                      <img
                        className="w-auto h-auto max-w-[120px]"
                        src={
                          TYPE_CONSTANT.SHIPPING_CONFIGS
                            .fulfilled_by_platform_icon_path
                        }
                        alt="fullfilled by platform"
                      />
                    )}
                  {!cartItem.listing?.fulfilled_by_platform &&
                    TYPE_CONSTANT.SHIPPING_CONFIGS
                      .fulfilled_by_seller_icon_path && (
                      <img
                        className="w-auto h-auto max-w-[120px]"
                        src={
                          TYPE_CONSTANT.SHIPPING_CONFIGS
                            .fulfilled_by_seller_icon_path
                        }
                        alt="fulfilled by seller"
                      />
                    )}
                </div>
              )}
            </div>
          );
        })}
      </div>

      {/*  */}

      {cart_details && cart_details?.length == 0 && <NoCartItem2 />}
    </div>
  ) : (
    <div className="border bg-[#3B3269] bg-opacity-[10%] shadow rounded-card   p-4   w-full mx-auto">
      <div className="animate-pulse flex space-x-4">
        <div className="flex-1 space-y-4 py-1">
          <div className="h-4 bg-[#3B3269] bg-opacity-[20%] rounded-card  w-3/4"></div>
          <div className="space-y-2">
            <div className="h-4 bg-[#3B3269] bg-opacity-[20%] rounded"></div>
            <div className="h-4 bg-[#3B3269] bg-opacity-[20%] rounded-card  w-5/6"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlobalCartItemBox;
