import { General_text } from '../../constant/Translation/General';
import { FileINputType, TextINputType } from '../../types/FormFieldTypes';
import { cn } from '../../utils/classNames';

const FileInput = ({
  title,
  current_value,
  set_new_value,
  note,
  is_required = true,
  is_disabled = false,
  onClick,
  onKeyDown,
  title_icon,
  styles,
  accepted_file,
  add_file_title,
  max_size,
  min_size,
  setIsError,
  setErrorMessage,
  delete_file,
  id = 'fileButton',
}: FileINputType) => {
  return (
    <div
      className={cn(' w-full flex flex-col gap-1 ', styles?.component_styles)}
    >
      {/* Title */}
      {title && (
        <p
          className={cn(
            ' text-gray-700  text-lg text-start flex items-center justify-start gap-2',
            styles?.title_styles
          )}
        >
          {title_icon && <span>{title_icon}</span>}
          {title}
        </p>
      )}

      <input
        id={id}
        type="file"
        className="hidden"
        accept={accepted_file ?? '.pdf, .jpeg, .jpg,.png'}
        placeholder=""
        onChange={(e) => {
          if (e.target.files?.length) {
            const fix_type = accepted_file.split(',');
            const file_name = e.target.files[0].name;
            const file_size = e.target.files[0].size / 1024 / 1024;

            if (
              fix_type.includes(
                `.${file_name.split('.')[file_name.split('.').length - 1]}`
              )
            ) {
              if (file_size <= max_size && file_size >= min_size) {
                set_new_value(e.target.files);
              } else {
                if (file_size > max_size) {
                  setIsError(true);
                  setErrorMessage(
                    `File size exceeds maximum limit ${max_size} MB`
                  );
                  return false;
                } else if (file_size < min_size) {
                  setIsError(true);
                  setErrorMessage(`Minimum file size is ${min_size} KB`);
                }
              }
            } else {
              setIsError(true);
              setErrorMessage('Select valid file');
              return false;
            }
          }
        }}
      />

      {current_value !== null && (
        <div className=" relative mt-4 flex w-full flex-col items-center border-2 border-dashed border-primary py-2">
          <p>{current_value}</p>
          <button
            className="mt-2 text-sm font-normal text-red-400 "
            onClick={(e) => {
              e.stopPropagation();
              delete_file();
            }}
          >
            {General_text.general.cancel}
          </button>
        </div>
      )}

      {current_value == null && (
        <button
          className={cn(
            ' mt-3 flex w-full items-center justify-center rounded-buttonborder-2 border-dashed  border-primary  bg-gray-100  py-10 text-base  font-semibold ',
            styles?.add_button_styles
          )}
          onClick={() => document.getElementById(id).click()}
        >
          {add_file_title}
        </button>
      )}
    </div>
  );
};

export default FileInput;
