import React, { useState } from 'react';
import {
  getListingComments,
  listingSelector,
  postListingComment,
} from '../../../store/feature/listingSlice';
import { useDispatch, useSelector } from 'react-redux';
import { loading_icon } from '../../Shared/Constant/Icons/AllIcons';
import { useRouter } from 'next/router';
import { authSelector } from '../../../store/feature/authSlice';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';
import Composer2 from '../../Shared/Composer/Composer2';

const CommentBox = ({ listing_details, styles, parent, onFinish }) => {
  const dispatch = useDispatch();
  const { comments, isFetching, isCommentsLoading } =
    useSelector(listingSelector);
  const router = useRouter();
  const { login } = useSelector(authSelector);
  //
  const [message, setMessage] = useState('');
  //
  const post_comment = (e) => {
    if (!login) {
      router.push(`/sign-in?to=${router.asPath}`);
      return false;
    }
    let post_data = {
      reference_id: listing_details?.id,
      reference_type: 'listings_comments',
      message: message,
    };

    if (parent) {
      post_data.parent = parent;
    }

    dispatch(
      postListingComment({
        params: { comment: post_data },
      })
    ).then((res) => {
      if (!res.payload.code) {
        dispatch(
          getListingComments({
            params: {
              reference_type: 'listings_comments',
              reference_id: listing_details?.id,
              parent: 0,
            },
          })
        );

        setMessage('');
        onFinish && onFinish();
      }
    });
  };

  return (
    <div
      className=" relative   "
      style={get_dynamic_styles(styles?.comment_box)}
    >
      <Composer2
        contents={message}
        setContents={(value) => setMessage(value)}
        mainClass={'   min-h-full h-full'}
      />
      <button
        onClick={() => post_comment()}
        className="btn-primary-fill absolute bottom-2 right-2 "
      >
        Comment
        {isCommentsLoading && loading_icon}
      </button>
    </div>
  );
};

export default CommentBox;
