/* eslint-disable react/prop-types */
import React from 'react';
import { Listing_text } from '../../../constant/Translation/Listing';
import { get_dynamic_styles_with_default_value } from '../../../constant/DynamicStylesWithDefaultValue';
import {
  CardBoxDefaultStyles,
  CardMainDefaultStyles,
} from '../../../constant/default_styles/ProductDetailsStyles';

const ListingTaxDetails = ({ listing_details, styles, data }) => {
  return (
    <div
      className="    w-full  mt-6  "
      style={get_dynamic_styles_with_default_value(
        styles?.main_styles,
        CardMainDefaultStyles
      )}
    >
      <div
        style={get_dynamic_styles_with_default_value(
          styles?.box_styles,
          CardBoxDefaultStyles
        )}
      >
        <h2 className="heading-h2">{Listing_text.product.listing_tax}</h2>

        <div className="grid grid-cols-2 gap-2 items-center  mt-4   ">
          {listing_details?.listing_taxes?.map((it) => {
            return (
              <>
                <p className="description-paragraph">{it?.title}</p>
                <p className="  whitespace-pre-line  description-paragraph  overflow-auto  scrollbar-none">
                  {it?.default_value}%
                </p>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ListingTaxDetails;
