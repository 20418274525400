import Image from 'next/image';
import favorite from '../../../../assets/Images/Home/favourite@3x.png';
import heartIcon from '../../../../assets/Images/Home/heartIcon@3x.png';
import { changeDateFormat, getThumbnailImage } from '../../Constant/Constant';
import { useRouter } from 'next/dist/client/router';
import Link from 'next/link';
import { TYPE_CONSTANT } from '../../../../constant/Web_constant';
import { Listing_text } from '../../../../constant/Translation/Listing';

const TableTypeCard = ({ product, like, is_hide_like_btn }) => {
  const listing_card_styles = TYPE_CONSTANT.STYLES_CONFIGS?.listing_card;

  const { goal_price, donation_analytics } = product || {};

  const funded_percentage = Math.round(
    (donation_analytics?.total_amount?.amount / goal_price?.amount) * 100
  )
    ? Math.round(
        (donation_analytics?.total_amount?.amount / goal_price?.amount) * 100
      )
    : 0;

  return (
    <div className="group relative ml-[--listingCardMarginLeft]">
      <Link
        href={
          product.slug
            ? `/l/${product.slug}`
            : `/l/${product.id}-${product.title.replace(
                /[ /?,#=]+/g,
                '-'
              )}?id=true`
        }
      >
        <a
          className="listing-card flex"
          style={{
            flexDirection: 'var(--listingCardFlexDirection)',
            flexWrap: 'var(--listingCardFlexWrap)',
            alignItems: 'var(--listingCardAlignItems)',
            alignContent: 'var(--listingCardAlignContents)',
            justifyContent: 'var(--listingCardJustifyContent)',
          }}
        >
          {/*  */}
          <div className=" w-full  flex items-center  justify-between  ">
            {/* Title */}
            {listing_card_styles?.title?.enabled !== false && (
              <p className=" listing-card-title ">{product.title}</p>
            )}

            {/* Price */}
            {listing_card_styles?.price?.enabled !== false && (
              <p className="  listing-card-price">
                <span className="   ">{product.offer_price.formatted}</span>

                {product?.offer_percent > 0 && (
                  <>
                    <span className="    line-through ">
                      {product.list_price.formatted}
                    </span>
                    <span className="  text-error ">
                      {product?.offer_percent}%
                    </span>
                  </>
                )}
              </p>
            )}
          </div>
        </a>
      </Link>
    </div>
  );
};

export default TableTypeCard;
