/* eslint-disable react/prop-types */
import React from 'react';
import Image from 'next/image';
import { useSelector } from 'react-redux';
import { configsSelector } from '../../../store/feature/configsSlice';
import * as constant from '../../Shared/Constant/TextConstant/listingDetailsConstant';
import { dynamic_translation_set } from '../../../constant/Translation/translation_set';
import { Listing_text } from '../../../constant/Translation/Listing';
import SelectDropDown from '../../Shared/SelectDropDown';
import SelectDropDown2 from '../../Shared/SelectDropDown2';
import ListVariant from './ListTypeVariant';
import DropdownTypeVariants from './DropdownTypeVariants';
import ButtonTypeVariant from './ButtonTypeVariant';
import ButtonTypeVariant2 from './ButtonTypeVariant2';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import { get_dynamic_styles_with_default_value } from '../../../constant/DynamicStylesWithDefaultValue';
import {
  CardBoxDefaultStyles,
  CardMainDefaultStyles,
} from '../../../constant/default_styles/ProductDetailsStyles';
const Variants = ({
  variants,
  setSelectedVariant,
  selectedVariant,
  listing_details,
  styles,
}) => {
  const { MARKETPLACE_MODULES, listings_configs } =
    useSelector(configsSelector);

  return (
    <div
      className="    w-full  "
      style={get_dynamic_styles_with_default_value(
        styles?.main_styles,
        CardMainDefaultStyles
      )}
    >
      <div
        className=""
        style={get_dynamic_styles_with_default_value(
          styles?.box_styles,
          CardBoxDefaultStyles
        )}
      >
        <h2 className="heading-h2">{Listing_text.product.select_variant}</h2>
        <div className="  mt-4   flex flex-col gap-4  ">
          {/* By default list type variant will show  */}
          {(TYPE_CONSTANT.LISTINGS_CONFIGS?.variants_selection == 'list' ||
            TYPE_CONSTANT.LISTINGS_CONFIGS?.variants_selection ==
              undefined) && (
            <ListVariant
              variants={variants}
              selectedVariant={selectedVariant}
              setSelectedVariant={setSelectedVariant}
              listings_configs={listings_configs}
              listing_details={listing_details}
            />
          )}

          {TYPE_CONSTANT.LISTINGS_CONFIGS?.variants_selection == 'dropdown' && (
            <DropdownTypeVariants
              variants={variants}
              selectedVariant={selectedVariant}
              setSelectedVariant={setSelectedVariant}
            />
          )}
          {TYPE_CONSTANT.LISTINGS_CONFIGS?.variants_selection == 'text' && (
            <ButtonTypeVariant2
              variants={variants}
              selectedVariant={selectedVariant}
              setSelectedVariant={(item) => setSelectedVariant(item.id)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Variants;
