import Link from 'next/link';
import { cn } from '../../../utils/classNames';

const ALink = ({
  children,
  href,
  type = '_parent',
  styles,
}: {
  children: React.ReactElement;
  href: string;
  type: string;
  styles?: {
    link: string;
  };
}) => {
  return (
    <Link href={href}>
      <a type={type} className={cn(' ', styles?.link)}>
        {children}
      </a>
    </Link>
  );
};

export default ALink;
