export const replaceSpecificStyles = ({
  styles,
  remove_keys,
  initial_values,
}) => {
  return Object.entries(styles).reduce(
    (prev, current) => {
      if (remove_keys[current[0]]) {
        return { ...prev, [current[0]]: remove_keys[current[0]] };
      } else {
        return { ...prev, [current[0]]: current[1] };
      }
    },
    initial_values ? initial_values : {}
  );
};
